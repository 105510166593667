import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { ConnectedRouter } from 'connected-react-router'

import { requests } from '../sagas/rootSaga';
// import { socket } from './socketio';

import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

import { PACRoot } from 'containers/pac/PACRoot'
import { LoginRoot } from 'containers/login/LoginRoot'
import { NurseNavRoot } from 'containers/nurseNav/NurseNavRoot'
import { HelpDeskRoot } from 'containers/helpDesk/HelpDeskRoot'
import NavigationUtil from 'util/NavigationUtil';

import {closeSignOutWarningPopUp} from 'actions/loginActions'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-129296270-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(){
    // socket.on('update', obj => {
    //   const {
    //     dispatch,
    //     liveUpdate
    //   } = this.props;
    //   if(liveUpdate){
    //     requests.dbRequests.forEach(data => {
    //       if(obj.model === "mulitple" || data.request.url === `/${obj.model}`){
    //         dispatch(data)
    //       }
    //     });
    //   }
    // })
  }

  handleClose() {
    const { dispatch } = this.props;
    dispatch(closeSignOutWarningPopUp());
  }
  render() {
    const {
      history,
      user,
      hasTriggeredSignOutWarning
    } = this.props;

    let content;
    /*
      four different interfaces can be shown:
      1)  Login interface, shown when user is unknown, or logged out
      2)  Nurse nav interface, user must be signed in and be a navigator
      3)  PAC interface, user must be signed in, not be a navigator, or be a navigator opting to view
      4)  Help desk interface, user must be signed, no nav or pac, with help desk permission
    */

    const currentApp = NavigationUtil.getCurrentApp(user);
    switch(currentApp){
    case NavigationUtil.nav:
      //nurse nav
      content = (
        <NurseNavRoot />
      )
      break; 
    case NavigationUtil.pac:
      //pac
      content = (
        <PACRoot />
      )
      break;
    case NavigationUtil.helpDesk:
      //help desk
      content = (
        <HelpDeskRoot />
      )
      break;
    default:
      //login
      content = (
        <LoginRoot />
      )
    }

    const logOutAlert = (<React.Fragment>
      <Dialog
        open={hasTriggeredSignOutWarning}
        onClose={this.handleClose}
      >
        <DialogTitle>You will be signed out due to inactivity in 2 minutes</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary"> STILL HERE? </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>);

    return (
      <ConnectedRouter history={history}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {logOutAlert}
          {content}
        </MuiPickersUtilsProvider>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  hasTriggeredSignOutWarning: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  liveUpdate: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const {
    user,
    hasTriggeredSignOutWarning,
    liveUpdate
  } = state;
  return {
    user,
    hasTriggeredSignOutWarning,
    liveUpdate
  };
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};
