import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { PatientTable } from 'components/PatientTable';
import { tablePatients, viewPatient } from 'actions/patientActions';
import StoreUtil from 'stores/StoreUtil'
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';

const styles = (theme) => ({
  pageContainer:{
    width: "100%",
    marginTop: 20
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }

});
const TABLE_NAME = "PatientsPage";
class PatientsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidUpdate(prevProps) {
    //has the url query string changed and do we need to request new patients?
    if (this.props.location.search != prevProps.location.search) {
      const clearingPreviousFilters = true;
      this.fetchInitialPatients(clearingPreviousFilters);
    }
  }

  componentDidMount() {
    const clearingPreviousFilters = true;
    // clear filters when on page
    this.fetchInitialPatients(clearingPreviousFilters);
  }

  fetchInitialPatients(clearingPreviousFilters = false) {
    this.setState({query: this.buildInitialQuery(clearingPreviousFilters) }, this.fetchPatients.bind(this));
  }

  buildInitialQuery(clearingPreviousFilters = false) {
    const {
      location,
      preferenceQuery
    } = this.props;

    let query = {
      where: { },
      limit: 25,
      offset: 0
    };

    if(preferenceQuery){
      query = preferenceQuery
      query.where = query.where || {}; // if there isn't a where object in the query, create an empty one
    }

    if (location.search && location.search.length > 0) {
      let search = location.search;

      if (search.substring(0,1) == "?") {
        search = search.substring(1,search.length);
      }

      if (parseInt(search) > 0) {
        query.where["MedicalRecNum"] = {$like:"%"+search+"%"};
        if(clearingPreviousFilters){
          delete query.where.$and;
          delete query.where.FirstName;
          delete query.order;
        }
      } else {
        let nameSearch = [];
        search.replace(',', '').replace('  ', ' ').split(' ').forEach(function(term) {
          nameSearch.push({
            $or: [
              { LastName: { $like:"%" + term + "%" } },
              { FirstName: { $like:"%" + term + "%" } }
            ]
          });
        });

        query.where.$and = nameSearch;
        if(clearingPreviousFilters){
          delete query.where["MedicalRecNum"];
          delete query.order;
        }
      }
    }

    return query;
  }

  fetchPatients() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(tablePatients(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchPatients.bind(this));
  }

  onRowClick(patient) {
    const { dispatch } = this.props;
    dispatch(viewPatient(patient));
  }

  render() {
    const {
      classes,
      patients
    } = this.props;

    const {
      query
    } = this.state;

    return (
      <div className={classes.pageContainer}>
        <PatientTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Patients</Typography>
            </div>
          }
          data={patients}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
        />

      </div>
    );
  }
}

PatientsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  patients: PropTypes.object.isRequired,
  location: PropTypes.object,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const { patients, preference } = state;

  return {
    patients: StoreUtil.get(patients, StoreUtil.COMMON_TABLE),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(PatientsPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PatientsPage};
