import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';

import DateUtil from 'util/DateUtil';
import DataUtil from 'util/DataUtil';

const styles = ({
});


class ConversationsTable extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {label:"Last", key: "PatientLastName", sortable: true, sortDown: false, style:{width:160}},
      {label:"First", key: "PatientFirstName", sortable: true, sortDown: false, style:{width:160}},
      {label:"DOS", key: "SurgeryDateTime", type:"date", sortable: true, sortDown: false,
        filter: true, format:DateUtil.formatFromDB, style:{width:100}},
      {label:"Admitted", key: "AdmissionDate", sortable: true, type:"date", sortDown: false,
        format:DateUtil.formatFromDB, style:{width:80}},
      {label:"Anticipated DC", key: "AnticipatedDischargeDate", type:"date",
        sortable: true, sortDown: false,
        format:DateUtil.formatFromDB, style:{width:120}},
      {label:"Navigator", key: "NavigatorLastName", format:this.navigatorFormat, sortable: true, style:{width:125}},
      {label:"Latest Message", key: "LastMessageDate", filterKey: "LastMessageText",
        format: this.messageFormat, sortable: true, filter: true},
      {label:"Status", key: "Status", sortable: true}
    ];

    this.columnsPlan = this.columns.slice();
    this.columnsPlan.splice(6, 0, {
      label:"Extension",
      key: "LosExtension",
      sortable: true,
      style:{width:70}
    });

    this.columnsAdmission = [
      {label:"Type", key: "ConversationType", format:this.typeFormat, sortable: true, style:{width:150}},
      {label:"Latest Message", key: "LastMessageDate", filterKey: "LastMessageText",
        format: this.messageFormat, sortable: true, filter: true},
      {label:"Status", key: "Status", sortable: true, style:{width:150}}
    ];
  }

  typeFormat(type) {
    let name = "";
    if(type == DataUtil.CONVERSATION_TYPE_PLAN_CHANGE){
      name = "Plan Change Rationale";
    }else{
      name = "Navigator Request";
    }
    return name;
  }

  messageFormat(data, row) {
    let message = "";
    let header = "";
    let date ="";

    if(row.LastMessageDate){
      date = DateUtil.formatDateTimeFromDB(row.LastMessageDate)+' ';
    }

    if(row.LastMessageLastName){
      if(row.LastMessageFirstName){
        header += row.LastMessageFirstName+" ";
      }
      header += row.LastMessageLastName;
      header += ": ";
    }
    if(row.LastMessageText){
      message += row.LastMessageText;
    }

    const isWebkit = 'WebkitAppearance' in document.documentElement.style;
    if(!isWebkit && message.length > 30){
      message = message.substring(0,60)+"...";
    }
    return (
      <React.Fragment>
        <span>{date}
          <b>{header}</b>
        </span>
        <br/>
        <span className="message-preview">{message}</span>
      </React.Fragment>
    )
  }

  navigatorFormat(date, row) {
    let name = "";
    if(row.NavigatorFirstName){
      name = row.NavigatorFirstName+" "+row.NavigatorLastName;
    }else{
      name = row.NavigatorLastName;

    }
    return name;
  }

  render() {
    const {
      isAdmissionMode,
      isPlanMode
    } = this.props;

    let usedColumns;
    if(isAdmissionMode){
      usedColumns = this.columnsAdmission;
    }else if(isPlanMode){
      usedColumns = this.columnsPlan;
    }else{
      usedColumns = this.columns;
    }
    return <DynamicTable {...this.props} columns={usedColumns}/>
  }
}

ConversationsTable.propTypes = {
  isPlanMode: PropTypes.bool.isRequired,
  isAdmissionMode: PropTypes.bool
};

const styled = withStyles(styles)(ConversationsTable);
export { styled as ConversationsTable };
