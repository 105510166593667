import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import StoreUtil from 'stores/StoreUtil';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class SurgeonBCItem extends Component {

  render() {
    const {
      surgeon,
      match
    } = this.props;

    let title = "";
    if(match.params.id == 0){
      title = "New Surgeon";
    }else if (surgeon && StoreUtil.getData(surgeon)) {
      let surgeonData = StoreUtil.getData(surgeon);
      title = surgeonData.Name;
    }

    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          path={NavigationUtil.navLink("/surgeons/"+match.params.id)}
        />
      </React.Fragment>
    );
  }
}

SurgeonBCItem .propTypes = {
  surgeon: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  const { match } = props;
  const { surgeons } = state;

  return {
    surgeon : StoreUtil.get(surgeons, StoreUtil.COMMON_ITEM, match.params.id)
  };
}

const connected = connect(mapStateToProps)(SurgeonBCItem);
export {connected as SurgeonBCItem };