import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
  },
  icon :{
    marginRight: theme.spacing.unit,
    fontSize: 20
  },
  iconNoText :{
    fontSize: 20
  }
});

class BreadcrumbItem extends Component {

  render() {
    const {
      classes,
      label,
      icon,
      path
    } = this.props;

    let iconCls = classes.icon;
    if(!label){
      iconCls = classes.iconNoText;
    }
    
    return (
      <Button
        color="inherit"
        className={classes.button}
        size="small"
        component={Link}
        to={path}
        id={path}
      >
        {icon && <Icon className={iconCls}>{icon}</Icon>}
        {label}
      </Button>
    );
  }
}

BreadcrumbItem.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string.isRequired
};

const styled = withStyles(styles)(BreadcrumbItem);
export {styled as BreadcrumbItem};