import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { listFacilities } from 'actions/facilityActions';
import StoreUtil from 'stores/StoreUtil';
import { MessageForm } from 'components/forms/MessageForm';
import {
  listNavTemplateMessages,
  requestForAdmission
} from 'actions/NavTemplateMessagesActions';

import {
  createNavMessagingLog
} from 'actions/navMessagingLogActions';

const schema = {
  type: 'object',
  required: ['Facility', 'Template', 'Message'],
  properties: {
    Facility: {type: 'string', title: 'Facility'},
    Template: {type: 'string', title: 'Template'},
    Message: {type: 'string', title: 'Message'}
  }
};

const uiSchema = {
  Facility: {},
  Template: {},
  Message: {
    "ui:widget": "textarea"
  }
};

class Messaging extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      formData: {},
      sentEmail: false
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(listFacilities());
    dispatch(listNavTemplateMessages());
  }

  onFormChange(data){
    const { navTemplateMessages } = this.props;
    // copy the form data without reference
    const { formData } = JSON.parse(JSON.stringify(data));
    let templateIndex;
    // set the message to the template body
    if((!formData.Message || formData.Message === "Please add template first. Thanks") && formData.Template){
      navTemplateMessages.forEach((data, index) => {
        if(`${data.DisplayLabel} - Subject: ${data.Subject}` === formData.Template){
          formData.Message = data.Body;
          templateIndex = index;
        }
      })
    }
    // disable the message box until a template is selected
    if(formData.Message && !formData.Template){
      formData.Message = "Please add template first. Thanks";
    }
    // set the formData in state
    this.setState({
      formData
    })

    if(templateIndex !== undefined){
      this.setState({
        templateIndex
      })
    }
  }

  onFormSubmit(){
    const {
      dispatch,
      navTemplateMessages,
      facilities,
      surgeryInfo,
      user
    } = this.props;
    const {
      formData
    } = this.state;
    const { Facility, Message } = formData;

    const emailReady = (Facility && Message.trim());
    if(emailReady){

      let email;
      facilities.data.forEach(data => {
        if(data.Name === Facility){
          email = data.PrimaryContactEmail
        }
      });

      dispatch(requestForAdmission({
        subject: navTemplateMessages[this.state.templateIndex].Subject,
        email,
        message: Message
      }));

      this.setState({
        sentEmail: true,
        formData: {
          ...this.state.formData,
          Message: "Message sent"
        }
      })

      let facilityId;
      facilities.data.forEach(data => {
        if(data.Name === Facility){
          facilityId = data.id
        }
      })

      const DateTimeSent = moment().valueOf();
      dispatch(createNavMessagingLog({
        SurgeryId: surgeryInfo.data.id,
        UserId: user.id,
        FacilityID: facilityId,
        FacilityName: Facility,
        Subject: navTemplateMessages[this.state.templateIndex].Subject,
        Body: Message,
        DateTimeSent
      }));
    }
  }

  onFormError(){}

  render() {
    const {
      facilities,
      navTemplateMessages
    } = this.props;

    if(!facilities.data || !navTemplateMessages.length){
      return (<div>{"loading..."}</div>)
    }

    schema.properties.Facility.enum = facilities.data.map(data => data.Name);
    schema.properties.Template.enum = navTemplateMessages.map(data =>{
      return `${data.DisplayLabel} - Subject: ${data.Subject}`;
    });

    return (
      <div style={{
        marginBottom: "16px",
        marginRight: "16px",
        marginTop: "8px"
      }}>
        <MessageForm
          schema={schema}
          uiSchema={uiSchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={this.state.formData}
          buttonText={this.state.sentEmail ? "EMAIL SENT" : "SEND MESSAGE TO PAC"}
        />
      </div>
    );
  }
}

Messaging.propTypes = {
  dispatch: PropTypes.func.isRequired,
  facilities: PropTypes.object.isRequired,
  navTemplateMessages: PropTypes.any.isRequired,
  surgeryInfo: PropTypes.object,
  user: PropTypes.object
}

function mapStateToProps(state) {
  const {
    facilities,
    navTemplateMessages,
    user
  } = state;

  return {
    facilities: StoreUtil.get(facilities, StoreUtil.COMMON_LIST),
    navTemplateMessages,
    user
  };
}

const connected = connect(mapStateToProps)(Messaging);
export { connected as Messaging };
