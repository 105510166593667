import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = () => ({
  container:{
  }
});

function CheckboxWidget(props) {
  const {
    schema,
    id,
    value,
    required,
    disabled,
    readonly,
    label,
    autofocus,
    onChange,
    rawErrors
  } = props;
  /*return (
    <div className={`checkbox ${disabled || readonly ? "disabled" : ""}`}>
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <label>
        <input
          type="checkbox"
          id={id}
          checked={typeof value === "undefined" ? false : value}
          required={required}
          disabled={disabled || readonly}
          autoFocus={autofocus}
          onChange={event => onChange(event.target.checked)}
        />
        <span>{label}</span>
      </label>
    </div>
  );*/
  let description = null;
  if(rawErrors != null){
    description = rawErrors;
  }else if(schema.description){
    description = schema.description;
  }

  return (
    <FormControl component="fieldset" required={required} error={rawErrors != null}>
      <FormControlLabel
        autoFocus={autofocus}
        disabled={disabled || readonly}
        control={
          <Checkbox
            id={id}
            checked={typeof value === "undefined" ? false : value}
            onChange={event => onChange(event.target.checked)}
          />
        }
        label={label}
      />
      {description &&
        <FormHelperText>{description}</FormHelperText>
      }
    </FormControl>
  );
}

CheckboxWidget.defaultProps = {
  autofocus: false
};

CheckboxWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.any,
  label: PropTypes.any
};

const styled = withStyles(styles)(CheckboxWidget);
export {styled as CheckboxWidget};