import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { push } from 'connected-react-router';
import Typography from '@material-ui/core/Typography';

import { PACHUDView } from 'containers/pac/PACHUDView';
import { ConversationsTable } from 'components/ConversationsTable';
import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil'
import { saveTablePreference } from 'actions/preferenceActions';
import { listConversations, viewConversation } from 'actions/messageActions';
import NavigationUtil from 'util/NavigationUtil';
import TableUtil from 'util/TableUtil';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  header: {
    marginTop:-theme.spacing.unit*5
  }
});
const TABLE_NAME = "PACConversationsPage";
class PACConversationsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };

    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    //task type changed in route
    if ((prevProps.planChangeMode != this.props.planChangeMode) || (prevProps.tab != this.props.tab) ) {

      this.fetchInitialConversations();
    }
  }

  componentDidMount() {
    this.fetchInitialConversations();
  }

  fetchInitialConversations() {
    this.setState({query: this.buildInitialQuery() }, this.fetchConversations.bind(this));
  }

  buildInitialQuery() {
    const {
      user,
      planChangeMode,
      tab
    } = this.props;
  
    let query = {
      limit: 25,
      offset: 0,
      order:[['LastMessageDate','DESC']],
      where:{}
    };
    const {
      preferenceQuery
    } = this.props;

    if(preferenceQuery){
      query = TableUtil.GetSavedQuery(preferenceQuery, tab);
    }

    query.where["FacilityId"] = user.FacilityId;

    delete query.where.Status;
    switch(tab){
    case 0:
      break;
    case 1:
      query.where["Status"] = {$eq: "Waiting on Provider"};
      break;
    case 2:
      query.where["Status"] = {$eq: "Waiting on Navigator"};
      break;
    case 3:
      query.where["Status"] = {$eq: "Resolved"};
    }

    if(planChangeMode){
      query.where.ConversationType = DataUtil.CONVERSATION_TYPE_PLAN_CHANGE;
    }else{
      query.where.ConversationType = DataUtil.CONVERSATION_TYPE_OTHER;
    }
    return query;
  }

  onTabChange(event, tabNew) {
    const { tab, dispatch, match } = this.props;
    if(tab == tabNew){
      return;
    }
    dispatch(push(NavigationUtil.pacLink(`/conversations/${match.params.conversationType}/${tabNew}`)));
  }

  fetchConversations() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listConversations(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;

    dispatch(saveTablePreference(TABLE_NAME, query, this.state.tab));
    this.setState({query: query}, this.fetchConversations.bind(this));
  }

  onRowClick(conversation) {
    const { dispatch } = this.props;
    dispatch(viewConversation(conversation.SurgeryId, conversation.AdmissionId, conversation.id, true));
  }

  render() {
    const {
      classes,
      conversations,
      planChangeMode,
      tab
    } = this.props;

    const {
      query
    } = this.state;

    let header;
    if(planChangeMode){
      header = "Plan Change Rationale";
    }else{
      header = "Other Messages";
    }

    return (
      <div className={classes.pageContainer}>
        <PACHUDView />
        <Typography
          variant="headline"
          className={classes.header}>
          {header}
        </Typography>
        <Tabs value={tab} onChange={this.onTabChange}>
          <Tab label="All" />
          <Tab label="Waiting for Provider" />
          <Tab label="Waiting for Navigator" />
          <Tab label="Closed" />
        </Tabs>
        <ConversationsTable 
          data={conversations}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          isPlanMode={planChangeMode}
        />

      </div>
    );
  }
}

PACConversationsPage.defaultProps = {
  tab : 0
};

PACConversationsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  conversations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  planChangeMode : PropTypes.bool.isRequired,
  preferenceQuery: PropTypes.object,
  tab: PropTypes.number.isRequired
};

function mapStateToProps(state, prevProps) {
  const { 
    messages,
    user,
    preference
  } = state;

  const {
    match
  } = prevProps;

  let tab = parseInt(match.params.tab);
  if(!tab){
    tab = 0;
  }

  const planChangeMode = match.params.conversationType == "plan";

  return {
    conversations: StoreUtil.get(messages, "conversations"),
    user,
    planChangeMode,
    preferenceQuery:preference.tables[TABLE_NAME],
    tab
  };
}

const styledPage = withStyles(styles)(PACConversationsPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACConversationsPage};
