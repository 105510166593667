import ReducerUtil from 'reducers/ReducerUtil'

import {
  API_SUBMIT_FEEDBACK
} from 'actions/feedbackActions';

export const feedback = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'feedback');
  if(logout.actionConsumed){
    return logout.state;
  }
  
  const update = ReducerUtil.reduceUpdate(state, action, API_SUBMIT_FEEDBACK);
  if(update.actionConsumed){
    return update.state;
  }
  return state;
}