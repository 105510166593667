import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { listSurgeries, viewSurgery } from 'actions/surgeryActions';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import StoreUtil from 'stores/StoreUtil';
import { saveTablePreference } from 'actions/preferenceActions';
import { SurgeryTable } from 'components/SurgeryTable';
import NavigationUtil from 'util/NavigationUtil'
import DataUtil from 'util/DataUtil';
import { getSurgeryFlags } from 'actions/surgeryFlagActions';

const styles = (theme) => ({
  lowRisk: {
    borderRadius: 5,
    backgroundColor: "#34a853",
    color: "#ffffff",
    padding: 4,
    textAlign:"center"
  },
  modRisk: {
    borderRadius: 5,
    backgroundColor: "#fbbc05",
    color: "#ffffff",
    padding: 4,
    textAlign:"center"
  },
  highRisk: {
    borderRadius: 5,
    backgroundColor: "#ea4335",
    color: "#ffffff",
    padding: 4,
    textAlign:"center"
  },
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const TABLE_NAME = "PatientSurgeriesView";
class PatientSurgeriesView extends React.Component {

  constructor(props) {
    super(props);
    this.state = { query: {} };
  }

  componentDidMount() {
    const {dispatch} = this.props;
    this.fetchInitialSurgeries();
    dispatch(getSurgeryFlags())
  }

  componentDidUpdate(prevProps) {
    //patient changed
    if (this.props.patientMRN != prevProps.patientMRN) {
      this.fetchInitialSurgeries();
    }
  }

  fetchInitialSurgeries() {
    this.setState({query: this.buildInitialQuery() }, this.fetchSurgeries.bind(this));
  }

  buildInitialQuery() {
    const {
      patientMRN,
      externalSource,
      preferenceQuery
    } = this.props;

    let query = {
      where: {
        MRN: patientMRN,
        ExternalSource: externalSource
      },
      limit: 25,
      offset: 0
    };

    if(preferenceQuery){
      query = preferenceQuery
      if(query.where && query.where.MRN){
        query.where.MRN = patientMRN;
      }
    }

    return query;
  }

  fetchSurgeries() {
    const { dispatch } = this.props;
    const { query } = this.state;
    dispatch(listSurgeries(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetchSurgeries.bind(this));
  }

  onRowClick(surgery) {
    const { dispatch } = this.props;
    dispatch(viewSurgery(surgery));
  }

  render() {
    const { 
      classes,
      surgeries,
      patientMRN,
      externalSource,
      user,
      surgeryFlag,
      navUsers
    } = this.props;
    
    const {
      query
    } = this.state;

    let linkTo = NavigationUtil.navLink("/surgery/0/edit/"+patientMRN);
    const isPTUser = DataUtil.isPTUser(user);

    return (
      <React.Fragment>
        <SurgeryTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Episodes</Typography>
              <Button
                color="primary"
                size="small"
                component={Link} to={linkTo}>
                <Icon className={classes.leftIcon}>add</Icon>
                  Add Episode
              </Button>
            </div>
          }
          data={surgeries}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          noDataMessage="No other surgeries for this patient were found."
          isPTUser={isPTUser}
          surgeryFlag={surgeryFlag}
          navUsers={navUsers}
        />
      </React.Fragment>
    );
  }

}

PatientSurgeriesView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  patientMRN: PropTypes.number.isRequired,
  externalSource: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  surgeries: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  surgeryFlag: PropTypes.array,
  navUsers: PropTypes.object.isRequired
}

function mapStateToProps(state, existingProps) {
  const { surgeries, preference, user, surgeryFlag, navUsers } = state;
  const { externalSource } = existingProps;



  return {
    surgeries: StoreUtil.get(surgeries, StoreUtil.COMMON_TABLE),
    preferenceQuery:preference.tables[TABLE_NAME],
    user,
    surgeryFlag,
    navUsers,
    externalSource
  };
}

const styled = withStyles(styles)(PatientSurgeriesView);
const connected = connect(mapStateToProps)(styled);
export { connected as PatientSurgeriesView }; 
