export const API_SUBMIT_FEEDBACK= 'API_SUBMIT_FEEDBACK';


export const submitFeedback = (formData)=> {
  return {
    type: API_SUBMIT_FEEDBACK,
    id:100,
    request: {
      url: '/Feedback',
      method: 'PUT',
      data:formData
    }
  }
};
