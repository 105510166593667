import { push } from 'connected-react-router'
import NavigationUtil from 'util/NavigationUtil'
export const API_ALERT_VIEW = 'API_ALERT_VIEW';
export const API_ALERT_TABLE = 'API_ALERT_TABLE';

export const tableAlerts = (options)=> {
  return {
    type: API_ALERT_TABLE,
    request: {
      url: '/AlertsDashboard',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const viewAlert = (area, id)=> {
  return push(NavigationUtil.navLink('/'+area+'/'+id));
};
