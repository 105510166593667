import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { NumberCard } from 'components/NumberCard';
import { get90DayDischarge } from 'actions/discharge90DayPageActions';
import NavigationUtil from 'util/NavigationUtil'

const styles = ({
  card: {

  }
});

class Pac90DayForm extends React.Component {

  componentDidMount() {
    this.updateCount();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {
      this.updateCount();
    }
  }

  updateCount() {
    const {
      dispatch
    } = this.props;

    let query = {
      limit: 50,
      offset: 0
    };

    dispatch(get90DayDischarge(query));
  }

  render() {
    const {
      dischargesCount
    } = this.props;

    let cardColor;

    if(dischargesCount > 0){
      cardColor = 'red';
    }

    return (
      <NumberCard
        title="90/DC form"
        number={dischargesCount}
        action="View"
        path={NavigationUtil.navLink("/90daydischarge")}
        cardColor={cardColor}
      />
    );
  }
}

Pac90DayForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dischargesCount: PropTypes.number.isRequired,
  viewAsUser: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const {
    discharge90Day,
    user
  } = state;

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }

  return {
    dischargesCount: discharge90Day.count || 0,
    viewAsUser
  }
}

const styled = withStyles(styles)(Pac90DayForm);
const connected = connect(mapStateToProps)(styled);
export { connected as Pac90DayForm };
