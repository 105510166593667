import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import { forgotPassword } from 'actions/loginActions';

const schemaEdit = {
  title: 'Forgot My Password',
  type: 'object',
  required: ['Email'],
  properties: {
    Email: {type: 'string', title: 'Email', format:'email' }
  }
};

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(data){
    this.props.dispatch(forgotPassword(data.formData.Email));
  }

  render() {
    let message;
    let messageIsError = false;
    if(this.props.forgotPassword.result === 'Success'){
      message = 'Success';
    }
    if(this.props.forgotPassword.result === 'Failure'){
      message = 'No email match';
      messageIsError = true;
    }

    if (this.props.forgotPassword.isCalling) {
      message = 'Sending..';
    }

    return (
      <div className={this.props.classes.pageContainer}>
        <MaterialForm
          schema={schemaEdit}
          onSubmit={this.onFormSubmit}
          formData={{Email: ''}}
          status={message}
          statusIsError={messageIsError}
        />
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  forgotPassword : PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    forgotPassword: state.user.forgotPassword
  }
}

const styledPage = withStyles(styles)(ForgotPasswordPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as ForgotPasswordPage};