import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';
import { push } from 'connected-react-router';
import TableUtil from 'util/TableUtil';
import Grid from '@material-ui/core/Grid';
import { FormDialog } from 'components/dialog/FormDialog'
import moment from 'moment';

import {
  listTasks,
  editTask,
  editPathway,
  startTask,
  resolveTask,
  deleteTask,
  updateTask
} from 'actions/tasksActions';

import { DynamicTable } from 'components/DynamicTable';

import TaskUtil from 'containers/tasks/TaskUtil'
import StoreUtil from 'stores/StoreUtil'
import NavigationUtil from 'util/NavigationUtil'
import DateUtil from 'util/DateUtil'

const noteSchemaEdit = {
  title: '',
  type: 'object',
  required: ['Status'],
  properties: {
    ScheduledFor: {
      type: 'string',
      title: 'Due Date',
      format: 'date'
    },
    Status: {
      type: 'string',
      title: 'Status Note',
      enum: [
        "Not Started",
        "In Progress",
        "Resolved"
      ]
    },
    StatusNote: {
      type: 'string',
      title: 'Status Note'
    }
  }
};
  
const NoteUiSchema = {
  StatusNote: {
    "ui:widget": "textarea"
  }
};
  

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  actionButton: {
    minHeight: 26,
    padding: "5px 8px",
    marginRight: theme.spacing.unit
  },
  actionIcon: {
    marginRight: theme.spacing.unit / 2,
    fontSize: 18
  },
  priorityIcon: {
    fontSize: 18,
    color: 'red'
  }
});

const TABLE_NAME = "SurgeryTasksView";

class SurgeryTasksView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {},
      columns: [
        {label:"Description", key: "TaskTypeId", format: this.renderDescription.bind(this)},
        {label:"Scheduled For / Recieved At", key: "ScheduledFor", sortable: true,
          sortDown: false, format: DateUtil.formatFromDB},
        {label:"Status", key: "Status", sortable: true, sortDown: false},
        {label:"Status Note", key: "StatusNote", sortable: true, sortDown: false},
        {label:"Priority", key: "Priority", sortable: true, sortDown: false,
          format: this.renderPriority.bind(this), style: {width:5}},
        {label:"Edit", key: "AutomatedEdit", sortable: false,
          format: this.renderEditButtons.bind(this)},
        {label:"Action", key: "AutomatedAction", sortable: false,
          format: this.renderActionButtons.bind(this)}
      ],
      tab: 0,
      notesOpen: false,
      notesTitle: '',
      notesTask: null
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onNoteSubmit = this.onNoteSubmit.bind(this);
  }

  componentDidMount() {
    const {
      dispatch,
      taskTypes
    } = this.props;

    TaskUtil.checkAndLoadTypes(taskTypes, dispatch); //make sure task types are loaded

    this.fetchInitialTasks();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.surgery != null &&
      (prevProps.surgery == null || this.props.surgery.id != prevProps.surgery.id )
      || (prevProps.tab != this.props.tab)) {
      this.fetchInitialTasks();
    }
  }

  fetchInitialTasks() {
    this.setState({query: this.buildInitialQuery() }, this.fetchTasks.bind(this));
  }

  buildInitialQuery() {
    const {
      surgery,
      preferenceQuery,
      tab
    } = this.props;

    let query = {
      include: [
        {
          association: "TaskType",
          attributes: [
            'QuestionGroupId',
            'Automated',
            'Name'
          ]
        }
      ],
      where: {},
      order: [["ScheduledFor", "DESC"]],
      offset: 0,
      limit:100
    };

    if(preferenceQuery){
      query = TableUtil.GetSavedQuery(preferenceQuery, tab);
    }

    switch(tab){
    case 0:
      query.where["Status"] = [TaskUtil.Status.NOT_STARTED, TaskUtil.Status.IN_PROGRESS];
      break;
    case 1:
      query.where["Status"] = [TaskUtil.Status.RESOLVED];
      break;
    }

    query.where["SurgeryId"] = surgery.id;

    return query;
  }

  onTabChange(event, tabNew) {
    const { tab, dispatch, surgery } = this.props;

    if(tab == tabNew){
      return;
    }
    dispatch(push(NavigationUtil.navLink(`/surgery/${surgery.id}/tasktab/${tabNew}`),{
      doNotAlterScroll:true
    }));
  }

  fetchTasks() {
    const { dispatch } = this.props;
    const { query } = this.state;
    
    dispatch(listTasks(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;

    dispatch(saveTablePreference(TABLE_NAME, query, this.state.tab));
    this.setState({query: query }, this.fetchSurgeries.bind(this));
  }

  onNoteSubmit(formResponse) {
    const {
      dispatch
    } = this.props;
    
    let taskData = {
      id: formResponse.formData.id,
      StatusNote: formResponse.formData.StatusNote,
      ScheduledFor: formResponse.formData.ScheduledFor,
      Status: formResponse.formData.Status
    }

    this.setState({ notesOpen: false });

    dispatch(updateTask(taskData, false));
  }

  onNoteCancel() {
    this.setState({ notesOpen: false });
  }

  onRowClick(task) {
    const {
      dispatch
    } = this.props;
    if(task.TaskType.Automated){
      dispatch(editPathway(task));
    } else {
      dispatch(editTask(task.SurgeryId, task.id));
    }
  }

  renderPriority(priority, task, classes) {
    return (
      <React.Fragment>
        {task.Priority && task.Priority === 'High' &&
            <Icon className={classes.priorityIcon}>priority_high</Icon>
        }
      </React.Fragment>
    );
  }

  renderEditButtons(automated, task, classes) {
    return (
      <React.Fragment>
        <Button size="small" className={classes.actionButton} onClick={this.onNotesClick.bind(this, task)}>
          <Icon className={classes.actionIcon}>create</Icon>
          {/* Notes */}
        </Button>
      </React.Fragment>
    );
  }

  renderActionButtons(automated, task, classes) {
    const {
      taskTypes
    } = this.props;

    let taskType = TaskUtil.getTaskTypeById(taskTypes, task.TaskTypeId);

    return (
      <React.Fragment>
        {task.Status && task.Status === TaskUtil.Status.NOT_STARTED &&
          (<Button size="small" className={classes.actionButton} onClick={this.onStartClick.bind(this, task)}>
            <Icon className={classes.actionIcon}>play_arrow</Icon>
            {/* Start */}
          </Button>)
        }
        {!task.ResolvedAt && task.Status && task.Status === TaskUtil.Status.IN_PROGRESS &&
          (<Button size="small" className={classes.actionButton} onClick={this.onResolveClick.bind(this, task)}>
            <Icon className={classes.actionIcon}>done</Icon>
            {/* Resolve */}
          </Button>)
        }
        {task.Status && task.Status === TaskUtil.Status.RESOLVED &&
          (<Button size="small" className={classes.actionButton} onClick={this.onStartClick.bind(this, task)}>
            <Icon className={classes.actionIcon}>undo</Icon>
            {/* Reopen */}
          </Button>)
        }
        {taskType && !taskType.Automated &&
          (<Button size="small" className={classes.actionButton} onClick={this.onDeleteClick.bind(this, task)}>
            <Icon className={classes.actionIcon}>delete</Icon>
            {/* Delete */}
          </Button>)
        }
      </React.Fragment>
    );
  }

  renderDescription(taskTypeId, task) {
    const {
      taskTypes
    } = this.props;

    let taskType = TaskUtil.getTaskTypeById(taskTypes, task.TaskTypeId);

    if (taskType) {
      if (TaskUtil.isTypeOther(taskType)) {
        return task.Description;
      }
      return taskType.Name;
    }
    return ""; //task type not loaded
  }

  onStartClick(task, event) {
    const {
      dispatch
    } = this.props;

    event.stopPropagation(); //don't allow row click to fire

    dispatch(startTask(task.id, true));
  }

  onNotesClick(task, event) {
    event.stopPropagation(); //don't allow row click to fire
    this.setState({ 
      notesOpen: true, 
      notesTitle: task.TaskType.Name+' Status Note', 
      notesTask: {
        ...task,
        ScheduledFor: moment(task.ScheduledFor).format(DateUtil.FORM_DATE_FORMAT)
      }
    });
  }

  onResolveClick(task, event) {
    const {
      dispatch
    } = this.props;

    event.stopPropagation(); //don't allow row click to fire

    dispatch(resolveTask(task.id));
  }

  onDeleteClick(task, event) {
    const {
      dispatch
    } = this.props;

    event.stopPropagation(); //don't allow row click to fire

    dispatch(deleteTask(task.id));
  }

  onConfirm(){
    const { surgery } = this.props;
    const surgeryData = surgery.data;

    this.setState({ notesOpen: false });
    // const {dispatch} = this.props;
    const formData = this.state.formData;

    if((surgeryData.MedicalRiskLevel != formData.MedicalRiskLevel)
      || (surgeryData.SocialRiskLevel != formData.SocialRiskLevel)) {
      formData.RiskLevelManuallyUpdated = true;
    }

    // dispatch(updateSurgery(formData, true));
  }

  onCancel(){
    this.setState({ notesOpen: false });
  }

  render() {
    const {
      query,
      columns
    } = this.state;

    const {
      classes,
      surgery,
      tasks,
      taskEdits,
      tab
    } = this.props;

    if (StoreUtil.hasSavedSinceLoad(taskEdits, tasks) 
    && query && Object.getOwnPropertyNames(query).length > 0) {
      this.fetchTasks();
    }

    let selectedTab;
    if(tab){
      selectedTab = tab;
    } else {
      selectedTab = 0;
    }
    
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <Tabs value={selectedTab} onChange={this.onTabChange}>
            <Tab label="Tasks" />
            <Tab label="Closed Tasks" />
          </Tabs>
          <div className={classes.headerContainer}>
            <Button
              color="primary"
              size="small"
              component={Link} to={NavigationUtil.navLink("/surgery/"+surgery.id+"/taskEdit/0")}>
              <Icon className={classes.leftIcon}>add</Icon>
                Add Task
            </Button>
          </div>
        </Grid>
        <DynamicTable
          rowClasses={classes}
          columns={columns}
          data={tasks}
          query={query}
          showFilters={false}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick.bind(this)}
        />
        <FormDialog 
          isOpen ={this.state.notesOpen}
          title={this.state.notesTitle}
          contentText={'Content'}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          cancelText={'Close'}
          confirmText={'Save'}
          formData={this.state.notesTask}
          onSubmit={this.onNoteSubmit}
          schemaEdit={noteSchemaEdit}
          uiSchema={NoteUiSchema}
        />
      </React.Fragment>
    );
  }
}

SurgeryTasksView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  taskTypes: PropTypes.object.isRequired,
  taskEdits: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  tab: PropTypes.number
}

function mapStateToProps(state) {
  const { tasks, preference } = state;
 
  return {
    tasks: StoreUtil.get(tasks, StoreUtil.COMMON_TABLE),
    taskTypes: StoreUtil.get(tasks, 'types'),
    taskEdits: StoreUtil.get(tasks, StoreUtil.COMMON_ITEM),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(SurgeryTasksView);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryTasksView };
