import ReducerUtil from 'reducers/ReducerUtil';
import DataUtil from 'util/DataUtil';

import {
  API_LIST_PROCEDURETYPES,
  API_GET_PROCEDURETYPES
} from 'actions/procedureTypeActions';

export const procedureTypes = (state = {}, action) => {
  const table = ReducerUtil.reduceTableGet(state, action, API_GET_PROCEDURETYPES);
  if(table.actionConsumed){
    if(action.data){
      const { rows } = action.data;
      
      const isDescOrder = action && action.meta && action.meta.requestAction
      && action.meta.requestAction.request && action.meta.requestAction.request.params
      && action.meta.requestAction.request.params.opts
      && action.meta.requestAction.request.params.opts.order
      && action.meta.requestAction.request.params.opts.order[0][1] === "DESC";

      // dont sort if the data is return in desc order
      if(isDescOrder) return table.state;

      DataUtil.sort(rows, "ProcedureType");
    }
    return table.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_LIST_PROCEDURETYPES)

  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "ProcedureType");
    return list.state;
  }
  return state;
};
