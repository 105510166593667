import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { MaterialForm } from 'components/forms/MaterialForm';
import { DataExportTable } from 'components/export/DataExportTable';
import { tableHospitalExports } from 'actions/exportActions';
import StoreUtil from 'stores/StoreUtil'
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';
import { listHospitals } from 'actions/hospitalActions';
import { LoadingView } from 'components/LoadingView';
import CSVUtil from 'util/CSVUtil'
import dotProp from 'dot-prop-immutable';

const styles = (theme) => ({
  pageContainer:{
  },
  formContainer:{
    marginBottom:theme.spacing.unit * 2
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }

});

const schema = {
  title: '',
  type: 'object',
  required: ['hospitalId', 'startDate', 'endDate'],
  properties: {
    hospitalId: {
      type: 'number', 
      title: 'Hospital'
    },
    startDate: {
      type: 'string', 
      title: 'Start Date',
      format: 'date'
    },
    endDate: {
      type: 'string', 
      title: 'End Date',
      format: 'date'
    }
  }
};

const schemeUI = {
  hospitalId:{
    classNames:'two-column-field'
  },
  startDate:{
    classNames:'three-column-field'
  },
  endDate:{
    classNames:'three-column-field',
    "ui:options": {}
  }
};

const TABLE_NAME = "HospitalExportPage";
class HospitalExportPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {},
      showData: false,
      startDate:null
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    //check if data load needed
    const{
      dispatch,
      hospitals
    } = this.props;

    if(StoreUtil.needsLoadLongCache(hospitals)){
      dispatch(listHospitals());
    }
  }

  buildInitialQuery() {
    let query = {
      where: { },
      limit: 10000,
      offset: 0,
      order:[["DateofSurgery","DESC"]]
    };

    const {
      preferenceQuery
    } = this.props;

    if(preferenceQuery){
      //query = preferenceQuery
    }


    return query;
  }

  fetch() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(tableHospitalExports(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query,showData:true}, this.fetch);
  }

  onRowClick() {

  }

  createCSV(){
    const {
      surgeries
    } = this.props;
    let data = StoreUtil.getData(surgeries);
    if(!data || !data.rows){
      return null;
    }
    return CSVUtil.createCSV(CSVUtil.HOSPITAL_HEADERS, data.rows);
  }

  onFormSubmit(){
    //download data from browser

    //convert the surgeries to csv
    let csv = this.createCSV();
    if(!csv){
      return;
    }

    //headers
    let downloadLink = document.createElement("a");
    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "surgeries.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  onFormError(){
  }

  onFormChange(data){
    const {
      formData
    } = data;

    if(formData.startDate != this.state.startDate){
      this.setState({startDate: formData.startDate});
    }

    if(formData.endDate && formData.startDate && formData.hospitalId){
      let query = this.buildInitialQuery();
      query.where.HospitalId = formData.hospitalId;
      query.where.DateofSurgery = { $lte: formData.endDate, $gte: formData.startDate };
      this.onQueryChange(query);
    }else{
      this.setState({
        showData:false
      });
    }
  }

  render() {
    const {
      classes,
      surgeries,
      hospitals
    } = this.props;

    const {
      query,
      showData,
      startDate
    } = this.state;

    let usedSchema = schema;

    let hospitalData = StoreUtil.getData(hospitals);

    if(StoreUtil.isLoadedOrFailed(hospitals)){
      if(hospitalData){
        const hospitalNames = [];
        const hospitalIds = [];
        hospitalData.forEach((hospital)=>{
          hospitalNames.push(hospital.Name);
          hospitalIds.push(hospital.id);
        });
        usedSchema.properties.hospitalId.enumNames = hospitalNames;
        usedSchema.properties.hospitalId.enum = hospitalIds;
      }else{
        return (
          <Typography
            color='error' variant='subheading'>
            There was an issue loading hospitals.  Please try again later.
          </Typography>
        );
      }
      
    }else{
      return (<LoadingView />);
    }

    let usedUISchema = schemeUI;
    if(startDate){
      usedUISchema = dotProp.set(usedUISchema,'endDate.ui:options.linked', startDate );
    }

    let shownData;
    if(showData && !StoreUtil.isLoading(surgeries)){
      shownData = surgeries;
    }else{
      shownData = {placeholder:true, showLoading:StoreUtil.isLoading(surgeries)};
    }
    return (
      <div className={classes.pageContainer}>
        <Typography
          variant="display1">
          Select Hospital and Dates for Export
        </Typography>

        <MaterialForm 
          allowSubmit={!StoreUtil.isLoading(surgeries)}
          className={classes.formContainer}
          schema={usedSchema}
          uiSchema={usedUISchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          inDialog={true}
          submitText="Download Data"
        />

        <DataExportTable 
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Selected Surgeries</Typography>
            </div>
          }
          data={shownData}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          onAdmitClick={this.onRowClick.bind(this)}
          preloadMessage={"Please Select Hospital and Dates"}
          noDataMessage={"No Surgeries Found"}
        />
      </div>
    );
  }
}

HospitalExportPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  preferenceQuery: PropTypes.object,
  surgeries: PropTypes.object.isRequired,
  hospitals: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { 
    exportData, 
    preference,
    hospitals
  } = state;

  return {
    hospitals: StoreUtil.get(hospitals, StoreUtil.COMMON_LIST),
    surgeries: StoreUtil.get(exportData, StoreUtil.COMMON_TABLE),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(HospitalExportPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as HospitalExportPage};
