import React, { Component } from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
});

const columns = [
  { label: "Name", key: "Name", sortable: true, sortDown: false },
  { label: "FacilityType", key: "FacilityType", sortable: true, sortDown: false }
];

class FacilityTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <DynamicTable {...this.props} columns={columns}/>
  }
}

FacilityTable.propTypes = DynamicTable.propTypes;

const styled = withStyles(styles)(FacilityTable);
export { styled as FacilityTable };
