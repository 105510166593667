import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import {TwoFactorForm} from 'components/TwoFactorForm';
import {twoFactorConfirmUser, resendTwoFactor} from 'actions/loginActions'

import AuthRule from 'businessRule/AuthRule';

const styles = ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop:30
  }
});

class TwoFactorPage extends React.Component {

  constructor(props) {
    super(props);
    // set the initial component state
    this.state = {
      userInput: {
        code: ''
      }
    };
    this.processFormTwoFactor = this.processFormTwoFactor.bind(this);
    this.onResendCode = this.onResendCode.bind(this);
    this.changeUser = this.changeUser.bind(this);
  }

  changeUser(event) {
    const field = event.target.name;
    const userInput = this.state.userInput;
    userInput[field] = event.target.value;

    this.setState({
      userInput
    });
  }

  processFormTwoFactor(event) {
    event.preventDefault();

    const { code } = this.state.userInput;
    const { dispatch } = this.props;

    if (code) {
      dispatch(twoFactorConfirmUser(code));
    }
  }

  onResendCode(){
    const { dispatch } = this.props;
    dispatch(resendTwoFactor());
  }

  render() {

    const {
      user,
      classes
    } = this.props;

    //already signed in??
    const redirectURL = AuthRule.getUserLoginRedirectURL(user);
    if(redirectURL){
      return (
        <Redirect to={redirectURL} push={false} />
      );
    }

    const callLoading = user.meta.twoFactorIsCalling && (user.meta.twoFactorCallTime +10000) < (new Date()).getTime();
    
    let showMessageAsError = true;

    let message = user.meta.errorMessage;

    if(user.meta.resentTwoFactorErrorAt > ((new Date()).getTime()) - 5000){
      message = "Issue resending code.";
    }else if(user.meta.resentTwoFactorAt > ((new Date()).getTime()) - 5000){
      message = "The code has been resent.";
      showMessageAsError = false;
    }
    const form = (
      <TwoFactorForm
        errors={message}
        onSubmit={this.processFormTwoFactor}
        onChange={this.changeUser}
        user={this.state.userInput}
        onResendCode={this.onResendCode}
        processing={callLoading}
        showAsError={showMessageAsError}
      />
    );

    return (
      <Card className={classes.container}>
        { form }
      </Card>
    );
  }

}

TwoFactorPage.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user
  };
}

const styled = withStyles(styles)(TwoFactorPage);
const connected = connect(mapStateToProps)(styled);
export { connected as TwoFactorPage };