import { push } from 'connected-react-router'
import NavigationUtil from 'util/NavigationUtil'

export const API_LIST_NAV_USERS = 'API_LIST_NAV_USERS';
export const UPDATE_VIEW_AS_NAV_USER = 'UPDATE_VIEW_AS_NAV_USER';
export const API_TABLE_NAV_USERS = 'API_TABLE_NAV_USERS';
export const API_UPDATE_NAV_USER = 'API_UPDATE_NAV_USER';
export const API_CREATE_NAV_USER = 'API_CREATE_NAV_USER';
export const API_GET_NAV_USER = 'API_GET_NAV_USER';
export const API_SEND_INVITE = 'API_SEND_INVITE';

export const listNavUsers = (loadingForViewAs, options, currentUser)=> {
  let action;
  if(loadingForViewAs){
    action = API_LIST_NAV_USERS;
    options = {
      where:{
        Active: true,
        $or: [
          {Role: 2}, {id: currentUser}, {Role: 8}
        ]
      },
      order: [
        'LastName', 'FirstName'
      ]
    }
  }else{
    action = API_TABLE_NAV_USERS;
  }
  
  return {
    type: action,
    request: {
      url: '/Users',
      method: 'GET',
      params: { opts: options }
    }
  }
};

export const viewUser = (currentUser, userToView)=> {
  return push(NavigationUtil.userLink(currentUser, '/users/'+userToView.id));
};

export const updateViewAsNavUser = (user)=> {
  return {
    type: UPDATE_VIEW_AS_NAV_USER,
    user: user
  }
};

export const getNavUser = (userId)=> {
  const options = {
    where: {id:userId },
    include :[
      {
        association: 'Facility',
        attributes:['Name'],
        required:false
      }
    ]
  }
  return {
    type: API_GET_NAV_USER,
    id: userId,
    request: {
      url: '/Users',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updateNavUser = (formData, role)=> {
  let options = null;
  let userId = 0;

  if(formData.id && formData.id > 0){
    userId = formData.id;
    options = {
      where: {id:userId }
    }
  }

  let type = API_UPDATE_NAV_USER;

  if(userId === 0){
    type = API_CREATE_NAV_USER;
  }

  let redirectOnSuccess = NavigationUtil.helpDeskLink('/users')

  if(role == 3 || 7) {
    redirectOnSuccess = NavigationUtil.navLink('/users')
  }

  return {
    type: type,
    id: userId,
    redirectOnSuccess,
    request:{
      url: '/Users',
      method: 'PUT',
      params : {opts:options},
      data : formData
    }
  }
};

export const sendInvite = (userId)=> {
  return {
    type: API_SEND_INVITE,
    request: {
      url: '/SendInvite',
      method: 'POST',
      data : {id:userId}
    }
  }
};
