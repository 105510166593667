export const API_LIST_NAV_TEMPLATE_MESSAGES = 'API_LIST_NAV_TEMPLATE_MESSAGES';

export const listNavTemplateMessages = ()=> {
  return {
    type: API_LIST_NAV_TEMPLATE_MESSAGES,
    request: {
      url: '/NavTemplateMessages',
      method: 'GET'
    }
  }
};

export const requestForAdmission = (formData)=> {
  return {
    type: API_LIST_NAV_TEMPLATE_MESSAGES,
    request:{
      url: '/RequestForAdmission',
      method: 'POST',
      data : formData
    }
  }
};