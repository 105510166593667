import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import { LoadingView } from 'components/LoadingView';
import StoreUtil from 'stores/StoreUtil'

import { replyToMessage, createMessage } from 'actions/messageActions';
import NavigationUtil from 'util/NavigationUtil'

const styles = theme => ({
  card: {
    marginBottom: theme.spacing.unit*2
  },
  body: {
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  cardActions: {
    justifyContent: "flex-end"
  },
  checkBox: {
    marginRight: theme.spacing.unit*2
  },
  outlinedInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
});

class MessageComposeView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      body: ""
    };
  }

  onBodyChange(event) {
    this.setState({
      body: event.target.value
    });
  }

  onSendClick() {
    const {
      dispatch,
      conversationId,
      admissionId,
      newConversationType
    } = this.props;

    const {
      body
    } = this.state;

    if(newConversationType){
      const data = {
        AdmissionId: admissionId,
        ConversationType: newConversationType,
        Message: body
      }
      dispatch(createMessage(data));
    }else{
      dispatch(replyToMessage(conversationId, body));
    }
    
    //remove body
    this.setState({
      body: ""
    });
  }

  render() {
    const {
      classes,
      message,
      conversationId,
      admissionId,
      isPAC,
      surgeryId,
      newConversationType
    } = this.props;

    const {
      body
    } = this.state;
    
    let error = null;
    if (StoreUtil.isSaving(message)) {
      return (
        <Card className={classes.card}>
          <CardContent>
            <LoadingView />
          </CardContent>
        </Card>
      );
    } else if (StoreUtil.isSaveFailed(message)) {
      error = "There was a problem replying. Please wait and try again later.";
    } else if (StoreUtil.didJustSave(message) && newConversationType) {
      //if was a new conversation, redirect to conversation page
      let convoId = conversationId;
      const messData = StoreUtil.getData(message);
      //need to find the conversation id
      if(messData){
        convoId = messData.id;
      }

      let url;
      if(isPAC){
        url = NavigationUtil.pacLink(`/admission/${admissionId}/conversation/${convoId}`);
      }else{
        url = NavigationUtil.navLink(`/surgery/${surgeryId}/admission/${admissionId}/conversation/${convoId}`);
      }
      return (<Redirect to={url} />);
    }

    let submitText;
    let label;
    if(newConversationType){
      submitText = "Send Message";
      label = "New Message";
    }else{
      submitText = "Send Reply";
      label = "Reply";
    }
    return (
      <Card className={classes.card} key="compose">
        <CardContent>
          {error && 
            (<Typography color="error">
              {error}
            </Typography>)
          }
          <TextField
            id="multiline-flexible"
            label={label}
            multiline
            rows="4"
            fullWidth
            value={body}
            onChange={this.onBodyChange.bind(this)}
            className={classes.body}
            margin="normal"
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.outlinedInput
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            color="primary"
            size="small"
            onClick={this.onSendClick.bind(this)}
            variant="contained">
            <Icon className={classes.leftIcon}>send</Icon>
            {submitText}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

MessageComposeView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  conversationId: PropTypes.number,
  admissionId: PropTypes.number,
  surgeryId: PropTypes.number,
  message: PropTypes.object,
  newConversationType:PropTypes.string,
  isPAC: PropTypes.bool.isRequired
}

function mapStateToProps(state, existingProps) {
  const {
    messages
  } = state;
  const {
    newConversationType,
    conversationId
  } = existingProps;

  let message;
  if(newConversationType){
    message = StoreUtil.get(messages, StoreUtil.COMMON_NEW_ITEM);
  }else{
    message = StoreUtil.get(messages, StoreUtil.COMMON_ITEM, conversationId);
  }
  return {
    message
  };
}

const styled = withStyles(styles)(MessageComposeView);
const connected = connect(mapStateToProps)(styled);
export { connected as MessageComposeView }; 