export const API_TABLE_RISKSCOREAUDIT = 'API_TABLE_RISKSCOREAUDIT';

export const getRiskScoreAudit = (surgeryId)=> {
  let options = {
    where: {
      SurgeryId: surgeryId
    },
    order: [
      ['updatedAt', 'DESC']
    ],
    limit: 0,
    offset: 0
  };

  return {
    type: API_TABLE_RISKSCOREAUDIT,
    request: {
      url: '/RiskScoreAudit',
      method: 'GET',
      params: {opts:options}
    }
  }
};