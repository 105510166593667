import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import axios from 'axios';
import createSagaMiddleware from 'redux-saga'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import rootReducer from 'reducers/rootReducer'
import rootSaga from 'sagas/rootSaga'

export const initialState = {
  appMessages: { },
  user : {
    Active : '',
    Email : '',
    FirstName : '',
    LastName : '',
    NavigatorID :'',
    Role : -1,
    Username : '',
    id : 1,
    FacilityId: -1,
    twoFactorRequired : false,
    viewAsUser:null,
    meta : {
      nurseNavViewingPac: false,
      authenticated : false,
      twoFactorIsCalling : false,
      errorMessage :'',
      loginIsCalling : false,
      loginCallTime : 0,
      resentTwoFactorAt:0,
      resentTwoFactorErrorAt:0,
      returnToURL:null
    },
    forgotPassword: {
      isCalling: false,
      result: null
    },
    resetPassword: {
      isCalling: false,
      result: null
    }
  },
  preference:{
    tables: {}
  },
  navUsers: {
    table:null,
    list:null,
    item:{}
  },
  patients: {
    // byMRN:{

    // },
    // EHRByMRN:{

    // },
    meta :{
      callPatient:{},
      callEHR:{},
      updatePatient: {},
      callSearch:''
    },
    search: {
      rows: [],
      offset: 0,
      count: 0
    }
  },
  surgeons: {
    table:null,
    item:{}
  },
  hospitals: {
    table:null,
    item:{}
  },
  surgeries:{
    table:null,
    item:{},
    dashview:{}
  },
  tasks:{
    types: { },
    byId:{ },
    list:{ },
    todaysCount: { count: 0, meta: {}}
  },
  postAcuteEvents:{
    item:{},
    list:{}
  },
  messages: {
    conversations: {},
    list: {},
    conversation: {}
  },
  positives :{
    item:{},
    list:{}
  },
  questionGroup:{
    item:{}
  },
  feedback: {
    item:{}
  },
  admissions: {
    item:{},
    table:{}
  },
  facilities: {
    table:null,
    item:{}
  },
  hospitalVisits:{
    item:{}
  },
  reasons:{
    table:null,
    list:null
  },
  readmissionFacilities: {
    list:{}
  },
  exportData: {
    table:null,
    workingData:null
  },
  alerts: {
    table:null
  },
  surgicalTeams: {
    list:null
  },
  hsxEvents: {},
  hsxAlerts: [],
  selectedHsxEvent: {},
  updatedHsxEvent: {}
}

/**TODO : encrypt this */
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "preference"]
}

const history = createBrowserHistory();
const connectedHistoryReducer = connectRouter(history)(rootReducer);
const persistedReducer = persistReducer(persistConfig, connectedHistoryReducer);


const sagaMiddleware = createSagaMiddleware();

const attachPathNameToActionMiddleware = store => next => action=>{
  if(store.getState().router
    && store.getState().router.location){
    action.pathname = store.getState().router.location.pathname;
  }

  next(action)
};

export const configureStore = () => {
  const middleware = [routerMiddleware(history), sagaMiddleware, attachPathNameToActionMiddleware];

  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware),
    ),
  );

  const axiosInstance = axios.create({
    //baseURL: process.env.API_BASE_URL,
    headers: { Pragma: 'no-cache' },
    baseURL: window.location.toString().replace(/^(.*\/\/[^/?#]*).*$/,"$1") + '/api/',
    withCredentials: true
  });

  sagaMiddleware.run(rootSaga, axiosInstance);

  let persistor = persistStore(store)

  return { store, persistor, history }
}
