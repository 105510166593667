import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { withStyles } from '@material-ui/core/styles';

import { FeedbackDialog } from 'containers/feedback/FeedbackDialog';

const styles = () => ({
  root: {
  },
  icon :{
    fontSize: 20
  }
});

class FeedbackButtonView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false
    };

    this.handleDialogClickOpen = this.handleDialogClickOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  } 

  handleDialogClickOpen(){
    this.setState({
      dialogOpen: true
    });
  }

  handleDialogClose(){
    this.setState({ dialogOpen: false });
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.root}>
        <Button
          onClick={this.handleDialogClickOpen}
          color="inherit"
          size="small"
        >
          <Icon className={classes.icon}>feedback</Icon>
        </Button>
        <FeedbackDialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
        />
      </div>
    );
  }
}

FeedbackButtonView.propTypes = {
  classes: PropTypes.object.isRequired
}

const styled = withStyles(styles)(FeedbackButtonView);
export { styled as FeedbackButtonView }; 