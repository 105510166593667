import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { push } from 'connected-react-router';
import NavigationUtil from 'util/NavigationUtil';

import StoreUtil from 'stores/StoreUtil'
import { PACAdmissionsTable } from 'components/pac/PACAdmissionsTable';
import { PACHUDView } from 'containers/pac/PACHUDView';
import { listAdmissions, viewAdmission } from 'actions/admissionActions';
import { saveTablePreference } from 'actions/preferenceActions';
import TableUtil from 'util/TableUtil';

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  },
  header: {
    position:'absolute',
    top: theme.spacing.unit*2,
    left: theme.spacing.unit*2
  }
});

const TABLE_NAME = "PACDashboardPage";
class PACDashboardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      query: {}
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);

    this.onQueryChange = this.onQueryChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.fetchInitialSurgeries();
  }

  componentDidUpdate(prevProps) {
    //tab changed
    if (prevProps.tab != this.props.tab) {
      this.fetchInitialSurgeries();
    }
  }

  fetchInitialSurgeries() {
    this.setState({query: this.buildInitialQuery() }, this.fetchSurgeries.bind(this));
  }

  buildInitialQuery(clearingFilters = false) {
    const {
      user,
      preferenceQuery,
      tab
    } = this.props;

    let query = {
      where: {},
      attributes:['id', 'AdmissionDate', 'AnticipatedDischargeDate','DischargeDate'],
      limit: 25,
      offset: 0,
      include: [
        {
          association:'Surgery',
          attributes:['id', 'SurgeryDateTime'],
          required: true,
          include: [
            {
              association:'Surgeon',
              attributes:['id', 'Name'],
              include: [
                {
                  association:'Navigator',
                  attributes:['id', 'Username', 'FirstName', 'LastName']
                }
              ]
            },
            {
              association:'Patient',
              attributes:['id', 'LastName', 'FirstName', 'ExternalSource']
            }
          ]
        },
        {
          association: 'Facility',
          attributes:['Name']
        }
      ],
      order:[["AdmissionDate","DESC"]]
    };

    if(preferenceQuery && !clearingFilters){
      query = TableUtil.GetSavedQuery(preferenceQuery, tab);
    }

    query.where["FacilityId"] = user.FacilityId;

    /*
      <Tab label="Active" />
      <Tab label="Upcoming Discharges" />
      <Tab label="Discharged" />
      <Tab label="All" />
      */
    if (tab == 0) {
      query.where["DischargeDate"] = null;
      delete query.where["AnticipatedDischargeDate"];
    } else if (tab == 1) {
      var threeDaysFuture = moment();
      threeDaysFuture = threeDaysFuture.add(3, "days");

      query.where["DischargeDate"] = null;
      query.where["AnticipatedDischargeDate"] = {lte:threeDaysFuture}
    } else if (tab == 2) {
      query.where["DischargeDate"] = {ne:null};
      delete query.where["AnticipatedDischargeDate"];
    } else if (tab == 3) {
      delete query.where["DischargeDate"];
      delete query.where["AnticipatedDischargeDate"];
    }

    return query;
  }

  fetchSurgeries() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listAdmissions(query));
  }

  clearFilters(){
    const clearingFilters = true;
    const query = this.buildInitialQuery(clearingFilters);
    this.onQueryChange(query);
  }

  onTabChange(event, tabNew) {
    const { tab, dispatch } = this.props;
    const clearFiltersTab  = 4;
    if(tab == tabNew){
      return;
    }
    if(tabNew === clearFiltersTab){
      return this.clearFilters();
    }
    dispatch(push(NavigationUtil.pacLink(`/home/${tabNew}`)));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query, this.state.tab));

    this.setState({query: query }, this.fetchSurgeries.bind(this));
  }

  onRowClick(admission) {
    const { dispatch } = this.props;
    dispatch(viewAdmission(admission));
  }

  render() {
    const {
      classes,
      tab,
      admissions
    } = this.props;

    const {
      query
    } = this.state;
    
    return (
      <div className={classes.pageContainer}>
        <PACHUDView />

        <Tabs value={tab} onChange={this.onTabChange}>
          <Tab label="Active" />
          <Tab label="Upcoming Discharges" />
          <Tab label="Discharged" />
          <Tab label="All" />
          <Tab label="Clear Filters" style={TableUtil.clearFilterStyles()}/>
        </Tabs>
        <PACAdmissionsTable
          data={admissions}
          query={query}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}
PACDashboardPage.defaultProps = {
  tab : 0
};
PACDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  admissions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  tab: PropTypes.number.isRequired
};

function mapStateToProps(state, existingProps) {
  const {
    admissions,
    user,
    preference
  } = state;

  const { match } = existingProps;
  let tab = parseInt(match.params.tab);
  if(!tab){
    tab = 0;
  }

  return {
    admissions: StoreUtil.get(admissions, StoreUtil.COMMON_TABLE),
    user,
    preferenceQuery:preference.tables[TABLE_NAME],
    tab
  };
}

const styledPage = withStyles(styles)(PACDashboardPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACDashboardPage};
