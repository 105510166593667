import ReducerUtil from 'reducers/ReducerUtil';
import DataUtil from 'util/DataUtil';

import {
  API_LIST_READMISSIONFACILITIES,
  API_GET_READMISSIONFACILITIES
} from 'actions/readmissionFacilityActions';

export const readmissionFacilities = (state = {}, action) => {
  const table = ReducerUtil.reduceTableGet(state, action, API_GET_READMISSIONFACILITIES);
  if(table.actionConsumed){
    if(action.data){
      const { rows } = action.data;

      const isDescOrder = action && action.meta && action.meta.requestAction
      && action.meta.requestAction.request && action.meta.requestAction.request.params
      && action.meta.requestAction.request.params.opts
      && action.meta.requestAction.request.params.opts.order
      && action.meta.requestAction.request.params.opts.order[0][1] === "DESC";

      // dont sort if the data is return in desc order
      if(isDescOrder) return table.state;
      
      DataUtil.sort(rows, "Name");
    }
    return table.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_LIST_READMISSIONFACILITIES)
  
  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "Name");
    return list.state;
  }
  return state;
};
