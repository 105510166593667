import { takeEvery } from 'redux-saga/effects';


function* scrollWindowToTop() {
  window.scrollTo(0,0);
  yield;
}

export const windowScrollSaga = [
  takeEvery( (action) => {
    return action 
      && action.type 
      && action.type.includes("LOCATION_CHANGE")
      && action.payload
      && action.payload.action
      && action.payload.action == "PUSH"
      && (!action.payload.location 
        || !action.payload.location.state 
        || !action.payload.location.state.doNotAlterScroll);
  }, scrollWindowToTop)
];
