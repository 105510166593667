import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb';
import { ViewAsUserView } from 'containers/users/ViewAsUserView';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import {logoutUser, switchToPACInterace} from 'actions/loginActions'
// this was remove due to QA's request. If you want to add the live function toggle back
// to the UI add this and the other comments back in this file (1 of 3)
// import {toggleLiveUpdate} from 'actions/liveUpdateActions'
import NavigationUtil from 'util/NavigationUtil'
import {FeedbackButtonView} from 'containers/feedback/FeedbackButtonView'
import {AuthRule} from 'businessRule/AuthRule'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  logo: {
    width: 45,
    height: 45,
    marginRight:15
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  authContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    margin: theme.spacing.unit,
    marginRight: theme.spacing.unit * 2,
    borderRadius: 2,
    background: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      background: fade(theme.palette.common.white, 0.25)
    },
    '& input': {
      transition: theme.transitions.create('width'),
      width: 140,
      '&:focus': {
        width: 220
      }
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchInput: {
    font: 'inherit',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme
      .spacing.unit * 7}px`,
    border: 0,
    display: 'block',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: 'inherit',
    width: '100%',
    '&:focus': {
      outline: 0
    },
    '&::placeholder': {
      color: "#ffffff",
      opacity: 0.5
    },
    '&:-ms-input-placeholder': {
      color: "#ffffff",
      opacity: 0.5
    },
    '&::-ms-input-placeholder': {
      color: "#ffffff",
      opacity: 0.5
    }
  },
  subtoolbar: {
    backgroundColor: theme.palette.primary.light,
    minHeight: 30,
    height: 30,
    padding: 0
  },
  menuIconButton: {
    marginTop: 4,
    marginLeft: theme.spacing.unit
  },
  menuIcon: {
    fontSize: 28
  },
  menuItemIcon: {
    marginRight: theme.spacing.unit
  },
  tasksButton: {
    marginRight: theme.spacing.unit*2,
    height: 30,
    minHeight: 30,
    padding: 4,
    borderRadius: 13,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  testEnvHeader: {
    background: "#909090"
  }
});

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      anchorEl: null,
      patientSearch: "",
      patientSearchOnPage: null
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handlePACLink = this.handlePACLink.bind(this);
  }

  handlePACLink(){
    const { dispatch } = this.props;
    dispatch(switchToPACInterace(true));
  }

  handleMenu(event){
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleLogout() {
    this.handleClose();

    const { dispatch } = this.props;
    dispatch(logoutUser());
  }

  onPatientSearch(event) {
    const { location } = this.props;

    this.setState({
      patientSearch: event.target.value,
      patientSearchOnPage: location.pathname
    });
  }

  onPatientKeyPress(event){
    let search = event.target.value;
    if(event.keyCode == 13) {
      const { dispatch } = this.props;
      dispatch(push(NavigationUtil.navLink('/patients?'+search)));

      this.setState({
        patientSearchOnPage: null
      });
    }
  }

  render() {
    const {
      classes,
      user,
      isPatientSearchPage,
      location
      // this was remove due to QA's request. If you want to add the live function toggle back
      // to the UI add this and the other comments back in this file (2 of 3)
      // dispatch,
      // liveUpdate
    } = this.props;

    const {
      anchorEl,
      patientSearch,
      patientSearchOnPage
    } = this.state;
    const open = Boolean(anchorEl);

    let usedSearchTerm = patientSearch;
    if(patientSearchOnPage != location.pathname && !isPatientSearchPage){
      usedSearchTerm = '';
    }

    const authenticated = AuthRule.doesUserHaveNurseNavPermission(user);
    const allowPACLinking = AuthRule.canUserSwitchToPACView(user);

    const version = process.env.RELEASE_VERSION;
    const testENV = process.env.IS_QA && " Test Environment"

    return (
      <AppBar position="sticky" className={process.env.IS_QA && classes.testEnvHeader}>
        <Toolbar>
          <Link to={NavigationUtil.navLink("/dashboard")}>
            <img src={require("images/logo_small_inverted.png")} className={classes.logo}/>
          </Link>

          <Typography variant="title" color="inherit" className={classes.flex}>
            Ortho Navigator {version}{testENV}
          </Typography>
          {authenticated && (
            <div className={classes.authContainer}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <input
                  className={classes.searchInput}
                  placeholder="Patient Search"
                  value={usedSearchTerm}
                  onChange={this.onPatientSearch.bind(this)}
                  onKeyDown={this.onPatientKeyPress.bind(this)}
                />
              </div>

              <Button
                variant="outlined"
                color="inherit"
                className={classes.tasksButton}
                component={Link}
                to={NavigationUtil.navLink("/tasks/today/Pre-Op")}>
                Pre
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.tasksButton}
                component={Link}
                to={NavigationUtil.navLink("/tasks/today/Post-Op")}>
                Post
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.tasksButton}
                component={Link}
                to={NavigationUtil.navLink("/tasks/today/90%20Day%20Post-Op")}>
                90
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.tasksButton}
                component={Link}
                to={NavigationUtil.navLink("/tasks/today/180%20Day%20Post-Op")}
              >180</Button>

              {/* this was remove due to QA's request. If you want to add the live function toggle back
              to the UI add this and the other comments back in this file (3 of 3) */}
              {/* <Button
                variant="contained"
                color={liveUpdate ? "secondary" : "inherit"}
                onClick={() => dispatch(toggleLiveUpdate())}>
                live
              </Button> */}

              <IconButton
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
                className={classes.menuIconButton}
              >
                <Icon className={classes.menuIcon}>menu</Icon>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={this.handleClose}
              >
                { AuthRule.doesUserHaveHelpDeskPermisson(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink("/users")}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>account_circle</Icon>
                    Users
                  </MenuItem>
                )}
                <MenuItem
                  component={Link}
                  to={NavigationUtil.navLink("/dashboard")}
                  onClick={this.handleClose}>
                  <Icon className={classes.menuItemIcon}>home</Icon>
                  Dashboard
                </MenuItem>
                { AuthRule.doesUserHaveNurseNavPermission(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink('/patients')}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>face</Icon>
                    Patients
                  </MenuItem>
                )}
                { AuthRule.doesUserHaveNurseNavPermission(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink('/export/hospital')}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>cloud_download</Icon>
                    Export Hospital Surgeries
                  </MenuItem>
                )}
                { AuthRule.doesUserHaveNurseNavPermission(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink('/import/hospital')}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>cloud_upload</Icon>
                    Import Hospital Surgeries
                  </MenuItem>
                )}
                { AuthRule.doesUserHaveHelpDeskPermisson(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink('/surgeons')}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>supervisor_account</Icon>
                    Surgeons
                  </MenuItem>
                )}
                { AuthRule.doesUserHaveHelpDeskPermisson(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink('/hospitals')}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>local_hospital</Icon>
                    Hospitals
                  </MenuItem>
                )}
                { AuthRule.doesUserHaveHelpDeskPermisson(user) &&  (
                  <MenuItem
                    component={Link}
                    to={NavigationUtil.navLink('/maintenance')}
                    onClick={this.handleClose}>
                    <Icon className={classes.menuItemIcon}>library_add</Icon>
                    Table Maintenance
                  </MenuItem>
                )}
                { allowPACLinking &&  (
                  <MenuItem onClick={this.handlePACLink}>
                    <Icon className={classes.menuItemIcon}>launch</Icon>
                    View PAC
                  </MenuItem>
                )}
                <MenuItem onClick={this.handleLogout}>
                  <Icon className={classes.menuItemIcon}>exit_to_app</Icon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
        {
          authenticated && (
            <Toolbar className={classes.subtoolbar}>
              <Breadcrumb />
              <ViewAsUserView />
              <FeedbackButtonView />
            </Toolbar>
          )
        }
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  isPatientSearchPage: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  liveUpdate: PropTypes.bool.isRequired
};

function mapStateToProps(state, existingProps) {
  const { location } = existingProps;
  const { liveUpdate } = state;
  const isPatientSearchPage = location.pathname && location.pathname.includes("/patients");

  return {
    user:state.user,
    isPatientSearchPage,
    liveUpdate
  };
}

const styled = withStyles(styles)(Header);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export {routed as Header};
