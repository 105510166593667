import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { updatePostAcuteEvent } from 'actions/postAcuteEventActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConfirmDialog } from 'components/dialog/Confirm';

const styles = (theme) => ({
  actionButton: {
    minHeight: 26,
    padding: "5px 8px",
    marginRight: theme.spacing.unit
  },
  actionIcon: {
    marginRight: theme.spacing.unit / 2,
    fontSize: 18
  }
});

class HospitalVisitTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {label:"Source", key: "Source", sortable: true, sortDown: false, format: this.pacSource},
        {label:"Date", key: "Date", sortable: true, sortDown: false, format: this.dateFormat},
        {label:"Type", key: "Status", sortable: true, sortDown: false},
        {label:"Facility", key: "ReadmissionFacility.Name", sortable: true, sortDown: false},
        {label:"Notes/Treatments", key: "ReadmissionNotes", sortable: true, sortDown: false},
        {label:"Primary Reason", key: "Reason.Text", sortable: true, sortDown: false},
        {label:"Secondary Reasons", key: "SecondaryReasons", sortable: false, 
          sortDown: false, format: this.secondaryReasonsFormat},
        {label:"Action", key: "id", sortable: false,
          format: this.renderActionButtons.bind(this)}
      ],
      confirmOpen: false,
      hospitalVisitId: null
    };

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  dateFormat(date) {
    if (date) {
      // let year = date.substring(2,4);
      // let month = date.substring(5,7);
      // let day = date.substring(8,10);
      // return parseInt(month)+"/"+parseInt(day)+"/"+year;
      return date
    }
    return "";
  }
  
  secondaryReasonsFormat(reasons) {
    if(reasons){
      let result = "";
      reasons.forEach((reason)=>{
        result += reason.Reasons.Text+" ";
      });
      return result;
    }else{
      return "";
    }
  }
  
  pacSource() {
    return "QCAP";
  }

  onConfirm(){
    const {
      dispatch
    } = this.props;

    this.setState({ confirmOpen: false });
    
    let hospitalVisitData = {
      id: this.state.hospitalVisitId,
      Deleted: true
    }

    dispatch(updatePostAcuteEvent(hospitalVisitData));
  }

  onCancel(){
    this.setState({ confirmOpen: false });
  }

  renderActionButtons(id, hospitalVisit) 
  {
    const {
      classes
    } = this.props;

    return (
      <React.Fragment>
        <Button size="small" className={classes.actionButton} onClick={this.onDeleteClick.bind(this, hospitalVisit)}>
          <Icon className={classes.actionIcon}>delete</Icon>
          {/* Delete */}
        </Button>
      </React.Fragment>
    );
  }

  onDeleteClick(hospitalVisit, event) {
    event.stopPropagation(); //don't allow row click to fire

    this.setState({ confirmOpen: true, hospitalVisitId: hospitalVisit.id });
  }

  render() {
    const {
      classes,
      data,
      query,
      source,
      onQueryChange,
      onRowClick,
      header
    } = this.props;

    return (
      <React.Fragment>
        <ConfirmDialog
          title={'Confirm Hospital Visit Delete'}
          contentText={'Are you sure you want to delete this hospital visit?'}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          isOpen={this.state.confirmOpen}
          cancelText={'No'}
          confirmText={'Yes'}
        />
        <DynamicTable
          columns={this.state.columns}
          classes={classes}
          data={data}
          query={query}
          source={source}
          onQueryChange={onQueryChange}
          onRowClick={onRowClick}
          header={header}
        />
      </React.Fragment>
    );
  }
}

HospitalVisitTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  source: PropTypes.string,
  showFilters: PropTypes.bool,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  header: PropTypes.string
}

function mapStateToProps() {
  return {
  };
}

const styled = withStyles(styles)(HospitalVisitTable);
const connected = connect(mapStateToProps)(styled);
export { connected as HospitalVisitTable }; 

