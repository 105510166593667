import { push } from 'connected-react-router';

export const API_TABLE_HOSPITALS = 'API_TABLE_HOSPITALS';
export const API_LIST_HOSPITALS = 'API_LIST_HOSPITALS';
export const API_GET_HOSPITAL = 'API_GET_HOSPITAL';
export const API_UPDATE_HOSPITAL_NAME = 'API_UPDATE_HOSPITAL_NAME';
export const API_UPDATE_HOSPITAL_DATA = 'API_UPDATE_HOSPITAL_DATA';
export const API_GET_HOSPITAL_ID = 'API_GET_HOSPITAL_ID';
import NavigationUtil from 'util/NavigationUtil'

export const listHospitals = () => {
  const options = {
    order: [
      ['Name', 'ASC']
    ]
  }

  return {
    type: API_LIST_HOSPITALS,
    request: {
      url: '/Hospitals',
      method: 'GET',
      params: {opts:options}
    }
  }
};

export const tableHospitals = (options)=> {
  if(!options.order){
    options.order = [];
    options.order.push(["Name", "ASC"])
  }
  return {
    type: API_TABLE_HOSPITALS,
    request: {
      url: '/Hospitals',
      method: 'GET',
      params: {opts:options}
    }
  }
};

export const getHospital = (hospitalId)=> {
  const options = {
    where: {id:hospitalId}
  }

  return {
    type: API_GET_HOSPITAL,
    id: hospitalId,
    request: {
      url: '/Hospitals',
      method: 'GET',
      params: {opts:options}
    }
  }
};

export const updateHospital = (formData)=> {
  let options = null;
  let hospitalId = 0;

  if(formData.id && formData.id >0) {
    hospitalId = formData.id;
    options = {
      where: {id:hospitalId}
    }
  }

  return {
    type: API_UPDATE_HOSPITAL_NAME,
    id: hospitalId,
    hospital: formData,
    request: {
      url: '/Hospitals',
      method: 'PUT',
      params: {opts:options},
      data: formData
    }
  }
};

export const viewHospital = (user, hospital)=> {
  return push(NavigationUtil.userLink(user,'/hospital/'+hospital.id));
};