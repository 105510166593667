import { success } from 'redux-saga-requests';

import {
  API_LIST_NAV_TEMPLATE_MESSAGES
} from 'actions/NavTemplateMessagesActions'

export const navTemplateMessages = (state = {}, action) => {
  if(action.type === success(API_LIST_NAV_TEMPLATE_MESSAGES)) {
    return action.data;
  }

  return state;
}
