export const API_HSX_EVENTS = 'API_HSX_EVENTS'
export const API_HSX_ALERTS = 'API_HSX_ALERTS'
export const API_HSX_UPDATE_STATUS = 'API_HSX_UPDATE_STATUS'
export const API_HSX_SELECTED_EVENT = 'API_HSX_SELECTED_EVENT'

export const getHSXEvents = options => {
  return {
    type: API_HSX_EVENTS,
    request: {
      url: '/vw_HSXEvents',
      method: 'GET',
      params: { opts: options }
    }
  }
}

export const setSelectedHSXEvent = event => {
  return {
    type: API_HSX_SELECTED_EVENT,
    payload: event
  }
}

export const getHSXalerts = options => {
  return {
    type: API_HSX_ALERTS,
    request: {
      url: '/vw_HSXAlerts',
      method: 'GET',
      params: { opts: options }
    }
  }
}

export const updateHSXStatus = (formData, backOnSuccess = false) => {
  let id = -1
  if (formData.id) {
    id = formData.id
  }

  return {
    type: API_HSX_UPDATE_STATUS,
    id: id,
    backOnSuccess,
    request: {
      url: '/ImportedEvents',
      method: 'PUT',
      data: formData
    }
  }
}
