export const API_LIST_PROCEDURETYPES = 'API_LIST_PROCEDURETYPES';
export const API_GET_PROCEDURETYPES = 'API_GET_PROCEDURETYPES';
export const CREATE_PROCEDURE = 'CREATE_PROCEDURE';

export const listProcedureTypes = (options = {}, asList = false)=> {
  const type = asList ? API_LIST_PROCEDURETYPES : API_GET_PROCEDURETYPES;
  return {
    type,
    request: {
      url: '/ProcedureTypes',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const createProcedureType = (data) => {
  return {
    type: CREATE_PROCEDURE,
    request: {
      url: '/ProcedureTypes',
      method: 'PUT',
      data
    }
  }
}
