import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { LoadingView } from 'components/LoadingView';
import { getPostAcuteEvent } from 'actions/postAcuteEventActions'
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';
import { DateUtil } from 'util/DateUtil';
import { SurgeryPostAcuteEventsView } from 'containers/postAcuteEvents/SurgeryPostAcuteEventsView';
import DataUtil from 'util/DataUtil'
import { PostAcuteEventEditView } from 'containers/postAcuteEvents/PostAcuteEventEditView';
import { getSurgery } from '../../actions/surgeryActions';

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  },
  spacer:{
    height: theme.spacing.unit * 2
  }
});

class PostAcuteEventEditPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const {
      match
    } = this.props;
    //changed
    
    if (prevProps.match.params.postAcuteEventId != match.params.postAcuteEventId) {
      this.doNeededInitialLoads();
    }
  }

  componentDidMount(){
    this.doNeededInitialLoads();
  }

  doNeededInitialLoads(){
    //check if data load needed
    const{
      postAcuteEventId,
      postAcuteEvent,
      surgery, 
      surgeryId,
      dispatch
    } = this.props;

    if(postAcuteEventId > 0 && StoreUtil.needsLoadNoCache(postAcuteEvent)){
      dispatch(getPostAcuteEvent(postAcuteEventId));
    }
    if (surgeryId && !surgery.data) {
      dispatch(getSurgery(surgeryId))
    }
    
  }

  render() {
    const {
      classes,
      postAcuteEvent,
      postAcuteEventId,
      surgery,
      source
    } = this.props;

    let existingData = null;
    let surgeryData;

    if(StoreUtil.isLoadedOrFailed(postAcuteEvent)){
      existingData = StoreUtil.getData(postAcuteEvent);
    }else if (postAcuteEventId != 0){
      return (<LoadingView />);
    }

    if(StoreUtil.isLoadedOrFailed(surgery)){
      surgeryData = StoreUtil.getData(surgery);
    }else{
      return (<LoadingView />);
    }

    DateUtil.stripTimeFromDBDate(existingData, 'Date');
    DateUtil.stripTimeFromDBDate(surgeryData, 'SurgeryDateTime');

    if(existingData){
      //convert the reasons
      existingData = DataUtil.deepCopy(existingData);
      let secondaryReasons = existingData.SecondaryReasons;
      delete existingData.SecondaryReasons;
      let secondaryReasonsIds = secondaryReasons.map((reason)=>{
        return reason.ReasonId;
      });
      existingData.SecondaryReasons = secondaryReasonsIds;
    }

    if(source){
      if(existingData && existingData.Source){
        existingData.Source = source;
      } else {
        existingData = { Source: source }
      }
    }

    const status = FormUtil.GetLoadStatus(postAcuteEvent, 'post acute event');

    return (
      <div className={classes.pageContainer}>
        <SurgeryPostAcuteEventsView surgery={surgeryData} />
        <div className={classes.spacer} />
        <PostAcuteEventEditView
          postAcuteEvent={existingData}
          surgery={surgeryData}
          status={status}
          allowSubmit={!StoreUtil.isSaving(postAcuteEvent)}
        />
      </div>
    );
  }
}

PostAcuteEventEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeryId : PropTypes.number.isRequired,
  surgery: PropTypes.object.isRequired,
  postAcuteEventId : PropTypes.number.isRequired,
  postAcuteEvent : PropTypes.object.isRequired,
  source: PropTypes.string,
  match: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const { postAcuteEvents, surgeries } = state;

  const { match } = existingProps;
  let surgeryId;
  let surgery;

  try{
    surgeryId = parseInt(match.params.surgeryId);
    if(isNaN(surgeryId)){
      surgeryId = 0;
    } else{
      surgery = StoreUtil.get(surgeries, StoreUtil.COMMON_ITEM, surgeryId);
    }
  }catch(error){
    console.log(error);
    surgeryId = 0;
  }
  let postAcuteEventId;
  try{
    postAcuteEventId = parseInt(match.params.postAcuteEventId);
    if(isNaN(postAcuteEventId)){
      postAcuteEventId = 0;
    }
  }catch(error){
    console.log(error);
    postAcuteEventId = 0;
  }

  let source;
  
  if(match && match.params && match.params.source){
    source = match.params.source;
  }
  
  let postAcuteEvent = null;
  if(!postAcuteEventId == 0){
    postAcuteEvent = StoreUtil.get(postAcuteEvents, StoreUtil.COMMON_ITEM, postAcuteEventId);
  }else{
    postAcuteEvent = StoreUtil.get(postAcuteEvents, StoreUtil.COMMON_NEW_ITEM);
  }
  
  return {
    surgeryId,
    surgery,
    postAcuteEventId,
    postAcuteEvent,
    source
  }
}

const styledPage = withStyles(styles)(PostAcuteEventEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PostAcuteEventEditPage};
