import React from "react";
import PropTypes from "prop-types";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  container:{
    width:'100%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column'
  },
  cPathway:{
    paddingTop:theme.spacing.unit * 2,
    paddingBottom:theme.spacing.unit * 2,
    paddingLeft:theme.spacing.unit,
    paddingRight:theme.spacing.unit,
    margin:0
  },
  cRow:{
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between'
  },
  cPathwayAlt:{
    backgroundColor: '#F5F5F5'
  },
  cHeader:{
    backgroundColor: fade(theme.palette.primary.light,0.6),
    paddingTop:theme.spacing.unit,
    paddingBottom:theme.spacing.unit
  },
  input:{
    backgroundColor:'#F8F8F8',
    width:'100%'
  },
  inputLabel:{
    transform: 'none',
    lineHeight:'120%',
    zIndex:10
  },
  inputPathway:{
    width:'35%',
    marginBottom:16,
    marginTop:16,
    backgroundColor:'transparent'
  },
  inputPathwayColor:{
    backgroundColor:'transparent'
  },
  inputLabelPathway:{
    width:'60%',
    position:'relative'
  },
  inputLabelHeader:{
    color:'#FFFFFF'
  }
});

function BaseInput(props) {
  // Note: since React 15.2.0 we can't forward unknown element attributes, so we
  // exclude the "options" and "schema" ones here.
  if (!props.id) {
    console.log("No id for", props);
    throw new Error(`no id for props ${JSON.stringify(props)}`);
  }
  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    schema,
    formContext,
    registry,
    rawErrors,
    classes,
    required,
    label,
    multiline,
    ...inputProps
  } = props;

  if(schema && formContext && registry && rawErrors){
    //just grabbing an error for not using this.  Need to be taken from ...inputProps
  }
  
  inputProps.type = options.inputType || inputProps.type || "text";
  const _onChange = ({ target: { value } }) => {
    return props.onChange(value === "" && options.emptyValue ? options.emptyValue : value);
  };

  let description;
  let hasError = false;
  if(rawErrors != null){
    if((value && value.length > 0)
      || required){
      description = rawErrors;
      hasError = rawErrors != null;
    }
  }else{
    description = schema.description;
  }

  let rows = 1;
  if(multiline){
    rows = 3;
    if(options.rows && options.rows>0){
      rows = options.rows;
    }
  }

  let containerClsList;
  let labelClsList;
  let inputClsList;

  if(options.pathwayMode){
    labelClsList = `${classes.inputLabel} ${classes.inputLabelPathway}`;
    if(multiline){
      inputClsList = `${classes.input} ${classes.inputPathwayColor}`;

      if(options.altRow){
        containerClsList = `${classes.container} ${classes.cPathway} ${classes.cPathwayAlt}`;
      }else{
        containerClsList = `${classes.container} ${classes.cPathway}`;
      }
    }else{
      inputClsList = `${classes.input} ${classes.inputPathway} ${classes.inputPathwayColor}`;

      if(options.altRow){
        containerClsList = `${classes.container} ${classes.cPathway} ${classes.cRow} ${classes.cPathwayAlt}`;
      }else{
        containerClsList = `${classes.container}  ${classes.cPathway} ${classes.cRow}`;
      }
    }
  }else{
    containerClsList = `${classes.container}`;
    labelClsList = `${classes.inputLabel}`;
    inputClsList = `${classes.input}`;
  }

  if(readonly){
    if(options.pathwayMode){
      containerClsList += ` ${classes.cHeader}`;
      labelClsList += ` ${classes.inputLabelHeader}`;
    }
    return (
      <FormControl 
        className={containerClsList}
        required={required}
        disabled={disabled} >
        <InputLabel 
          shrink={true}
          className={labelClsList}
          disabled={disabled}
          disableAnimation={true}
          required={required}
        >{label}</InputLabel>
      </FormControl>
    );
  }else{
    return (
      <TextField 
        required={required}
        label={label}
        className={containerClsList}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        value={value == null ? "" : value}
        onChange={_onChange}
        onBlur={onBlur && (event => onBlur(inputProps.id, event.target.value))}
        onFocus={onFocus && (event => onFocus(inputProps.id, event.target.value))}
        helperText={description}
        error={hasError}
        margin={'normal'}
        multiline={multiline}
        InputProps={{
          className: inputClsList
        }}
        type={inputProps.type}
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
          className: labelClsList
        }}
        rows={rows}
      />
    );
  }
}

BaseInput.defaultProps = {
  type: "text",
  required: false,
  disabled: false,
  readonly: false,
  autofocus: false
};

BaseInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  schema: PropTypes.any,
  formContext: PropTypes.any,
  registry: PropTypes.any,
  rawErrors: PropTypes.any,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  pathwayMode: PropTypes.bool
};

const styled = withStyles(styles)(BaseInput);
export {styled as BaseInput};