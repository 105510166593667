import { createRequestInstance, watchRequests } from 'redux-saga-requests';
import axiosDriver from 'redux-saga-requests-axios';
import { all } from 'redux-saga/effects';
// import { loginSaga } from 'sagas/loginSaga';
// import { logoutSaga } from 'sagas/logoutSaga';
import { copySurgerySaga } from 'sagas/copySurgerySaga';
import { navigateOnSuccessSaga } from 'sagas/navigateOnSuccessSaga';
import { windowScrollSaga } from 'sagas/windowScrollSaga';
import { formResolveSaga } from 'sagas/formResolveSaga';
import { postAcuteEventSaga } from 'sagas/postAcuteEventSaga';
import { autoSignOutSaga } from 'sagas/autoSignOutSaga';
import { surgerySaga } from 'sagas/surgerySaga';
import { navUserSaga } from 'sagas/navUserSaga';
import { missingFacility } from 'sagas/missingFacilitySaga';
import { createFlagSaga, removeFlagSaga } from 'sagas/surgeryFlagSaga';
import { navMessaging } from 'sagas/navMessagingSaga';
import { getQuestionOptionsSaga } from 'sagas/questionOptionsSaga';

export const requests = {
  pageURL: null,
  dbRequests: []
}

function onRequestSaga(request, action) {
  // cache the page's GET request only. This is used when the app needs updated data
  if(request.method === "GET"){
    const pageURL = window.location.toString();
    const usedAction = JSON.parse(JSON.stringify(action))
    delete usedAction.pathname;
    delete usedAction.response;
    if(requests.pageURL !== pageURL){
      requests.pageURL = pageURL;
      requests.dbRequests = [usedAction];
    } else {
      requests.dbRequests.push(usedAction);
    }
  }
  return request;
}

function onResponseSaga(response, action) {
  if(action){
    action.response = response;
  }

  return response;
}

function onErrorSaga(error) {
  return { error };
}

function onAbortSaga(/*action*/) {
}

const root = function* rootSaga(axiosInstance) {
  yield createRequestInstance(axiosInstance, {
    driver: axiosDriver,
    onRequest: onRequestSaga,
    onSuccess: onResponseSaga,
    onError: onErrorSaga,
    onAbort: onAbortSaga
  });
  yield all([
    watchRequests({
      takeLatest: false //setting to true aborts previous requests of the same type
    }),
    // ...loginSaga,
    // ...logoutSaga,
    ...copySurgerySaga,
    ...navigateOnSuccessSaga,
    ...windowScrollSaga,
    ...formResolveSaga,
    ...postAcuteEventSaga,
    ...autoSignOutSaga,
    ...surgerySaga,
    ...navUserSaga,
    ...missingFacility,
    ...createFlagSaga,
    ...removeFlagSaga,
    ...navMessaging,
    ...getQuestionOptionsSaga
  ]);
}
export default root;
