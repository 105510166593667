import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import StoreUtil from 'stores/StoreUtil';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class FacilityBCItem extends Component {

  render() {
    const {
      facility,
      match
    } = this.props;

    let title = "Facility";
    if(match.params.id == 0){
      title = "New Facility";
    }else if (facility && StoreUtil.getData(facility)) {
      title = facility.data.Name;
    }
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          path={NavigationUtil.pacLink("/facilities/"+match.params.id)}
        />
      </React.Fragment>
    );
  }
}

FacilityBCItem .propTypes = {
  facility: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  const { match } = props;
  const { facilities } = state;

  return {
    facility: StoreUtil.get(facilities, StoreUtil.COMMON_ITEM, match.params.id)
  };
}

const connected = connect(mapStateToProps)(FacilityBCItem);
export {connected as FacilityBCItem };