import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StoreUtil from 'stores/StoreUtil'
import { SurgeryTable } from 'components/SurgeryTable';
import { listSurgeries, copySurgeryData } from 'actions/surgeryActions';
import moment from 'moment';
import { ConfirmDialog } from 'components/dialog/Confirm';
import DataUtil from 'util/DataUtil';
import { getSurgeryFlags } from 'actions/surgeryFlagActions';

const styles = ({
  pageContainer:{
    width: '100%'
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  }
});

class SurgeryCopySelectPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {},
      confirmOpen: false
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {dispatch} = this.props;
    this.fetchInitialSurgeries();
    dispatch(getSurgeryFlags())
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id)) {
      this.fetchInitialSurgeries();
    }
  }

  fetchInitialSurgeries() {
    this.setState({query: this.buildInitialQuery() }, this.fetchSurgeries.bind(this));
  }

  buildInitialQuery() {
    const {
      medicalRecNum,
      toSurgeryId
    } = this.props;

    let query = {
      where: {
        MRN: medicalRecNum,
        id: { $ne: toSurgeryId },
        DOS: { $and: { $gt: moment().subtract(1, 'years').format('MM/DD/YYYY') } }
      },
      limit: 25,
      offset: 0
    };

    return query;
  }

  fetchSurgeries() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listSurgeries(query));
  }

  onQueryChange(query) {
    this.setState({query: query }, this.fetchSurgeries.bind(this));
  }

  onRowClick(surgery) {
    this.setState({ confirmOpen: true, fromSurgeryId: surgery.id });
  }

  onConfirm(){
    const { dispatch, toSurgeryId } = this.props;

    this.setState({ confirmOpen: false });
    dispatch(copySurgeryData(toSurgeryId, this.state.fromSurgeryId));
  }

  onCancel(){
    this.setState({ confirmOpen: false });
  }

  render() {
    const {
      classes,
      surgeries,
      user,
      surgeryFlag
    } = this.props;

    const {
      query
    } = this.state;

    const isPTUser = DataUtil.isPTUser(user)

    return (
      <div className={classes.pageContainer}>
        <SurgeryTable
          data={surgeries}
          query={query}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
          noDataMessage="No other surgeries for this patient were found."
          isPTUser={isPTUser}
          surgeryFlag={surgeryFlag}
        />
        <ConfirmDialog
          title={'Confirm Surgery Data Copy'}
          contentText={'Are you sure you want to copy the data from this surgery?'}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          isOpen={this.state.confirmOpen}
          cancelText={'No'}
          confirmText={'Yes'}
        />
      </div>
    );
  }
}

SurgeryCopySelectPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeries: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  toSurgeryId: PropTypes.number,
  medicalRecNum: PropTypes.number,
  surgeryFlag: PropTypes.number.isRequired
};

function mapStateToProps(state, props) {
  const { user, surgeries, surgeryFlag } = state;
  const { match } = props;

  let medicalRecNum;

  try{
    medicalRecNum = parseInt(match.params.mrn);
    if(isNaN(medicalRecNum)){
      medicalRecNum = 0;
    }
  }catch(error){
    console.log(error);
    medicalRecNum;
  }

  let surgeryId;

  try{
    surgeryId = parseInt(match.params.surgeryId);
    if(isNaN(surgeryId)){
      surgeryId = 0;
    }
  }catch(error){
    console.log(error);
    surgeryId;
  }

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }

  return {
    surgeries: StoreUtil.get(surgeries, StoreUtil.COMMON_TABLE),
    toSurgeryId: surgeryId,
    medicalRecNum: medicalRecNum,
    viewAsUser,
    user,
    surgeryFlag
  };
}

const styledPage = withStyles(styles)(SurgeryCopySelectPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as SurgeryCopySelectPage};
