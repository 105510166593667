export const LIST_MESSAGING_LOG = 'LIST_MESSAGING_LOG';
export const CREATE_MESSAGING_LOG = 'CREATE_MESSAGING_LOG';

export const listNavMessagingLog = (options = {}) => {
  return {
    type: LIST_MESSAGING_LOG,
    request: {
      url: '/NavMessagingLog',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const createNavMessagingLog = (data) => {
  return {
    type: CREATE_MESSAGING_LOG,
    request: {
      url: '/NavMessagingLog',
      method: 'PUT',
      data
    }
  }
};