import { success } from 'redux-saga-requests';

import {
  GET_90_DAY_DISCHARGE
} from 'actions/discharge90DayPageActions';

export const discharge90Day = (state = {}, action) => {
  if(action.type === success(GET_90_DAY_DISCHARGE)) {
    return action.data;
  }

  return state;
}