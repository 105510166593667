import dotProp from 'dot-prop-immutable';
import { success, error, abort } from 'redux-saga-requests';

import { initialState } from 'stores/configureStore';

import {
  API_LOGIN,
  API_TWOFACTOR,
  API_LOGOUT,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
  API_RESEND_TWO_FACTOR,
  VIEW_PAC,
  SELECT_PAC_FACILITY
} from 'actions/loginActions';

import {
  UPDATE_VIEW_AS_NAV_USER
} from 'actions/navUserActions';

const hasSessionDied = (action) => {
  let responseObject;
  if(action.error && action.error.response){
    responseObject = action.error.response;
  }else if(action.meta 
    && action.meta.requestAction
    && action.meta.requestAction.response){
    responseObject = action.meta.requestAction.response;
  }

  if(!responseObject){
    //??? where is the response
    return false;
  }
  const is401 = responseObject.status == 401;

  //response might be an array if action is multiple calls
  let hasUserHeader = false;
  if(Array.isArray(responseObject)){
    //check each has user
    for(let x=0; x< responseObject.length; x++){
      hasUserHeader = responseObject[x].headers 
        && responseObject[x].headers.rnav_user
        && responseObject[x].headers.rnav_user.length>20;

      if(!hasUserHeader){
        break;
      }
    }
  }else{
    hasUserHeader = responseObject.headers 
    && responseObject.headers.rnav_user
    && responseObject.headers.rnav_user.length>20;
  }
  return is401 || !hasUserHeader;
}

export const user = (state = {}, action) => {
  switch (action.type) {
  case API_LOGOUT:
    return initialState.user;
  case API_LOGIN:{
    //check for path to return to
    let returnToURL = (state.meta && state.meta.returnToURL) ? state.meta.returnToURL : null;
    if(!returnToURL && action.returnToURL){
      returnToURL = action.returnToURL
    }
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        loginIsCalling: true,
        loginCallTime: (new Date()).getTime(),
        authenticated : false,
        errorMessage : '',
        twoFactorIsCalling: false,
        returnToURL
      })
    });
  }
  case success(API_LOGIN):{
    //save user to state
    let userData = action.data;
    if(!userData.hasOwnProperty('twoFactorRequired')){
      userData.twoFactorRequired = false;
    }
    let hasTwoFactor = userData.twoFactorRequired === true;
    let newState = Object.assign({}, state, userData);
    newState.meta = Object.assign({}, state.meta, {
      loginIsCalling: false,
      authenticated : !hasTwoFactor,
      authenticatedTime: (new Date()).getTime(),
      errorMessage : ''
    });
    
    return newState;
  }
  case error(API_LOGIN):{
    const errorMessage = action && action.error && action.error.response && action.error.response.data || 'Something went wrong'
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        loginIsCalling: false,
        twoFactorNeeded : false,
        errorMessage : errorMessage == 'Unauthorized' ? 'Login failed.  Please check your credentials.' : errorMessage,
        authenticated : false
      })
    });
  }
  case abort(API_LOGIN):
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        loginIsCalling: false,
        twoFactorNeeded : false,
        authenticated : false,
        errorMessage : '',
        twoFactorIsCalling: false
      })
    });
  case error(API_TWOFACTOR):
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        twoFactorIsCalling: false,
        errorMessage : 'Code failed.  Please retype.',
        authenticated : false
      })
    });
  case abort(API_TWOFACTOR):
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        twoFactorIsCalling: false,
        authenticated : false,
        errorMessage : ''
      })
    });
  case API_TWOFACTOR:
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        twoFactorIsCalling : true,
        twoFactorCallTime: (new Date()).getTime(),
        authenticated : false,
        errorMessage : ''
      })
    });
  case success(API_TWOFACTOR): {
    //check flag changed
    let userDataAfter2 = action.data;
    if(!userDataAfter2.hasOwnProperty('twoFactorRequired')){
      userDataAfter2.twoFactorRequired = false;
    }
    let stillNeedsTwoFactor = userDataAfter2.twoFactorRequired === true;

    if(stillNeedsTwoFactor){
      return Object.assign({}, state, {
        meta : Object.assign({}, state.meta, {
          twoFactorIsCalling: false,
          errorMessage : 'Code failed.  Please retype.',
          authenticated : false
        })
      });
    }else{
      return Object.assign({}, state, {
        meta : Object.assign({}, state.meta, {
          twoFactorIsCalling : false,
          authenticated : true,
          authenticatedTime: (new Date()).getTime(),
          errorMessage : ''
        })
      });
    }
  }

  case API_FORGOT_PASSWORD: {
    return Object.assign({}, state, { forgotPassword: { isCalling: true } });
  }
  case success(API_FORGOT_PASSWORD):{
    return Object.assign({}, state, { forgotPassword: {isCalling: false,
      result: 'Success' } });
  }
  case error(API_FORGOT_PASSWORD):{
    return Object.assign({}, state, { forgotPassword: {isCalling: false,
      result: 'Failure' } });
  }
  case API_RESET_PASSWORD: {
    return Object.assign({}, state, { resetPassword: { isCalling: true } });
  }
  case success(API_RESET_PASSWORD):{
    return Object.assign({}, state, { resetPassword: {isCalling: false,
      result: 'Success' } });
  }
  case error(API_RESET_PASSWORD):{
    return Object.assign({}, state, { resetPassword: {isCalling: false,
      result: 'Failure' } });
  }
  case success(API_RESEND_TWO_FACTOR):{
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        resentTwoFactorAt: (new Date()).getTime()
      })
    });
  }
  case error(API_RESEND_TWO_FACTOR):{
    return Object.assign({}, state, {
      meta : Object.assign({}, state.meta, {
        resentTwoFactorErrorAt: (new Date()).getTime()
      })
    });
  }
  case UPDATE_VIEW_AS_NAV_USER: {
    return Object.assign({}, state, {
      viewAsUser: action.user
    });
  }
  case VIEW_PAC: {
    let statePACView = dotProp.set(state, 'meta.nurseNavViewingPac', action.nurseNavViewingPac);
    return dotProp.set(statePACView, 'FacilityId', -1);
  }
  case SELECT_PAC_FACILITY: {
    return dotProp.set(state, 'FacilityId', action.id);
  }
  default:
    if(hasSessionDied(action) 
      && state && state.meta 
      && state.meta.authenticated){
      return Object.assign({}, initialState.user, {
        meta : Object.assign({}, initialState.user.meta, {
          returnToURL:action.pathname
        })
      });
    }else if(state && state.meta 
      && state.meta.authenticated
      && state.meta.returnToURL
      && action.type.includes("LOCATION_CHANGE")
      && action.pathname == state.meta.returnToURL){
      return Object.assign({}, state, {
        meta : Object.assign({}, state.meta, {
          returnToURL:null
        })
      });
    }else{
      return state;
    }
  }
};
