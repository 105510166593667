import ReducerUtil from 'reducers/ReducerUtil'

import {
  API_ALERT_TABLE
} from 'actions/alertActions';

export const alerts = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'alerts');
  if(logout.actionConsumed){
    return logout.state;
  }

  const table = ReducerUtil.reduceTableGet(state, action, API_ALERT_TABLE);
  if(table.actionConsumed){
    if(table.state.table.data && action.data){
      table.state.table.data.rows = action.data.rows || action.data;
    }
    return table.state;
  }

  return state;
}