import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { MaterialForm } from "components/forms/MaterialForm";
import StoreUtil from 'stores/StoreUtil';
import { createMessage } from 'actions/messageActions';
import { getAdmission } from 'actions/admissionActions';
import { Redirect } from 'react-router-dom';
import FormUtil from 'util/FormUtil';
import DataUtil from 'util/DataUtil';
import NavigationUtil from 'util/NavigationUtil'

const schema = {
  title: '',
  type: 'object',
  required: ['NavigatorRequestType','Message'],
  properties: {
    NavigatorRequestType: {
      type: 'string',
      title: 'Type',
      enum: [
        "Admitting Patient to a Portal",
        "Discharge Requests",
        "Update Requests",
        "Discharge Expectations"
      ]
    },
    Message: {
      type: 'string',
      title: 'Message'
    }
  }
};

const uiSchema = {
  Subtype:{
    classNames:'two-column-field'
  },
  Message:{
    "ui:widget": "textarea"
  }
};

const styles = (theme) => ({
  pageContainer:{
    width: 800 + (theme.spacing.unit*4),
    maxWidth: 800 + (theme.spacing.unit*4),
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  }
});

class NewConversationPage extends React.Component {

  constructor(props) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      dispatch,
      admissionId,
      admission
    } = this.props;

    if(StoreUtil.needsLoadLongCache(admission)){
      dispatch(getAdmission(admissionId));
    }
  }

  onFormSubmit(data){
    const {admissionId, dispatch} = this.props;
    const formData = data.formData;

    formData.ConversationType = DataUtil.CONVERSATION_TYPE_OTHER;

    formData.AdmissionId = admissionId;
    /**TODO - database does not yet have a conversation "sub type" column!
      when it does the form may need to change*/
    dispatch(createMessage(formData));
  }

  onFormError(error){
    console.log(error);
  }

  onFormChange(){}

  render() {
    const {
      classes,
      message,
      admissionId,
      admission,
      surgeryId
    } = this.props;

    //save happened, lets redirect
    if (StoreUtil.didJustSave(message)) {
      return (<Redirect to={NavigationUtil.navLink(
        `/surgery/${surgeryId}/admission/${admissionId}/conversation/${StoreUtil.getData(message).id}/`) } />);
    }

    let patientStr = "";
    let data = StoreUtil.getData(admission);
    if(data && data.Surgeries && data.Surgeries.Patients){
      let patientData = data.Surgeries.Patients;
      patientStr = `${patientData.FirstName} ${patientData.LastName}`;
    }
    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(message, 'conversation');

    return (
      <div className={classes.pageContainer}>
        <div className={classes.header}>
          <Typography
            className={classes.headerPageTitle}
            variant="display1">
            New Conversation - {patientStr}
          </Typography>
        </div>
        <MaterialForm
          schema={schema}
          uiSchema={uiSchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

NewConversationPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  surgeryId: PropTypes.number.isRequired,
  admissionId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  admission: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const{
    messages,
    admissions
  } = state;

  const { match } = existingProps;
  let admissionId;
  let surgeryId;
  try{
    admissionId = parseInt(match.params.admissionId);
    surgeryId = parseInt(match.params.surgeryId);
  }catch(error){
    console.log(error);
  }

  let message = StoreUtil.get(messages, StoreUtil.COMMON_NEW_ITEM);
  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);

  return {
    message,
    admissionId,
    admission,
    surgeryId
  }
}

const styledPage = withStyles(styles)(NewConversationPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as NewConversationPage};
