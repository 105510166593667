import { success } from 'redux-saga-requests';

import {
  API_GET_SURGERY_FLAG
} from 'actions/surgeryFlagActions';

export const surgeryFlag = (state = [], action) => {
  if(success(API_GET_SURGERY_FLAG) == action.type){
    return action.data
  }
  return state;
}
