import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';

const styles = ({

});

function activeFormat(active) {
  if(active)
    return "Active"
  else
    return "Inactive"
}

//this controls which columns are displayed and how they are looked up in data
const columns = [
  {label:"First Name", key: "FirstName", sortable: true, sortDown: false},
  {label:"Last Name", key: "LastName", sortable: true, sortDown: false},
  {label:"Email", key: "Email", sortable: true, sortDown: false},
  {label:"Roles", key: "Role", numeric: false, sortable: false,
    sortDown: false, filter: false, style:{width:50}},
  {label:"Facility", key: "Facility.Name", sortable: true, sortDown:false},
  {label:"Active/Inactive", key: "Active", sortable: true, sortDown: false,
    filter: false, format:activeFormat}
];

class UserTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <DynamicTable {...this.props} columns={columns}/>;
  }
}

UserTable.propTypes = DynamicTable.propTypes;

const styled = withStyles(styles)(UserTable);
export {styled as UserTable};