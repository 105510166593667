import ReducerUtil from 'reducers/ReducerUtil';
import DataUtil from 'util/DataUtil';

import {
  API_LIST_DISCHARGEDISPOSITIONS
} from 'actions/dischargeDispositionActions';

export const dischargeDispositions = (state = {}, action) => {
  const list = ReducerUtil.reduceListGet(state, action, API_LIST_DISCHARGEDISPOSITIONS)

  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "Text");
    return list.state;
  }
  return state;
};
