import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { HUDView } from 'containers/HUDView';
import { DynamicTable } from 'components/DynamicTable';
import { listAdmissions } from 'actions/admissionActions';
import { viewSurgery } from 'actions/surgeryActions'
import StoreUtil from 'stores/StoreUtil';
import DateUtil from 'util/DateUtil';
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';
import moment from 'moment';

const styles = ({
  pageContainer:{
    width: "100%"
  }
});

const columns = [
  {label:"MRN", key: "Surgery.Patient.MedicalRecNum", sortable: true, sortDown: false, style:{width:60}},
  {label:"Last Name", key: "Surgery.Patient.LastName", sortable: true, sortDown: false},
  {label:"First Name", key: "Surgery.Patient.FirstName", sortable: true, sortDown: false},
  {label:"Surgery", key: "Surgery.SurgeryDateTime", type:"date", sortable: true,
    format: DateUtil.formatFromDB, style:{width:80}},
  {label:"Anticipated DC Date", key: "AnticipatedDischargeDate", type:"date", sortable: true,
    format: DateUtil.formatFromDB, style:{width:150}},
  {label:"Facility", key: "Facility.Name", sortable: true, sortDown: false}
];

const TABLE_NAME = "OverdueDischargesPage";
class OverdueDischargesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitialAdmissions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewAsUser != null &&
    (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {

      this.fetchInitialAdmissions();
    }
  }

  fetchInitialAdmissions() {
    this.setState({query: this.buildInitialQuery() }, this.fetchAdmissions.bind(this));
  }

  buildInitialQuery() {
    const {
      preferenceQuery
    } = this.props;

    let query = {
      where: {
        AnticipatedDischargeDate: { $lt: moment().format('L') },
        DischargeDate: null
      },
      order: [
        ['AnticipatedDischargeDate','ASC']
      ],
      limit: 50,
      offset: 0
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    return query;
  }

  fetchAdmissions() {
    const { dispatch, viewAsUser } = this.props;
    const { query } = this.state;

    const facilityOptions = {
      where: {
        Name: { $ne: 'Novacare' }
      }
    }

    dispatch(listAdmissions(query, viewAsUser.id, facilityOptions));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchAdmissions.bind(this));
  }

  onRowClick(admission) {
    const { dispatch } = this.props;

    let surgery = { id: admission.SurgeryId }

    dispatch(viewSurgery(surgery))
  }

  render() {
    const {
      classes,
      admissions
    } = this.props;

    const {
      query
    } = this.state;

    let header = 'Overdue Discharges';

    return (
      <div className={classes.pageContainer}>

        <HUDView />

        <DynamicTable
          columns={columns}
          data={admissions}
          header={header}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          idKey="id"
        />

      </div>
    );
  }
}

OverdueDischargesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  admissions: PropTypes.object,
  viewAsUser: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const {
    admissions,
    user,
    preference
  } = state;

  var { viewAsUser } = user;
  if(!viewAsUser){
    viewAsUser = user;
  }

  let taskList = StoreUtil.get(admissions, StoreUtil.COMMON_TABLE);

  return {
    admissions: taskList,
    viewAsUser,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(OverdueDischargesPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as OverdueDischargesPage};
