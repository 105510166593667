import React, {Component} from 'react';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class PACPatientSearchBCItem extends Component {

  render() {
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label="Find Patient"
          icon="search"
          path={NavigationUtil.pacLink("/patientSearch")}
        />
      </React.Fragment>
    );
  }
}

PACPatientSearchBCItem.propTypes = {
};


export {PACPatientSearchBCItem as PACPatientSearchBCItem };