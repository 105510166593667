import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil'

import { viewCreatePlanChange } from 'actions/messageActions'

const styles = (theme) => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing.unit*2
  },
  header: {
    display:'flex',
    justifyContent:'space-between'
  },
  content: {
    paddingBottom: 16
  },
  title: {

  }
});

class MessagesHeaderView extends React.Component {

  onEditClick(){
    const {
      conversation,
      dispatch,
      isPAC,
      surgeryId
    } = this.props;
    const data = StoreUtil.getData(conversation);
    //send user to plan change edit page
    if(data && data.AdmissionId){
      dispatch(viewCreatePlanChange(data.AdmissionId, isPAC, surgeryId, data.AdmissionPlanChanges.id))
    }
  }

  render() {
    const {
      classes,
      conversation,
      isPAC
    } = this.props;

    const data = StoreUtil.getData(conversation);
    let content = '';
    if(data){
      let title;
      let reasons;
      let planChangeStatus;
      let extension;
      const isPlanChange = data.ConversationType == DataUtil.CONVERSATION_TYPE_PLAN_CHANGE;
      let canEdit = false;
      if(isPlanChange){
        title = "Plan Change Rationale";
        extension = data.AdmissionPlanChanges.LosExtension;
        if(data.AdmissionPlanChanges.AdmissionPlanChangeReasons
            && data.AdmissionPlanChanges.AdmissionPlanChangeReasons.length>0){
          reasons = "";
          
          data.AdmissionPlanChanges.AdmissionPlanChangeReasons.forEach((reason)=>{
            if(reason.Reason.Text){
              reasons+= reason.Reason.Text+", ";
            }
          });

          reasons = reasons.substring(0, reasons.length - 2);
        }
        planChangeStatus = data.AdmissionPlanChanges.Status;
        canEdit = !isPAC && !DataUtil.isConversationResolved(data);
      }else{
        title = "Conversation";
      }
      content = (
        <React.Fragment>
          <div className={classes.header}>
            <Typography className={classes.title} variant="headline" color="textSecondary">
              {title}
            </Typography>
            {(canEdit &&
              <Button
                color="primary"
                onClick={this.onEditClick.bind(this)}
                variant="contained">
                Edit Plan
              </Button>
            )}
          </div>
          {(extension &&
            <Typography variant="subheading" className={classes.number}>
              <b>LOS Extension: </b>{extension}
            </Typography>
          )}
          {(reasons &&
            <Typography variant="subheading" className={classes.number}>
              <b>Reason: </b> {reasons}
            </Typography>
          )}
          {(planChangeStatus &&
            <Typography variant="subheading" className={classes.number}>
              <b>Plan Change Status: </b> {planChangeStatus}
            </Typography>
          )}
          <Typography variant="subheading" className={classes.number}>
            <b>Conversation Status: </b> {data.Status}
          </Typography>
        </React.Fragment>
      );
    }
    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          {content}
        </CardContent>
      </Card>
    );
  }
}

MessagesHeaderView.propTypes = {
  classes: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  isPAC: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  surgeryId: PropTypes.number
}

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(MessagesHeaderView);
const connectedPage = connect(mapStateToProps)(styled);
const injected = connectedPage;
export { injected as MessagesHeaderView };
export default injected;
