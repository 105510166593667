import { call, takeEvery } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import { success, sendRequest } from 'redux-saga-requests';
import { sendInvite } from 'actions/navUserActions';
import {
  API_CREATE_NAV_USER
} from 'actions/navUserActions';

function* sendInviteToUser(action) {
  yield call(
    sendRequest,
    sendInvite(action.meta.requestAction.response.data.id),
    { dispatchRequestAction: false });
}

export const navUserSaga = [
  takeEvery(success(API_CREATE_NAV_USER), sendInviteToUser)
];
