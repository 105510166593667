import ReducerUtil from 'reducers/ReducerUtil'


import {
  API_ADMISSION_CREATE,
  API_GET_ADMISSIONS,
  API_GET_ADMISSION
} from 'actions/admissionActions';

export const admissions = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'admissions');
  if(logout.actionConsumed){
    return logout.state;
  }

  const table = ReducerUtil.reduceTableGet(state, action, API_GET_ADMISSIONS);
  if(table.actionConsumed){
    return table.state;
  }

  const single = ReducerUtil.reduceSingleGet(state, action, API_GET_ADMISSION);
  if(single.actionConsumed){
    return single.state;
  }

  const create = ReducerUtil.reduceUpdate(state, action, API_ADMISSION_CREATE);
  if(create.actionConsumed){
    return create.state;
  }
  return state;
}