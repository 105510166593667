import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import StoreUtil from 'stores/StoreUtil';

import { PatientDetailView } from 'containers/patients/PatientDetailView';
import { PatientSurgeriesView } from 'containers/patients/PatientSurgeriesView';
import NavigationUtil from 'util/NavigationUtil'

const styles = theme => ({
  pageContainer:{
    width: "100%",
    marginTop: 40
  },
  spacer: {
    height: theme.spacing.unit*2
  }
});

class PatientDetailPage extends React.Component {

  render() {
    const {
      classes,
      match
    } = this.props;

    let mrn;
    if (match.params && match.params.mrn) {
      mrn = parseInt(match.params.mrn);
    }

    let externalSource;
    if (match.params && match.params.exteralSource) {
      externalSource = match.params.exteralSource;
    }
    if (!mrn || !externalSource) {
      //we must have an mrn number in url
      return (<Redirect to={NavigationUtil.navLink("/dashboard")} />);
    }

    return (
      <div className={classes.pageContainer}>
        <PatientDetailView patientMRN={mrn} externalSource={externalSource} showDetailsButton={false}/>
        <div className={classes.spacer} />
        <PatientSurgeriesView patientMRN={mrn} externalSource={externalSource} className={classes.marginTop}/>
      </div>
    );
  }
}

PatientDetailPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
  // patient: PropTypes.object
};

// MSTP should be removed, patient is not here
function mapStateToProps(state, props) {
  const { patients } = state;
  const { match } = props;
  // NOTE: byMRN is never set
  return {
    patient : StoreUtil.get(patients, "byMRN", match.params.mrn)
  };
}

const styledPage = withStyles(styles)(PatientDetailPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PatientDetailPage};
