import React, { Component } from 'react';
import { Provider} from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react'

// import { socket } from './socketio';
import Button from '@material-ui/core/Button';

import { configureStore } from 'stores/configureStore';
import { App } from 'containers/App';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1b3465'
    },      
    secondary: {
      main: '#d8b175'
    }
  },

  overrides: {
    MuiTableCell: {
      root: {
        padding: '10px 8px 10px 8px',
        '&:last-child': {
          paddingRight: '10px'
        }
      }
    }
  }
});
const { store, persistor, history }  = configureStore();

export class Root extends Component {

  constructor(props){
    super(props)
    this.state = { update: false }
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount(){
    // socket.emit('connection');
    // socket.on('update', obj => {
    //   if(obj.model === "mulitple") this.setState({ update: true  });
    // })
  }

  refreshPage () {
    this.setState({ update: true })
    location.reload();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiThemeProvider theme={theme}>
            <App history={history}/>
            {this.state.update && <Button
              style={{
                position: "absolute",
                bottom: 10,
                right: 10
              }}
              variant="contained"
              color="primary"
              onClick={this.refreshPage}>
              update avaliable
            </Button>}
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}
