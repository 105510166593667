import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {QuestionGroupView} from 'containers/forms/QuestionGroupView';

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class QuestionGroupPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      surgeryId,
      questionGroupId,
      taskId,
      user
    } = this.props;
    
    return (
      <div className={classes.pageContainer}>
        <QuestionGroupView
          surgeryId={surgeryId}
          questionGroupId={questionGroupId}
          taskId={taskId}
          user={user}
        />
      </div>
    );
  }
}

QuestionGroupPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeryId: PropTypes.number.isRequired,
  questionGroupId: PropTypes.number.isRequired,
  taskId: PropTypes.number,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const { match } = existingProps;
  const { user } = state;
  let surgeryId;
  let questionGroupId;
  let taskId;
  try{
    surgeryId = parseInt(match.params.surgeryId);
    if(isNaN(surgeryId)){
      surgeryId = 0;
    }
    questionGroupId = parseInt(match.params.questionGroupId);
    if(isNaN(questionGroupId)){
      questionGroupId = 0;
    }

    if(!isNaN(parseInt(match.params.taskId))){
      taskId = parseInt(match.params.taskId);
    }
  }catch(error){
    console.log(error);
    surgeryId = 0;
    questionGroupId = 0;
  }
  
  return {
    surgeryId,
    questionGroupId,
    taskId,
    user
  }
}

const styledPage = withStyles(styles)(QuestionGroupPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as QuestionGroupPage};
