import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  container:{
    marginTop:theme.spacing.unit
  },
  list:{
    maxHeight : 400,
    width:'100%'
  },
  selectAll:{
    
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent: 'space-between'
  },
  containerP:{
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent:'space-between',
    paddingTop:theme.spacing.unit * 2,
    paddingBottom:theme.spacing.unit * 2,
    paddingLeft:theme.spacing.unit,
    paddingRight:theme.spacing.unit,
    margin:0
  },
  containerPAlt:{
    backgroundColor: '#F5F5F5'
  },
  listP:{
    width:'25%',
    backgroundColor:'transparent'
  },
  headerP:{
    width:'60%'
  }
});

function selectValue(value, selected, all) {
  const selectedArr = selected || [];
  const at = all.indexOf(value);
  const updated = selectedArr.slice(0, at).concat(value, selected.slice(at));
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value, selected) {
  return selected.filter(v => v !== value);
}

function CheckboxesWidget(props) {
  const { 
    id, 
    disabled, 
    options, 
    value, 
    autofocus, 
    readonly, 
    onChange,
    rawErrors,
    required,
    schema,
    classes
  } = props;
  const { enumOptions } = options;
  /*return (
    <div className="checkboxes" id={id}>
      {enumOptions.map((option, index) => {
        const checked = value.indexOf(option.value) !== -1;
        const disabledCls = disabled || readonly ? "disabled" : "";
        const checkbox = (
          <span>
            <input
              type="checkbox"
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={event => {
                const all = enumOptions.map(({ value }) => value);
                if (event.target.checked) {
                  onChange(selectValue(option.value, value, all));
                } else {
                  onChange(deselectValue(option.value, value));
                }
              }}
            />
            <span>{option.label}</span>
          </span>
        );
        return inline ? (
          <label key={index} className={`checkbox-inline ${disabledCls}`}>
            {checkbox}
          </label>
        ) : (
          <div key={index} className={`checkbox ${disabledCls}`}>
            <label>{checkbox}</label>
          </div>
        );
      })}
    </div>
  );*/

  let description = null;
  if(rawErrors != null){
    description = rawErrors;
  }else if(schema.description){
    description = schema.description;
  }

  const showAllButton = options.showAllButton;
  const all = enumOptions.map(({ value }) => value);
  const allChecked = value && value.length == all.length;
  let buttonText;
  if(allChecked){
    buttonText = "Deselect All";
  }else{
    buttonText = "Select All";
  }

  let containerCls;
  let headerCls;
  let itemCls;
  if(options.pathwayMode){
    if(options.altRow){
      containerCls = `${classes.containerP} ${classes.containerPAlt}`;
    }else{
      containerCls = classes.containerP;
    }
    headerCls = classes.headerP;
    itemCls = classes.listP;
  }else{
    containerCls = classes.container;
    headerCls = classes.header;
    itemCls = classes.list;
  }

  return (
    <div className={containerCls}>
      <div className={headerCls}>
        <FormLabel component="legend">{schema.title}</FormLabel>
        {showAllButton && (<Button
          color="primary"
          variant="text"
          onClick={() => {
            if(allChecked){
              onChange([]);
            }else{
              onChange(all);
            }
          }}
        >{buttonText}</Button>)}
      </div>
      <FormControl 
        className={itemCls}
        component="fieldset" 
        required={required} 
        error={rawErrors != null}>
        {enumOptions.map((option, index) => {
          const checked = Boolean(value) && value.indexOf(option.value) !== -1;
          const checkbox = (
            <FormControlLabel
              autoFocus={autofocus && index === 0}
              disabled={disabled || readonly}
              key={`${id}_${index}`}
              control={
                <Checkbox
                  id={`${id}_${index}`}
                  checked={checked}
                  onChange={event => {
                    if (event.target.checked) {
                      onChange(selectValue(option.value, value, all));
                    } else {
                      onChange(deselectValue(option.value, value));
                    }
                  }}
                />
              }
              label={option.label}
            />
          );
          return checkbox;
        })}
        {description &&
          <FormHelperText>{description}</FormHelperText>
        }
      </FormControl>
    </div>
  );
}

CheckboxesWidget.defaultProps = {
  autofocus: false,
  options: {
    inline: false
  }
};

CheckboxesWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool
  }).isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.any,
  classes: PropTypes.any
};

const styled = withStyles(styles)(CheckboxesWidget);
export {styled as CheckboxesWidget};
