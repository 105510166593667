import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
});

class ConfirmDialog extends React.Component {
  render() {
    const {
      classes,
      title,
      contentText,
      onConfirm,
      onCancel,
      isOpen,
      cancelText,
      confirmText
    } = this.props;

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
        >
          <DialogTitle className={classes.title}>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              {contentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={onCancel} color="primary">
              {cancelText}
            </Button>
            <Button className={classes.button} onClick={onConfirm} color="primary" autoFocus>
              {confirmText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired
}

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(ConfirmDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ConfirmDialog };
