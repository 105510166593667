import React, { Fragment, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import NavigationUtil from 'util/NavigationUtil'
import { Footer } from 'components/Footer';

import { PACHeader } from 'containers/pac/PACHeader';
import { PACDashboardPage } from 'containers/pac/PACDashboardPage';
import { PACPatientSearchPage } from 'containers/pac/PACPatientSearchPage';
import { PACAdmissionDetailPage } from 'containers/pac/PACAdmissionDetailPage'
import { FacilityPage } from 'containers/facilities/FacilityPage';
import { FacilityEditPage } from 'containers/facilities/FacilityEditPage';
import { PACSelectFacilityPage } from 'containers/pac/PACSelectFacilityPage';
import { PACConversationsPage } from 'containers/pac/PACConversationsPage';
import { PACMessagesPage } from 'containers/pac/PACMessagesPage';
import { PACPlanChangeRationalePage } from 'containers/pac/PACPlanChangeRationalePage';
import { PACQuestionGroupPage } from 'containers/pac/PACQuestionGroupPage';
import { PACHospitalVisitPage } from 'containers/pac/PACHospitalVisitPage';

const styles = () =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width:"100%"
  }
});

class PACRoot extends Component {
  render() {
    const {
      classes,
      facilityId,
      location
    } = this.props;

    //if you come into pac without a facility, you must pick
    if((facilityId === null || facilityId === undefined || facilityId < 0) 
      && !location.pathname.includes('selectFacility')){
      return (<Redirect to={NavigationUtil.pacLink('/selectFacility')} push={false} />);
    }
    return (
      <div className={classes.container}>
        <PACHeader />
        <Fragment>
          <Switch>
            <Route
              path={NavigationUtil.pacdir+'/home/:tab?'}
              component={PACDashboardPage} />
            <Route
              path={NavigationUtil.pacdir+'/patientSearch'}
              component={PACPatientSearchPage}
              exact={true} />
            <Route
              path={NavigationUtil.pacdir+'/admission/:id'}
              component={PACAdmissionDetailPage}
              exact={true} />
            <Route
              path={NavigationUtil.pacdir+'/facilities'}
              component={FacilityPage}
              exact={true} />
            <Route
              path={NavigationUtil.pacdir+'/facilities/:facilityId'}
              component={FacilityEditPage}
              exact={true} />
            <Route
              path={NavigationUtil.pacdir+'/selectFacility'}
              component={PACSelectFacilityPage}
              exact={true} />
            <Route
              path={NavigationUtil.pacdir+'/conversations/:conversationType/:tab?'}
              component={PACConversationsPage} />
            <Route
              path={NavigationUtil.pacdir+'/admission/:admissionId/conversation/:conversationId/:messageId?'}
              component={PACMessagesPage}
              exact={true} />
            <Route
              path={NavigationUtil.pacdir+'/admission/:admissionId/planchange'}
              component={PACPlanChangeRationalePage}
              exact={true} />

            <Route
              path={NavigationUtil.pacdir+'/admission/:admissionId/surgery/:surgeryId/form/:questionGroupId'}
              component={PACQuestionGroupPage}
              exact={true} />

            <Route
              path={NavigationUtil.pacdir+'/admission/:admissionId/ed/:id/:dischargeAfter?'}
              component={PACHospitalVisitPage}
              exact={true} />

            <Redirect to={NavigationUtil.pacdir+'/home'} />
          </Switch>
        </Fragment>
        <Footer className={classes.footer}/>
      </div>
    );
  }
}

PACRoot.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  facilityId : PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const { user } = state;
  let facilityId = -1;
  if(user && user.FacilityId){
    facilityId = user.FacilityId;
  }
  return {
    facilityId
  };
}

const styledApp = withStyles(styles)(PACRoot);
const connectedApp = connect(mapStateToProps)(styledApp);
const routedApp = withRouter(connectedApp);
export {routedApp as PACRoot};
