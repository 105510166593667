import React, {Component, Fragment} from 'react';
import { DatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core/styles';
import DataUtil from 'util/DataUtil';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  Icon
} from '@material-ui/core';

import { PropTypes } from 'prop-types';
import moment from 'moment';
import StoreUtil from 'stores/StoreUtil';
import dotProp from 'dot-prop-immutable';
import DateUtil from 'util/DateUtil'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  table:{
    minWidth: 800
  },
  tableDialog:{
    minWidth: 500
  },
  filterField : {
    marginBottom: 0
  },
  filterInputField: theme.typography.caption,
  filterInputField2:{
    lineHeight:'inherit'
  },
  dateField:{
    '& > div > input': {
      fontSize:'0.75rem'
    },
    '& > div > div > button': {
      width:20,
      '& > span > span': {
        fontSize:18
      }
    },
    '& > p': {
      display:'none'
    }
  },
  altRow:{
    backgroundColor: 'rgba(0,0,0,0.03)',
    cursor: "pointer",
    height: 30
  },
  invalidRow:{
    backgroundColor: 'rgba(255,0,0,0.1)',
    cursor: "pointer",
    height: 30
  },
  regRow:{
    cursor: "pointer",
    height: 30
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 300
  },
  spinnerWrapper: {
    textAlign: "center",
    padding: 120
  },
  noData: {
    fontWeight: 1000,
    fontSize: 18,
    textAlign: "center",
    padding: 50
  },
  title: {
    marginBottom: 10
  },
  custom: {
    //this allows top level pages to override and pass in styles
  }
});

class DynamicTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false, // controls the diaolog box open status (end date option)
      DateRange: {},
      selectingEndDate: false,
      dateColumnKey: null
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);

    // opens the end date question dialog box
    this.openEndDateDialogBox = this.openEndDateDialogBox.bind(this);
    // handle the case in which the user rejects an end date
    this.handleEndDateReject = this.handleEndDateReject.bind(this);
    // handle the case in which the user requests an end date
    this.handleEndDateAccept = this.handleEndDateAccept.bind(this);
    // disables the days before the start date on the data picker
    this.disableDaysBeforeStartDate = this.disableDaysBeforeStartDate.bind(this);
    this.isHighRisk = this.isHighRisk.bind(this);
    this.setDOSOnPageLoad = this.setDOSOnPageLoad.bind(this);
    this.getDOSDates = this.getDOSDates.bind(this);
    this.hasSelectedADateRange = this.hasSelectedADateRange.bind(this);
  }

  componentDidMount() {
    this.cacheColumnPaths();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.columns != prevProps.columns) {
      this.cacheColumnPaths();
    }
    this.setDOSOnPageLoad();    
  }

  setDOSOnPageLoad() {
    // set the DOS to state when returning to page if it's not already set
    const filter = this.props.query;
    const hasDOSFilter = filter && filter.where && filter.where.DOS;
    const hasNotRecordedDateRange = !this.state.DateRange.hasOwnProperty("DOS");
    if(hasDOSFilter && hasNotRecordedDateRange){
      const dosDates = this.getDOSDates();
      const hasSelectedADateRange = this.hasSelectedADateRange(dosDates);
      const DOS = {
        startDate: dosDates.firstDate,
        endDate: hasSelectedADateRange ? dosDates.secondDate : dosDates.firstDate
      }
      this.setState({ DateRange: { DOS } });
    }
  }

  getDOSDates(){
    // returns the start and end filtered dates
    const filter = this.props.query;
    return {
      firstDate: moment(filter.where["DOS"].$and.$gt).add(1,'days').format(DateUtil.DISPLAY_FORMAT),
      secondDate: moment(filter.where["DOS"].$and.$lt).subtract(1,'days').format(DateUtil.DISPLAY_FORMAT)
    }
  }

  hasSelectedADateRange(dosDates){
    // returns a boolean to determine if there is a filtered datange range
    const minimumDaysToBeConsideredADateRange = 2;
    return moment(dosDates.secondDate).diff(dosDates.firstDate, "days") > minimumDaysToBeConsideredADateRange;
  }

  onDateRangePage() {
    // returns a boolean to determine if the currect page has to date range filter available
    const onDateRangePage =
      (window.location.href.indexOf("nursenav/dashboard") !== -1) ||
      (window.location.href.indexOf("nursenav/tasks") !== -1) ||
      (window.location.href.indexOf("nursenav/patients") !== -1);
    return onDateRangePage;
  }

  handleDateInputChange(columnKey, event){
    // handles a date range change
    if(!this.onDateRangePage()) return;

    const value = event.target.value;
    // the query used to filter the data
    let query = Object.assign({}, this.props.query);
    if(value
      && value.length == DateUtil.DISPLAY_FORMAT.length
      && value.indexOf('_') == -1){
      let newDate = moment(value, DateUtil.DISPLAY_FORMAT);
      if(newDate){
        this.onFilterChange(columnKey,newDate);
      }

      if(query && query.where) delete query.where[columnKey]
      if (this.props.onQueryChange) this.props.onQueryChange(query);
    } else if(value === ""){
      // if the date is clear the value will be empty
      // clear the date field by delete the date key in the query
      delete query.where[columnKey];
      this.props.onQueryChange(query);
    }
  }

  handleDateOnChange(columnKey, value, hasAskForEndDate = false) {
    if(!hasAskForEndDate && value && !this.state.selectingEndDate){
      // use the date format with DISPLAY_FORMAT format
      const formattedDate = moment(value).format(DateUtil.DISPLAY_FORMAT);
      // set the start date in state
      return this.setState({
        DateRange: {
          [columnKey]: {
            startDate: formattedDate
          }
        },
        dateColumnKey: columnKey
      }, () => this.openEndDateDialogBox()); // open the dialog box to ask for the end date
    }

    const column = this.state.dateColumnKey || columnKey;
    if(!hasAskForEndDate && !this.state.selectingEndDate) return this.onFilterChange(column, value);
    
    if(this.state.selectingEndDate){
      const end = moment(value).add(1, "days");
      const endDate = moment(end).format(DateUtil.DISPLAY_FORMAT);
      return this.setState({
        DateRange: {
          [column]: {
            ...this.state.DateRange[column],
            endDate
          }
        },
        selectingEndDate: false
      }, () => this.onFilterChange(column, this.state.DateRange[column].startDate));
    }

    const hasStartDate = this.state.DateRange[column].hasOwnProperty("startDate");
    const hasEndDate = this.state.DateRange[column].hasOwnProperty("endDate");
    if(hasStartDate && hasEndDate){
      const startDate = this.state.DateRange[column].startDate;
      this.onFilterChange(column, startDate)
    } else {
      const startDate = this.state.DateRange[column].startDate;
      const endDate = moment(value).format(DateUtil.DISPLAY_FORMAT);
      this.setState({
        DateRange: {
          [column]: {
            startDate,
            endDate
          }
        }
      }, () => this.onFilterChange(column, startDate));
    }
  }

  openEndDateDialogBox() {
    this.setState({  open: true  }); // opens the dialog box to ask for end date
  }

  datePickerDialog; // date  picker reference set up (used to ope the data picker programmatically)

  setDatePickerDialogReference(ref) { // date  picker reference set up (used to ope the data picker programmatically)
    this.datePickerDialog = ref;
  }

  handleEndDateReject() { // if the user does not want to select a date range
    this.setState({
      open: false
    }, () => this.handleDateOnChange(
      this.state.dateColumnKey, this.state.DateRange[this.state.dateColumnKey].startDate, true)
    );
  }

  handleEndDateAccept() {
    this.setState({ // close dialog box, indicate that the end date has been requested
      open: false,
      selectingEndDate: true
    }, () => this.datePickerDialog.open()); // open calendar
  }

  disableDaysBeforeStartDate(day) {
    if(this.state.DateRange[this.state.dateColumnKey] &&
    this.state.DateRange[this.state.dateColumnKey].startDate &&
    this.state.selectingEndDate){
      const format = DateUtil.DISPLAY_FORMAT;
      const formatted = moment(day).format(format);
      const isBeforeStartDate = (
        moment(this.state.DateRange[this.state.dateColumnKey].startDate).diff(formatted, "days") >= 0
      );
      return isBeforeStartDate;
    }
    return false
  }

  onOrderChange(newOrderBy) {
    let query = Object.assign({}, this.props.query);
    let oldOrderBy = this.props.idKey;
    let order = "DESC";

    let oldHasOrder = false;
    if (query.order && query.order.length > 0) {
      //save out old order if it hasn't been saved
      if(!query.baseOrder){
        query.baseOrder = query.order;
      }
      oldOrderBy = query.order[0].slice(0,query.order[0].length-1).join('.');
      order = query.order[0][query.order[0].length-1];
      oldHasOrder = true;
    }else{
      //no base order, so it must be the id field
      query.baseOrder = [[this.props.idKey, 'DESC']];
    }

    if (oldHasOrder && oldOrderBy == newOrderBy) {
      //already sorting this column, change direction
      if (order.toUpperCase() == "ASC") {
        order = "DESC";
      } else {
        order = "ASC";
      }
      query.order[0][query.order[0].length-1] = order;
    }else{
      query.order = [];
      if(query.baseOrder){
        query.order = query.order.concat(query.baseOrder);
      }

      let orderItem;
      //if new order by is a deep object, with "." operators in it,
      //need to be made into array with each piece of path in array
      if(newOrderBy.indexOf('.') > -1){
        orderItem = newOrderBy.split('.');
        orderItem.push(order.toUpperCase());
      }else{
        orderItem = [newOrderBy, order.toUpperCase()];
      }

      //make sure this isn't in the base sort
      let includedInBase = false;
      for(let x=0;x<query.order.length;x++){
        let sort = query.order[x];
        if(sort.length == orderItem.length){
          let allMatch = true;
          for(let y=0;y<sort.length-1;y++){
            if(sort[y] != orderItem[y]){
              allMatch = false;
              break;
            }
          }
          includedInBase = allMatch;
          break;
        }
      }
      if(!includedInBase){
        query.order.unshift(orderItem);
      }
    }

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onFilterChange(columnKey, event) {
    let column = this.getColumnByKey(columnKey);
    if(column.filterKey){
      columnKey = column.filterKey;
    }
    let query = Object.assign({}, this.props.query);

    let objPath = "";
    let parentObj = query;
    let columnFieldName = columnKey;
    if(columnKey.indexOf('.') > -1 && parentObj.include){
      let varNames = columnKey.split('.');
      columnFieldName = varNames[varNames.length-1];
      varNames = varNames.slice(0,-1);
      let x;
      varNames.forEach((name)=>{
        for(x=0; x<parentObj.include.length; x++){
          if(parentObj.include[x].association == name){
            if(objPath){
              objPath += ".include."+x;
            }else{
              objPath += "include."+x;
            }
            parentObj = parentObj.include[x];
            break;
          }
        }
      })
    }

    if(!parentObj.where){
      parentObj.where = {}
    }
    let filters = parentObj.where;
    let value;

    const useFieldInQuery = this.state.DateRange.hasOwnProperty(columnKey);
    if (column.type == "date" && useFieldInQuery) {
      value = event;

      const fieldKey = this.state.dateColumnKey || columnKey;
      const diff = Math.abs(
        moment(
          this.state.DateRange[fieldKey].startDate).diff(this.state.DateRange[fieldKey].endDate, "days"
        )
      );
      const dateRange = diff ? diff : 1;

      if (value) {
        filters[columnFieldName] = {
          $and:{
            $gt: moment(this.state.DateRange.startDate || value).subtract(1,'d').format(DateUtil.FORM_DATE_FORMAT),
            $lt: moment(this.state.DateRange.endDate || value).add(dateRange,'d').format(DateUtil.FORM_DATE_FORMAT)
          }
        };
      } else {
        delete filters[columnFieldName];
      }

    } else if(event.target){
      value = event.target.value;
      if (value) {
        if (value.length > 2 && value.startsWith('"') && value.endsWith('"')) {
          filters[columnFieldName] = { $eq: value.substring(1,value.length-1)};
        } else if (column.filterExact && column.filterExact === true)  {
          filters[columnFieldName] = { $eq: value};
        }
        else {
          filters[columnFieldName] = { $like: "%"+value+"%"};
        }
      } else {
        delete filters[columnFieldName];
      }
    }

    let fullPath;
    if(objPath){
      fullPath = objPath+".where";
    }else{
      fullPath = "where";
    }
    if(Object.keys(filters).length == 0){
      query = dotProp.delete(query, fullPath);
    }else{
      query = dotProp.set(query, fullPath, filters);
    }

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onPageChange(event, page) {
    // change the page offset when switching between pages
    let query = Object.assign({}, this.props.query);

    query.offset = page * query.limit;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onRowsPerPageChange(event) {
    // change the table row limit ex: 25, 50, 100, 200
    let query = Object.assign({}, this.props.query);

    query.limit = event.target.value;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  flattenWhereQuery(obj, child, path){
    if(!child){
      return;
    }
    if(child.where){
      for (var prop in child.where) {
        if(path){
          obj[path+"."+prop] = child.where[prop];
        }else{
          obj[prop] = child.where[prop];
        }
      }
    }
    if(child.include && Array.isArray(child.include)){
      child.include.forEach((include)=>{
        if(path){
          this.flattenWhereQuery(obj,include,path+"."+include.association);
        }else{
          this.flattenWhereQuery(obj,include,include.association);
        }
      })
    }
  }

  onRowClick(row) {
    if (this.props.onRowClick) {
      this.props.onRowClick(row);
    }
  }

  getDateRangeFilterLabel(filters, column) {
    if(!this.onDateRangePage()) return;
    const hasDateRangeFilters = filters && filters.where &&
                                filters.where.hasOwnProperty(column.key) && filters.where["DOS"];
    if(hasDateRangeFilters){
      const dosDates = this.getDOSDates();
      if(this.hasSelectedADateRange(dosDates)){
        return `${dosDates.firstDate} - ${dosDates.secondDate}`;
      }
    }
    return null;
  }

  isHighRisk(id){
    const {surgeryFlag} = this.props;
    const HighRiskFlagID = 1;

    let isHighRisk = false;
    if(surgeryFlag && surgeryFlag.length && id){
      surgeryFlag.forEach(data => {
        const { SurgeryId } = data;
        if(SurgeryId === id && data.FlagId === HighRiskFlagID){
          isHighRisk = true;
        }
      })
    }

    return isHighRisk
  }

  render() {
    const {
      classes,
      rowClasses,
      columns,
      idKey,
      showFilters,
      data,
      query,
      header,
      noDataMessage,
      preloadMessage,
      inDialog,
      rowExtraRenderer
    } = this.props;

    /*DATA CAN BE SEVERAL THINGS!!
      IT CAN BE:
        1) StoreUtil LoadedData object
        2) An array
        3) An object with .data, .count,
    */
    let dataCount = -1;
    let isDataLoading = true;
    let dataRows = null;
    let didLoadOccur = true;
    if(StoreUtil.isLoadedData(data)){
      if(StoreUtil.getData(data)){
        isDataLoading = false;
        dataCount = StoreUtil.getData(data).count;
        dataRows = StoreUtil.getData(data).rows;
        dataRows = DataUtil.extractNestedData(dataRows);
      }else{
        isDataLoading = StoreUtil.isLoading(data);
        if(!isDataLoading){
          didLoadOccur = false;
        }
      }
    }else if(data && Array.isArray(data)){
      isDataLoading = false;
      dataCount = data.length;
      dataRows = data;
    }else if(data && !data.loading && data.count >= 0){
      isDataLoading = false;
      dataCount = data.count;
      dataRows = data.rows;
      dataRows = data.rows;
    }else if(data && data.placeholder){
      didLoadOccur = data.showNoData === true;
      dataCount = 0;
      dataRows = [];
      isDataLoading = data.showLoading === true;
    }

    let altRowFlag, rowClass;

    let rowsPerPage = 25;
    let page = 0;
    let pages = 0;
    let order = "desc";
    let orderBy = this.props.idKey;
    let filters = {};
    if (query) {
      rowsPerPage = query.limit;
      page = Math.floor(query.offset / rowsPerPage);
      pages = 0;
      if (dataCount > -1) {
        pages = Math.ceil(dataCount / rowsPerPage);
      }
      if (query.order && query.order.length > 0) {
        if(query.order[0].length > 2){
          orderBy = query.order[0].slice(0,-1).join('.');
          order = query.order[0][query.order[0].length-1].toLowerCase();
        }else{
          orderBy = query.order[0][0];
          order = query.order[0][1].toLowerCase();
        }
      }

      //find all where clauses, and condense into one object
      filters = {};
      this.flattenWhereQuery(filters, query, "");
    }

    let title;
    if (header) {
      if (typeof header === "string") {
        title = (<Typography variant="headline" component="h3" className={classes.title}>
          {header}
        </Typography>);
      } else {
        title = header;
      }
    }

    let tableClass;
    if(inDialog){
      tableClass = classes.tableDialog;
    }else{
      tableClass = classes.table;
    }

    // diaolog box for end date option
    const dialog = (<Dialog open={this.state.open} onClose={this.handleEndDateReject}>
      <DialogTitle> Select an end date? </DialogTitle>
      <DialogActions>
        <Button onClick={this.handleEndDateAccept} color="primary"> YES </Button>
        <Button onClick={this.handleEndDateReject} color="primary"> NO </Button>
      </DialogActions>
    </Dialog>);

    return (
      <Paper className={classes.root}>
        {dialog}
        {title}
        <div className={classes.tableWrapper}>
          <Table className={tableClass}>
            <TableHead>
              <TableRow>
                {columns.map(column => {

                  let filter;
                  if (showFilters) {
                    let currentFilter = filters[column.key];
                    if(column.filterKey){
                      currentFilter = filters[column.filterKey];
                    }

                    let exactFilter = false;

                    if(column.filterExact){
                      exactFilter = column.filterExact
                    }

                    if (column.type == "date") {
                      //remove the %value% for filter
                      if (currentFilter && currentFilter.$and && currentFilter.$and.$gt) {
                        currentFilter = moment(currentFilter.$and.$gt).add(1,'days').format(DateUtil.FORM_DATE_FORMAT);
                      } else {
                        currentFilter = null;
                      }

                      const dateRange = this.getDateRangeFilterLabel(this.props.query, column);

                      const className = `${classes.filterInputField} ${classes.filterInputField2} ${classes.dateField}`;

                      filter = (
                        <DatePicker
                          label={dateRange || currentFilter}
                          showTodayButton
                          clearable
                          value={currentFilter}
                          onChange={this.handleDateOnChange.bind(this,column.key)}
                          onInputChange={this.handleDateInputChange.bind(this,column.key)}
                          className={className}
                          fullWidth
                          format={DateUtil.DISPLAY_FORMAT}
                          style={column.style}
                          keyboard
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          ref={r => this.setDatePickerDialogReference(r)}
                          shouldDisableDate = {this.disableDaysBeforeStartDate}
                        />);
                    } else {
                      //remove the %value% for filter
                      if (currentFilter && currentFilter.$like && currentFilter.$like.length > 0) {
                        currentFilter = currentFilter.$like.substring(1,currentFilter.$like.length-1);
                      } else if (exactFilter === true && currentFilter && currentFilter.$eq
                          && currentFilter.$eq.length > 0) {
                        currentFilter = currentFilter.$eq;
                      } else if (currentFilter && currentFilter.$eq && currentFilter.$eq.length > 0) {
                        currentFilter = '"'+currentFilter.$eq+'"';
                      } else {
                        currentFilter = "";
                      }
                      filter = (
                        <TextField
                          id={column.key}
                          inputProps={{className:[classes.filterInputField,classes.filterInputField2]}}
                          onChange={this.onFilterChange.bind(this, column.key)}
                          margin="none"
                          value={currentFilter}
                          type={column.type ? column.type : "text"}
                          disabled={(column.filter != undefined && !column.filter)}
                          fullWidth={(column.style && column.style.width) ? false : true }
                          style={column.style}
                        />
                      );
                    }
                  }

                  return (
                    <TableCell
                      key={column.key}
                      className={classes.condensedCell}
                      padding="dense"
                      sortDirection={orderBy === column.key ? order : false}
                      style={column.style}
                    >
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={order}
                        disabled={column.sortable ? false : true}
                        onClick={this.onOrderChange.bind(this, column.key)}
                      >
                        {column.label}
                      </TableSortLabel>
                      {filter}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>

            {(isDataLoading || !didLoadOccur) ?

              //loading don't show anything
              null

              : //else show data

              <TableBody >
                {(dataRows.map(row => {
                  altRowFlag = !altRowFlag;
                  if(row.invalid){
                    rowClass = classes.invalidRow;
                  }else if(altRowFlag){
                    rowClass = classes.altRow;
                  }else{
                    rowClass = classes.regRow;
                  }

                  let rowKey = row[idKey] || row["SurgeryID"]

                  if(idKey === "Surgeries[0].id" && row.Surgeries[0] && row.Surgeries[0].id){
                    rowKey = row.Surgeries[0].id
                  }

                  return (
                    <Fragment key={rowKey}>
                      <TableRow
                        className={rowClass}
                        key={rowKey}
                        onClick={this.onRowClick.bind(this, row)}>
                        {columns.map(column => {
                          return (
                            <TableCell
                              className={classes.condensedCell}
                              key={rowKey+"."+column.key}
                              padding="dense"
                              style={{position: "relative"}}
                            >
                              {(column.key === "MRN") && this.isHighRisk(row.id) &&
                              <Icon style={{
                                position: "absolute",
                                top: 4,
                                left: 54,
                                fontSize: 20,
                                color: "rgb(12, 34, 79)"}}>star_rate</Icon>}
                              {column.format?
                                //user format function
                                column.format(this.resolveColumnPath(column, row), row, rowClasses, column.key)
                                :
                                //else place raw data in
                                this.resolveColumnPath(column, row)}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      {rowExtraRenderer && 
                        <TableRow
                          className={rowClass}
                          key={'extra_'+rowKey}
                          onClick={this.onRowClick.bind(this, row)}>
                          {rowExtraRenderer(row, columns, altRowFlag)}
                        </TableRow>
                      }
                    </Fragment>
                  );
                }))}
              </TableBody>
            }
          </Table>
        </div>

        {(isDataLoading) ?
          <div className={classes.spinnerWrapper}>
            <CircularProgress color="secondary" />
          </div>
          : //else data has loaded
          (dataCount == 0 || !didLoadOccur) ? //but there is no data
            <Typography variant="subheading" className={classes.noData}>
              { didLoadOccur ? noDataMessage : preloadMessage}
            </Typography>
            : null
        }

        {pages > 0 &&
          <TablePagination
            component="div"
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
            rowsPerPageOptions={[25,50,100,200]}
          />
        }
      </Paper>
    );
  }

  getColumnByKey(key) {
    return this.props.columns.find(function(column) {
      return column.key == key;
    });
  }

  cacheColumnPaths() {
    const {
      columns
    } = this.props;

    //loop through and cache the paths for each column
    //this avoids searching for the . in every key for everycell
    {columns.map(column => {
      let usedKey = column.key;
      while(usedKey.endsWith('_')){
        usedKey = usedKey.substring(0,usedKey.length-1);
      }
      column._path = usedKey.split('.');
    })}

  }

  resolveColumnPath(column, row) {
    if (!column._path) {
      this.cacheColumnPaths();
    }
    return column._path.reduce(function(prev, curr) {
      return prev ? prev[curr] : undefined
    }, row || self)
  }
}

DynamicTable.defaultProps = {
  idKey: "id",
  showFilters: true,
  noDataMessage: "No records found.",
  preloadMessage: "",
  inDialog: false
};

DynamicTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rowClasses: PropTypes.object,
  columns: PropTypes.array.isRequired,
  query: PropTypes.object,
  data: PropTypes.any.isRequired,
  idKey: PropTypes.string,
  showFilters: PropTypes.bool,
  header: PropTypes.any,
  noDataMessage: PropTypes.string,
  preloadMessage: PropTypes.string,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  inDialog: PropTypes.bool,
  rowExtraRenderer: PropTypes.func,
  surgeryFlag: PropTypes.array
};

const styled = withStyles(styles)(DynamicTable);
export {styled as DynamicTable};
