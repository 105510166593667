import {
  API_SET_QUESTION_OPTIONS
} from 'actions/questionGroupActions';

export const questionOptions = (state = {}, action) => {
  switch(action.type){
  case API_SET_QUESTION_OPTIONS:
    return action.data;
  }
  return state;
}

