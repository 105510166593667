import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { getPatientDetailByMRN } from 'actions/patientActions';
import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';
import StoreUtil from 'stores/StoreUtil';
import { DateUtil } from 'util/DateUtil';
import NavigationUtil from 'util/NavigationUtil'

import { getSurgery } from 'actions/surgeryActions'

class SurgeryBCItem extends Component {

  componentDidMount(){
    const {
      surgeryId,
      surgery,
      dispatch,
      isNewSurgery,
      // patient,
      mrn,
      externalSource
    } = this.props;

    if(isNewSurgery){
      // if((mrn) && (!patient || StoreUtil.needsLoadNoCache(patient))){
      if(mrn && externalSource){
        dispatch(getPatientDetailByMRN(mrn, externalSource));
      }
    }else{
      if(StoreUtil.needsLoadLongCache(surgery)){
        dispatch(getSurgery(surgeryId));
      }
    }
  }

  render() {
    const {
      surgeryId,
      surgery,
      patient,
      isNewSurgery
    } = this.props;

    var title = "Surgery";
    var mrn = "";
    var externalSource = "";
    // TODO get patient ExternalSource
    if(surgery && StoreUtil.getData(surgery)){
      title = 'Episode';
    }

    var patientTitle = "Patient";
    let patientData = null;
    if(isNewSurgery){
      patientData = StoreUtil.getData(patient);
    } else if(surgery
      && StoreUtil.getData(surgery)
      && StoreUtil.getData(surgery).Patient){
      patientData = StoreUtil.getData(surgery).Patient;
    }
    
    if (patientData) {
      mrn = patientData.MedicalRecNum;
      externalSource = patientData.ExternalSource;
      patientTitle = patientData.LastName+", "+
        patientData.FirstName +
        " - MRN: "+patientData.MedicalRecNum+
        " - DOB: "+DateUtil.formatFromDB(patientData.BirthDate);
    }

    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={patientTitle}
          icon="face"
          path={NavigationUtil.navLink("/patients/"+mrn+"/"+externalSource)}
        />

        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          icon="assignment"
          path={NavigationUtil.navLink("/surgery/"+surgeryId)}
        />
      </React.Fragment>
    );
  }
}

SurgeryBCItem .propTypes = {
  surgery: PropTypes.object.isRequired,
  patient: PropTypes.object,
  mrn: PropTypes.number,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  surgeryId : PropTypes.string.isRequired,
  isNewSurgery: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  externalSource: PropTypes.string
};

function mapStateToProps(state, props) {
  const { match } = props;
  const {
    surgeries,
    patients
  } = state;
  const surgeryId = match.params.surgeryId;
  
  const isNewSurgery = surgeryId == 0;
  let surgery = null;
  let patient;
  let mrn;
  let externalSource;
  
  if(!isNewSurgery){
    surgery = StoreUtil.get(surgeries, StoreUtil.COMMON_ITEM, surgeryId);
  }else{
    surgery = StoreUtil.get(surgeries, StoreUtil.COMMON_NEW_ITEM);
    mrn = parseInt(match.params.mrn);
    externalSource = match.params.externalSource;
    patient = StoreUtil.get(patients, StoreUtil.COMMON_ITEM, mrn)
  }

  return { 
    surgery,
    surgeryId,
    patient,
    mrn,
    externalSource,
    isNewSurgery
  };
}

const connected = connect(mapStateToProps)(SurgeryBCItem);
export {connected as SurgeryBCItem };
