import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import { PostAcuteUtil } from 'util/PostAcuteUtil';
import { LoadingView } from 'components/LoadingView';
import { updatePostAcuteEvent } from 'actions/postAcuteEventActions';
import { setSelectedHSXEvent } from 'actions/hsxActions';
import NavigationUtil from 'util/NavigationUtil';
import StoreUtil from 'stores/StoreUtil';
import { DateUtil } from 'util/DateUtil';
import dotProp from 'dot-prop-immutable';
import moment from 'moment';
import DataUtil from 'util/DataUtil';

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  },
  spacer:{
    height: theme.spacing.unit * 2
  }
});

const schemaEditRoot = ({
  title: 'Post Acute Event',
  type: 'object',
  required: ['EventType', 'Date', 'PrimaryReasonId'],
  properties: {
    Date: { type: 'string', title: 'Date', format: 'date' },
    Source: { type: 'string', title: 'Source',
      enum: [
        '30 Day Post-Op',
        '90 Day Post-Op'
      ]
    },
    EventType: { type: 'string', title: 'Event Type',
      enum:[
        'Complication',
        'ED',
        'No Readmission/Visit',
        'OBS',
        'PCP Office Visit',
        'Readmission',
        'Surgeon Office Visit',
        'Transfer',
        'Urgent Care'
      ]
    },
    ReadmissionFacilityId: { type: 'number', title: 'Facility' },
    PrimaryReasonId: { type: 'number', title: 'Primary Reason', minItems: 0 },
    SecondaryReasons: {
      title: 'Secondary Reasons',
      "type": "array",
      "items": {
        type: 'number'
      }
    },
    Notes: { type: 'string', title: 'Notes/Treatments' }
  }
});

const schemeUIRoot = ({
  EventType:{
    classNames:'two-column-field'
  },
  Source:{
    classNames:'two-column-field'
  },
  Date:{
    classNames:'two-column-field'
  },
  ReadmissionFacilityId:{
    classNames:'two-column-field'
  },
  PrimaryReasonId:{
    classNames:'two-column-field'
  },
  SecondaryReasons:{
    classNames:'skinny-column-field'
  },
  Notes:{
    "ui:widget": "textarea"
  }
});

class PostAcuteEventEditView extends React.Component {

  constructor(props) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onDataTransform = this.onDataTransform.bind(this);
    this.onClear = this.onClear.bind(this);
    this.sortReasons = this.sortReasons.bind(this);

    let schemaEdit = DataUtil.deepCopy(schemaEditRoot);
    schemaEdit = this.updateSchemaForReasons(schemaEdit, null);

    this.state = {
      schemeUI: schemeUIRoot,
      schemaEdit: schemaEdit,
      eventType:null,
      compReasons: {},
      otherReasons: {},
      reasonNames: {},
      reasonIds: {},
      key: Date.now()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      reasons,
      postAcuteEvent
    } = this.props;
    
    //changed
    if (prevProps && match && prevProps.match &&
      prevProps.match.params && match.params &&
      prevProps.match.params.postAcuteEventId != match.params.postAcuteEventId) {
      this.doNeededInitialLoads();
    }

    //check for reasons load and event load, update schema
    let reasonLoad = (StoreUtil.isLoaded(reasons) && !StoreUtil.isLoaded(prevProps.reasons));
    
    if(reasonLoad){
      let eventType = this.state.eventType;
      
      if(postAcuteEvent && eventType != postAcuteEvent.EventType){
        eventType = postAcuteEvent.EventType;
      }

      let schemaEdit = DataUtil.deepCopy(schemaEditRoot);
      schemaEdit = this.updateSchemaForReasons(schemaEdit, eventType);
      
      this.setState({ schemaEdit, eventType });
    }
  }

  componentDidMount(){
    this.doNeededInitialLoads();
    const {
      postAcuteEvent,
      source
    } = this.props;
    if(postAcuteEvent){
      const eventType = postAcuteEvent.EventType;
      let schemaEdit = DataUtil.deepCopy(schemaEditRoot);
      if(source=='180 Day Post-Op'){
        schemaEdit.properties.Source.enum=[...schemaEdit.properties.Source.enum,'180 Day Post-Op'];
      }
      schemaEdit = this.updateSchemaForReasons(schemaEdit, eventType);
      this.setState({ schemaEdit, eventType });
    }
  }

  componentWillUnmount() {
    const {dispatch} = this.props;

    dispatch(setSelectedHSXEvent({}))
  }

  doNeededInitialLoads(){
    //check if data load needed
    const {
      readmissionFacilities,
      reasons,
      dispatch
    } = this.props;

    const types = [
      'HospitalVisit',
      'Readmit',
      'Comp'
    ];

    PostAcuteUtil.checkAndLoadFacilities(readmissionFacilities, dispatch);
    PostAcuteUtil.checkAndLoadReasons(reasons, dispatch, types);
  }

  onFormSubmit(data){
    const {
      dispatch,
      postAcuteEvent,
      surgery,
      location,
      source
    } = this.props;
    
    const formData = data.formData;
    formData.SurgeryId = surgery.id;
    if(postAcuteEvent){
      formData.id = postAcuteEvent.id;
    }

    let redirectUrl;
    
    if(source){
      this.setState({key: Date.now()});
      redirectUrl = location;
    } else{
      if(data.submitSource && data.submitSource != "Save"){
        this.setState({key: Date.now()});
        redirectUrl = NavigationUtil.navLink('/surgery/'+formData.SurgeryId+'/postAcute/0');
      } else {
        redirectUrl = NavigationUtil.navLink('/surgery/'+formData.SurgeryId);
      }
    }

    //change secondary reasons to objects
    let secondaryReasonIds = formData.SecondaryReasons;
    delete formData.SecondaryReasons;
    let secondaryReasons = [];
    let seenReasonMap = {};
    if(secondaryReasonIds && secondaryReasonIds.length>0 ){
      secondaryReasonIds.forEach((reasonId)=>{
        if(reasonId && !seenReasonMap[reasonId]){
          seenReasonMap[reasonId] = true;
          secondaryReasons.push({
            ReasonId:reasonId
          });
        }
      });
    }

    dispatch(updatePostAcuteEvent(formData, secondaryReasons, redirectUrl));
  }

  onFormError(){
    
  }

  onDataTransform(formData){
    const {
      eventType
    } = this.state;
    
    //Initialize seconrdary reasons
    if(!formData.SecondaryReasons){
      formData = dotProp.set(formData, 'SecondaryReasons', []);
    }

    //Add schema for Primary/Secondary Reason
    if(eventType != formData.EventType){
      if(formData.EventType == 'No Readmission/Visit'){
        formData = dotProp.set(formData, 'Date', moment().format(DataUtil.FORM_DATE_FORMAT));
      }

      let schemaEditNew = DataUtil.deepCopy(schemaEditRoot);
      schemaEditNew = this.updateSchemaForReasons(schemaEditNew, formData.EventType);
      
      this.setState({ schemaEdit:schemaEditNew, eventType:formData.EventType });
      
      //clear reasons
      formData = dotProp.set(formData, 'PrimaryReasonId', null);
      formData = dotProp.set(formData, 'SecondaryReasons', []);
    }
    
    
    return formData;
  }

  sortReasons(reasons){
    if(reasons && reasons.data && reasons.data.rows){
      const sorted_rows = reasons.data.rows.sort(function(a, b){
        if(a.Text < b.Text) { return -1; }
        if(a.Text > b.Text) { return 1; }
        return 0;
      });
      reasons.data.rows = sorted_rows;
    }
  }

  updateSchemaForReasons(schemaEdit, type){
    const {
      reasons
    } = this.props;

    this.sortReasons(reasons);
    
    let reasonNames = [];
    let reasonIds = [];
    const reasonsData = StoreUtil.getData(reasons);

    if(type == 'No Readmission/Visit'){
      schemaEdit = dotProp.set(schemaEdit, 'required', ['EventType', 'Date']);
      schemaEdit = dotProp.delete(schemaEdit, 'properties.ReadmissionFacilityId');
      schemaEdit = dotProp.delete(schemaEdit, 'properties.PrimaryReasonId');
      schemaEdit = dotProp.delete(schemaEdit, 'properties.SecondaryReasons');
    } else {
      if(type && type == 'Complication') {
        if(reasonsData){
          reasonsData.rows.forEach((reason) => {
            if(reason.Type == 'Comp'){
              reasonNames.push(reason.Text);
              reasonIds.push(reason.id);
            }
          });
        }else{
          reasonNames.push('');
          reasonIds.push(null);
        }

        //no primary reasons, only secondary
        schemaEdit = dotProp.set(schemaEdit, 'required', 
          ['EventType', 'Date', 'SecondaryReasons']);
        schemaEdit = dotProp.delete(schemaEdit, 'properties.PrimaryReasonId');
        schemaEdit = dotProp.set(schemaEdit, 'properties.SecondaryReasons.title',"Reasons"); 

        schemaEdit = dotProp.set(schemaEdit, 'properties.SecondaryReasons.items.enum', reasonIds);
        schemaEdit = dotProp.set(schemaEdit, 'properties.SecondaryReasons.items.enumNames', reasonNames);
      }else {
        if(reasonsData){
          reasonsData.rows.forEach((reason) => {
            if(reason.Type != 'Comp'){
              reasonNames.push(reason.Text);
              reasonIds.push(reason.id);
            }
          });
        }else{
          reasonNames.push('');
          reasonIds.push(null);
        }

        schemaEdit = dotProp.set(schemaEdit, 'properties.PrimaryReasonId.enumNames', reasonNames);
        schemaEdit = dotProp.set(schemaEdit, 'properties.PrimaryReasonId.enum', reasonIds);

        schemaEdit = dotProp.set(schemaEdit, 'properties.SecondaryReasons.items.enum', reasonIds);
        schemaEdit = dotProp.set(schemaEdit, 'properties.SecondaryReasons.items.enumNames', reasonNames);
      }
    }
    return schemaEdit;
  }

  onClear(){
    const {
      surgery,
      questionGroupId,
      taskId,
      dispatch
    } = this.props
    
    this.setState({key: Date.now()});
    dispatch(push(NavigationUtil.navLink('/surgery/'+surgery.id+'/form/'+questionGroupId+'/task/'+taskId)));
  }

  render() {
    const {
      classes,
      postAcuteEvent,
      surgery,
      readmissionFacilities,
      reasons,
      source,
      status,
      allowSubmit,
      onPostAcuteEventFormChange,
      selectedHsxEvent
    } = this.props;

    let {
      schemeUI,
      schemaEdit
    } = this.state
    
    let paeData = postAcuteEvent;
    let surgeryData = surgery;
    
    if(!StoreUtil.isLoadedOrFailed(reasons) || !StoreUtil.isLoadedOrFailed(readmissionFacilities)) {
      return (<LoadingView />);
    }

    //Add schema for facilities
    if(StoreUtil.isLoaded(readmissionFacilities) && schemaEdit.properties.ReadmissionFacilityId) {
      let readmissionFacilityNames = [];
      let readmissionFacilityIds = [];
      const readmissionFacilitiesData = StoreUtil.getData(readmissionFacilities);

      readmissionFacilitiesData.forEach((readmissionFacility) => {
        readmissionFacilityNames.push(readmissionFacility.Name);
        readmissionFacilityIds.push(readmissionFacility.id);
      });

      schemaEdit = dotProp.set(schemaEdit, 'properties.ReadmissionFacilityId.enumNames', readmissionFacilityNames);
      schemaEdit = dotProp.set(schemaEdit, 'properties.ReadmissionFacilityId.enum', readmissionFacilityIds);

      schemaEdit = dotProp.set(schemaEdit, 'properties.ReadmissionFacilityId.enumNames', readmissionFacilityNames);
      schemaEdit = dotProp.set(schemaEdit, 'properties.ReadmissionFacilityId.enum', readmissionFacilityIds);
    }

    if (selectedHsxEvent.Description) {
      schemaEdit = dotProp.set(
        schemaEdit,
        'properties.Notes.default',
        selectedHsxEvent.Description
      )
    }

    if (selectedHsxEvent.EventDate) {
      schemaEdit = dotProp.set(
        schemaEdit,
        'properties.Date.default',
        moment(selectedHsxEvent.EventDate).format(DataUtil.FORM_DATE_FORMAT)
      )
    }

    if (selectedHsxEvent.ReadmissionFacilityId !== 0 && dotProp.get(schemaEdit, 'properties.ReadmissionFacilityId') ){
      schemaEdit = dotProp.set(
        schemaEdit,
        'properties.ReadmissionFacilityId.default',
        selectedHsxEvent.ReadmissionFacilityId
      )
    }
    if (selectedHsxEvent.ReadmissionFacilityId == 0 ){
      schemaEdit = dotProp.set(
        schemaEdit,
        'properties.Notes.default',
        `${selectedHsxEvent.Description} -- Readmission Facility : ${selectedHsxEvent.ReadmissionFacilityDisplayName}`
      )
    }
    DateUtil.stripTimeFromDBDate(paeData, 'Date');
    DateUtil.stripTimeFromDBDate(surgeryData, 'SurgeryDateTime');

    if(schemeUI.Date){
      const options = {}
      if(surgeryData.DischargeDate){
        if(source == '90 Day Post-Op'){
          options.linkedMax = moment(surgeryData.DischargeDate).add(90, "days").format(DataUtil.FORM_DATE_FORMAT);
        } else if(source == '180 Day Post-Op') {
          // Default to 180 Days
          options.linkedMax = moment(surgeryData.DischargeDate).add(180, "days").format(DataUtil.FORM_DATE_FORMAT);
        }
        else {
          options.linkedMax = null;
        }
      } 

      options.linked = moment(surgeryData.SurgeryDateTime).format(DataUtil.FORM_DATE_FORMAT);

      if (paeData && paeData.Date) {
        paeData.Date = moment(paeData.Date).format(DataUtil.FORM_DATE_FORMAT)
      }
      // minimum date is surgeryDate unless it postacute event is No Readmission/Visit
      options.linked = moment(surgeryData.SurgeryDateTime).format(DataUtil.FORM_DATE_FORMAT)
      if(paeData && paeData.EventType == 'No Readmission/Visit'){
        options.linked = moment().format(DataUtil.FORM_DATE_FORMAT);
        options.linkedMax = null
      }
      schemeUI.Date['ui:options'] = {...options};
    }

    if(source && !postAcuteEvent){
      if(paeData && paeData.Source){
        paeData.Source = source;
      } else {
        paeData = { Source: source }
      }
    }
    
    let secondaryText;
    let showCancel = false;
    let onClearAction;

    if(!source){
      secondaryText = 'Save and Add New';
      showCancel = true;
    } else {
      onClearAction = this.onClear;
    }

    if (surgery.Surgeon.SurgicalTeamId==2) {
      schemaEdit = dotProp.set(schemaEdit, 'properties.Source.enum', ['30 Day Post-Op', '90 Day Post-Op', '180 Day Post-Op'])
    }

    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={schemaEdit}
          uiSchema={schemeUI}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={paeData}
          onDataTransform={this.onDataTransform}
          allowSubmit={allowSubmit}
          status={status.status}
          statusIsError={status.failure}
          showCancel={showCancel}
          secondaryText={secondaryText}
          onClear={onClearAction}
          key={this.state.key}
          onPostAcuteEventFormChange={
            onPostAcuteEventFormChange ?
              onPostAcuteEventFormChange.bind(this, {surgery, postAcuteEvent})
              : null
          }
        />
      </div>
    );
  }
}

PostAcuteEventEditView.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgery: PropTypes.object.isRequired,
  postAcuteEvent : PropTypes.object,
  readmissionFacilities: PropTypes.object,
  reasons: PropTypes.object,
  source: PropTypes.string,
  location: PropTypes.string,
  status: PropTypes.object,
  allowSubmit: PropTypes.bool,
  match: PropTypes.object,
  questionGroupId: PropTypes.string,
  taskId: PropTypes.string,
  onPostAcuteEventFormChange: PropTypes.func,
  selectedHsxEvent: PropTypes.object
};

function mapStateToProps(state) {
  const { 
    readmissionFacilities, 
    reasons,
    selectedHsxEvent
  } = state;
  
  return {
    readmissionFacilities: StoreUtil.get(readmissionFacilities, StoreUtil.COMMON_LIST),
    reasons: StoreUtil.get(reasons, StoreUtil.COMMON_TABLE),
    selectedHsxEvent
  }
}

const styledPage = withStyles(styles)(PostAcuteEventEditView);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PostAcuteEventEditView};
