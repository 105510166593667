import React, {Component, Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import DataUtil from 'util/DataUtil';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';

import { PropTypes } from 'prop-types';
import StoreUtil from 'stores/StoreUtil';
import dotProp from 'dot-prop-immutable';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  table:{
    minWidth: 200
  },
  tableDialog:{
    minWidth: 200
  },
  filterField : {
    marginBottom: 0
  },
  filterInputField: theme.typography.caption,
  filterInputField2:{
    lineHeight:'inherit'
  },
  dateField:{
    '& > div > input': {
      fontSize:'0.75rem'
    },
    '& > div > div > button': {
      width:20,
      '& > span > span': {
        fontSize:18
      }
    },
    '& > p': {
      display:'none'
    }
  },
  altRow:{
    backgroundColor: 'rgba(0,0,0,0.03)',
    cursor: "pointer",
    height: 30
  },
  invalidRow:{
    backgroundColor: 'rgba(255,0,0,0.1)',
    cursor: "pointer",
    height: 30
  },
  regRow:{
    cursor: "pointer",
    height: 30
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 300
  },
  spinnerWrapper: {
    textAlign: "center",
    padding: 120
  },
  noData: {
    fontWeight: 1000,
    fontSize: 18,
    textAlign: "center",
    padding: 50
  },
  title: {
    marginBottom: 10
  },
  custom: {
    //this allows top level pages to override and pass in styles
  }
});

class MaintenanceTable extends Component {

  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
  }

  componentDidMount() {
    this.cacheColumnPaths();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.columns != prevProps.columns) {
      this.cacheColumnPaths();
    }
  }

  onOrderChange(newOrderBy) {
    let query = Object.assign({}, this.props.query);
    let oldOrderBy = this.props.idKey;
    let order = "DESC";

    let oldHasOrder = false;
    if (query.order && query.order.length > 0) {
      //save out old order if it hasn't been saved
      if(!query.baseOrder){
        query.baseOrder = query.order;
      }
      oldOrderBy = query.order[0].slice(0,query.order[0].length-1).join('.');
      order = query.order[0][query.order[0].length-1];
      oldHasOrder = true;
    }else{
      //no base order, so it must be the id field
      query.baseOrder = [[this.props.idKey, 'DESC']];
    }

    if (oldHasOrder && oldOrderBy == newOrderBy) {
      //already sorting this column, change direction
      if (order.toUpperCase() == "ASC") {
        order = "DESC";
      } else {
        order = "ASC";
      }
      query.order[0][query.order[0].length-1] = order;
    }else{
      query.order = [];
      if(query.baseOrder){
        query.order = query.order.concat(query.baseOrder);
      }

      let orderItem;
      //if new order by is a deep object, with "." operators in it,
      //need to be made into array with each piece of path in array
      if(newOrderBy.indexOf('.') > -1){
        orderItem = newOrderBy.split('.');
        orderItem.push(order.toUpperCase());
      }else{
        orderItem = [newOrderBy, order.toUpperCase()];
      }

      //make sure this isn't in the base sort
      let includedInBase = false;
      for(let x=0;x<query.order.length;x++){
        let sort = query.order[x];
        if(sort.length == orderItem.length){
          let allMatch = true;
          for(let y=0;y<sort.length-1;y++){
            if(sort[y] != orderItem[y]){
              allMatch = false;
              break;
            }
          }
          includedInBase = allMatch;
          break;
        }
      }
      if(!includedInBase){
        query.order.unshift(orderItem);
      }
    }

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query, this.props.tableKey);
    }
  }

  onFilterChange(columnKey, event) {
    const column = this.getColumnByKey(columnKey);

    let query = Object.assign({}, this.props.query);

    let objPath = "";
    let parentObj = query;
    let columnFieldName = columnKey;

    if(!parentObj.where){
      parentObj.where = {}
    }
    let filters = parentObj.where;
    let value;

    if(event.target){
      value = event.target.value;
      if (value) {
        if (value.length > 2 && value.startsWith('"') && value.endsWith('"')) {
          filters[columnFieldName] = { $eq: value.substring(1,value.length-1)};
        } else if (column.filterExact && column.filterExact === true)  {
          filters[columnFieldName] = { $eq: value};
        }
        else {
          filters[columnFieldName] = { $like: "%"+value+"%"};
        }
      } else {
        delete filters[columnFieldName];
      }
    }

    const fullPath = (objPath) ? `${objPath}.where` :  "where";
    if(Object.keys(filters).length == 0){
      query = dotProp.delete(query, fullPath);
    }else{
      query = dotProp.set(query, fullPath, filters);
    }

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query, this.props.tableKey);
    }
  }

  onPageChange(event, page) {
    let query = Object.assign({}, this.props.query);

    query.offset = page * query.limit;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query, this.props.tableKey);
    }
  }

  onRowsPerPageChange(event) {
    let query = Object.assign({}, this.props.query);

    query.limit = event.target.value;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query, this.props.tableKey);
    }
  }

  flattenWhereQuery(obj, child, path){
    if(!child){
      return;
    }
    if(child.where){
      for (var prop in child.where) {
        if(path){
          obj[path+"."+prop] = child.where[prop];
        }else{
          obj[prop] = child.where[prop];
        }
      }
    }
    if(child.include && Array.isArray(child.include)){
      child.include.forEach((include)=>{
        if(path){
          this.flattenWhereQuery(obj,include,path+"."+include.association);
        }else{
          this.flattenWhereQuery(obj,include,include.association);
        }
      })
    }
  }

  onRowClick(row) {
    if (this.props.onRowClick) {
      this.props.onRowClick(row);
    }
  }

  render() {
    const {
      classes,
      rowClasses,
      columns,
      idKey,
      showFilters,
      data,
      query,
      header,
      noDataMessage,
      preloadMessage,
      inDialog,
      rowExtraRenderer
    } = this.props;

    /*DATA CAN BE SEVERAL THINGS!!
      IT CAN BE:
        1) StoreUtil LoadedData object
        2) An array
        3) An object with .data, .count,
    */
    let dataCount = -1;
    let isDataLoading = true;
    let dataRows = null;
    let didLoadOccur = true;
    if(StoreUtil.isLoadedData(data)){
      if(StoreUtil.getData(data)){
        isDataLoading = false;
        if(data.data.count){
          dataCount = StoreUtil.getData(data).count;
          dataRows = StoreUtil.getData(data).rows;
        } else if(Array.isArray(data.data)) {
          dataCount = data.data.length;
          dataRows = data.data;
        }
        dataRows = DataUtil.extractNestedData(dataRows);
      }else{
        isDataLoading = StoreUtil.isLoading(data);
        if(!isDataLoading){
          didLoadOccur = false;
        }
      }
    }else if(data && Array.isArray(data)){
      isDataLoading = false;
      dataCount = data.length;
      dataRows = data;
    }else if(data && !data.loading && data.count >= 0){
      isDataLoading = false;
      dataCount = data.count;
      dataRows = data.rows;
      dataRows = data.rows;
    }else if(data && data.placeholder){
      didLoadOccur = data.showNoData === true;
      dataCount = 0;
      dataRows = [];
      isDataLoading = data.showLoading === true;
    }

    let altRowFlag, rowClass;

    let rowsPerPage = 25;
    let page = 0;
    let pages = 0;
    let order = "desc";
    let orderBy = this.props.idKey;
    let filters = {};
    if (query) {
      rowsPerPage = query.limit;
      page = Math.floor(query.offset / rowsPerPage);
      pages = 0;
      if (dataCount > -1) {
        pages = Math.ceil(dataCount / rowsPerPage);
      }
      if (query.order && query.order.length > 0) {
        if(query.order[0].length > 2){
          orderBy = query.order[0].slice(0,-1).join('.');
          order = query.order[0][query.order[0].length-1].toLowerCase();
        }else{
          orderBy = query.order[0][0];
          order = query.order[0][1].toLowerCase();
        }
      }

      //find all where clauses, and condense into one object
      filters = {};
      this.flattenWhereQuery(filters, query, "");
    }

    let title;
    if (header) {
      if (typeof header === "string") {
        title = (<Typography variant="headline" component="h3" className={classes.title}>
          {header}
        </Typography>);
      } else {
        title = header;
      }
    }

    let tableClass;
    if(inDialog){
      tableClass = classes.tableDialog;
    }else{
      tableClass = classes.table;
    }

    return (
      <Paper className={classes.root}>
        {title}
        <div className={classes.tableWrapper}>
          <Table className={tableClass}>
            <TableHead>
              <TableRow>
                {columns.map(column => {

                  let filter;
                  if (showFilters) {
                    let currentFilter = filters[column.key];
                    if(column.filterKey){
                      currentFilter = filters[column.filterKey];
                    }

                    let exactFilter = false;

                    if(column.filterExact){
                      exactFilter = column.filterExact
                    }


                    //remove the %value% for filter
                    if (currentFilter && currentFilter.$like && currentFilter.$like.length > 0) {
                      currentFilter = currentFilter.$like.substring(1,currentFilter.$like.length-1);
                    } else if (exactFilter === true && currentFilter && currentFilter.$eq
                        && currentFilter.$eq.length > 0) {
                      currentFilter = currentFilter.$eq;
                    } else if (currentFilter && currentFilter.$eq && currentFilter.$eq.length > 0) {
                      currentFilter = '"'+currentFilter.$eq+'"';
                    } else {
                      currentFilter = "";
                    }
                    filter = (
                      <TextField
                        id={column.key}
                        inputProps={{className:[classes.filterInputField,classes.filterInputField2]}}
                        onChange={this.onFilterChange.bind(this, column.key)}
                        margin="none"
                        value={currentFilter}
                        type={column.type ? column.type : "text"}
                        disabled={(column.filter != undefined && !column.filter)}
                        fullWidth={(column.style && column.style.width) ? false : true }
                        style={column.style}
                      />
                    );
                  }

                  return (
                    <TableCell
                      key={column.key}
                      className={classes.condensedCell}
                      padding="dense"
                      sortDirection={orderBy === column.key ? order : false}
                      style={column.style}
                    >
                      <TableSortLabel
                        active={orderBy === column.key}
                        direction={order}
                        disabled={column.sortable ? false : true}
                        onClick={this.onOrderChange.bind(this, column.key)}
                      >
                        {column.label}
                      </TableSortLabel>
                      {filter}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>

            {(isDataLoading || !didLoadOccur) ?

              //loading don't show anything
              null

              : //else show data

              <TableBody >
                {dataRows && dataRows.length && (dataRows.map((row, index) => {
                  altRowFlag = !altRowFlag;
                  if(row.invalid){
                    rowClass = classes.invalidRow;
                  }else if(altRowFlag){
                    rowClass = classes.altRow;
                  }else{
                    rowClass = classes.regRow;
                  }

                  const rowKey = row[idKey] || row["SurgeryID"]

                  return (
                    <Fragment key={index}>
                      <TableRow
                        className={rowClass}
                        key={rowKey}
                        onClick={this.onRowClick.bind(this, row)}>
                        {columns.map(column => {
                          return (
                            <TableCell
                              className={classes.condensedCell}
                              key={rowKey+"."+column.key}
                              padding="dense"
                            >
                              {column.format?
                                //user format function
                                column.format(this.resolveColumnPath(column, row), row, rowClasses, column.key)
                                :
                                //else place raw data in
                                this.resolveColumnPath(column, row)}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      {rowExtraRenderer && 
                        <TableRow
                          className={rowClass}
                          key={'extra_'+rowKey}
                          onClick={this.onRowClick.bind(this, row)}>
                          {rowExtraRenderer(row, columns, altRowFlag)}
                        </TableRow>
                      }
                    </Fragment>
                  );
                }))}
              </TableBody>
            }
          </Table>
        </div>

        {(isDataLoading) ?
          <div className={classes.spinnerWrapper}>
            <CircularProgress color="secondary" />
          </div>
          : //else data has loaded
          (dataCount == 0 || !didLoadOccur) ? //but there is no data
            <Typography variant="subheading" className={classes.noData}>
              { didLoadOccur ? noDataMessage : preloadMessage}
            </Typography>
            : null
        }

        {pages > 0 &&
          <TablePagination
            component="div"
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
            rowsPerPageOptions={[25,50,100,200]}
          />
        }
      </Paper>
    );
  }

  getColumnByKey(key) {
    return this.props.columns.find(function(column) {
      return column.key == key;
    });
  }

  cacheColumnPaths() {
    const {
      columns
    } = this.props;

    //loop through and cache the paths for each column
    //this avoids searching for the . in every key for everycell
    {columns.map(column => {
      let usedKey = column.key;
      while(usedKey.endsWith('_')){
        usedKey = usedKey.substring(0,usedKey.length-1);
      }
      column._path = usedKey.split('.');
    })}

  }

  resolveColumnPath(column, row) {
    if (!column._path) {
      this.cacheColumnPaths();
    }
    return column._path.reduce(function(prev, curr) {
      return prev ? prev[curr] : undefined
    }, row || self)
  }
}

MaintenanceTable.defaultProps = {
  idKey: "id",
  showFilters: true,
  noDataMessage: "No records found.",
  preloadMessage: "",
  inDialog: false
};

MaintenanceTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rowClasses: PropTypes.object,
  columns: PropTypes.array.isRequired,
  query: PropTypes.object,
  data: PropTypes.any.isRequired,
  tableKey: PropTypes.string,
  idKey: PropTypes.string,
  showFilters: PropTypes.bool,
  header: PropTypes.any,
  noDataMessage: PropTypes.string,
  preloadMessage: PropTypes.string,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  inDialog: PropTypes.bool,
  rowExtraRenderer: PropTypes.func
};

const styled = withStyles(styles)(MaintenanceTable);
export {styled as MaintenanceTable};
