import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { MaterialForm } from "components/forms/MaterialForm";
import StoreUtil from 'stores/StoreUtil';
import { getPlanChange, updatePlanChange, resolveConversation } from 'actions/messageActions';
import { getAdmission } from 'actions/admissionActions';
import FormUtil from 'util/FormUtil';
import DataUtil from 'util/DataUtil';

const schema = {
  title: '',
  type: 'object',
  required: ['Status'],
  properties: {
    Status: {
      type: 'string',
      title: 'Status',
      enum: [
        'Open',
        'Resolved',
        'Completed'
      ]
    },
    LosExtension: {
      type: 'number',
      title: 'LOS Extension',
      enum: [
        1,
        2,
        3
      ],
      enumNames: [
        "1 day",
        "2 days",
        "3 days"
      ]
    }
  }
};

const uiSchema = {
  LosExtension:{
    classNames:'two-column-field'
  }
};

const styles = (theme) => ({
  pageContainer:{
    width: 800 + (theme.spacing.unit*4),
    maxWidth: 800 + (theme.spacing.unit*4),
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  reasonContainer:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'center',
    width:'100%'
  },
  reasonHeader:{
    width:'50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    marginTop:theme.spacing.unit*2
  },
  reasonItem:{
    width:'50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    paddingLeft:theme.spacing.unit*2,
    boxSizing: 'border-box',
    paddingRight:theme.spacing.unit,
    backgroundColor:'#F8F8F8'
  },
  inputContainer:{
    width:'80%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    position:'relative'
  },
  inputLabel:{
    transform: 'none',
    lineHeight:'120%',
    zIndex:10,
    position:'relative'
  }
});

class NurseNavPlanChangeEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      reasons: [],
      submittedWithoutReasons: false
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      dispatch,
      admissionId,
      admission,
      planChangeId,
      planChange
    } = this.props;

    if(StoreUtil.needsLoadLongCache(admission)){
      dispatch(getAdmission(admissionId));
    }

    if(StoreUtil.needsLoadNoCache(planChange)){
      dispatch(getPlanChange(planChangeId));
    }
  }

  onFormSubmit(data){
    const {
      dispatch, 
      planChangeId,
      planChange
    } = this.props;
    const formData = data.formData;
    
    let updateData = {
      id: planChangeId,
      Status: formData.Status,
      LosExtension: formData.LosExtension
    }

    let planChangeData = StoreUtil.getData(planChange);
    let isNewStatusOpen = !DataUtil.isPlanRationaleResolved(formData);
    let isConversationOpen = !DataUtil.isConversationResolved(planChangeData.Conversation);
    //change the conversation status if needed
    if(!isNewStatusOpen && isConversationOpen){
      dispatch(resolveConversation(parseInt(planChangeData.Conversation.id)));
    }
    
    dispatch(updatePlanChange(updateData));
  }

  render() {
    const {
      classes,
      admission,
      planChange
    } = this.props;

    let patientStr = "";
    let adData = StoreUtil.getData(admission);
    if(adData && adData.Surgery && adData.Surgery.Patient){
      let patientData = adData.Surgery.Patient;
      patientStr = `${patientData.FirstName} ${patientData.LastName}`;
    }
    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(planChange, 'plan change');

    let data = StoreUtil.getData(planChange);
    let reasons = [];
    if(data && data.AdmissionPlanChangeReasons){
      reasons = data.AdmissionPlanChangeReasons;
    }

    return (
      <div className={classes.pageContainer}>
        <div className={classes.header}>
          <Typography
            className={classes.headerPageTitle}
            variant="display1">
            Plan Change Rationale - {patientStr}
          </Typography>
        </div>
        <div className={classes.reasonContainer}>
          <div
            className={classes.reasonHeader}
            key="reasonHeader">
            <FormControl
              className={classes.inputContainer}
              required={true} >
              <InputLabel
                className={classes.inputLabel}
                shrink={true}
                disableAnimation={true}
                required={true}
              >Reason(s) for Extension</InputLabel>
            </FormControl>
          </div>
          {reasons.map(
            reason => {
              return (
                <div
                  className={classes.reasonItem}
                  key={reason.Reason.id}>
                  <Typography
                    variant="subheading">
                    {reason.Reason.Text}
                  </Typography>
                </div>
              )
            }
          )}
        </div>
        <MaterialForm
          schema={schema}
          uiSchema={uiSchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          status={status}
          statusIsError={failure}
          formData={data}
        />
      </div>
    );
  }
}

NurseNavPlanChangeEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  admissionId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  admission: PropTypes.object.isRequired,
  planChangeId: PropTypes.number.isRequired,
  planChange: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const{
    messages,
    admissions
  } = state;

  const { match } = existingProps;
  let admissionId;
  let planChangeId;
  try{
    admissionId = parseInt(match.params.admissionId);
    planChangeId = parseInt(match.params.planChangeId);
  }catch(error){
    console.log(error);
  }

  let message = StoreUtil.get(messages, StoreUtil.COMMON_NEW_ITEM);
  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);
  const planChange = StoreUtil.get(messages, 'planChange', planChangeId);

  return {
    message,
    admissionId,
    admission,
    planChangeId,
    planChange
  }
}

const styledPage = withStyles(styles)(NurseNavPlanChangeEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as NurseNavPlanChangeEditPage};
