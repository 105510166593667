import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import merge from 'deepmerge'
import moment from 'moment';
import dotProp from 'dot-prop-immutable';

import FormUtil from 'util/FormUtil';
import StoreUtil from 'stores/StoreUtil';

import { LoadingView } from 'components/LoadingView';
import { loadTaskById, updateTask, startTask } from 'actions/tasksActions';
import TaskUtil from 'containers/tasks/TaskUtil'

const schemaEdit = {
  title: 'Task',
  type: 'object',
  required: ['TaskTypeId'],
  properties: {
    TaskTypeId: {type:'number', title: 'Task Type'},
    StatusNote: {type: 'string', title: 'StatusNote'},
    Description: {type: 'string', title: 'Description'},
    Frequency: {type: 'boolean', title: 'Recurring', default: false},
    Priority: { type: 'string', title: 'Priority', enum:['High'], enumNames:['High'] }
  }
};

const schemaOccurOnce = {
  required: ['ScheduledFor'],
  properties: {
    "ScheduledFor":{title: 'Scheduled For', format: 'date', type: 'string'}
  }
}

const schemaRecurring = {
  required: ['ScheduledFor', 'Until'],
  properties: {
    ScheduledFor:{title: 'Choose start date', format: 'date', type: 'string'},
    Until:{title: 'Choose end date', format: 'date', type: 'string'},
    DayOfWeekId:{
      type: 'array',
      title: 'Day of task recurrence',
      items: {
        type: "number",
        enum:[0,1,2,3,4,5,6],
        enumNames: ['Sun','Mon','Tue','Wed','Thu','Fri','Sat']
      },
      uniqueItems: true
    }
  }
}

const uiSchemaRecurring = {
  DayOfWeekId: {
    "ui:widget": "checkboxes"
  },
  TaskTypeId: {
    classNames:"two-column-field"
  },
  ScheduledFor:{
    classNames:'two-column-field',
    "ui:options": {
      future: true,
      todayButton: true
    }
  },
  Until:{
    "ui:options": {},
    classNames:'two-column-field'
  },
  Description: {
    "ui:widget": "textarea"
  },
  StatusNote: {
    "ui:widget": "textarea"
  }
};

const uiSchemaOccurOnce = {
  TaskTypeId: {
    classNames:"two-column-field"
  },
  ScheduledFor:{
    classNames:'two-column-field',
    "ui:options": {
      future: true,
      todayButton: true
    }
  },
  Description: {
    "ui:widget": "textarea"
  },
  StatusNote: {
    "ui:widget": "textarea"
  }
};

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

const dayArray = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];

class TaskEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRecurring: false,
      ScheduledFor: null,
      isSubmitting: false,
      schemaEdit: {
        title: 'Task',
        type: 'object',
        required: ['TaskTypeId'],
        properties: {
          TaskTypeId: {type:'number', title: 'Task Type'},
          StatusNote: {type: 'string', title: 'StatusNote'},
          Description: {type: 'string', title: 'Description'},
          Frequency: {type: 'boolean', title: 'Recurring', default: false},
          Priority: { type: 'string', title: 'Priority', enum:['High'], enumNames:['High'] }
        }
      },
      uiSchema: {
        DayOfWeekId: {
          "ui:widget": "checkboxes"
        },
        TaskTypeId: {
          classNames:"two-column-field"
        },
        ScheduledFor:{
          classNames:'two-column-field',
          "ui:options": {
            future: true,
            todayButton: true
          }
        },
        Until:{
          "ui:options": {},
          classNames:'two-column-field'
        },
        Description: {
          "ui:widget": "textarea"
        },
        StatusNote: {
          "ui:widget": "textarea"
        }
      }
    }
  }

  componentDidMount() {
    //check if data load needed
    const {
      // task,
      taskTypes,
      dispatch
    } = this.props;

    this.updateTaskStarted();

    TaskUtil.checkAndLoadTypes(taskTypes, dispatch); //make sure task types are loaded

    let id = this.getTaskId();
    if (id > 0 ) {
      dispatch(loadTaskById(id));
    }
    // setinitial state for editschema
    this.updateSchema();
    this.updateUiSchema();

    // if(StoreUtil.isLoaded(taskTypes)) {
    //   this.updateTaskTypeEnums();
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      task,
      taskData,
      taskTypesLoaded 
    } = this.props;

    if (!prevProps.task || prevProps.task.id != task.id) {
      this.updateTaskStarted();
    }
    // run when taskData loads
    if(prevProps.taskData !== taskData ){
      if(taskData.Frequency === true){
        this.setState({isRecurring: true})
      } else {
        this.setState({isRecurring: false})
      }
    }

    if(prevState.isRecurring !== this.state.isRecurring){
      this.updateSchema();
      this.updateUiSchema();
    }
    if(taskTypesLoaded && !this.state.schemaEdit.properties.TaskTypeId.enum){
      this.updateTaskTypeEnums();
    }
  }

  updateUiSchema(){
    if(this.state.isRecurring){
      this.setState({uiSchema: uiSchemaRecurring})
    } else {
      this.setState({uiSchema: uiSchemaOccurOnce})
    }
  }

  updateTaskTypeEnums(){
    let schema = this.state.schemaEdit;
    let taskTypes = StoreUtil.getData(this.props.taskTypes)
    let taskTypeEnums = [];
    let taskTypeEnumNames = [];
    taskTypes.forEach((taskType) => {
      if (!taskType.Automated) {
        taskTypeEnums.push(taskType.id);
        taskTypeEnumNames.push(taskType.Name);
      }
    });

    schema = dotProp.set(schema,"properties.TaskTypeId.enum", taskTypeEnums)
    schema = dotProp.set(schema,"properties.TaskTypeId.enumNames", taskTypeEnumNames)
    schema = dotProp.set(schema,"properties.TaskTypeId.default", taskTypeEnums[0])
    this.setState({schemaEdit: schema});
  }

  updateSchema(){
    let schema = {
      title: 'Task',
      type: 'object',
      required: ['TaskTypeId'],
      properties: {
        TaskTypeId: {type:'number', title: 'Task Type'},
        StatusNote: {type: 'string', title: 'StatusNote'},
        Description: {type: 'string', title: 'Description'},
        Frequency: {type: 'boolean', title: 'Recurring', default: false},
        Priority: { type: 'string', title: 'Priority', enum:['High'], enumNames:['High'] }
      }
    };
    if(this.state.isRecurring){
      schema = merge(schema, schemaRecurring);
    }else{
      schema = merge(schema, schemaOccurOnce);
    }
    this.setState({schemaEdit: schema});
  }

  updateTaskStarted() {
    const {
      task,
      dispatch,
      taskData
    } = this.props;

    // let taskData = StoreUtil.getData(task);

    if(taskData && taskData.Status === TaskUtil.Status.NOT_STARTED
      && StoreUtil.isLoaded(task) && !StoreUtil.isSaving(task)) {
      dispatch(startTask(taskData.id));
    }
  }

  onFormSubmit(data) {
    const {
      dispatch
    } = this.props;

    let formData = data.formData;
    formData.id = this.getTaskId();
    formData.SurgeryId = this.getSurgeryId();

    formData.DayOfWeek = "";
    if(formData.DayOfWeekId && formData.Frequency){
      formData.DayOfWeekId.forEach((day) => {
        formData.DayOfWeek += (dayArray[day] + " ");
      })
    }
    if(formData.Frequency===false){
      formData.Until = null;
      formData.DayOfWeek = null;
    }

    let resolve = false;
    
    if(data.submitSource && data.submitSource != "Save"){
      resolve = true;
    }
    this.setState({isSubmitting: true})
    dispatch(updateTask(formData, true, resolve));
  }

  onFormError(err){
    console.log("this is an error", err);
  }

  onFormChange(form){
    if(form.formData){
      if(!form.formData.StatusNote){
        form.formData.StatusNote = "";
      }
      if(form.formData.Frequency === true){
        this.setState({isRecurring: true});
      } else {
        this.setState({isRecurring: false});
      }

      if(form.formData.ScheduledFor != this.state.ScheduledFor){
        this.setState({ScheduledFor: form.formData.ScheduledFor});
      }
    }
  }

  getTaskId() {
    const { match } = this.props;

    if (match.params && match.params.id) {
      return parseInt(match.params.id);
    }

    return 0;
  }

  getSurgeryId() {
    const { match } = this.props;
    if (match.params && match.params.surgeryId) {
      return parseInt(match.params.surgeryId);
    }
    return 0;
  }

  render() {
    const {
      classes,
      task,
      // taskTypes,
      taskData
    } = this.props;

    const {
      isRecurring,
      ScheduledFor,
      isSubmitting,
      schemaEdit,
      uiSchema
    } = this.state;

    if(!StoreUtil.getData(task)
      && this.getTaskId() > 0 ){
      //wait for load
      return (<LoadingView />);
    }

    if (isSubmitting) {
      return (<LoadingView />);
    }

    let schema = schemaEdit;
    let usedUISchema = dotProp.set(uiSchema,'Until.ui:options.linked', null );

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(task, 'task');

    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          key="task-edit-page"
          schema={schema}
          uiSchema={usedUISchema}
          onChange={this.onFormChange.bind(this)}
          onSubmit={this.onFormSubmit.bind(this)}
          onError={this.onFormError.bind(this)}
          allowSubmit={!StoreUtil.isSaving(task)}
          formData={taskData}
          status={status}
          statusIsError={failure}
          secondaryText='Save and Resolve'
        />
      </div>
    );
  }
}

TaskEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  taskTypes: PropTypes.object,
  taskFrequency: PropTypes.bool,
  taskData: PropTypes.object,
  taskTypesLoaded : PropTypes.bool
};

function mapStateToProps(state, props) {
  const { tasks } = state;

  const { match } = props;
  let taskId;
  try{
    taskId = parseInt(match.params.id);
    if(isNaN(taskId)){
      taskId = 0;
    }
  }catch(error){
    console.log(error);
    taskId;
  }

  const isNewTask = taskId == 0;
  let task = null;

  if(!isNewTask){
    task = StoreUtil.get(tasks, StoreUtil.COMMON_ITEM, taskId);
  }else{
    task = StoreUtil.get(tasks, StoreUtil.COMMON_NEW_ITEM);
  }
  const storeData = StoreUtil.getData(task);
  let taskData = {}
  if(storeData){
    taskData={
      ...storeData, 
      ScheduledFor: storeData.ScheduledFor ? moment(storeData.ScheduledFor).format('YYYY-MM-DDTHH:mm:ss') : null,
      Until: storeData.Until ?  moment(storeData.Until).format('YYYY-MM-DDTHH:mm:ss') : null
    };
  }

  if(taskData){
    let daysOfWeek = taskData.DayOfWeek;
    if(daysOfWeek != null){
      let justDays = daysOfWeek.split(" ");
      justDays.pop();
      taskData.DayOfWeekId = [];
      justDays.forEach((day)=>{
        if(day === 'Sun'){
          taskData.DayOfWeekId.push(0);
        }
        if(day === 'Mon'){
          taskData.DayOfWeekId.push(1);
        }
        if(day === 'Tue'){
          taskData.DayOfWeekId.push(2);
        }
        if(day === 'Wed'){
          taskData.DayOfWeekId.push(3);
        }
        if(day === 'Thu'){
          taskData.DayOfWeekId.push(4);
        }
        if(day === 'Fri'){
          taskData.DayOfWeekId.push(5);
        }
        if(day === 'Sat'){
          taskData.DayOfWeekId.push(6);
        }
      })
    }
  }

  return {
    taskTypes: StoreUtil.get(tasks, 'types'),
    taskTypesLoaded: StoreUtil.isLoaded(StoreUtil.get(tasks, 'types')),
    task,
    taskData
  }
}

const styledPage = withStyles(styles)(TaskEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as TaskEditPage};
