import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { darken } from '@material-ui/core/styles/colorManipulator';
import StoreUtil from 'stores/StoreUtil';
import { getSurgery } from 'actions/surgeryActions';
import { getPostAcuteEvent, updatePostAcuteEvent } from 'actions/postAcuteEventActions';
import {QuestionGroupView} from 'containers/forms/QuestionGroupView';
import { SurgeryPostAcuteEventsView } from 'containers/postAcuteEvents/SurgeryPostAcuteEventsView';
import { PostAcuteEventEditView } from 'containers/postAcuteEvents/PostAcuteEventEditView';
import { LoadingView } from 'components/LoadingView';
import DataUtil from 'util/DataUtil';
import { viewSurgery } from 'actions/surgeryActions';
import { loadTaskById } from 'actions/tasksActions';
import TaskUtil from 'containers/tasks/TaskUtil';

const styles = (theme) => ({
  container: {
    width:'100%'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  headerPageTitle:{
    marginRight: theme.spacing.unit * 2
  },
  spacer:{
    height: theme.spacing.unit * 2
  },
  active: {
    backgroundColor: "#34a853",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#34a853", 0.4)
    },
    alignSelf:'center'
  },
  inactive: {
    backgroundColor: "#ea4335",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#ea4335", 0.4)
    },
    alignSelf:'center'
  }
});

class TaskFormPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      postAcuteFormData: {}
    }
    this.onCancel = this.onCancel.bind(this);
    this.submitPostAcuteEventForm = this.submitPostAcuteEventForm.bind(this);
    this.onPostAcuteEventFormChange = this.onPostAcuteEventFormChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {surgery, surgeryId, dispatch, postAcuteEvent, postAcuteEventId, taskId, task} = this.props;

    if(StoreUtil.needsLoadNoCache(surgery)){
      dispatch(getSurgery(surgeryId));
    }

    if(postAcuteEventId && StoreUtil.needsLoadNoCache(postAcuteEvent)){
      dispatch(getPostAcuteEvent(postAcuteEventId));
    }

    if(taskId && taskId > 0 && StoreUtil.needsLoadNoCache(task)){
      dispatch(loadTaskById(taskId));
    }
  }

  componentDidUpdate(){
    const {dispatch, postAcuteEvent, postAcuteEventId} = this.props;

    if(postAcuteEventId && StoreUtil.needsLoadNoCache(postAcuteEvent)){
      dispatch(getPostAcuteEvent(postAcuteEventId));
    }
  }

  onCancel(){
    const{
      surgery,
      dispatch
    } = this.props;
    let surgeryData = StoreUtil.getData(surgery);
    
    dispatch(viewSurgery(surgeryData));
  }

  onPostAcuteEventFormChange(postAcuteData, data) {
    this.setState({
      postAcuteFormData: {data, ...postAcuteData}
    })
  }

  submitPostAcuteEventForm() {
    const {
      dispatch
    } = this.props;

    const {
      postAcuteFormData
    } = this.state;

    const formData = postAcuteFormData.data.formData;
    formData.SurgeryId = postAcuteFormData.surgery.id;
    if(postAcuteFormData.postAcuteEvent){
      formData.id = postAcuteFormData.postAcuteEvent.id;
    }

    //change secondary reasons to objects
    let secondaryReasonIds = formData.SecondaryReasons;
    delete formData.SecondaryReasons;
    let secondaryReasons = [];
    let seenReasonMap = {};
    if(secondaryReasonIds && secondaryReasonIds.length>0 ){
      secondaryReasonIds.forEach((reasonId)=>{
        if(reasonId && !seenReasonMap[reasonId]){
          seenReasonMap[reasonId] = true;
          secondaryReasons.push({
            ReasonId:reasonId
          });
        }
      });
    }

    dispatch(updatePostAcuteEvent(formData, secondaryReasons));
  }

  render() {
    const { 
      surgery,
      surgeryId,
      questionGroupId,
      taskId,
      classes,
      postAcuteEvent,
      postAcuteEventId,
      task,
      user
    } = this.props;

    if(!StoreUtil.getData(surgery)){
      return (<LoadingView />);
    }

    let status = {
      status: '',
      failure: false
    }

    let surgeryData = StoreUtil.getData(surgery);
    let paeData;
    
    if(postAcuteEventId && StoreUtil.isLoadedOrFailed(postAcuteEvent)){
      paeData = StoreUtil.getData(postAcuteEvent)
    }else if (postAcuteEventId){
      return (<LoadingView />);
    }

    let taskData;

    if(taskId && StoreUtil.isLoadedOrFailed(task)){
      taskData = StoreUtil.getData(task)
    }else if (taskId){
      return (<LoadingView />);
    }

    let disableSave = false;
    if(taskData.Status == TaskUtil.Status.RESOLVED){
      disableSave = true;
    }

    if(paeData){
      //convert the reasons
      paeData = DataUtil.deepCopy(paeData);
      let secondaryReasons = paeData.SecondaryReasons;
      delete paeData.SecondaryReasons;
      let secondaryReasonsIds = secondaryReasons.map((reason)=>{
        return reason.ReasonId;
      });
      paeData.SecondaryReasons = secondaryReasonsIds;
    }
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography
            className={classes.headerPageTitle}
            variant="display1">
            {taskData.Description}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <SurgeryPostAcuteEventsView 
          surgery={surgeryData}
          source={taskData.Description} 
          showAddButton={false}
          taskId={taskId}
          questionGroupId={questionGroupId}
        />
        <div className={classes.spacer} />
        {taskData.Status != TaskUtil.Status.RESOLVED &&
          <PostAcuteEventEditView
            surgery={surgeryData}
            source={taskData.Description}
            status={status}
            postAcuteEvent={paeData}
            taskId={taskId}
            questionGroupId={questionGroupId}
            onPostAcuteEventFormChange={this.onPostAcuteEventFormChange}
          />
        }
        <div className={classes.spacer} />
        {(questionGroupId && questionGroupId != 'null') && <QuestionGroupView
          surgeryId={surgeryId}
          questionGroupId={questionGroupId}
          taskId={taskId}
          user={user}
          onCancel={this.onCancel}
          backOnCancel={false}
          disableSave={disableSave}
          submitPostAcuteEventForm={this.submitPostAcuteEventForm}
        />}
      </div>
    );
  }
}

TaskFormPage.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object,
  surgeryId : PropTypes.string.isRequired,
  questionGroupId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  task: PropTypes.object,
  postAcuteEvent: PropTypes.object,
  postAcuteEventId: PropTypes.string,
  user: PropTypes.object.isRequired
}

function mapStateToProps(state, existingProps) {
  const { match } = existingProps;
  const { surgeries, postAcuteEvents, tasks, user } = state;

  const surgeryId = match.params.surgeryId;
  const taskId = match.params.taskId;
  const questionGroupId = match.params.questionGroupId;

  let postAcuteEvent;
  let postAcuteEventId;

  if(match.params.postAcuteEventId){
    postAcuteEventId = match.params.postAcuteEventId;
    postAcuteEvent = StoreUtil.get(postAcuteEvents, StoreUtil.COMMON_ITEM, postAcuteEventId);
  }

  let task;

  if(taskId && taskId > 0){
    task = StoreUtil.get(tasks, StoreUtil.COMMON_ITEM, taskId);
  }
  
  return {
    surgery: StoreUtil.get(surgeries, StoreUtil.COMMON_ITEM, surgeryId),
    surgeryId,
    taskId,
    task,
    questionGroupId,
    postAcuteEvent,
    postAcuteEventId,
    user
  };
}

const styled = withStyles(styles)(TaskFormPage);
const connected = connect(mapStateToProps)(styled);
export { connected as TaskFormPage };
