import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


import { DynamicTable } from 'components/DynamicTable';

import { DateUtil } from 'util/DateUtil'
import { DataUtil } from 'util/DataUtil'

const styles = theme => ({
  actionButton: {
    marginRight: theme.spacing.unit
  }
});


class PACPatientTable extends Component {

  constructor(props) {
    super(props);
    this.renderActionButton = this.renderActionButton.bind(this);
    this.onActionClick = this.onActionClick.bind(this);

    this.columns = [
      {label:"Last", key: "LastName", sortable: false, sortDown: false},
      {label:"First", key: "FirstName", sortable: false, sortDown: false, style:{width:160}},
      {label:"DOB", key: "BirthDate", sortable: false, sortDown: false,
        filter: false, format:this.dateFormat, style:{width:160}},
      {label:"DOS", key: "Surgeries.SurgeryDateTime", sortable: false, sortDown: false,
        filter: false, format:this.dosFormat, style:{width:160}},
      {label:"Navigator", key: "Surgeries.Surgeon.User", sortable: false, sortDown: false,
        filter: false, format:this.navigatorFormat, style:{width:160}},
      {label:"Action", key: "action", sortable: false,
        format: this.renderActionButton, style:{width:160}}
    ];
  }

  navigatorFormat(date, row) {
    if(row.Surgeries 
      && Array.isArray(row.Surgeries) 
      && row.Surgeries.length>0
      && row.Surgeries[0].Surgeon
      && row.Surgeries[0].Surgeon.User){
      return DataUtil.formatNameForUser(row.Surgeries[0].Surgeon.User);
    }
    
    return '';
  }

  dosFormat(date, row) {
    if(row.Surgeries 
      && Array.isArray(row.Surgeries) 
      && row.Surgeries.length>0){
      date = row.Surgeries[0].SurgeryDateTime;
    }
    
    return DateUtil.formatFromDB(date);
  }

  dateFormat(date) {
    return DateUtil.formatFromDB(date);
  }

  onActionClick(row) {
    const { onAdmitClick } = this.props;
    onAdmitClick(row);
  }

  renderActionButton(field, row, classes) {

    let admission = DataUtil.findAdmissionForPatient(row);

    let text;
    if(admission){
      text = "View"
    }else{
      text = "Admit"
    }

    return (
      <React.Fragment>
        <Button 
          size="small" 
          variant="outlined" 
          className={classes.actionButton} 
          onClick={this.onActionClick}>
          {text}
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { 
      classes,
      onAdmitClick,
      ...inputProps
    } = this.props;

    if(onAdmitClick){
      //used to prevent passthrough
    }
    return (
      <DynamicTable 
        columns={this.columns} 
        rowClasses={classes}
        showFilters={false}
        idKey="Surgeries[0].id"
        preloadMessage={"Please enter Patient Last Name and DOB"}
        noDataMessage={"No Patients Found"}
        {...inputProps} 
      />
    );
  }
}
PACPatientTable.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  data: PropTypes.object.isRequired,
  header: PropTypes.any,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onAdmitClick: PropTypes.func.isRequired
};


const styled = withStyles(styles)(PACPatientTable);
export {styled as PACPatientTable};
