export const API_ALERT_COUNTS = 'API_ALERT_COUNTS';

export const getAlertCount = (options)=> {
  return {
    type: API_ALERT_COUNTS,
    request: {
      url: '/alertCount',
      method: 'GET',
      params : {opts:options}
    }
  }
};