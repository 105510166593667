import { takeEvery, put } from 'redux-saga/effects';
import { success} from 'redux-saga-requests';
import {
  API_GET_QUESTION_OPTIONS,
  API_SET_QUESTION_OPTIONS
} from 'actions/questionGroupActions';

function* questionOptions(action) {
  yield put({
    type: API_SET_QUESTION_OPTIONS,
    data: action.data
  })
}

export const getQuestionOptionsSaga = [
  takeEvery(success(API_GET_QUESTION_OPTIONS), questionOptions)
];