import React, {Component} from 'react';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class UsersBCItem extends Component {

  render() {
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label="Users"
          icon="perm_identity"
          path={NavigationUtil.navLink("/users")}
        />
      </React.Fragment>
    );
  }
}

UsersBCItem.propTypes = {
};


export {UsersBCItem as UsersBCItem };