import { call, takeEvery } from 'redux-saga/effects';
import { sendRequest } from 'redux-saga-requests';
import { success} from 'redux-saga-requests';
import { updateRiskScore } from 'actions/surgeryActions';
import {
  API_UPDATE_PERTINENT_POSITIVES
} from 'actions/positivesActions';

function* updateRiskScores(action) {
  yield call(
    sendRequest,
    updateRiskScore(action.meta.requestAction.id),
    { dispatchRequestAction: false });
}

//TODO: Create case for failure
export const surgerySaga = [
  takeEvery(success(API_UPDATE_PERTINENT_POSITIVES), updateRiskScores)
];
