import React from "react";
import PropTypes from "prop-types";

function TextareaWidget(props) {
  const { BaseInput } = props.registry.widgets;
  return <BaseInput multiline={true} {...props} />;
}

TextareaWidget.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  registry: PropTypes.any
};


export default TextareaWidget;
export { TextareaWidget as TextareaWidget};