export const API_GET_SURGERY_ADD_LINFO = 'API_GET_SURGERY_ADD_LINFO';
export const API_SURGERY_ADD_LINFO_UPDATE = 'API_SURGERY_ADD_LINFO_UPDATE';

export const getSurgeryAddlInfo = (surgeryId)=> {
  const options = {
    where: {SurgeryId: surgeryId}
  }
  return {
    type: API_GET_SURGERY_ADD_LINFO,
    id: surgeryId,
    request: {
      url: '/SurgeryAddlInfo',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updateSurgeryAddlInfo = (formData, backOnSuccess = false)=> {
  let options = (formData.id && formData.id > 0) ? {
    where: { id: formData.id }
  } : null;

  return {
    type: API_SURGERY_ADD_LINFO_UPDATE,
    id: formData.id || 0,
    surgery: formData,
    backOnSuccess,
    request:{
      url: '/SurgeryAddlInfo',
      method: 'PUT',
      params : {opts:options},
      data : formData
    }
  }
};