import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { DateUtil } from 'util/DateUtil';
import { DataUtil } from 'util/DataUtil';
import { markMessageRead } from 'actions/messageActions';
import AuthRule from 'businessRule/AuthRule';

const styles = theme => ({
  card: {
    marginBottom: theme.spacing.unit*2
  }
});

class MessageView extends React.Component {

  componentDidMount() {
    this.updateMessageRead();
  }

  componentDidUpdate(prevProps) {
    const {
      message
    } = this.props;
    //message changed
    if (!prevProps.message || prevProps.message.id != message.id) {
      this.updateMessageRead();
    }
  }

  updateMessageRead() {
    const {
      message,
      dispatch,
      user
    } = this.props;

    let msg = {
      id: message.id,
      IsRead: true,
      ReadById: user.id
    }

    const messageFromPAC = DataUtil.isAppPAC(message.App);

    if (!message.IsRead && !AuthRule.doesUserHaveHelpDeskPermisson(user)){
      if((messageFromPAC && !AuthRule.doesUserHavePACPermission(user))
        || (!messageFromPAC && !AuthRule.doesUserHaveNurseNavPermission(user))){
        let userTmp = {
          id:user.id,
          FirstName:user.FirstName,
          LastName:user.LastName,
          Username:user.Username
        }

        dispatch(markMessageRead(msg, userTmp));
      }
    }
  }

  render() {
    const {
      message,
      classes
    } = this.props;

    let state = "Unread";
    if (message.IsRead) {
      if(message.ReadBy){
        let name;
        if(message.ReadBy.LastName){
          if(message.ReadBy.FirstName){
            name = message.ReadBy.FirstName + " " + message.ReadBy.LastName;
          }else{
            name = message.ReadBy.LastName;
          }
        }else{
          name = message.ReadBy.Username
        }
        state = "Read by " + name + " on " +
          DateUtil.formatFromDB(message.updatedAt);
      }else{ 
        state = DateUtil.formatFromDB(message.updatedAt)+ " : Read";
      }
    }else{
      state = DateUtil.formatFromDB(message.updatedAt)+ " : "+state;
    }

    let sender;
    if(message.Sender && message.Sender.LastName){
      if(message.Sender.FirstName){
        sender = message.Sender.FirstName + " " + message.Sender.LastName;
      }else{
        sender = message.Sender.LastName;
      }
    }else{
      if(DataUtil.isAppPAC(message.App)){
        sender = "Provider";
      }else{
        sender = "Nurse Navigator";
      }
    }

    return (
      <Card className={classes.card} key={"message-"+message.id}>
        <CardContent>
          <Typography color="textSecondary">
            {sender}
          </Typography>
          <Typography color="textSecondary">
            {state}
          </Typography>
          <Typography variant="body1">
            {message.Message}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

MessageView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isPAC: PropTypes.bool,
  user: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const {
    user
  } = state;

  return {
    user
  };
}

const styled = withStyles(styles)(MessageView);
const connected = connect(mapStateToProps)(styled);
export { connected as MessageView };
