import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getQuestionGroup, updateQuestionGroupAnswers } from 'actions/questionGroupActions';
import { LoadingView } from 'components/LoadingView';
import { MaterialForm } from 'components/forms/MaterialForm';
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';
import QuestionGroupUtil from 'util/QuestionGroupUtil';
import { loadTaskById, startTask, resolveTask } from 'actions/tasksActions';
import TaskUtil from 'containers/tasks/TaskUtil';
import dotProp from 'dot-prop-immutable';
import DateUtil from 'util/DateUtil';
import DataUtil from 'util/DataUtil';
import { formDefaults } from 'util/FormDefaults';
import { updateAdmission } from 'actions/admissionActions';
import { updateSurgery } from 'actions/surgeryActions';

const styles = (theme) => ({
  container: {
    width:'100%',
    display:'flex',
    flexDirection:'column',
    margin : theme.spacing.unit
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  }
});

class QuestionGroupView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      localMessage: null,
      alteredSchemaUI : null
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      questionGroup,
      questionGroupId,
      surgeryId,
      dispatch,
      taskId,
      task,
      admissionId
    } = this.props;

    this.updateTaskStarted();

    if (taskId && taskId > 0 && StoreUtil.needsLoadNoCache(task)) {
      dispatch(loadTaskById(taskId));
    }

    if(StoreUtil.needsLoadNoCache(questionGroup)){
      dispatch(getQuestionGroup(questionGroupId, surgeryId, admissionId));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      task
    } = this.props;
    
    if (!prevProps.task || !prevProps.task.data || prevProps.task.id != task.id) {
      this.updateTaskStarted();
    }
  }

  updateTaskStarted() {
    const {
      taskId,
      task,
      dispatch
    } = this.props;

    let taskData = StoreUtil.getData(task);
    
    if(taskId && taskData && taskData.Status === TaskUtil.Status.NOT_STARTED
      && StoreUtil.isLoaded(task)) {
      dispatch(startTask(taskId));
    }
  }

  onFormSubmit(data){
    const {
      surgeryId,
      questionGroupId,
      questionGroup,
      dispatch,
      onQuestionGroupSubmit,
      admissionId,
      taskId,
      admission,
      user,
      submitPostAcuteEventForm
    } = this.props;
    const formData = data.formData;
    const databaseData = StoreUtil.getData(questionGroup);
    const dbData = QuestionGroupUtil.ConvertFormDataToDatabase(databaseData, formData);
    
    if((!dbData || dbData.totalChanges < 1) && (data.submitSource && data.submitSource == "Save")){
      //nothing to save, show a message?
      this.setState({
        localMessage:"Please alter a field before saving."
      });
    } else if((!dbData || dbData.totalChanges < 1) && (data.submitSource && data.submitSource != "Save")) {
      dispatch(resolveTask(taskId, true));
    } else{
      let resolveTaskId;

      if(data.submitSource && data.submitSource != "Save"){
        resolveTaskId = taskId;
      }

      let backOnSuccess = true;
      if(onQuestionGroupSubmit){
        backOnSuccess = false;
      }
      dispatch(
        updateQuestionGroupAnswers(questionGroupId, surgeryId, dbData, admissionId, resolveTaskId,backOnSuccess)
      );

      const isPACDischargeForm = questionGroupId === DataUtil.DISCHARGE_QUESTION_DATE_ID;
      const isPTDischargeForm = questionGroupId === DataUtil.DISCHARGE_QUESTION_PT_GROUP_ID;
      if( isPACDischargeForm || isPTDischargeForm ){
        const { uiSchema, formData } = data;
        const {
          FacilityId,
          SurgeryId,
          AdmissionDate,
          AnticipatedDischargeDate,
          VisitCount
        } = admission.data;

        const dischargeDateQuestionOrder = 1; // if the question order changes this most be updated
        const dischargeDateId = uiSchema["ui:order"][dischargeDateQuestionOrder];
        const dischargeDate = formData[dischargeDateId];
        const requestData = {
          id: admissionId,
          FacilityId,
          SurgeryId,
          AdmissionDate,
          AnticipatedDischargeDate,
          VisitCount,
          DischargeDate: dischargeDate,
          UpdatedById: user["id"]
        }
        dispatch(updateAdmission(requestData))
      }

      const isDiabetesForm = questionGroupId === DataUtil.DIABETES_QUESTION_GROUP_ID;
      if(isDiabetesForm){
        const updates = {
          id: surgeryId,
          Alc: dbData.answersUpdate[0].Value,
          UpdatedById: user.id
        }
        dispatch(updateSurgery(updates));
      }

      if(onQuestionGroupSubmit){
        onQuestionGroupSubmit(formData);
      }
    }

    if(submitPostAcuteEventForm){
      submitPostAcuteEventForm();
    }
  }

  onFormError(err){
    console.log('on form error!!', err);
  }

  onFormChange(data){
    const{
      questionGroup,
      readyOnlyOnEdit
    } = this.props;

    if(this.state.localMessage){
      this.setState({
        localMessage:null
      });
    }

    if(questionGroup && StoreUtil.getData(questionGroup)){
      let formProcessed = StoreUtil.getData(questionGroup).processed;
      if(formProcessed.dependentQuestions && formProcessed.dependentQuestions.length>0){
        //need to update schema, because questions might have changed
        const newSchemaUI = QuestionGroupUtil.alterSchemaForState(formProcessed, readyOnlyOnEdit, data.formData);
        this.setState({
          alteredSchemaUI:newSchemaUI
        });
      }
    }
  }

  render() {
    //find surgery
    const { classes,
      questionGroup,
      readyOnlyOnEdit,
      overrideStatusError,
      taskId,
      onCancel,
      backOnCancel,
      task,
      minDate,
      disableSave
    } = this.props;

    const {
      localMessage,
      alteredSchemaUI
    } = this.state;

    if(!StoreUtil.isLoadedOrFailed(questionGroup)){
      return (<LoadingView />);
    } else if(taskId && taskId > 0 && !StoreUtil.isLoadedOrFailed(task)) {
      return (<LoadingView />);
    } else if(StoreUtil.isLoadFailed(questionGroup)){
      return (
        <Typography
          className={classes.headerContainer}
          variant='title' color='error'>
          There was an issue loading the forms.
        </Typography>
      );
    }else{

      let {
        status,
        failure
      } = FormUtil.GetLoadStatus(questionGroup, 'form');

      if(overrideStatusError){
        status = overrideStatusError;
        failure = true;
      }

      if(localMessage){
        status = localMessage;
        failure = false;
      }

      const databaseData = StoreUtil.getData(questionGroup);
      
      let schema, answers, schemaUI;
      let hasAnswers = true;
      //get form schema
      if(databaseData && databaseData.processed){
        const processedForm = databaseData.processed;
        schema = processedForm.schema;
        answers = processedForm.answers;
        schemaUI = processedForm.schemaUI;
        hasAnswers = processedForm.hasAnswers;
        //change for read only
        if(alteredSchemaUI){
          schemaUI = alteredSchemaUI;
        }else{
          schemaUI = QuestionGroupUtil.alterSchemaForState(processedForm, readyOnlyOnEdit, answers);
        }
      }

      if(minDate){
        Object.keys(schema.properties).forEach((field)=>{
          if(schema.properties[field].format && schema.properties[field].format == 'date'){
            schemaUI[field] = dotProp.set(schemaUI[field],'ui:options.linked', DateUtil.formatToDB(minDate));
          }
        });
      }

      let name ='';
      if(databaseData && databaseData.name){
        name = databaseData.name;
      }

      let secondaryText;

      if(taskId && taskId > 0){
        secondaryText = 'Save and Resolve';
      }

      let allowSubmit = !readyOnlyOnEdit || !hasAnswers;

      if(disableSave){
        allowSubmit = false;
      }

      formDefaults.forEach(data => { // adds any form defaults
        const { QuestionId, defaultValue } = data;
        if(schema && schema.properties && schema.properties.hasOwnProperty(QuestionId)){
          // use default if no value exist in the field
          const useDefault = answers[QuestionId] === "";
          answers[QuestionId] = useDefault ? defaultValue : answers[QuestionId];
        }
      })
      
      return (
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography variant="headline">{name}</Typography>
          </div>
          <div className={classes.formContainer}>
            <MaterialForm
              schema={schema}
              uiSchema={schemaUI}
              onSubmit={this.onFormSubmit}
              onError={this.onFormError}
              onChange={this.onFormChange}
              formData={answers}
              status={status}
              statusIsError={failure}
              secondaryText={secondaryText}
              allowSubmit={allowSubmit}
              onCancel={onCancel}
              backOnCancel={backOnCancel}
            />
          </div>
        </div>
      );
    }
  }
}
QuestionGroupView.defaultProps = {
  readyOnlyOnEdit: false,
  backOnCancel: true,
  disableSave: false
}

QuestionGroupView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgeryId : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  questionGroupId : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  questionGroup: PropTypes.object.isRequired,
  readyOnlyOnEdit: PropTypes.bool.isRequired,
  onQuestionGroupSubmit: PropTypes.func,
  overrideStatusError: PropTypes.string,
  taskId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  task: PropTypes.object,
  admissionId: PropTypes.number,
  onCancel: PropTypes.func,
  backOnCancel: PropTypes.bool,
  minDate: PropTypes.string,
  disableSave: PropTypes.bool,
  admission: PropTypes.object,
  user: PropTypes.object.isRequired,
  submitPostAcuteEventForm: PropTypes.func
}

function mapStateToProps(state, existingProps) {
  const { questionGroupId, surgeryId, taskId, admissionId } = existingProps;
  const { questionGroup, tasks } = state;

  let formId = QuestionGroupUtil.getFormStoreId(questionGroupId, surgeryId, admissionId);
  let group = StoreUtil.get(questionGroup, StoreUtil.COMMON_ITEM, formId);
  let task = StoreUtil.get(tasks, StoreUtil.COMMON_ITEM, taskId);
  
  return {
    questionGroup: group,
    taskId: taskId,
    task
  };
}

const styled = withStyles(styles)(QuestionGroupView);
const connected = connect(mapStateToProps)(styled);
export { connected as QuestionGroupView };
export default QuestionGroupView;
