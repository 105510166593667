import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import StoreUtil from 'stores/StoreUtil';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class TaskBCItem extends Component {

  render() {
    const {
      taskType
    } = this.props;

    let title = "Task";
    // console.log(taskType);
    if (taskType) {
      title = taskType.Name;
    }

    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          icon="check_circle"
          path={NavigationUtil.navLink("/surgery/"+this.getSurgeryId()+"/task/"+this.getTaskId())}
        />
      </React.Fragment>
    );
  }

  getTaskId() {
    const { match } = this.props;

    if (match.params && match.params.id) {
      return parseInt(match.params.id);
    }
    return 0;
  }

  getSurgeryId() {
    const { match } = this.props;

    if (match.params && match.params.surgeryId) {
      return parseInt(match.params.surgeryId);
    }
    return 0;
  }  
}

TaskBCItem .propTypes = {
  task: PropTypes.object.isRequired,
  taskType: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  const { match } = props;
  const { tasks } = state;

  let task = StoreUtil.get(tasks, StoreUtil.COMMON_ITEM, match.params.id);

  let taskType = null;
  if (task != null && StoreUtil.isLoaded(task)) {
    let types = StoreUtil.get(tasks, "types");
    //find the task type i want
    if (types && types.data && Array.isArray(types.data.rows)) {
      taskType = types.data.rows.find(x => x.id === task.data.TaskTypeId);  
    }
  }

  return {
    task : task,
    taskType : taskType
  };
}

const connected = connect(mapStateToProps)(TaskBCItem);
export {connected as TaskBCItem };
