export const API_GET_HOSPITAL_VISIT = 'API_GET_HOSPITAL_VISIT';
export const API_TABLE_HOSPITAL_VISIT_EPISODE = 'API_TABLE_HOSPITAL_VISIT_EPISODE';
export const API_UPDATE_HOSPITAL_VISIT = 'API_UPDATE_HOSPITAL_VISIT';
export const API_TABLE_HOSPITAL_VISIT = 'API_TABLE_HOSPITAL_VISIT';

export const tableHospitalVisitsForEpisodeId = (surgeryId, options)=> {
  
  options.include = [
    {
      association: "Admission",
      where:{
        SurgeryId:surgeryId
      },
      attributes: [
        "id",
        "SurgeryId"
      ]
    },
    {
      association: "ReadmissionFacility",
      attributes: [
        "id",
        "Name"
      ]
    },
    {
      association: "Reason",
      attributes: [
        "id",
        "Text"
      ]
    },
    {
      association: "SecondaryReasons",
      attributes: ["id"],
      include:[
        {
          association: "Reasons",
          attributes: [
            "id",
            "Text"
          ]
        }
      ]
    }
  ];

  if(options && options.where){
    options.where.Deleted = false;
  } else {
    options.where = {
      Deleted: false
    }
  }

  return {
    type: API_TABLE_HOSPITAL_VISIT_EPISODE,
    id: surgeryId,
    request: {
      url: '/HospitalVisits',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const tableHospitalVisitsForAdmission = (options)=> {
  if(options && options.where){
    options.where.Deleted = false;
  } else {
    options.where = {
      Deleted: false
    }
  }

  return {
    type: API_TABLE_HOSPITAL_VISIT,
    request: {
      url: '/HospitalVisits',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const getHospitalVisit = (id)=> {
  const options = {
    where: {id: id},
    include: [
      {
        association:'SecondaryReasons'
      },
      {
        association: "Admission",
        attributes: [
          "id",
          "SurgeryId"
        ]
      }
    ]
  }
  return {
    type: API_GET_HOSPITAL_VISIT,
    id: id,
    request: {
      url: '/HospitalVisits',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updateHospitalVisit = (formData, secondaryReasons, backOnSuccess = true, redirectOnSuccess = null )=> {
  let id = 0;
  if(formData.id && formData.id > 0){
    id = formData.id;
  }
  
  let resultOptions = {
    include: {
      association:"SecondaryReasons"
    }
  };

  let body = {
    modelName:"HospitalVisits",
    modelData: formData,
    relationModelName:"HospitalVisitSecondaryReasons",
    relationData:secondaryReasons,
    relationModelForeignKey:"HospitalVisitId",
    modelResultOption: resultOptions
  }

  return {
    type: API_UPDATE_HOSPITAL_VISIT,
    id: id,
    backOnSuccess,
    redirectOnSuccess,
    request:{
      url: '/UpsertAndRelate',
      method: 'PUT',
      data : body
    }
  }
};