import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TableCell from '@material-ui/core/TableCell';
import { DynamicTable } from 'components/DynamicTable';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { DateUtil } from 'util/DateUtil'
import { DataUtil } from 'util/DataUtil'
import QuestionGroupUtil from 'util/QuestionGroupUtil'
import { connect } from 'react-redux';

const styles = (theme) => ({
  answerSingle:{
    display:'inline'
  },
  answerDiv:{
    marginLeft:theme.spacing.unit,
    marginRight:theme.spacing.unit
  },
  extraRow:{

  },
  extraRowForm: {
    marginBottom:theme.spacing.unit
  },
  extraRowFormTitle:{
    fontWeight:600,
    fontSize:'0.85rem'
  }
});


class SurgeryAdmissionsTable extends Component {

  constructor(props) {
    super(props);

    const {
      user
    } = this.props;

    const isPTUser = DataUtil.isPTUser(user);
    const Anticipated_label = isPTUser ? "Anticipated NOV" : "Anticipated LOS/NOV";
    const Actual_label = isPTUser ? "Actual NOV" : "Actual LOS/NOV";

    this.columns = [
      {
        label: "Admitted",
        key: "AdmissionDate",
        sortable: true,
        type: "date",
        sortDown: false,
        format: this.dateFormat,
        style: {width:100}
      },
      {
        label: "Anticipated Discharge",
        key: "AnticipatedDischargeDate",
        type: "date", 
        sortable: true,
        sortDown: false,
        format: this.dateFormat,
        style: {width:150}
      },
      {
        label: Anticipated_label,
        key: "AnticipatedDischargeDate_", 
        sortable: false,
        sortDown: false,
        filter: false,
        format: this.lengthOfStay,
        style: {width:150}
      },
      {
        label: "Discharged",
        key: "DischargeDate",
        sortable: true,
        type: "date", 
        format: this.dateFormat,
        style: {width:100}
      },
      {
        label: Actual_label,
        key: "DischargeDate_",
        sortable: false,
        filter: false,
        format: this.lengthOfStay,
        style: {width:140}
      },
      {
        label: "Facility",
        key: "Facility.Name",
        sortable: true,
        style: {width:150}
      },
      {
        label: "Action",
        key: "none",
        sortable: false,
        style: {width:50},
        format: this.renderActionButtons.bind(this)
      }
    ];
  }

  navigatorFormat(date, row) {
    if(row.Surgeries 
      && row.Surgeries.Surgeon
      && row.Surgeries.Surgeon.User){
      return DataUtil.formatNameForUser(row.Surgeries.Surgeon.User);
    }
    
    return '';
  }

  lengthOfStay(date, row){
    if(!date){
      return '';
    }
    
    let periodStart = moment(row.AdmissionDate);
    let periodEnd = moment(date);
    const days = periodEnd.diff(periodStart, 'days');

    if(days != 1){
      return `${days} days`;
    }else{
      return `${days} day`;  
    }
  }

  dateFormat(date) {
    return DateUtil.formatFromDB(date);
  }

  renderActionButtons(none, admission) {
    return (
      <Button size="small" onClick={this.onMessageClick.bind(this, admission)}>
        <Icon >message</Icon>
      </Button>
    );
  }

  onMessageClick(admission, event) {
    const {
      onMessageClick
    } = this.props;

    event.stopPropagation(); //don't allow row click to fire

    if(onMessageClick){
      onMessageClick(admission);
    }
  }

  rowExtraRenderer(row, columns){
    const {
      classes
    } = this.props;

    let answers = row.Answers;
    //sort answers into initial eval and discharge
    let initAnswers = [];
    let dischargeAnswers = [];

    if(answers && answers.length > 0){
      answers.forEach((answer)=>{
        if(answer.Question 
          && answer.AnswerValues 
          && answer.AnswerValues[0]
          && answer.AnswerValues[0].Value){
          let response = {
            question: answer.Question.Question,
            answer: answer.AnswerValues[0].Value,
            key: answer.AnswerValues[0].id+"answer"
          }
          if(answer.Question.QuestionGroupId == DataUtil.INITIAL_EVAL_QUESTION_GROUP_ID){
            initAnswers.push(response);
          }else if(answer.Question.QuestionGroupId == DataUtil.DISCHARGE_QUESTION_GROUP_ID){
            dischargeAnswers.push(response);
          }else{
            console.log("UNKNOWN QUESTION GROUP ON ADMISSION ANSWER "+answer.Question.QuestionGroupId);
          }
        }
      });
    }
    let initEvalForm = QuestionGroupUtil.renderResponses(initAnswers,classes);
    let initialEval = (
      <div
        className={classes.extraRowForm}
      >
        <Typography
          variant='body1'
          className={classes.extraRowFormTitle}>
          <b>Initial Evaluation</b>
        </Typography>
        {initEvalForm}
      </div>
    );

    let dischargeForm = QuestionGroupUtil.renderResponses(dischargeAnswers,classes);
    let dischargeEval = (
      <div
        className={classes.extraRowForm}
      >
        <Typography
          variant='body1'
          className={classes.extraRowFormTitle}>
          Discharge Evaluation Summary
        </Typography>
        {dischargeForm}
      </div>
    );

    return (
      <TableCell
        key={row['id']+"extraContent"}
        padding="dense"
        colSpan={columns.length}
        className={classes.extraRow}
      >
        {initialEval}
        {dischargeEval}
      </TableCell>
    );

  }

  render() {
    const { 
      classes,
      ...inputProps
    } = this.props;

    if(classes){
      //not using these, don't pass through
    }
    return (
      <DynamicTable 
        columns={this.columns} 
        noDataMessage={"No Admissions Found"}
        rowExtraRenderer={this.rowExtraRenderer.bind(this)}
        {...inputProps} 
      />
    );
  }
}
SurgeryAdmissionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  header: PropTypes.any,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onMessageClick: PropTypes.func,
  isPTSurgery: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const { user, surgeries } = state;

  const params = location.pathname.split("/");
  const surgeryId = params[params.length - 1];
  const isPTSurgery = DataUtil.isPTSurgeryBySurgery(surgeries, surgeryId);

  return { user, isPTSurgery };
}

const styled = withStyles(styles)(SurgeryAdmissionsTable);
const connected = connect(mapStateToProps)(styled);
export {connected as SurgeryAdmissionsTable};