import { success } from 'redux-saga-requests'
import {
  API_HSX_EVENTS,
  API_HSX_SELECTED_EVENT,
  API_HSX_UPDATE_STATUS
} from 'actions/hsxActions'

export const hsxEvents = (state = {}, action) => {
  if (action.type === success(API_HSX_EVENTS)) return action.data
  return state
}

export const selectedHsxEvent = (state = {}, action) => {
  if (action.type === API_HSX_SELECTED_EVENT) return action.payload
  return state
}

export const updatedHsxEvent = (state = {}, action) => {
  if (action.type === API_HSX_UPDATE_STATUS) {
    if (action.payload) {
      return action.payload
    } else {
      return state
    }
  }
  if (action.type === success(API_HSX_UPDATE_STATUS)) {
    return { success: true }
  }
  return state
}
