import React, {Component} from 'react';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class FacilityListBCItem extends Component {

  render() {
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label="Facilities"
          icon="local_hospital"
          path={NavigationUtil.pacLink("/facilities")}
        />
      </React.Fragment>
    );
  }
}

FacilityListBCItem.propTypes = {
};


export {FacilityListBCItem as FacilityListBCItem };