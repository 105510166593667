import ReducerUtil from 'reducers/ReducerUtil'
import DataUtil from 'util/DataUtil';

import {
  API_LIST_NAV_USERS,
  API_TABLE_NAV_USERS,
  API_GET_NAV_USER,
  API_UPDATE_NAV_USER
} from 'actions/navUserActions';

export const navUsers = (state = {}, action) => {
  const result = ReducerUtil.simpleReduce(
    state, 
    action, 
    'navUsers',
    API_TABLE_NAV_USERS,
    API_GET_NAV_USER,
    API_UPDATE_NAV_USER
  );

  if(result.actionConsumed){
    return result.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_LIST_NAV_USERS);
  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "LastName");
    return list.state;
  }
  
  return state;
}