import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import dotProp from 'dot-prop-immutable';

import { DetailDataField } from 'components/detail/DetailDataField'

const styles = (theme) => ({
  container: {
    display:'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },
  listContainer: {
    paddingLeft:10
  },
  header: {
    display:'flex',
    alignItems:'center',
    height:15,
    background:fade(theme.palette.common.black, 0.04),
    paddingTop:10,
    paddingBottom:10,
    paddingLeft:10
  }
});

class DetailDataGroup extends React.Component {

  getDeepValue(object, path) {
    if(!object || !path){
      return '';
    }

    while(path.endsWith('_')){
      path = path.substring(0,path.length-1);
    }
    if(path.includes(',')){
      //combine multiple fields
      let fields = path.split(',');
      let value = '';
      for(let x=0; x<fields.length; x++){
        if(x != 0){
          value += ' ';
        }
        value += this.getDeepValue(object, fields[x]);
      }
      return value;
    }
    try{
      return dotProp.get(object, path);
    }catch(e){
      return '';
    }
  }

  render() {
    let {
      classes,
      schema,
      data,
      editLink,
      title,
      wideValue
    } = this.props;

    let usedTitle;
    if(title){
      usedTitle = title;
    }else if(schema.title){
      usedTitle = schema.title;
    }
    const allowEdit = editLink != null;

    if(!schema || !schema.properties){
      return <Typography variant="headline">DetailDataGroup BAD SCHEMA</Typography>;
    }
    const fields = Object.entries(schema.properties).map((keyValue) => {
      const fieldKey = keyValue[0];
      const fieldDef = keyValue[1];

      const value = this.getDeepValue(data, fieldKey);
      return (
        <DetailDataField
          key={fieldKey}
          value={value}
          fieldDef={fieldDef}
          wideValue={wideValue} />
      );
    });

    return (
      <div className={classes.container}>
        {usedTitle &&
          <div className={classes.header}>
            <Typography variant="subheading">{usedTitle}</Typography>
            {allowEdit &&
              <Button
                variant="text"
                color="primary"
                component={Link} to={editLink}>
                (Edit)
              </Button>
            }
          </div>
        }
        <div className={classes.listContainer}>
          {fields}
        </div>
      </div>
    );
  }
}

DetailDataGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  schema : PropTypes.object.isRequired,
  data : PropTypes.object,
  editLink : PropTypes.string,
  title : PropTypes.string,
  wideValue: PropTypes.bool/*,
  onDataAction : PropTypes.function*/
};

const styled = withStyles(styles)(DetailDataGroup);
export {styled as DetailDataGroup};
