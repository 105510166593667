import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import StoreUtil from 'stores/StoreUtil';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class HospitalBCItem extends Component {

  render() {
    const {
      hospital,
      match
    } = this.props;

    let title = "Hospital";
    if(match.params.id == 0){
      title = "New Hospital";
    }else if (hospital && StoreUtil.getData(hospital)) {
      title = hospital.data.Name;
    }
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          path={NavigationUtil.navLink("/hospital/"+match.params.id)}
        />
      </React.Fragment>
    );
  }
}

HospitalBCItem .propTypes = {
  hospital: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  const { match } = props;
  const { hospitals } = state;

  return {
    hospital: StoreUtil.get(hospitals, StoreUtil.COMMON_ITEM, match.params.id)
  };
}

const connected = connect(mapStateToProps)(HospitalBCItem);
export {connected as HospitalBCItem };