import { call, takeEvery } from 'redux-saga/effects';
import { sendRequest } from 'redux-saga-requests';
import { success} from 'redux-saga-requests';

import { 
  API_UPDATE_QUESTION_GROUP
} from 'actions/questionGroupActions';

import {
  resolveTask
} from 'actions/tasksActions';

function* resolveFormTask(action) {
  if(action.meta.requestAction.taskId){
    
    yield call(
      sendRequest,
      resolveTask(action.meta.requestAction.taskId),
      { dispatchRequestAction: false });
  }
}

export const formResolveSaga = [
  takeEvery(success(API_UPDATE_QUESTION_GROUP), resolveFormTask)
];
