
class DataUtil {
  static sort(arr, field){
    if(!arr){
      return
    }
    // cache the indices of the empty columns
    const emptyFieldIndex = [];
    arr.forEach((data, index) => {
      const hasValue = data[field] && data[field].trim().length !== 0;
      if(!hasValue) emptyFieldIndex.push(index);
    });
    emptyFieldIndex.reverse();
    // filter out the empty columns
    emptyFieldIndex.map(data => arr.splice(data, 1));
    // sort the list by the field key
    arr.sort(function(a, b){
      if(!a[field]  || !b[field]) return null;
      if(a[field].trim().toUpperCase() < b[field].trim().toUpperCase()) return -1;
      if(a[field].trim().toUpperCase() > b[field].trim().toUpperCase()) return 1;
      return 0;
    });
  }

  static isPTUser(user){
    if(user && user.viewAsUser) return user.viewAsUser.Role === 8;
    return user.Role === 8;
  }

  static isPTSurgeryByAdmission(admission){
    return admission && admission.data && admission.data.Surgery && admission.data.Surgery.VisitType === "PT";
  }

  static isPTSurgeryBySurgery(surgeries, surgeryId){
    let type = false;
    if(surgeries.item && surgeries.item[surgeryId] && surgeries.item[surgeryId].data){
      type = surgeries.item[surgeryId].data.VisitType;
    }
    return type === "PT";
  }

  static extractNestedData(uiData){
    let extractedData = [];
    if(Array.isArray(uiData)
      && uiData[0]
      && uiData[0].Surgeries
      && Array.isArray(uiData[0].Surgeries)
      && uiData[0].Surgeries.length > 0){
      uiData.map((udata, index) => {
        udata.Surgeries.map(data => {
          extractedData.push({
            ...uiData[index],
            Surgeries: [data]
          })
        })
      })
      return extractedData;
    }
    return uiData;
  }

  //Remove element from array by value
  static remove(array, element){
    const index = array.indexOf(element);
    if(index && index >= 0){
      array.splice(index, 1);
    }
  }

  static get INITIAL_EVAL_QUESTION_GROUP_ID(){
    return 1;
  }

  static get INITIAL_EVAL_PT_QUESTION_GROUP_ID(){
    return 102;
  }

  static get DISCHARGE_QUESTION_GROUP_ID(){
    return 2;
  }

  static get DISCHARGE_QUESTION_DATE_ID(){
    return 2;
  }

  static get DIABETES_QUESTION_GROUP_ID() {
    return 64;
  }

  static get CONVERSATION_TYPE_PLAN_CHANGE(){
    return 'plan_change_rationale';
  }

  static get DISCHARGE_QUESTION_PT_GROUP_ID(){
    return 103;
  }

  static get CONVERSATION_TYPE_OTHER(){
    return 'navigator_request';
  }

  static isPlanRationaleResolved(planChange){
    if(!planChange || !planChange.Status){
      return false;
    }
    return DataUtil.isStringSame(planChange.Status, "resolved")
      || DataUtil.isStringSame(planChange.Status, "completed");
  }

  static isConversationResolved(conversation){
    if(!conversation || !conversation.Status){
      return false;
    }
    return DataUtil.isStringSame(conversation.Status, "resolved")
      || DataUtil.isStringSame(conversation.Status, "complete");
  }

  static isAppPAC(appStr){
    return DataUtil.isStringSame(appStr,'PAC');
  }

  static isStringSame(str1, str2){
    if(!str1 && !str2){
      return true;
    }
    if(!str1){
      return false;
    }
    return str1.localeCompare(str2, 'en', {sensitivity: 'base'}) == 0;
  }
  static formatNameForUser(user){
    if(!user){
      return '';
    }

    let name = '';
    if(user.LastName){
      if(user.FirstName){
        name = user.FirstName + ' ' + user.LastName;
      }else{
        name = user.LastName;
      }
    }else if(user.Username){
      name = user.Username;
    }else if(user.email){
      name = user.email;
    }else{
      name = user.id;
    }

    return name;
  }

  static formatNameForUserLastFirstOrder(user){
    if(!user){
      return '';
    }

    let name = '';
    if(user.LastName){
      if(user.FirstName){
        name = user.LastName + ', ' + user.FirstName;
      }else{
        name = user.LastName;
      }
    }else if(user.Username){
      name = user.Username;
    }else if(user.email){
      name = user.email;
    }else{
      name = user.id;
    }

    return name;
  }

  static findAdmissionForPatient(patient){
    let admission = null;
    if(patient.Surgeries
      && Array.isArray(patient.Surgeries)
      && patient.Surgeries.length>0){

      let x;
      let y;
      let surgery;
      let adSearch;

      for(x=0; x<patient.Surgeries.length; x++){
        surgery = patient.Surgeries[x];
        if(surgery.Admissions
          && Array.isArray(surgery.Admissions)
          && surgery.Admissions.length > 0 ){
          for(y=0; y<surgery.Admissions.length; y++){
            adSearch = surgery.Admissions[y];
            if(adSearch.id && !adSearch.DischargeDate){
              admission = adSearch;
              break;
            }
          }
        }
        if(admission){
          break;
        }
      }
    }
    return admission;
  }

  static deepCopy(obj){
    return JSON.parse(JSON.stringify(obj));
  }
}

export {DataUtil as DataUtil}
export default DataUtil
