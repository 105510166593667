import ReducerUtil from 'reducers/ReducerUtil'
import DataUtil from 'util/DataUtil';

import {
  API_TABLE_HOSPITALS,
  API_GET_HOSPITAL,
  API_UPDATE_HOSPITAL_NAME,
  API_LIST_HOSPITALS
} from 'actions/hospitalActions';

export const hospitals = (state = {}, action) => {
  const result = ReducerUtil.simpleReduce(
    state, 
    action, 
    'hospitals',
    API_TABLE_HOSPITALS,
    API_GET_HOSPITAL,
    API_UPDATE_HOSPITAL_NAME
  );
  if(result.actionConsumed){
    return result.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_LIST_HOSPITALS)
  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "Name");
    return list.state;
  }

  return state;
}