import React, { Fragment, Component } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AuthRule from 'businessRule/AuthRule';

import { DashboardPage } from 'containers/DashboardPage';
import { SurgeonPage } from 'containers/surgeons/SurgeonPage';
import { HospitalPage } from 'containers/hospitals/HospitalPage';
import { TableMaintenancePage } from 'containers/tableMaintenance/TableMaintenance';
import { HospitalEditPage } from 'containers/hospitals/HospitalEditPage';
import { UsersPage } from 'containers/users/UsersPage';
import { UserEditPage } from 'containers/users/UserEditPage';
import { SurgeonEditPage } from 'containers/surgeons/SurgeonEditPage';
import { PatientsPage } from 'containers/patients/PatientsPage';
import { PatientDetailPage } from 'containers/patients/PatientDetailPage';
import { PatientEditPage } from 'containers/patients/PatientEditPage';
import { SurgeryDetailPage } from 'containers/surgeries/SurgeryDetailPage';
import { RiskScoreAuditPage } from 'containers/surgeries/RiskScoreAuditPage';
import { TaskEditPage } from 'containers/tasks/TaskEditPage';
import { TasksTodayPage } from 'containers/tasks/TasksTodayPage';
import { OverdueDischargesPage } from 'containers/admissions/OverdueDischargesPage';
import { Discharge90DayPage } from 'containers/admissions/Discharge90DayPage';
import { ConversationsPage } from 'containers/messages/ConversationsPage';
import { MessagesPage } from 'containers/messages/MessagesPage';
import { SurgeryEditPage } from 'containers/surgeries/SurgeryEditPage';
import { QuestionGroupPage } from 'containers/forms/QuestionGroupPage';
import { PertinentPositivesEditPage } from 'containers/positives/PertinentPositivesEditPage';
import { PostAcuteEventEditPage } from 'containers/postAcuteEvents/PostAcuteEventEditPage';
import { AdmissionDetailPage } from 'containers/admissions/AdmissionDetailPage';
import { SurgeryCopySelectPage } from 'containers/surgeries/SurgeryCopySelectPage';
import { NewConversationPage } from 'containers/messages/NewConversationPage';
import { NurseNavPlanChangeEditPage } from 'containers/messages/NurseNavPlanChangeEditPage';
import { HospitalExportPage } from 'containers/export/HospitalExportPage';
import { HospitalImportPage } from 'containers/export/HospitalImportPage';
import { TaskFormPage } from 'containers/tasks/TaskFormPage';
import { AlertsDashboardPage } from 'containers/alerts/AlertsDashboardPage';
import { HSXAlertsDashboardPage } from 'containers/alerts/HSXAlertsDashboardPage';

import NavigationUtil from 'util/NavigationUtil';



const styles = theme =>({
  content: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width:"100%",
    padding: theme.spacing.unit * 2,
    boxSizing: 'border-box'
  }
});

class NurseNavContentArea extends Component {
  render() {
    const {
      classes,
      authenticated,
      location
    } = this.props;

    if(!authenticated){
      return (<Redirect to="/login" />);
    }
    return (
      <div className={classes.content}>
        <Fragment>
          <Switch>
            <Route
              path={NavigationUtil.navdir+'/dashboard/:tab?'}
              component={DashboardPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/users'}
              component={UsersPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/users/:userId'}
              component={UserEditPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/patients'}
              component={PatientsPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/patients/:mrn/:exteralSource'}
              component={PatientDetailPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/patientEdit/:mrn/:externalSource'}
              component={PatientEditPage}
              exact={true}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgeons'}
              component={SurgeonPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgeons/:surgeonId'}
              component={SurgeonEditPage}
              authed={authenticated}
              key={location.pathname} />
            <Route exact
              path={NavigationUtil.navdir+'/hospitals'}
              component={HospitalPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/hospital/:hospitalId'}
              component={HospitalEditPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/maintenance'}
              component={TableMaintenancePage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgery/:surgeryId'}
              component={SurgeryDetailPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgery/:surgeryId/tasktab/:tab'}
              component={SurgeryDetailPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgery/:surgeryId/riskaudit'}
              component={RiskScoreAuditPage}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgery/:surgeryId/taskEdit/:id'}
              component={TaskEditPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/patientEdit/:mrn'}
              component={PatientEditPage}
              exact={true}
              authed={authenticated} />
            <Route exact
              path={NavigationUtil.navdir+'/surgery/:surgeryId/copy/:mrn'}
              component={SurgeryCopySelectPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/tasks/today/:taskTypeName?'}
              component={TasksTodayPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/overduedischarges'}
              component={OverdueDischargesPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/90daydischarge'}
              component={Discharge90DayPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/alertsdashboard'}
              component={AlertsDashboardPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir + '/hsxalertsdashboard'}
              component={HSXAlertsDashboardPage}
              authed={authenticated}
            />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/edit/:mrn?'}
              component={SurgeryEditPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/conversations/:conversationType'}
              component={ConversationsPage}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/admission/:admissionId/conversation/:conversationId'}
              component={MessagesPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/form/:questionGroupId/:taskId?'}
              component={QuestionGroupPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/form/:questionGroupId/task/:taskId/:postAcuteEventId?'}
              component={TaskFormPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/positivesEdit/'}
              component={PertinentPositivesEditPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/postAcute/:postAcuteEventId/:source?'}
              component={PostAcuteEventEditPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/admission/:id'}
              component={AdmissionDetailPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/admission/:admissionId/newMessage'}
              component={NewConversationPage}
              exact={true}
              authed={authenticated} />
            <Route
              path={NavigationUtil.navdir+'/surgery/:surgeryId/admission/:admissionId/planchange/:planChangeId'}
              component={NurseNavPlanChangeEditPage}
              exact={true}
              authed={authenticated} />
            <Route 
              path={NavigationUtil.navdir+'/export/hospital'}
              component={HospitalExportPage}
              exact={true}
              authed={authenticated} />
            <Route 
              path={NavigationUtil.navdir+'/import/hospital'}
              component={HospitalImportPage}
              exact={true}
              authed={authenticated} />
            <Redirect to={NavigationUtil.navdir+"/dashboard"} />
          </Switch>
        </Fragment>
      </div>
    );
  }
}

NurseNavContentArea.propTypes = {
  classes: PropTypes.object.isRequired,
  authenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { user } = state;
  const authenticated = AuthRule.doesUserHaveNurseNavPermission(user);
  return {
    authenticated
  };
}

const styledApp = withStyles(styles)(NurseNavContentArea);
const connectedApp = connect(mapStateToProps)(styledApp);
const routedApp = withRouter(connectedApp);
export {routedApp as NurseNavContentArea};
