import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StoreUtil from 'stores/StoreUtil'
import { getRiskScoreAudit } from 'actions/riskScoreAuditActions';
import { RiskScoreAuditTable } from 'components/RiskScoreAuditTable';

const styles = ({
  pageContainer:{
    width: '100%'
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  }
});

class RiskScoreAuditPage extends React.Component {

  constructor(props) {
    super(props);

    this.fetchRiskLevelAudit = this.fetchRiskLevelAudit.bind(this);
  }

  componentDidMount() {
    this.fetchRiskLevelAudit();
  }

  componentDidUpdate() {
  }

  fetchRiskLevelAudit() {
    const { dispatch, surgeryId } = this.props;
    
    dispatch(getRiskScoreAudit(surgeryId));
  }

  render() {
    const {
      classes,
      riskScoreAudit
    } = this.props;
    
    return (
      <div className={classes.pageContainer}>
        <RiskScoreAuditTable
          data={riskScoreAudit}
          noDataMessage="No audit records found."
        />
      </div>
    );
  }
}

RiskScoreAuditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  riskScoreAudit: PropTypes.object.isRequired,
  surgeryId: PropTypes.string.isRequired
};

function mapStateToProps(state, existingProps) {
  const { riskScoreAudit} = state;

  const { match } = existingProps;
  const surgeryId = match.params.surgeryId;
  
  return {
    riskScoreAudit: StoreUtil.get(riskScoreAudit, StoreUtil.COMMON_TABLE),
    surgeryId
  };
}

const styledPage = withStyles(styles)(RiskScoreAuditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as RiskScoreAuditPage};
