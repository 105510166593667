import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from 'react-router';

import { MaterialForm } from "components/forms/MaterialForm";
import {submitFeedback} from 'actions/feedbackActions';
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';

const styles = (theme) => ({
  container: {
    padding:theme.spacing.unit * 2
  }
});

const schema = {
  title: '',
  type: 'object',
  required: ['Comment'],
  properties: {
    Comment: {
      type: 'string',
      title: 'Feedback',
      minLength: 5,
      description:"Please be as descriptive as possible."
    }
  }
};

const uiSchema = {
  Comment:{
    "ui:widget": "textarea"
  }
};

class FeedbackDialog extends React.Component {

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }


  handleClose(){
    this.props.onClose();
  }

  onFormSubmit(data){
    const {
      dispatch,
      user,
      location
    } = this.props;
    const formData = data.formData;
    formData.UpdatedById = user.id;
    formData.Path = location.pathname;

    dispatch(submitFeedback(formData));
  }

  onFormError(){
  }

  onFormChange(){
  }

  render() {
    const {
      classes,
      open,
      feedbackData
    } = this.props;

    if(StoreUtil.didJustSave(feedbackData)){
      this.handleClose();
    }
    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(feedbackData, 'feedback');

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <DialogTitle id="simple-dialog-title">Let us know what you think</DialogTitle>
        <div className={classes.container} >
          <MaterialForm
            schema={schema}
            uiSchema={uiSchema}
            onChange={this.onFormChange}
            onSubmit={this.onFormSubmit}
            onError={this.onFormError}
            status={status}
            statusIsError={failure}
            inDialog={true}
            backOnCancel={false}
            onCancel={this.handleClose}
          />
        </div>
      </Dialog>
    );
  }
}

FeedbackDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  feedbackData: PropTypes.object.isRequired,
  open : PropTypes.bool.isRequired,
  dispatch : PropTypes.func.isRequired,
  user : PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { feedback, user } = state;

  return {
    feedbackData : StoreUtil.get(feedback, StoreUtil.COMMON_ITEM, 100),
    user
  };
}

const styled = withStyles(styles)(FeedbackDialog);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);

export { routed as FeedbackDialog}
