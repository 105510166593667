import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  Typography,
  Paper,
  Icon,
  Button,
  Dialog,
  DialogTitle,
  Tooltip
} from '@material-ui/core';
import Form from "react-jsonschema-form";
import Grid from '@material-ui/core/Grid';

import { getSurgery } from 'actions/surgeryActions';
import { DetailDataGroup } from 'components/detail/DetailDataGroup';
import { LoadingView } from 'components/LoadingView'
import StoreUtil from 'stores/StoreUtil';
import NavigationUtil from 'util/NavigationUtil'
import DataUtil from 'util/DataUtil';
import { Messaging } from 'components/message/Messaging';
import { createSurgeryFlag, getSurgeryFlags, removeSurgeryFlag, sendSHREmail } from 'actions/surgeryFlagActions';
import { TextareaWidget } from 'components/forms/TextareaWidget';
import { BaseInput } from 'components/forms/BaseInput';

import { getSurgeryAddlInfo } from 'actions/surgeryAddlInfoActions';
import { listDischargeDispositions } from 'actions/dischargeDispositionActions';

const styles = (theme) => ({
  container: {
    display:'flex',
    flexDirection:'column',
    padding : theme.spacing.unit*2
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  listContainer:{
    minHeight:200,
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap'
  },
  headerSurgery: {
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const eventSchema = {
  title:'Event Information',
  properties:{
    'Surgeon.Navigator.FirstName,Surgeon.Navigator.LastName':{
      title: 'Navigator',
      type: 'string'
    },
    'VisitStatus':{
      title: 'Visit Status',
      type: 'string'
    },
    'Surgeon.Name':{
      title: 'Surgeon',
      type: 'string'
    },
    DisplayProcedureName:{
      title: 'Procedure',
      type: 'string'
    },
    ProcedureNote:{
      title: 'Procedure Note',
      type: 'string'
    },
    SurgeryDateTime:{
      title: 'DOS',
      type: 'string',
      format: 'date'
    },
    'Hospital.Name':{
      title: 'Hospital',
      type: 'string'
    },
    'SurgeryDateTime_':{
      title: 'Days Until Surgery',
      type: 'string',
      format: 'daysUntil'
    }
  }
};

const dischargeDashboardSchema = {
  title:'Discharge Information',
  properties:{
    DischargeDate:{
      title: 'Discharge Date',
      type: 'string',
      format: 'date'
    },
    Discharge:{
      title: 'Discharge Disposition',
      type: 'string'
    },
    DischargeNote:{
      title: 'Discharge Note',
      type: 'string'
    },
    HospitalLengthofStay:{
      title: 'Hospital LOS',
      type: 'string'
    },
    'DischargeFacility.Name':{
      title: 'Actual PAC Facility',
      type: 'string'
    },
    PostAcuteLengthOfStay:{
      title: 'Actual PAC LOS/NOV',
      type: 'string'
    }
  }
};

const PTdischargeDashboardSchema = {
  title:'Discharge Information',
  properties:{
    DischargeDate:{
      title: 'Discharge Date',
      type: 'string',
      format: 'date'
    },
    PTName:{
      title: 'Actual PT Facility',
      type: 'string'
    },
    PTNumberOfVisits:{
      title: 'Actual PT NOV',
      type: 'string'
    },
    PTAnticipatedNumberOfVisits: {
      title: 'Estimated NOV',
      type: 'string'
    }
  }
};

const dischargeSchema = {
  title:'Discharge Information',
  properties:{
    DischargeDate:{
      title: 'Discharge Date',
      type: 'string',
      format: 'date'
    },
    'DischargeDisposition.Text':{
      title: 'Discharge Disposition',
      type: 'string'
    },
    DispositionNotes:{
      title: 'Discharge Note',
      type: 'string'
    },
    HospitalLengthofStay:{
      title: 'Hospital LOS',
      type: 'string'
    },
    'DischargeFacility.Name':{
      title: 'Actual PAC Facility',
      type: 'string'
    },
    PostAcuteLengthOfStay:{
      title: 'Actual PAC LOS/NOV',
      type: 'string'
    }
  }
};

const PTdischargeSchema = {
  title:'Discharge Information',
  properties:{
    DischargeDate:{
      title: 'Discharge Date',
      type: 'string',
      format: 'date'
    },
    PTName:{
      title: 'Actual PT Facility',
      type: 'string'
    },
    PTNumberOfVisits:{
      title: 'Actual PT NOV',
      type: 'string'
    },
    PTAnticipatedNumberOfVisits: {
      title: 'Estimated NOV',
      type: 'string'
    }
  }
};

const riskSchema = {
  title:'Risk Information',
  properties:{
    Bmi:{
      title: 'BMI',
      type: 'number'
    },
    MedicalRiskLevel:{
      title: 'Medical Risk Level',
      type: 'string',
      format: 'risk'
    },
    SocialRiskLevel:{
      title: 'Social Risk Level',
      type: 'string',
      format: 'risk'
    }
  }
};

function FieldTemplate(propsTemplate) {
  const {
    classNames,
    children,
    hidden
  } = propsTemplate;
  if (hidden) {
    return children;
  }

  return (
    <div className={classNames}>
      {children}
    </div>
  );
}

function ObjectFieldTemplate (propsTemplate) {
  const { TitleField, DescriptionField } = propsTemplate;
  
  return (
    <Fragment>
      {(propsTemplate.uiSchema["ui:title"] || propsTemplate.title) && (
        <TitleField
          id={`${propsTemplate.idSchema.$id}__title`}
          title={propsTemplate.title || propsTemplate.uiSchema["ui:title"]}
          required={propsTemplate.required}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.description && (
        <DescriptionField
          id={`${propsTemplate.idSchema.$id}__description`}
          description={propsTemplate.description}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.properties.map(prop => prop.content)}
    </Fragment>
  );
}

const customWidgets = {
  TextareaWidget,
  BaseInput
};

const schema = {
  type: 'object',
  required: ['Reason'],
  properties: {
    Reason: {type: 'string', title: 'Reason for changing status'}
  }
};

const uiSchema = {
  Reason: {
    "ui:widget": "textarea"
  }
};

class SurgeryDetailView extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      open: false, // controls the diaolog box open status
      formData: {},
      error: null
    }
    this.openSuperHighRiskToggle = this.openSuperHighRiskToggle.bind(this);
    this.handleToggleRequest = this.handleToggleRequest.bind(this);
    this.cancelToggleRequest = this.cancelToggleRequest.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      surgeryInfo,
      dispatch,
      surgery
    } = this.props;
    // ? cache preventing /nursenav/surgery/surgery.id from loading after edit
    // if(StoreUtil.needsLoadNoCache(surgeryInfo)){
    //   dispatch(getSurgery(surgery.id));
    // }
    dispatch(getSurgery(surgery.id));
    dispatch(getSurgeryFlags());
    // fetch the surgeryAddlInfo data for the current surgery
    dispatch(getSurgeryAddlInfo(surgery.id));
    // fetch all discharge dispositions
    dispatch(listDischargeDispositions());
  }

  openSuperHighRiskToggle(){ 
    this.setState({
      open: true
    })
  }

  handleToggleRequest(){
    const {
      surgery,
      surgeryInfo,
      dispatch,
      isHighRisk,
      surgeryFlagIds
    } = this.props;

    const { Reason } = this.state.formData;
    const hasSHRNotesComplete = Reason && Reason.trim().length;

    if(!isHighRisk && !hasSHRNotesComplete) return;

    if(hasSHRNotesComplete > 500){
      return this.setState({
        error: "Field must have less than 500 charaters"
      })
    }

    if(isHighRisk){
      const disabledSurgeryFlagId = 0;
      const data = {
        SurgeryId: surgery.id,
        PatientId: surgeryInfo.data.Patient.id,
        FacilityId: 1,
        FlagId: disabledSurgeryFlagId,
        UpdatedById: surgeryInfo.data.User.id
      }

      surgeryFlagIds.forEach(id => {
        dispatch(removeSurgeryFlag({
          ...data,
          id
        }));
      })
      
    } else {
      const HighRiskFlagID = 1;
      const data = {
        SurgeryId: surgery.id,
        PatientId: surgeryInfo.data.Patient.id,
        FacilityId: 1,
        FlagId: HighRiskFlagID,
        Notes: this.state.formData.Reason,
        UpdatedById: surgeryInfo.data.User.id
      }

      if(surgeryFlagIds.length){
        surgeryFlagIds.forEach(id => {
          dispatch(createSurgeryFlag({
            ...data,
            id: id
          }));
        })
      } else {
        dispatch(createSurgeryFlag(data));
      }
      
      const emails = process.env.SHR_EMAILS;
      dispatch(sendSHREmail(surgery.MRN, {emails, notes: this.state.formData.Reason}))
    }

    this.setState({
      open: false,
      formData: {}
    });
  }

  cancelToggleRequest(){
    this.setState({
      open: false
    })
  }

  onChange(data) {
    // copy the form data without reference
    const { formData } = JSON.parse(JSON.stringify(data));
    // set the notes into state
    this.setState({ formData });
  }

  render() {
    //find surgery
    const {
      classes,
      surgery,
      surgeryInfo,
      isPTSurgery,
      isHighRisk,
      highRiskNotes,
      surgeryAddlInfo,
      dischargeDispositions
    } = this.props;

    const {
      error
    } = this.state;

    if(!StoreUtil.getData(surgeryInfo)){
      return (<LoadingView />);
    }else if(StoreUtil.isLoadFailed(surgeryInfo)){
      return (
        <Typography
          className={classes.headerContainer}
          variant='title' color='error'>
          There was an issue loading the surgery.
        </Typography>
      );

    }else{
      let surgeryInfoData = StoreUtil.getData(surgeryInfo);
      const surgeryLink = NavigationUtil.navLink('/surgery/'+surgery.id+'/edit');
      const riskAuditLink = NavigationUtil.navLink('/surgery/'+surgery.id+'/riskaudit');

      if(surgeryInfoData
          && surgeryInfoData.ProcedureType
          && surgeryInfoData.ProcedureType.ProcedureType){
        surgeryInfoData.DisplayProcedureName = surgeryInfoData.ProcedureType.ProcedureType;
      } else {
        surgeryInfoData.DisplayProcedureName = surgeryInfoData.ProcedureName;
      }

      let useDischargeSchema = isPTSurgery ? PTdischargeSchema : dischargeSchema;

      if(surgeryInfoData && !surgeryInfoData.DischargeDisposition){
        useDischargeSchema = isPTSurgery ? PTdischargeDashboardSchema : dischargeDashboardSchema;
      }

      if(isPTSurgery){
        surgeryInfoData.PTName = surgery.PTName;
        surgeryInfoData.PTNumberOfVisits = surgery.PTNumberOfVisits;
        surgeryInfoData.PTAnticipatedNumberOfVisits = surgery.PTAnticipatedNumberOfVisits;
      }

      const dialog = (<Dialog open={this.state.open} onClose={this.cancelToggleRequest}>
        <DialogTitle> Do you want to toggle the Summary Patient status? </DialogTitle>
        <p
          style={{color: "#f44336", fontSize: 18, margin: 0, marginLeft: "2rem"}}
        >{error}</p>
        <div style={{padding: 20, paddingTop: 0}}>
          <Form
            key={'form'}
            ObjectFieldTemplate={ObjectFieldTemplate}
            FieldTemplate={FieldTemplate}
            widgets={customWidgets}
            schema={!isHighRisk ? schema : {}}
            uiSchema={!isHighRisk ? uiSchema : {}}
            onChange={this.onChange}
            showErrorList={false}
            formData={this.state.formData}
            noHtml5Validate={true}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <div>
                <Button
                  type="submit"
                  color="primary"
                  margin="normal"
                  variant="raised"
                  onClick={this.handleToggleRequest}>
                  {"Yes"}
                </Button>
                <Button
                  style={{marginLeft: 20}}
                  type="submit"
                  color="primary"
                  margin="normal"
                  variant="raised"
                  onClick={this.cancelToggleRequest}>
                  {"No"}
                </Button>
              </div>
            </Grid>
          </Form>
        </div>
      </Dialog>);

      if(surgeryAddlInfo.length && surgeryAddlInfo[0].SecondaryPostAcuteDispositionId){
        // add the secondary discharge disposition if there exists one for this surgery
        const list = StoreUtil.get(dischargeDispositions, StoreUtil.COMMON_LIST);
        if(list.data){
          list.data.forEach(data => {
            if(data.id === surgeryAddlInfo[0].SecondaryPostAcuteDispositionId){
              useDischargeSchema.properties['SecondaryPostAcuteDisposition.Text'] = {
                title: "Secondary Discharge Disposition", type: "string"
              };
              surgeryInfo.data.SecondaryPostAcuteDisposition = { Text: data.Text }
            }
          })
        }
      } else {
        delete useDischargeSchema.properties["SecondaryPostAcuteDisposition.Text"];
      }

      const HtmlTooltip = withStyles(theme => ({
        tooltip: {
          backgroundColor: '#F8F8F8',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(16)
        }
      }))(Tooltip);
      
      return (
        <Paper className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography className={classes.headerSurgery} variant="headline">Episode Details</Typography>
            {isHighRisk ? (<HtmlTooltip
              style={{opacity: 1}}
              title={
                <React.Fragment>
                  {highRiskNotes}
                </React.Fragment>
              }
            >
              <Button color="primary" size="small" onClick={this.openSuperHighRiskToggle} >
                <Icon className={classes.leftIcon}>star_rate</Icon>
                Summary Patient toggle
              </Button>
            </HtmlTooltip>) : (<Button color="primary" size="small" onClick={this.openSuperHighRiskToggle} >
              Summary Patient toggle
            </Button>)}
            
            {dialog}
            <Button
              color="primary"
              size="small"
              component={Link} to={riskAuditLink}>
              <Icon className={classes.leftIcon}>pageview</Icon>
              Risk Level History
            </Button>
            <Button
              color="primary"
              size="small"
              component={Link} to={surgeryLink}>
              <Icon className={classes.leftIcon}>edit_icon</Icon>
              Edit Episode
            </Button>

          </div>

          <div className={classes.listContainer}>
            <DetailDataGroup
              schema={eventSchema}
              data={surgeryInfoData}
            />
            <DetailDataGroup
              schema={useDischargeSchema}
              data={surgeryInfoData}
            />
            {!isPTSurgery &&
                <DetailDataGroup
                  schema={riskSchema}
                  data={surgeryInfoData}
                />
            }
            <Messaging surgeryInfo={surgeryInfo}/>
          </div>
        </Paper>
      );
    }
  }
}

SurgeryDetailView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  surgeryInfo: PropTypes.object.isRequired,
  isPTSurgery: PropTypes.bool.isRequired,
  surgeryFlag: PropTypes.array.isRequired,
  isHighRisk: PropTypes.bool.isRequired,
  highRiskNotes: PropTypes.string,
  surgeryFlagIds: PropTypes.array.isRequired,
  surgeryAddlInfo: PropTypes.array,
  dischargeDispositions: PropTypes.object
}

function mapStateToProps(state, existingProps) {
  const { surgery } = existingProps;
  const {
    surgeries,
    surgeryFlag,
    surgeryAddlInfo,
    dischargeDispositions
  } = state;
  let surgeryInfo = StoreUtil.get(surgeries, StoreUtil.COMMON_ITEM, surgery.id);
  const isPTSurgery = DataUtil.isPTSurgeryBySurgery(surgeries, surgery.id);

  let isHighRisk = false;
  let highRiskNotes = "";
  let surgeryFlagIds = [];
  const HighRiskFlagID = 1;
  if(surgeryFlag.length && surgery.id){
    surgeryFlag.forEach(data => {
      const { SurgeryId } = data;
      if(SurgeryId === surgery.id){
        if(data.FlagId === HighRiskFlagID){
          isHighRisk = true;
          highRiskNotes = data.Notes
        }
        surgeryFlagIds.push(data.id)
      }
    })
  }

  return {
    surgeryInfo,
    isPTSurgery,
    surgeryFlag,
    isHighRisk,
    highRiskNotes,
    surgeryFlagIds,
    surgeryAddlInfo,
    dischargeDispositions
  };
}

const styled = withStyles(styles)(SurgeryDetailView);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryDetailView };
