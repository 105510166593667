import dotProp from 'dot-prop-immutable';

const STATUS_SAVING = 'STATUS_SAVING';
const STATUS_SAVED = 'STATUS_SAVED';
const STATUS_SAVE_ABORT = 'STATUS_SAVE_ABORT';
const STATUS_SAVE_FAILED = 'STATUS_SAVE_FAILED';

const STATUS_LOADING = 'STATUS_LOADING';
const STATUS_FAILED = 'STATUS_FAILED';
const STATUS_SUCCESS = 'STATUS_SUCCESS';
const STATUS_ABORT = 'STATUS_ABORT';
const STATUS_NONE = 'STATUS_NONE';

const CACHE_LONG_SECONDS = 3600;
const CACHE_MEDIUM_SECONDS = 600;
const CACHE_NONE_SECONDS = 1;

const NEW_LOAD_TIMEOUT = 1000;
const NEW_SAVE_TIMEOUT = 4000;
const LOADING_STATUS_TIMEOUT = 30000;

class StoreUtil {

  static get COMMON_ITEM(){
    return 'item';
  } 

  static get COMMON_NEW_ITEM(){
    return 'newItem';
  }

  static get COMMON_LIST(){
    return 'list';
  }

  static get COMMON_BULK(){
    return 'bulk';
  }

  static get COMMON_TABLE(){
    return 'table';
  }

  static getDataPath(path, id = -1){
    let dataPath;
    if(id > -1){
      dataPath = path+'.'+id;
    }else{
      dataPath = path;
    }

    return dataPath;
  }

  static get(store, path, id = -1){
    let dataPath = StoreUtil.getDataPath(path, id);

    let loadedData = dotProp.get(store, dataPath);

    if(!loadedData){
      //empty, don't have this is store yet
      return StoreUtil.createNewLoadedData();
    }else {
      return loadedData;
    }
  }

  static setDataStatus(status, isLoad, store, path, id = -1){
    let loadedDataPath = StoreUtil.getDataPath(path, id);
    let loadedDataOld = dotProp.get(store, loadedDataPath);

    let loadedData;
    if(!loadedDataOld || !loadedDataOld.meta){
      loadedData =  StoreUtil.createNewLoadedData();
    }else{
      loadedData =  StoreUtil.touchExistingLoadedData(loadedDataOld);
    }

    if(isLoad){
      loadedData = dotProp.set(loadedData, "meta.loadStatus", status);
    }else{
      loadedData = dotProp.set(loadedData, "meta.saveStatus", status);
    }
    
    loadedData = dotProp.set(loadedData, `meta.statusTime.${status}`, (new Date).getTime());

    return dotProp.set(store, loadedDataPath, loadedData);
  }

  static setLoadStatus(status, store, path, id = -1){
    return StoreUtil.setDataStatus(status, true, store, path, id);
  }

  static setSaveStatus(status, store, path, id = -1){
    return StoreUtil.setDataStatus(status, false, store, path, id);
  }

  static setSaving(store, path, id = -1){
    return StoreUtil.setSaveStatus(STATUS_SAVING,store, path, id);
  }

  static setSaved(newData, store, path, id = -1){
    let newStore = StoreUtil.setSaveStatus(STATUS_SAVED,store, path, id);

    if(newData){
      let dataPath = StoreUtil.getDataPath(path, id);
      newStore = dotProp.set(newStore, dataPath+".data", newData);
    }

    return newStore;
  }

  static setSaveFailed(store, path, id = -1){
    return StoreUtil.setSaveStatus(STATUS_SAVE_FAILED,store, path, id);
  }

  static setSaveAbort(store, path, id = -1){
    return StoreUtil.setSaveStatus(STATUS_SAVE_ABORT,store, path, id);
  }

  static setLoading(store, path, id = -1){
    return StoreUtil.setLoadStatus(STATUS_LOADING,store, path, id);
  }

  static setLoadFailed(store, path, id = -1){
    return StoreUtil.setLoadStatus(STATUS_FAILED,store, path, id);
  }

  static setLoadAborted(store, path, id = -1){
    return StoreUtil.setLoadStatus(STATUS_ABORT,store, path, id);
  }

  static setLoaded(newData, store, path, id = -1){
    let newStore = StoreUtil.setLoadStatus(STATUS_SUCCESS,store, path, id);
    let dataPath = StoreUtil.getDataPath(path, id);
    newStore = dotProp.set(newStore, dataPath+".data", newData);
    return newStore;
  }

  static needsLoadLongCache(loadedData){
    return StoreUtil.needsLoad(loadedData,CACHE_LONG_SECONDS);
  }

  static needsLoadNoCache(loadedData){
    return StoreUtil.needsLoad(loadedData,CACHE_NONE_SECONDS);
  }

  static needsLoadMediumCache(loadedData){
    return StoreUtil.needsLoad(loadedData,CACHE_MEDIUM_SECONDS);
  }

  //compare a saved object to a load object and return true of save happened AFTER load
  //saved object can also be a group of saved objects (items by id)
  static hasSavedSinceLoad(savedData,loadedData) {
    if (!StoreUtil.isLoaded(loadedData)) {
      return false; //wait for load
    }

    let saved = StoreUtil.isSaved(savedData);
    let savedEpoch = 0;
    if (saved) {
      savedEpoch = savedData.meta.statusTime[STATUS_SAVED];

    } else if (!saved && savedData && !savedData.meta) { //a group of objects to check
      for (let id in savedData) {
        if (StoreUtil.isSaved(savedData[id])) {
          if (savedData[id].meta.statusTime[STATUS_SAVED] > savedEpoch) {
            savedEpoch = savedData[id].meta.statusTime[STATUS_SAVED];
          }
        } else {
          return  false;
        }
      }
    }

    if (savedEpoch > 0) {
      const loadedEpoch = loadedData.meta.statusTime[STATUS_SUCCESS];
      return (savedEpoch > loadedEpoch);
    }

    return false;
  }

  static needsLoad(loadedData,cacheSecondsLimit = 1){
    if(!loadedData.meta){
      return true;
    }

    if(StoreUtil.isLoading(loadedData)){
      return false;
    }

    if(StoreUtil.isSaving(loadedData)){
      return false;
    }

    if(StoreUtil.isLoaded(loadedData)){
      //based on cache time
      const nowEpoch = (new Date).getTime();
      const dataEpoch = loadedData.meta.statusTime[STATUS_SUCCESS];
      if(!dataEpoch){
        return true;
      }
      return nowEpoch - dataEpoch > cacheSecondsLimit * 1000;
    }

    return true;
  }

  static isLoadedOrFailed(loadedData){
    return StoreUtil.isLoaded(loadedData) || StoreUtil.isLoadFailed(loadedData);
  }

  static isLoading(loadedData){
    if(!loadedData.meta){
      return false;
    }

    if(loadedData.meta.loadStatus == STATUS_LOADING){
      //timeout check
      const nowEpoch = (new Date).getTime();
      const loadEpoch = loadedData.meta.statusTime[STATUS_LOADING];
      if(nowEpoch - loadEpoch > LOADING_STATUS_TIMEOUT){
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
  }

  static isLoadFailed(loadedData){
    if(!loadedData.meta){
      return false;
    }

    return loadedData.meta.loadStatus == STATUS_FAILED;
  }

  static isLoaded(loadedData){
    if(!loadedData.meta){
      return false;
    }
    return loadedData.meta.loadStatus == STATUS_SUCCESS;
  }

  static isSaving(loadedData){
    if(!loadedData.meta){
      return false;
    }
    return loadedData.meta.saveStatus == STATUS_SAVING;
  }

  static isSaved(loadedData){
    if(!loadedData || !loadedData.meta){
      return false;
    }

    return loadedData.meta.saveStatus == STATUS_SAVED;
  }

  static isSaveFailed(loadedData){
    if(!loadedData.meta){
      return false;
    }

    return loadedData.meta.saveStatus == STATUS_SAVE_FAILED;
  }

  static isSaveAborted(loadedData){
    if(!loadedData.meta){
      return false;
    }

    return loadedData.meta.saveStatus == STATUS_SAVE_ABORT;
  }

  static getData(loadedData){
    if(loadedData){
      return loadedData.data;
    }else{
      return null;
    }
  }

  static createNewMeta(){
    return {
      statusTime: {},
      loadStatus: STATUS_NONE,
      saveStatus: STATUS_NONE
    }
  }

  static createNewLoadedData(){
    return {
      data: null,
      meta: StoreUtil.createNewMeta()
    }
  }

  static touchExistingLoadedData(loadedData){
    return {
      data: loadedData.data,
      meta: loadedData.meta
    }
  }

  static isLoadedData(data){
    return data && data.meta && data.meta.statusTime;
  }

  static didJustSave(data){
    return StoreUtil.isSaved(data) && ((data.meta.statusTime[STATUS_SAVED] + NEW_SAVE_TIMEOUT) > (new Date).getTime());
  }

  static didJustLoad(data){
    return StoreUtil.isLoaded(data) 
      && ((data.meta.statusTime[STATUS_SUCCESS] + NEW_LOAD_TIMEOUT) > (new Date).getTime());
  }

  static getLastTime(data, type){
    if(!data || !data.meta || !data.meta.statusTime || !data.meta.statusTime[type]){
      return null;
    }
    return data.meta.statusTime[type];
  }

  static getLastSaveTime(data){
    return StoreUtil.getLastTime(data, STATUS_SAVED);
  }

  static getLastLoadTime(data){
    return StoreUtil.getLastTime(data, STATUS_SUCCESS);
  }
}

export {StoreUtil as StoreUtil}
export default StoreUtil
