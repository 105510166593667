import ReducerUtil from 'reducers/ReducerUtil'

import {
  API_GET_HOSPITAL_VISIT,
  API_UPDATE_HOSPITAL_VISIT,
  API_TABLE_HOSPITAL_VISIT_EPISODE,
  API_TABLE_HOSPITAL_VISIT
} from 'actions/hospitalVisitActions';

export const hospitalVisits = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'hospitalVisits');
  if(logout.actionConsumed){
    return logout.state;
  }

  const single = ReducerUtil.reduceSingleGet(state, action, API_GET_HOSPITAL_VISIT);
  if(single.actionConsumed){
    return single.state;
  }

  const update = ReducerUtil.reduceUpdate(state, action, API_UPDATE_HOSPITAL_VISIT);
  if(update.actionConsumed){
    return update.state;
  }

  const tableEpisode = ReducerUtil.reduceTableGet(state, action, API_TABLE_HOSPITAL_VISIT_EPISODE);
  if(tableEpisode.actionConsumed){
    return tableEpisode.state;
  }

  const table = ReducerUtil.reduceTableGet(state, action, API_TABLE_HOSPITAL_VISIT, "admission");
  if(table.actionConsumed){
    return table.state;
  }

  return state;
}
