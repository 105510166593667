import { success, error, abort } from 'redux-saga-requests';
import StoreUtil from 'stores/StoreUtil'
import { initialState } from 'stores/configureStore';


import {
  API_LOGOUT
} from 'actions/loginActions';

const untouched = {
  actionConsumed:false,
  state: null
};

class ReducerUtil {

  static consumedResponse(newState){
    return {
      actionConsumed: true,
      state: newState
    }
  }
  static simpleReduce(state, action, stateAreaName, tableAction, singleAction, updateAction, idField='id'){
    const logout = ReducerUtil.reduceLogout(state, action, stateAreaName);
    if(logout.actionConsumed){
      return logout;
    }

    const table = ReducerUtil.reduceTableGet(state, action, tableAction);
    if(table.actionConsumed){
      return table;
    }

    const single = ReducerUtil.reduceSingleGet(state, action, singleAction, idField);
    if(single.actionConsumed){
      return single;
    }

    const update = ReducerUtil.reduceUpdate(state, action, updateAction, idField);
    if(update.actionConsumed){
      return update;
    }

    //not touched
    return untouched;
  }

  static reduceLogout(state, action, stateAreaName){
    switch (action.type) {

    case API_LOGOUT:
      return ReducerUtil.consumedResponse(initialState[stateAreaName]);
    default:
      return untouched;
    }
  }

  static reduceBulkGet(state, action, listAction, idCreationFunction){
    switch (action.type) {

    case success(listAction):{
      //save list to state by id
      let listData = action.data;
      if(listData && Array.isArray(listData)){
        //save each item by id
        let byId = {};
        let hash;
        listData.forEach((item)=>{
          if(idCreationFunction){
            hash = idCreationFunction(item);
            item.hash = hash;
            byId[hash] = item;
          }else{
            byId[item.id] = item;
          }
        });
        return ReducerUtil.consumedResponse(
          StoreUtil.setLoaded(byId, state, StoreUtil.COMMON_BULK)
        );
      }
    }//fallthrough
    case error(listAction):
    case abort(listAction):
    case listAction:{
      return ReducerUtil.consumedResponse(
        ReducerUtil.reduceLoadStatus(state,action,listAction, StoreUtil.COMMON_BULK)
      );
    }

    default:
      return untouched;
    }
  }

  static reduceListGet(state, action, listAction, storeField = StoreUtil.COMMON_LIST){
    switch (action.type) {

    case success(listAction):{
      //save surgeon list to state
      let listData = action.data;
      if(listData && Array.isArray(listData)){
        return ReducerUtil.consumedResponse(
          StoreUtil.setLoaded(listData, state, storeField)
        );
      }
    }//fallthrough
    case error(listAction):
    case abort(listAction):
    case listAction:{
      return ReducerUtil.consumedResponse(
        ReducerUtil.reduceLoadStatus(state,action,listAction, storeField)
      );
    }

    default:
      return untouched;
    }
  }

  static reduceTableGet(state, action, tableAction, storeField = StoreUtil.COMMON_TABLE){
    switch (action.type) {

    case success(tableAction):{
      //save surgeon list to state
      let tableData = action.data;
      if(tableData && tableData.rows){
        return ReducerUtil.consumedResponse(
          StoreUtil.setLoaded(tableData, state, storeField)
        );
      }
    }//fallthrough
    case error(tableAction):
    case abort(tableAction):
    case tableAction:{
      return ReducerUtil.consumedResponse(
        ReducerUtil.reduceLoadStatus(state,action,tableAction, storeField)
      );
    }

    default:
      return untouched;
    }
  }


  static reduceSingleGet(state, action, singleAction,
    idField = 'id',
    storeField = StoreUtil.COMMON_ITEM,
    arrayExpected = false,
    dataResolver = null){
    switch (action.type) {
    case success(singleAction):{
      let id = action.meta.requestAction[idField];
      let loadedData;
      if(dataResolver != null){
        loadedData = dataResolver(action);
      }else{
        loadedData = action.data;

        if(!arrayExpected && Array.isArray(loadedData)){
          loadedData = loadedData[0];
        }
      }
      if(loadedData
        && ( (arrayExpected && Array.isArray(loadedData)) || loadedData.id)){
        return ReducerUtil.consumedResponse(
          StoreUtil.setLoaded(loadedData, state, storeField, id)
        );
      }
    }//fallthrough
    case error(singleAction):
    case abort(singleAction):
    case singleAction:{
      let id;
      if(action[idField]){
        id = action[idField];
      }else{
        id = action.meta.requestAction[idField];
      }
      return ReducerUtil.consumedResponse(
        ReducerUtil.reduceLoadStatus(state,action,singleAction, storeField, id)
      );
    }
    default:
      return untouched;
    }
  }

  static reduceUpdate(state, action, updateAction, 
    idField = 'id', 
    saveResponse = false, 
    storeField = StoreUtil.COMMON_ITEM,
    newStoreField = StoreUtil.COMMON_NEW_ITEM){
    switch (action.type) {
    case success(updateAction):{
      let id = action.meta.requestAction[idField];
      if(id == null){
        id = -1;
      }
      let data = action.data;

      if(Array.isArray(data)){
        data = data[0];
      }

      //new items return object with id, but didn't have an id prior
      if(id < 1 && data && data.id){
        //new item
        let newStore = StoreUtil.setSaved(data, state, storeField, data[idField]);

        //don't save whole data, just the id
        let newData = {
          id: data.id
        }
          
        return ReducerUtil.consumedResponse(
          StoreUtil.setSaved(newData,newStore, newStoreField)
        );
      }
      //update
      let responseSave = null;
      if(saveResponse){
        responseSave = data;
      }
      return ReducerUtil.consumedResponse(
        StoreUtil.setSaved(responseSave, state, storeField, id)
      );
    }
    case updateAction:
    case abort(updateAction):
    case error(updateAction):{
      let id;
      if(action.type == updateAction){
        if(action[idField] && action[idField] > 0){
          id = action[idField];
        }else{
          //new
          id = -1;
        }
      }else{
        if(action.meta.requestAction[idField]
          && action.meta.requestAction[idField] > 0){
          id = action.meta.requestAction[idField];
        }else{
          //new surgeon
          id = -1;
        }
      }
      if(id > 0){
        return ReducerUtil.consumedResponse(
          ReducerUtil.reduceSaveStatus(state,action,updateAction, storeField, id)
        );
      }else{
        return ReducerUtil.consumedResponse(
          ReducerUtil.reduceSaveStatus(state,action,updateAction, newStoreField, id)
        );
      }
    }
    default:
      return untouched;
    }
  }

  static reduceSaveStatus(store, action, eventName, itemName, id = -1){
    switch(action.type){
    case eventName:
      return StoreUtil.setSaving(store, itemName, id);
    case abort(eventName):
      return StoreUtil.setSaveAborted(store, itemName, id);
    case error(eventName):
    case success(eventName)://assume fallthrough error
      return StoreUtil.setSaveFailed(store, itemName, id);
    default:
      return store;
    }
  }

  static reduceLoadStatus(store, action, eventName, itemName, id = -1){
    switch(action.type){
    case eventName:
      return StoreUtil.setLoading(store, itemName, id);
    case abort(eventName):
      return StoreUtil.setLoadAborted(store, itemName, id);
    case error(eventName):
    case success(eventName)://assume fallthrough error
      return StoreUtil.setLoadFailed(store, itemName, id);
    default:
      return store;
    }
  }

}

export {ReducerUtil as ReducerUtil}
export default ReducerUtil
