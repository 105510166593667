import { listDischargeFacilities } from 'actions/dischargeFacilityActions';
import { listDischargeDispositions } from 'actions/dischargeDispositionActions';
import { listProcedureTypes } from 'actions/procedureTypeActions';
import { listPatients } from 'actions/patientActions';
import { listHospitals } from 'actions/hospitalActions';
import { listSurgeons } from 'actions/surgeonActions';
import StoreUtil from 'stores/StoreUtil'

class SurgeryUtil {

  static checkAndLoadFacilities(dischargeFacilities, dispatch) {
    if (!dischargeFacilities || StoreUtil.needsLoadLongCache(dischargeFacilities)) {
      dispatch(listDischargeFacilities({}, true));
    }
  }

  static checkAndLoadPatients(patients, dispatch) {
    if (!patients || StoreUtil.needsLoadLongCache(patients)) {
      dispatch(listPatients());
    }
  }

  static getReadmissionFacilityById(dischargeFacilities, dischargeFacilityId) {
    if (dischargeFacilities) {
      let typesArray = dischargeFacilities;

      //check if it actual array or data obj
      if (!Array.isArray(typesArray) && typesArray.data && Array.isArray(typesArray.data)) {
        typesArray = typesArray.data;
      }

      if (Array.isArray(typesArray)) {
        return typesArray.find((dischargeFacility) => {
          return (dischargeFacility.id == dischargeFacilityId);
        });
      }
    }
    return null;
  }

  static checkAndLoadDispositions(dischargeDispositions, dispatch) {
    if (!dischargeDispositions || StoreUtil.needsLoadLongCache(dischargeDispositions)) {
      dispatch(listDischargeDispositions());
    }
  }

  static checkAndLoadSurgeons(surgeons, dispatch) {
    if (!surgeons || StoreUtil.needsLoadLongCache(surgeons)) {
      dispatch(listSurgeons());
    }
  }

  static checkAndLoadHospitals(hospitals, dispatch) {
    if (!hospitals || StoreUtil.needsLoadLongCache(hospitals)) {
      dispatch(listHospitals());
    }
  }

  static getDispositionById(dischargeDispositions, dischargeDispositionId) {
    if (dischargeDispositions) {
      let typesArray = dischargeDispositions;

      //check if it actual array or data obj
      if (!Array.isArray(typesArray) && typesArray.data && Array.isArray(typesArray.data)) {
        typesArray = typesArray.data;
      }

      if (Array.isArray(typesArray)) {
        return typesArray.find((dischargeDisposition) => {
          return (dischargeDisposition.id == dischargeDispositionId);
        });
      }
    }
    return null;
  }

  static checkAndLoadProcedureTypes(procedureTypes, dispatch) {
    if (!procedureTypes || StoreUtil.needsLoadLongCache(procedureTypes)) {
      dispatch(listProcedureTypes({}, true));
    }
  }

  static getProcedureTypeById(procedureTypes, procedureTypeId) {
    if (procedureTypes) {
      let typesArray = procedureTypes;

      //check if it actual array or data obj
      if (!Array.isArray(typesArray) && typesArray.data && Array.isArray(typesArray.data)) {
        typesArray = typesArray.data;
      }

      if (Array.isArray(typesArray)) {
        return typesArray.find((procedureType) => {
          return (procedureType.id == procedureTypeId);
        });
      }
    }
    return null;
  }

}

export { SurgeryUtil as SurgeryUtil }
export default SurgeryUtil
