import { takeEvery, put } from 'redux-saga/effects';
import { success} from 'redux-saga-requests';
import {
  API_CREATE_SURGERY_FLAG,
  API_GET_SURGERY_FLAG,
  API_REMOVE_SURGERY_FLAG
} from 'actions/surgeryFlagActions';

function* getUpdatedSurgeryFlags() {
  yield put({
    type: API_GET_SURGERY_FLAG,
    request: {
      url: '/SurgeryFlag',
      method: 'GET'
    }
  })
}

export const createFlagSaga = [
  takeEvery(success(API_CREATE_SURGERY_FLAG), getUpdatedSurgeryFlags)
];

export const removeFlagSaga = [
  takeEvery(success(API_REMOVE_SURGERY_FLAG), getUpdatedSurgeryFlags)
];