import { call, takeEvery, select } from 'redux-saga/effects';
import { sendRequest } from 'redux-saga-requests';
import { success} from 'redux-saga-requests';

import { 
  API_UPDATE_POST_ACUTE_EVENT
} from 'actions/postAcuteEventActions';

import {
  getPostAcuteEventsForEpisodeId
} from 'actions/postAcuteEventActions';

const preferenceQuery = (state) => state.preference.tables['SurgeryPostAcuteEventsView'];

function* updatePostAcuteEventList(action) {
  let query = yield select(preferenceQuery);

  yield call(
    sendRequest,
    getPostAcuteEventsForEpisodeId(action.meta.requestAction.surgeryId,
      query),
    { dispatchRequestAction: false });
}

export const postAcuteEventSaga = [
  takeEvery(success(API_UPDATE_POST_ACUTE_EVENT), updatePostAcuteEventList)
];
