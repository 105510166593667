import ReducerUtil from 'reducers/ReducerUtil'
import DataUtil from 'util/DataUtil';

import {
  API_GET_REASONS,
  API_GET_REASONS_LIST
} from 'actions/reasonActions';

export const reasons = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'reasons');
  if(logout.actionConsumed){
    return logout.state;
  }

  const table = ReducerUtil.reduceTableGet(state, action, API_GET_REASONS);
  if(table.actionConsumed){
    if(action.data){
      const { rows } = action.data;
      
      const isDescOrder = action && action.meta && action.meta.requestAction
      && action.meta.requestAction.request && action.meta.requestAction.request.params
      && action.meta.requestAction.request.params.opts
      && action.meta.requestAction.request.params.opts.order
      && action.meta.requestAction.request.params.opts.order[0][1] === "DESC";

      // dont sort if the data is return in desc order
      if(isDescOrder) return table.state;

      DataUtil.sort(rows, "Text");
    }
    return table.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_GET_REASONS_LIST);
  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "Text");
    return list.state;
  }

  return state;
}
