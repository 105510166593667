import TableUtil from 'util/TableUtil';
export const SAVE_TABLE_PREFERENCE = 'SAVE_TABLE_PREFERENCE';

export const saveTablePreference = (tableName, query, tabIndex = -1) => {
  let queryStore = TableUtil.UpdateQueryForStore(query, tabIndex);
  return {
    type: SAVE_TABLE_PREFERENCE,
    tableName,
    query:queryStore
  }
}