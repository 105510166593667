import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Form from "react-jsonschema-form";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { BaseInput } from 'components/forms/BaseInput';
import { SelectWidget } from 'components/forms/SelectWidget';
import { TitleField } from 'components/forms/TitleField';
import { TextareaWidget } from 'components/forms/TextareaWidget';
import { MaterialArrayFieldTemplate } from 'components/forms/MaterialArrayFieldTemplate';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  formContainer:{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center'
  },
  form:{
    width: 280,
    maxWidth: 800
  },
  formDialog:{
    width: 500,
    maxWidth: 500
  },
  button:{
    marginLeft : theme.spacing.unit * 2
  },
  submitContainer:{
    display:'flex',
    alignItems:'center',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  header: {
    display:'flex',
    alignItems:'center',
    height:15,
    background:fade(theme.palette.common.black, 0.04),
    paddingTop:10,
    paddingBottom:10,
    paddingLeft:10,
    width: "100%"
  }
});

function FieldTemplate(propsTemplate) {
  const {
    classNames,
    children,
    hidden
  } = propsTemplate;
  if (hidden) {
    return children;
  }

  return (
    <div className={classNames}>
      {children}
    </div>
  );
}

function ObjectFieldTemplate (propsTemplate) {
  const { TitleField, DescriptionField } = propsTemplate;
  
  return (
    <Fragment>
      {(propsTemplate.uiSchema["ui:title"] || propsTemplate.title) && (
        <TitleField
          id={`${propsTemplate.idSchema.$id}__title`}
          title={propsTemplate.title || propsTemplate.uiSchema["ui:title"]}
          required={propsTemplate.required}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.description && (
        <DescriptionField
          id={`${propsTemplate.idSchema.$id}__description`}
          description={propsTemplate.description}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.properties.map(prop => prop.content)}
    </Fragment>
  );
}

const customFields = { TitleField };
const customWidgets = {
  BaseInput,
  SelectWidget,
  TextareaWidget
};

class MessageForm extends Component{

  constructor(props) {
    super(props);
    this.state = {
      hasBeenSubmittedWithErrors: false,
      errorMessage: null
    };
  }

  render(){
    const {
      classes,
      schema,
      uiSchema,
      formData,
      validateImmediate,
      onSubmit,
      buttonText,
      ...otherProps
    } = this.props;

    const {
      hasBeenSubmittedWithErrors,
      localFormData
    } = this.state;

    let usedUISchema = uiSchema;
    usedUISchema['ui:options'] = {
      displayLabel: false
    }

    let usedFormData = formData;
    if(localFormData){
      usedFormData = localFormData;
    }

    let formCls = classes.form;

    const formKey = 'form';
    
    return (
      <div className={classes.formContainer}>
        <div className={classes.header}>
          <Typography variant="subheading">Send Message to PAC</Typography>
        </div>
        <Form
          key={formKey}
          ObjectFieldTemplate={ObjectFieldTemplate}
          FieldTemplate={FieldTemplate}
          ArrayFieldTemplate={MaterialArrayFieldTemplate}
          className={formCls}
          fields={customFields}
          widgets={customWidgets}
          showErrorList={false}
          schema={schema}
          uiSchema={usedUISchema}
          liveValidate={validateImmediate || hasBeenSubmittedWithErrors}
          formData={usedFormData}
          {...otherProps}
          noHtml5Validate={true}
          ref={(form) => {this.form=form;}}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <div className={classes.submitContainer}>
              <Button
                className={classes.button}
                type="submit"
                color="primary"
                margin="normal"
                variant="raised"
                onClick={onSubmit}>
                {buttonText}
              </Button>
            </div>
          </Grid>
        </Form>
      </div>
    );
  }
}

MessageForm.propTypes = {
  classes: PropTypes.object.isRequired,
  validateImmediate: PropTypes.bool,
  uiSchema: PropTypes.object,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object,
  buttonText: PropTypes.string
};

const styledPage = withStyles(styles)(MessageForm);
const connectedPage = connect()(styledPage);
export {connectedPage as MessageForm};