import DateUtil from 'util/DateUtil';
import moment from 'moment';

import Papa from 'papaparse';

import { addFacility } from 'actions/facilityActions';
import { getObjWithLowerCaseKeys } from 'reducers/exportReducer';

const columnSep = ",";
const rowSep = "\r\n";

const hospitalFields = [
  {
    name:"ID",
    key:"id",
    alterable:false,
    hideFromExport:true
  },
  {
    name:"Navigator",
    key:"Navigator",
    alterable:false
  },
  {
    name:"Location",
    key:"Location",
    alterable:false
  },
  {
    name:"Name",
    key:"Name",
    alterable:false
  },
  {
    name:"Age",
    key:"Age",
    alterable:true
  },
  {
    name:"Birth Date",
    key:"BirthDate",
    format:'date',
    alterable:false
  },
  {
    name:"Date of Surgery",
    key:"DateofSurgery",
    format:'date',
    alterable:false
  },
  {
    name:"Surgeon",
    key:"Surgeon",
    alterable:false
  },
  {
    name:"Procedure",
    key:"Procedure",
    alterable:true
  },
  {
    name:"FYI",
    key:"FYI",
    alterable:true,
    longContent:true
  },
  {
    name:"Anticipated Discharge Disposition",
    key:"AnticipatedDischargeDisposition",
    alterable:true
  },
  {
    name:"Anticipated Post-Acute Facility",
    key:"AnticipatedPostAcuteFacility",
    alterable:false
  },
  {
    name:"Reason",
    key:"Reason",
    alterable:false,
    longContent:true
  },
  {
    name:"Actual Discharge Disposition",
    key:"DischargeDisposition",
    keyEnumId:"DischargeDispositionId",
    alterable:true,
    enum:"disposition",
    required:true
  },
  {
    name:"Actual Post Acute Facility",
    key:"PostAcuteFacility",
    keyEnumId:"DischargeFacilityId",
    saveEnumString:false,
    alterable:true,
    enum:"facility",
    requiredIf:{
      parentKey:"DischargeDisposition",//PARENT MUST BE LISTED FIRST
      values:[//these should be upper case always
        "HHA WITH PT",
        "HHA- RN ONLY",
        "HHA RN/PT",
        "IRF",
        "SNF",
        "LTAC"
      ]
    }
  },
  {
    name:"Actual Discharge Date",
    key:"DischargeDate",
    format:'date',
    alterable:true,
    required:true
  },
  {
    name:"Discharge Notes",
    key:"DispositionNotes",
    alterable:true,
    longContent:true
  }
];

class CSVUtil {
  static get COLUMN_SEPARATOR(){
    return ",";
  }

  static get ROW_SEPARATOR(){
    return rowSep;
  }

  static get HOSPITAL_HEADERS(){
    return hospitalFields;
  }

  static prepareCSVWorkingDataForDB(headers, workingData, enumMap, surgeryDatabaseDataByHash){
    let dbReadyData = [];
    const alterableFields = hospitalFields.map(data => data.alterable && data.name);
    const loosened_restriction_fields = alterableFields.filter(data => data);
    for(let x=0; x<workingData.length; x++){
      let dbRow = {};
      dbRow.id = workingData[x].id;
      headers.forEach((header)=>{
        let value = workingData[x][header.key];
        const has_loosened_restrictions = loosened_restriction_fields.indexOf(header.key) !== -1;
        if(header.alterable && !has_loosened_restrictions){
          if(header.enum){
            let idx = enumMap[header.enum].indexOf(value.toUpperCase());
            if(header.saveEnumString){
              dbRow[header.keyEnumId] = enumMap[header.enum+"Camel"][idx];
            }else{
              dbRow[header.keyEnumId] = enumMap[header.enum+"Id"][idx];
            }
          }else if(header.format == 'date'){
            dbRow[header.key] = DateUtil.formatToDB(DateUtil.parseDateFromUser(value));
          }else{
            dbRow[header.key] = value;
          }
        }
      });


      let hash = workingData[x].hash;
      let currentDB = surgeryDatabaseDataByHash[hash];
      //add hospital length of stay
      if(dbRow.DischargeDate
        && currentDB
        && currentDB.DateofSurgery){
        let surgeryDate = DateUtil.parseDateFromDB(currentDB.DateofSurgery);
        surgeryDate = moment(surgeryDate);
        let dischargeDate = DateUtil.parseDateFromDB(dbRow.DischargeDate);
        dischargeDate = moment(dischargeDate);
        dbRow.HospitalLengthofStay = moment(dischargeDate).diff(surgeryDate, 'days');
      }
      dbReadyData.push(dbRow);
    }
    return dbReadyData;
  }

  static addMissingFacilityAndUpdateDB(dbSurgeryList, workingData, dispatch) {
    let newFacilities = CSVUtil.getNewFacilities(dbSurgeryList, workingData);
    newFacilities.forEach(facility => {
      dispatch(addFacility(facility.id, facility.PostAcuteFacility));
    })
  }

  static findAndReplaceNameFields(shownData, surgeryBulk) {
    const bulkData = surgeryBulk.data;
    shownData.forEach(data => {
      const dataHash = data.hash.toLowerCase();
      const surgeryBulkWithLowerCaseKey = getObjWithLowerCaseKeys(bulkData);
      if(dataHash in surgeryBulkWithLowerCaseKey){
        data.Name = surgeryBulkWithLowerCaseKey[dataHash].Name;
      }
    })
  }

  static getNewFacilities(dbSurgeryList, workingData){
    const newFacilityIds = [];
    const homeOnlyId = 1;
    const excludedNewFacilityIds = [homeOnlyId];
    dbSurgeryList.forEach(data => {
      if(!data.DischargeFacilityId && !excludedNewFacilityIds.includes(data.DischargeDispositionId)){
        const facility = workingData.filter(fac => fac.id === data.id);
        newFacilityIds.push({
          id: facility[0].id,
          PostAcuteFacility: facility[0].PostAcuteFacility
        });
      }
    });
    return newFacilityIds;
  }

  static didFieldChange(header, newValue, sourceValue){
    if(header.key === "Name"){
      return !(newValue.toLowerCase() === newValue.toLowerCase());
    } else if(header.format == "date"){
      if(newValue == sourceValue){
        return false;
      }
      let newDate = DateUtil.parseDateFromUser(newValue);
      let oldDate = DateUtil.parseDateFromDB(sourceValue);

      return !newDate || !newDate.isSame(oldDate, 'day');
    }else{
      let replacedNewValue = newValue;
      let repalcedSourceValue = sourceValue;

      if(newValue && typeof newValue == 'string'){
        replacedNewValue = newValue.replace(/['",]+/g, '');
      }
      if(sourceValue && typeof sourceValue == 'string'){
        repalcedSourceValue = sourceValue.replace(/['",]+/g, '');
      }
      return newValue != sourceValue && replacedNewValue != repalcedSourceValue;
    }
  }

  static checkRowForInvalidFields(headers, workingDataRow, dataSource, referenceDateKey, enumMap){
    let invalidFields = [];

    headers.forEach((header)=>{
      let fieldAdded = false;
      let value = workingDataRow[header.key];
      //check that rows weren't altered
      
      if(!fieldAdded && !header.alterable && dataSource){
        //remove all commas, and quotes to compare
        if(CSVUtil.didFieldChange(header, value, dataSource[header.key])){
          invalidFields.push(header.key);
          fieldAdded = true;
        }
      }
      
      //check exists if required
      if(!fieldAdded && header.required){
        if(!value && value !== 0){
          invalidFields.push(header.key);
          fieldAdded = true;
        }
      }

      if(!fieldAdded && header.requiredIf){
        //if the parent is in error, make the child. THIS MEANS PARENT IS BEFORE CHILD
        let parentValue = workingDataRow[header.requiredIf.parentKey];
        if(invalidFields.includes(header.requiredIf.parentKey)){
          invalidFields.push(header.key);
          fieldAdded = true;
        }else if(header.requiredIf.values.includes(parentValue.toUpperCase())){
          //field is required!!!
          if(!value && value !== 0){
            invalidFields.push(header.key);
            fieldAdded = true;
          }
        }else{
          //field is not required!
        }
      }
      

      //check dates are formated, and after DOS
      if(!fieldAdded && header.alterable && header.format == 'date'){
        let dateUser = DateUtil.parseDateFromUser(value);
        if(!dateUser 
            || isNaN(dateUser)){
          invalidFields.push(header.key);
          fieldAdded = true;
        }else if(referenceDateKey && dataSource && dataSource[referenceDateKey]){
          let refDate = DateUtil.parseDateFromDB(dataSource[referenceDateKey]);
          if(refDate && dateUser < refDate){
            invalidFields.push(header.key);
            fieldAdded = true;
          }
        }
      }

      //check field is correct enum value
      if(!fieldAdded && header.alterable && header.enum && enumMap[header.enum] && (header.enum !== "facility")){
        // (&& (header.enum !== "facility")) remove facilities from hopsital restrictions per Diane's request
        //check if value included
        if(value && !enumMap[header.enum].includes(value.toUpperCase())){
          invalidFields.push(header.key);
          fieldAdded = true;
        }
      }
    });

    return invalidFields;
  }

  static transformFieldForCSV(header, value){
    let valueEsc;
    if(header.format && header.format == 'date'){
      valueEsc = DateUtil.formatFromDB(value);
    }else{
      valueEsc = value+"";
      valueEsc = valueEsc.replace(/"/g, '""');
      if (valueEsc.search(/("|,|\n)/g) >= 0){
        valueEsc = '"' + valueEsc + '"';
      }
    }
    return valueEsc;
  }

  static createCSV(headers, list){
    let csv = ' ';
    headers.forEach((header)=>{
      if(header.hideFromExport){
        //leave out of the file
      }else{
        csv += header.name+columnSep;
      }
    });
    csv += rowSep;
    list.forEach((surgery)=>{
      headers.forEach((header)=>{
        if(header.hideFromExport){
        //leave out of the file
        }else {
          if(header.key && surgery[header.key]){
            let valueEsc = CSVUtil.transformFieldForCSV(header,surgery[header.key]);
            csv += valueEsc;
          }
          csv += columnSep;
        }
      });
      csv += rowSep;
    });
    return csv;
  }

  static parseCSV(headers, strData){
    let parsed = Papa.parse(strData);
    if(parsed.data){
      //check and remove first row
      let data = parsed.data;

      //number of headers
      let numOfHeaders = 0;
      headers.forEach((header)=>{
        if(header.hideFromExport){
        //leave out of the file
        }else{
          numOfHeaders++;
        }
      });

      if(Array.isArray(data) 
        && data.length>1
        && data[0].length >= numOfHeaders){
        data.splice(0,1);
        let resultRows = [];
        let rowIdx;
        for(let x=0; x< data.length; x++){
          let rowData = data[x];
          if(rowData.length >= numOfHeaders){
            let rowResult = {};
            rowIdx = 0;
            for(let y=0; y<headers.length; y++){
              if(headers[y].hideFromExport){
                //skipped, not in csv
              }else{
                rowResult[headers[y].key] = rowData[rowIdx];
                rowIdx++;
              }
            }  
            resultRows.push(rowResult);
          }
        }
        return resultRows;
      }else{
        return "The uploaded file is malformed.";
      }
    }else{
      return "The uploaded file is not the correct format.";
    }
  }
}

export {CSVUtil as CSVUtil}
export default CSVUtil