import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import dotProp from 'dot-prop-immutable';
import { getHospitalVisit, updateHospitalVisit } from 'actions/hospitalVisitActions'
import { listReasons } from 'actions/reasonActions'
import { listReadmissionFacilities } from 'actions/readmissionFacilityActions'
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';
import { LoadingView } from 'components/LoadingView';
import { getAdmission } from 'actions/admissionActions';
import NavigationUtil from 'util/NavigationUtil';
import DateUtil from 'util/DateUtil';
import DataUtil from 'util/DataUtil';
import moment from 'moment';

const schemaEdit = {
  title: 'ED Visit',
  type: 'object',
  required: ['Status', 'Date', 'ReadmissionFacilityId', 'PrimaryReadmissionReasonId'],
  properties: {
    Status: {
      type: 'string',
      title: 'Status',
      enum:[
        'ED',
        'Readmission'
      ]
    },
    Date: {
      type: 'string',
      title: 'Date',
      format: 'date'
    },
    ReadmissionFacilityId: {
      type: 'number',
      title: 'Facility'
    },
    PrimaryReadmissionReasonId: {
      type: 'number',
      title: 'Primary Reason'
    },
    SecondaryReasons: {
      title: 'Secondary Reasons',
      "type": "array",
      "items": {
        type: 'number'
      }
    },
    ReadmissionNotes: {
      type: 'string',
      title: 'Notes'
    }
  }
};

const schemeUI = {
  Status:{
    "ui:widget": "select",
    classNames:'two-column-field'
  },
  Date:{
    classNames:'two-column-field'
  },
  ReadmissionNotes:{
    "ui:widget": "textarea"
  },
  SecondaryReasons:{
    classNames:'two-column-field'
  }
}

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class PACHospitalVisitPage extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onDataTransform = this.onDataTransform.bind(this);
  }

  componentDidMount(){
    const {
      hospitalVisitId,
      hospitalVisit,
      isHospitalVisitNew,
      dispatch,
      reasons,
      readmissionFacilities,
      admission,
      admissionId
    } = this.props;

    if(StoreUtil.needsLoadNoCache(admission)){
      dispatch(getAdmission(admissionId));
    }

    if(!isHospitalVisitNew && StoreUtil.needsLoadNoCache(hospitalVisit)){
      dispatch(getHospitalVisit(hospitalVisitId));
    }

    if(StoreUtil.needsLoadLongCache(readmissionFacilities)){
      dispatch(listReadmissionFacilities({}, true));
    }

    if(StoreUtil.needsLoadNoCache(reasons)){
      let query = {
        where:{
          Type:'Readmit'
        }
      };
      dispatch(listReasons(query,true));
    }
  }

  onFormSubmit(data){
    const {
      dispatch, 
      admissionId, 
      isHospitalVisitNew,
      dischargeAfter,
      admission
    } = this.props;
    const formData = data.formData;
    formData.AdmissionId = admissionId;

    //change secondary reasons to objects
    let sentData = DataUtil.deepCopy(formData);
    if(isHospitalVisitNew){
      delete sentData.id;
    }

    let secondaryReasonIds = sentData.SecondaryReasons;
    delete sentData.SecondaryReasons;
    let secondaryReasons = [];
    let seenReasonMap = {};
    if(secondaryReasonIds && secondaryReasonIds.length>0 ){
      secondaryReasonIds.forEach((reasonId)=>{
        if(reasonId && !seenReasonMap[reasonId]){
          seenReasonMap[reasonId] = true;
          secondaryReasons.push({
            ReasonId:reasonId
          });
        }
      });
    }

    let redirectUrl;
    const admissionData = StoreUtil.getData(admission);
    if(admissionData){
      if(dischargeAfter){
        const path =
        `/admission/${admissionData.id}`
        +`/surgery/${admissionData.Surgery.id}`
        +`/form/${DataUtil.DISCHARGE_QUESTION_GROUP_ID}`;

        redirectUrl = NavigationUtil.pacLink(path);
      }  
    }
    

    dispatch(updateHospitalVisit(sentData, secondaryReasons, !redirectUrl, redirectUrl));
  }

  onFormError(){
  }

  onFormChange(){
  }

  onDataTransform(formData){
    //Initialize seconrdary reasons
    if(!formData.SecondaryReasons){
      formData = dotProp.set(formData, 'SecondaryReasons', []);
    }
    
    return formData;
  }

  render() {
    const {
      classes,
      hospitalVisit,
      isHospitalVisitNew,
      reasons,
      readmissionFacilities,
      admission
    } = this.props;
    
    let schema = schemaEdit;

    if((!isHospitalVisitNew && !StoreUtil.isLoadedOrFailed(hospitalVisit))
      || !StoreUtil.isLoadedOrFailed(readmissionFacilities)
      || !StoreUtil.isLoadedOrFailed(reasons)){
      return (
        <div className={classes.pageContainer}>
          <LoadingView />
        </div>
      );
    }

    let usedSchema = schema;
    if(StoreUtil.getData(reasons)){
      //enum for reason
      let reasonIds = [];
      let reasonNames = [];
      const reasonList = StoreUtil.getData(reasons);
      reasonList.forEach((reason)=>{
        reasonIds.push(reason.id);
        reasonNames.push(reason.Text);
      });

      usedSchema = dotProp.set(usedSchema, 'properties.PrimaryReadmissionReasonId.enumNames', reasonNames);
      usedSchema = dotProp.set(usedSchema, 'properties.PrimaryReadmissionReasonId.enum', reasonIds);

      usedSchema = dotProp.set(usedSchema, 'properties.SecondaryReasons.items.enumNames', reasonNames);
      usedSchema = dotProp.set(usedSchema, 'properties.SecondaryReasons.items.enum', reasonIds);
    }

    if(StoreUtil.getData(readmissionFacilities)){
      //enum for facilities
      let facIds = [];
      let facNames = [];
      const facList = StoreUtil.getData(readmissionFacilities);
      facList.forEach((facility)=>{
        facIds.push(facility.id);
        facNames.push(facility.Name);
      });
      schema.properties.ReadmissionFacilityId.enum = facIds;
      schema.properties.ReadmissionFacilityId.enumNames = facNames;
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(hospitalVisit, 'ED Visit');

    let hospitalVisitData;
    if(!isHospitalVisitNew){
      hospitalVisitData = StoreUtil.getData(hospitalVisit);
      hospitalVisitData.Date = moment(hospitalVisitData.Date).format(DataUtil.FORM_DATE_FORMAT)
    }

    //date fix
    DateUtil.stripTimeFromDBDate(hospitalVisitData, 'Date');

    let usedUISchema = schemeUI;
    let admissionData;

    if(admission){
      admissionData = StoreUtil.getData(admission);

      let admissionMin = moment.utc(admissionData.AdmissionDate).format(DateUtil.FORM_DATE_FORMAT);
      usedUISchema = dotProp.set(usedUISchema,'Date.ui:options.linked', admissionMin);
    }


    if(hospitalVisitData){
      //convert the reasons
      hospitalVisitData = DataUtil.deepCopy(hospitalVisitData);
      let secondaryReasons = hospitalVisitData.SecondaryReasons;
      if(secondaryReasons){
        delete hospitalVisitData.SecondaryReasons;
        let secondaryReasonsIds = secondaryReasons.map((reason)=>{
          return reason.ReasonId;
        });
        hospitalVisitData.SecondaryReasons = secondaryReasonsIds;
      }
    }
    
    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={usedSchema}
          uiSchema={usedUISchema}
          onChange={this.onFormChange}
          onDataTransform={this.onDataTransform}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={hospitalVisitData}
          allowSubmit={!StoreUtil.isSaving(hospitalVisit)}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

PACHospitalVisitPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isHospitalVisitNew: PropTypes.bool.isRequired,
  hospitalVisit : PropTypes.object.isRequired,
  hospitalVisitId : PropTypes.number.isRequired,
  readmissionFacilities : PropTypes.object.isRequired,
  reasons : PropTypes.object.isRequired,
  user : PropTypes.object.isRequired,
  admissionId : PropTypes.number.isRequired,
  dischargeAfter:PropTypes.bool.isRequired,
  admission : PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const {
    hospitalVisits,
    readmissionFacilities,
    reasons,
    user,
    admissions
  } = state;

  const { match } = existingProps;
  let hospitalVisitId;
  let admissionId;
  let dischargeAfter = false;
  try{
    hospitalVisitId = parseInt(match.params.id);
    if(isNaN(hospitalVisitId)){
      hospitalVisitId = 0;
    }

    admissionId = parseInt(match.params.admissionId);
    if(isNaN(admissionId)){
      admissionId = 0;
    }
    dischargeAfter = match.params.dischargeAfter == 'discharge';
  }catch(error){
    console.log(error);
    hospitalVisitId = 0;
    admissionId = 0;
  }

  let isHospitalVisitNew = hospitalVisitId < 1;
  let hospitalVisit;
  if(isHospitalVisitNew){
    hospitalVisit = StoreUtil.get(hospitalVisits, StoreUtil.COMMON_NEW_ITEM);
  }else{
    hospitalVisit = StoreUtil.get(hospitalVisits, StoreUtil.COMMON_ITEM, hospitalVisitId);
  }

  return {
    isHospitalVisitNew,
    hospitalVisit,
    hospitalVisitId,
    readmissionFacilities : StoreUtil.get(readmissionFacilities, StoreUtil.COMMON_LIST),
    reasons : StoreUtil.get(reasons, StoreUtil.COMMON_LIST),
    admissionId,
    user,
    dischargeAfter,
    admission:StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId)
  }
}

const styledPage = withStyles(styles)(PACHospitalVisitPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACHospitalVisitPage};
