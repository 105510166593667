export const API_ALERTS_AND_COUNTS = 'API_ALERTS_AND_COUNTS';

export const getAlertsAndCounts = (options)=> {
  return {
    type: API_ALERTS_AND_COUNTS,
    request: {
      url: '/alertsAndCounts',
      method: 'GET',
      params : {opts:options}
    }
  }
};