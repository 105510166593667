export const GET_90_DAY_DISCHARGE = 'GET_90_DAY_DISCHARGE';

export const get90DayDischarge = (options)=> {
  return {
    type: GET_90_DAY_DISCHARGE,
    request:{
      url: '/PTAlerts',
      method: 'GET',
      params: { opts:options }
    }
  }
};