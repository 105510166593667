import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

import { MaterialForm } from "components/forms/MaterialForm";
import FormUtil from 'util/FormUtil';
import StoreUtil from 'stores/StoreUtil';
import { LoadingView } from 'components/LoadingView';
import { getSurgeon, updateSurgeon } from 'actions/surgeonActions';
import { listNavUsers } from 'actions/navUserActions';
import NavigationUtil from 'util/NavigationUtil'
import { listSurgicalTeams } from 'actions/surgicalTeamActions';

const schema = {
  title: 'Surgeon',
  type: 'object',
  required: ['Name', 'SchSysId', 'NavigatorUserId'],
  properties: {
    Name: {type: 'string', title: 'Surgeon Name', minLength: 1, description:"Type the surgeon's name"},
    SchSysId: {
      type: 'string',
      title: 'System Id',
      minLength: 4,
      maxLength: 4,
      description:'Four digit surgeon id number'
    },
    NavigatorUserId: {type: 'number', title: 'Nurse Navigator',
      description:'Select nurse navigator associated with this surgeon'},
    SurgicalTeamId: {type: 'number', title: 'Surgical Team',
      description:'Select this surgeon\'s surgical team'}
  }
};

const uiSchema = {
};

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class SurgeonEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const{
      isNewSurgeon,
      surgeonId,
      dispatch,
      users,
      surgeon,
      surgicalTeams
    } = this.props;

    if(!isNewSurgeon && StoreUtil.needsLoadNoCache(surgeon)){
      dispatch(getSurgeon(surgeonId));
    }

    if(StoreUtil.needsLoadLongCache(users)){
      dispatch(listNavUsers(true));
    }

    if(StoreUtil.needsLoadLongCache(surgicalTeams)){
      dispatch(listSurgicalTeams());
    }
  }

  onFormSubmit(data){
    const {surgeonId, dispatch, isNewSurgeon} = this.props;
    const formData = data.formData;

    if(!isNewSurgeon){
      formData.id = surgeonId;
    }else{
      delete formData.id;
    }
    dispatch(updateSurgeon(formData));
  }

  onFormError(){
  }

  onFormChange(){
  }

  render() {
    const {
      classes,
      isNewSurgeon,
      surgeon,
      users,
      user,
      surgicalTeams
    } = this.props;

    let scheme = schema;
    let existingData = null;

    //save happened, lets redirect
    if (isNewSurgeon && StoreUtil.didJustSave(surgeon)) {
      return (<Redirect to={NavigationUtil.userLink(user, "/surgeons/"+StoreUtil.getData(surgeon).id)} />);
    }

    let userData = StoreUtil.getData(users);
    let teamData = StoreUtil.getData(surgicalTeams);

    if((userData && userData.length > 0) && (teamData && teamData.length > 0)
    && (isNewSurgeon || StoreUtil.isLoadedOrFailed(surgeon))){
      const userNames = [];
      const userIds = [];
      let i;
      for(i in userData){
        userNames.push(userData[i].FirstName + ' ' + userData[i].LastName);
        userIds.push(userData[i].id);
      }

      existingData = StoreUtil.getData(surgeon);

      scheme.properties.NavigatorUserId.enumNames = userNames;
      scheme.properties.NavigatorUserId.enum = userIds;

      const teamNames = [];
      const teamIds = [];

      for(i in teamData){
        teamNames.push(teamData[i].Name);
        teamIds.push(teamData[i].id);
      }

      scheme.properties.SurgicalTeamId.enumNames = teamNames;
      scheme.properties.SurgicalTeamId.enum = teamIds;
    }else{
      return (<LoadingView />);
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(surgeon, 'surgeon');

    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={schema}
          uiSchema={uiSchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={existingData}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

SurgeonEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isNewSurgeon: PropTypes.bool.isRequired,
  surgeonId: PropTypes.number.isRequired,
  surgeon : PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  surgicalTeams: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const{
    surgeons,
    navUsers,
    user,
    surgicalTeams
  } = state;

  const { match } = existingProps;

  //get surgeon id from url
  let surgeonId;
  try{
    surgeonId = parseInt(match.params.surgeonId);
    if(isNaN(surgeonId)){
      surgeonId = 0;
    }
  }catch(error){
    console.log(error);
    surgeonId = 0;
  }

  const isNewSurgeon = surgeonId == 0;
  let surgeon = null;
  if(!isNewSurgeon){
    surgeon = StoreUtil.get(surgeons, StoreUtil.COMMON_ITEM, surgeonId);
  }else{
    surgeon = StoreUtil.get(surgeons, StoreUtil.COMMON_NEW_ITEM);
  }

  return {
    surgeon,
    users : StoreUtil.get(navUsers, StoreUtil.COMMON_LIST),
    isNewSurgeon,
    surgeonId,
    user,
    surgicalTeams: StoreUtil.get(surgicalTeams, StoreUtil.COMMON_LIST)
  }
}

const styledPage = withStyles(styles)(SurgeonEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as SurgeonEditPage};
