import { listTaskTypes } from 'actions/tasksActions';
import StoreUtil from 'stores/StoreUtil'

const status = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  //TODO create new status for "has questions"
  RESOLVED: "Resolved"
}

class TaskUtil {

  static canManuallyResolve(taskData) {
    return (taskData.Status == null);
  }

  static isTypeOther(taskType) {
    return taskType.Name == "Other";
  }

  static getTaskTypeById(taskTypes, taskTypeId) {
    if (taskTypes) {
      let typesArray = taskTypes;
      //check if it actual array or data obj
      if (!Array.isArray(typesArray) && typesArray.data && Array.isArray(typesArray.data)) {
        typesArray = typesArray.data;
      }

      if (Array.isArray(typesArray)) {
        return typesArray.find((taskType) => {
          return (taskType.id == taskTypeId);
        });
      }
    }
    return null;
  }

  static checkAndLoadTypes(taskTypes, dispatch) {
    if (!taskTypes || StoreUtil.needsLoadLongCache(taskTypes)) {
      dispatch(listTaskTypes());
    }
  }

}

TaskUtil.Status = status;

export {TaskUtil as TaskUtil}
export default TaskUtil
