import React from 'react';
import { PropTypes } from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles= (theme) => ({
  loader: {
    width:'100%',
    display:'flex',
    alignItems:'center',
    flexDirection:'column'
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

class LoadingView extends React.Component {

  render() {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.loader}>
        <CircularProgress className={classes.progress} color="secondary" />
      </div>
    );
  }
}

LoadingView.propTypes = {
  classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(LoadingView);
export {styled as LoadingView};

