import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DateUtil from 'util/DateUtil'

function ConfirmSendToNavDialog (props) {
  const {
    open,
    handleClose,
    selectedSurgery,
    selectedRow,
    handleSendToNavConfirm,
    selectedSurgeryId
  } = props

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='confirm_remove'
      >
        <DialogTitle id='confirm_sendToNav_dialog'>
          Send Event To Navigator
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`If you do not wish to send the navigator this event, ${selectedRow.Description ||
              'no description provided'}, with Event Date ${DateUtil.formatFromDB(
              selectedRow.EventDate
            )} for ${selectedRow.FirstName} ${
              selectedRow.LastName
            } (DOB: ${DateUtil.formatFromDB(selectedRow.EventDate)}), cancel or
            close this dialog.`}
          </DialogContentText>
          {selectedSurgeryId && (
            <DialogContentText>
              {selectedSurgery.id &&
                `Marking this single surgery as Sent to Navigator: Surgeon: ${
                  selectedSurgery.Surgeon.Name
                }; Location: ${
                  selectedSurgery.Hospital.Name
                }; Surgery Date: ${DateUtil.formatFromDB(
                  selectedSurgery.SurgeryDateTime
                )}`}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Close
          </Button>
          <Button onClick={handleSendToNavConfirm} color='primary'>
            Send To Navigator
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

ConfirmSendToNavDialog.propTypes = {
  associatedSurgeries: PropTypes.array,
  handleClose: PropTypes.func,
  handleContinue: PropTypes.func,
  handleSendToNavConfirm: PropTypes.func,
  sendToNav: PropTypes.func,
  handleRemove: PropTypes.func,
  open: PropTypes.bool,
  postAcuteEventsListRows: PropTypes.array,
  selectedRow: PropTypes.shape({
    Associated_Surgeries: PropTypes.string,
    Description: PropTypes.string,
    EventDate: PropTypes.string,
    EventType: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    MRN: PropTypes.number,
    NavigatorUserId: PropTypes.number,
    PartialPAE: PropTypes.number,
    ReadmissionFacilityDisplayName: PropTypes.string,
    ReadmissionFacilityId: PropTypes.number,
    Status: PropTypes.string,
    SurgeonId: PropTypes.number,
    SurgeryDate: PropTypes.string,
    SurgeryId: PropTypes.number,
    id: PropTypes.number
  }),
  selectedSurgery: PropTypes.object,
  setSelectedSurgery: PropTypes.func,
  selectedSurgeryId: PropTypes.string
}

export { ConfirmSendToNavDialog }
