import { push } from 'connected-react-router'
import NavigationUtil from 'util/NavigationUtil'
export const API_PATIENT_DETAIL = 'API_PATIENT_DETAIL';
export const API_PATIENT_EHR = 'API_PATIENT_EHR';
export const API_PATIENT_TABLE = 'API_PATIENT_TABLE';
export const API_PATIENT_UPDATE = 'API_PATIENT_UPDATE';
export const API_PATIENT_LIST = 'API_PATIENT_LIST';
export const API_PATIENT_SURVEY = 'API_PATIENT_SURVEY';

export const getPatientDetailByMRN = (patientMedicalRecNum, externalSource)=> {
  const options = {
    where: {
      MedicalRecNum: patientMedicalRecNum,
      ExternalSource: externalSource
    } 
  }
  return {
    type: API_PATIENT_DETAIL,
    mrn: patientMedicalRecNum, 
    externalSource: externalSource,
    request: {
      url: '/Patients',
      method: 'GET',
      params : {opts:options}
    }
  }
};

// ! NOTE this function is not called
// export const getPatientEHRByMRN = (patientMedicalRecNum)=> {
//   const options = {
//     where: {PatientMRN:patientMedicalRecNum }
//   }
//   return {
//     type: API_PATIENT_EHR,
//     mrn: patientMedicalRecNum,
//     request: {
//       url: '/EHRPatientData',
//       method: 'GET',
//       params : {opts:options}
//     }
//   }
// };

export const tablePatients = (options)=> {
  if(!options.order){
    options.order = [];
    options.order.push(["LastName", "ASC"])
  }
  return {
    type: API_PATIENT_TABLE,
    request: {
      url: '/Patients',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listPatients = ()=> {
  return {
    type: API_PATIENT_LIST,
    request: {
      url: '/Patients',
      method: 'GET'
    }
  }
};

export const viewPatient = (patient)=> {
  return push(NavigationUtil.navLink('/patients/'+patient.MedicalRecNum+'/'+patient.ExternalSource));
};

export const updatePatient = (formData, backOnSuccess = false)=> {
  let options = null;
  let mrn = 0;

  if(formData.MedicalRecNum && formData.MedicalRecNum > 0 && formData.ExternalSource){
    mrn = formData.MedicalRecNum;
    options = {
      where: {
        MedicalRecNum:mrn,
        ExternalSource: formData.ExternalSource
      }
    }
  }
  
  return {
    type: API_PATIENT_UPDATE,
    mrn: mrn,
    patient: formData,
    backOnSuccess,
    request:{
      url: '/Patients',
      method: 'PUT',
      params : {opts:options},
      data : formData
    }
  }
};

export const surveyPatient = (surgeryId, data)=> {  
  return {
    type: API_PATIENT_SURVEY,
    id: surgeryId,
    data: data,
    request:{
      url: '/SurveyPatient',
      method: 'POST',
      data : {
        id: surgeryId,
        data: data
      }
    }
  }
};
