import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { SurgeonTable } from 'components/SurgeonTable';
import { Link } from 'react-router-dom'
import Icon from '@material-ui/core/Icon';

import { tableSurgeons } from 'actions/surgeonActions';
import { viewSurgeon } from 'actions/surgeonActions';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import StoreUtil from 'stores/StoreUtil'
import NavigationUtil from 'util/NavigationUtil'
import { saveTablePreference } from 'actions/preferenceActions';

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});
const TABLE_NAME = "SurgeonPage";
class SurgeonPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidUpdate(prevProps) {
    //has the url query string changed and do we need to request new patients?
    if (this.props.location.search != prevProps.location.search) {
      this.fetchInitialUsers();
    }
  }

  componentDidMount() {
    const {
      surgeonsTableData
    } = this.props;

    if(StoreUtil.needsLoadNoCache(surgeonsTableData)){
      this.fetchInitialSurgeons();
    }
  }

  fetchInitialSurgeons() {
    this.setState({query: this.buildInitialQuery() }, this.fetchSurgeons.bind(this));
  }

  buildInitialQuery() {

    let query = {
      where: { },
      limit: 25,
      offset: 0
    };

    const {
      preferenceQuery
    } = this.props;

    if(preferenceQuery){
      query = preferenceQuery
    }

    return query;
  }

  fetchSurgeons() {
    const { dispatch } = this.props;
    const { query } = this.state;

    console.log("Fetching surgeons with query: "+JSON.stringify(query));
    dispatch(tableSurgeons(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchSurgeons.bind(this));
  }

  onRowClick(surgeons) {
    const { dispatch, user } = this.props;
    dispatch(viewSurgeon(user, surgeons));
  }

  render() {
    const {
      classes,
      surgeonsTableData,
      user
    } = this.props;

    const {
      query
    } = this.state;

    return (
      <React.Fragment>
        <SurgeonTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Surgeons</Typography>
              <Button
                color="primary"
                size="small"
                component={Link} to={NavigationUtil.userLink(user, "/surgeons/0")} >
                <Icon className={classes.leftIcon}>add</Icon>
                New Surgeon
              </Button>
            </div>
          }
          data = {surgeonsTableData}
          query = {query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
        />
      </React.Fragment>
    );
  }
}

SurgeonPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  surgeonsTableData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const { user, surgeons, preference } = state;

  const surgeonsTableData = StoreUtil.get(surgeons, StoreUtil.COMMON_TABLE);
  return {
    user,
    surgeonsTableData,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(SurgeonPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as SurgeonPage};
