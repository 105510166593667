import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from "components/forms/MaterialForm";
import StoreUtil from 'stores/StoreUtil';
import { getHospital, updateHospital } from 'actions/hospitalActions'
import { Redirect } from 'react-router-dom';
import FormUtil from 'util/FormUtil';
import NavigationUtil from 'util/NavigationUtil'

const schema = {
  title: 'Hospital',
  type: 'object',
  required: ['Name'],
  properties: {
    Name: {type: 'string', title: 'Hospital Name', minLength: 1, description:"Type the hospital's name"}
  }
};

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class HospitalEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const{
      isNewHospital,
      hospitalId,
      hospital,
      dispatch} = this.props;

    if(!isNewHospital && StoreUtil.needsLoadNoCache(hospital)){
      dispatch(getHospital(hospitalId));
    }
  }

  onFormSubmit(data){
    const {isNewHospital, hospitalId, dispatch} = this.props;
    const formData = data.formData;

    if(!isNewHospital){
      formData.id = hospitalId;
    }else{
      delete formData.id;
    }
    
    dispatch(updateHospital(formData));
  }

  onFormError(err){
    console.log('on form error!!', err);
  }

  onFormChange(){}

  render() {
    const {
      classes,
      hospital,
      isNewHospital,
      user
    } = this.props;

    //save happened, lets redirect
    if (isNewHospital && StoreUtil.didJustSave(hospital)) {
      return (<Redirect to={NavigationUtil.userLink(user,"/hospital/"+StoreUtil.getData(hospital).id)} />);
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(hospital, 'hospital');

    let hospitalData = StoreUtil.getData(hospital);
    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={schema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={hospitalData}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

HospitalEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  hospital: PropTypes.object.isRequired,
  isNewHospital: PropTypes.bool.isRequired,
  hospitalId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const{
    hospitals,
    user
  } = state;

  const { match } = existingProps;
  let hospitalId;
  try{
    hospitalId = parseInt(match.params.hospitalId);
    if(isNaN(hospitalId)){
      hospitalId = 0;
    }
  }catch(error){
    console.log(error);
    hospitalId = 0;
  }

  const isNewHospital = hospitalId == 0;
  let hospital = null;
  if(!isNewHospital){
    hospital = StoreUtil.get(hospitals, StoreUtil.COMMON_ITEM, hospitalId);
  }else{
    hospital = StoreUtil.get(hospitals, StoreUtil.COMMON_NEW_ITEM);
  }

  return {
    hospital,
    isNewHospital,
    hospitalId,
    user
  }

}

const styledPage = withStyles(styles)(HospitalEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as HospitalEditPage};