import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';

import NavigationUtil from 'util/NavigationUtil'
import { Footer } from 'components/Footer';

import { HelpDeskHeader } from 'containers/helpDesk/HelpDeskHeader';
import { HelpDeskDashboardPage } from 'containers/helpDesk/HelpDeskDashboardPage';
import { FacilityPage } from 'containers/facilities/FacilityPage';
import { FacilityEditPage } from 'containers/facilities/FacilityEditPage';
import { SurgeonPage } from 'containers/surgeons/SurgeonPage';
import { HospitalPage } from 'containers/hospitals/HospitalPage';
import { HospitalEditPage } from 'containers/hospitals/HospitalEditPage';
import { UsersPage } from 'containers/users/UsersPage';
import { UserEditPage } from 'containers/users/UserEditPage';
import { SurgeonEditPage } from 'containers/surgeons/SurgeonEditPage';


const styles = (theme) =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width:"100%",
    padding: theme.spacing.unit * 2,
    boxSizing: 'border-box'
  }
});

class HelpDeskRoot extends Component {
  render() {
    const {
      classes,
      location
    } = this.props;

    return (
      <div className={classes.container}>
        <HelpDeskHeader />
        <div className={classes.content}>
          <Switch>
            <Route
              path={NavigationUtil.helpDeskDir+'/home'}
              component={HelpDeskDashboardPage}
              exact={true} />
            <Route
              path={NavigationUtil.helpDeskDir+'/facilities'}
              component={FacilityPage}
              exact={true} />
            <Route
              path={NavigationUtil.helpDeskDir+'/facilities/:facilityId'}
              component={FacilityEditPage}
              exact={true} />
            <Route exact
              path={NavigationUtil.helpDeskDir+'/users'}
              component={UsersPage} />
            <Route exact
              path={NavigationUtil.helpDeskDir+'/users/:userId'}
              component={UserEditPage} />
            <Route exact
              path={NavigationUtil.helpDeskDir+'/surgeons'}
              component={SurgeonPage} />
            <Route exact
              path={NavigationUtil.helpDeskDir+'/surgeons/:surgeonId'}
              component={SurgeonEditPage} 
              key={location.pathname} />
            <Route exact
              path={NavigationUtil.helpDeskDir+'/hospitals'}
              component={HospitalPage} />
            <Route exact
              path={NavigationUtil.helpDeskDir+'/hospital/:hospitalId'}
              component={HospitalEditPage} />
            <Redirect to={NavigationUtil.helpDeskDir+'/home'} />
          </Switch>
        </div>
        <Footer className={classes.footer}/>
      </div>
    );
  }
}

HelpDeskRoot.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps() {
  return {};
}

const styledApp = withStyles(styles)(HelpDeskRoot);
const connectedApp = connect(mapStateToProps)(styledApp);
const routedApp = withRouter(connectedApp);
export {routedApp as HelpDeskRoot};
