import React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = () => ({
  inputContainer:{
    width:'80%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    position:'relative'
  },
  inputLabel:{
    transform: 'none',
    lineHeight:'120%',
    zIndex:10,
    position:'relative'
  }
});

function TitleField(props) {
  const { id, title, required,classes, rawErrors } = props;
  const legend = title;
  const style = {
    width: '100%'
  };
  
  let hasError = rawErrors != null;
  if(id.includes("root__")){
    return <Typography style={style} variant="display1" component='legend' id={id}>{legend}</Typography>
  }else{
    return (
      <FormControl
        className={classes.inputContainer}
        required={required}
        error={hasError} >
        <InputLabel
          error={hasError}
          className={classes.inputLabel}
          shrink={true}
          disableAnimation={true}
          required={required}
        >{legend}</InputLabel>
      </FormControl>
    );
  }

  
}

TitleField.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  required: PropTypes.bool
};

const styledPage = withStyles(styles)(TitleField);
export {styledPage as TitleField};
