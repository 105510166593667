//import ReducerUtil from 'reducers/ReducerUtil'
import dotProp from 'dot-prop-immutable';

import {
  SAVE_TABLE_PREFERENCE
} from 'actions/preferenceActions';
import {
  API_LOGOUT
} from 'actions/loginActions';

export const preference = (state = {}, action) => {
  /*const logout = ReducerUtil.reduceLogout(state, action, 'preference');
  if(logout.actionConsumed){
    return logout.state;
  }*/

  switch(action.type){
  case API_LOGOUT:
    return state;//leave it
  case SAVE_TABLE_PREFERENCE:
    return dotProp.set(state, `tables.${action.tableName}`, action.query);
  }
  return state;
}
