import { push } from 'connected-react-router';
import NavigationUtil from 'util/NavigationUtil'
export const API_TABLE_SURGEONS = 'API_TABLE_SURGEONS';
export const API_LIST_SURGEONS = 'API_LIST_SURGEONS';
export const API_GET_SURGEON = 'API_GET_SURGEON';
//export const API_NEW_SURGEON = 'API_NEW_SURGEON';
export const API_UPDATE_SURGEON = 'API_UPDATE_SURGEON';
export const API_UPDATE_SURGEON_NAME = 'API_UPDATE_SURGEON_NAME';
export const API_UPDATE_SURGEON_RELATION = 'API_UPDATE_SURGEON_RELATION';

export const tableSurgeons = (options)=> {
  if(!options.order){
    options.order = [];
    options.order.push(["Name", "ASC"])
  }
  return {
    type: API_TABLE_SURGEONS,
    request: {
      url: '/Surgeons',
      method: 'GET',
      params: {opts:options}
    }
  }
};

export const listSurgeons = ()=> {

  return {
    type: API_LIST_SURGEONS,
    request: {
      url: '/Surgeons',
      method: 'GET'
    }
  }
};

export const getSurgeon = (surgeonId)=> {
  const options = {
    where: {id:surgeonId}
    //change attribute to hospitalList
    //add attribute array with just ids
  }
  return {
    type: API_GET_SURGEON,
    id: surgeonId,
    request: {
      url: '/Surgeons',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updateSurgeon = (formData)=>{
  let surgeonId = -1;

  if(formData.id && formData.id > 0){
    surgeonId = formData.id;
  }

  return {
    type: API_UPDATE_SURGEON,
    id: surgeonId,
    surgeon: formData,
    request: createUpdateSurgeonNameRequest(formData)
  }

  /*}else{
    //NEW SURGEON!!  need saga!!!
    return {
      type: API_NEW_SURGEON,
      surgeon: formData
    }
  }*/
};

const createUpdateSurgeonNameRequest = (formData) => {
  let options = null;
  let surgeonId = 0;

  if(formData.id && formData.id > 0){
    surgeonId = formData.id;
    options = {
      where: {id:surgeonId}
    }
  }

  return {
    url: '/Surgeons',
    method: 'PUT',
    params: {opts:options},
    data: formData
  };
}

export const createSurgeon = (formData)=>{
  let surgeonId = 0;

  return {
    type: API_UPDATE_SURGEON_NAME,
    id: surgeonId,
    surgeon: formData,
    request:createUpdateSurgeonNameRequest(formData)
  }
};

const createUpdateSurgeonRelationsRequest = (formData) => {
  return {
    url: `/setSurgeonsHospitals/${formData.id}`,
    method: 'POST',
    data: formData.hospitalList
  };
}

export const createSurgeonRelations = (formData)=>{
  return {
    type: API_UPDATE_SURGEON_RELATION,
    id: formData.id,
    surgeon: formData,
    request:createUpdateSurgeonRelationsRequest(formData)
  }
}

export const viewSurgeon = (user, surgeon)=> {
  return push(NavigationUtil.userLink(user, '/surgeons/'+surgeon.id));
};