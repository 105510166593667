import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';
import { PatientBCItem } from 'components/breadcrumb/PatientBCItem';
import { SurgeryBCItem } from 'components/breadcrumb/SurgeryBCItem';
import { TaskBCItem } from 'components/breadcrumb/TaskBCItem';
import { HospitalBCItem } from 'components/breadcrumb/HospitalBCItem';
import { TableMaintenanceBCItem } from 'components/breadcrumb/TableMaintenanceBCItem';
import { HospitalListBCItem } from 'components/breadcrumb/HospitalListBCItem';
import { PatientsBCItem } from 'components/breadcrumb/PatientsBCItem';
import { SurgeonsBCItem } from 'components/breadcrumb/SurgeonsBCItem'
import { SurgeonBCItem } from 'components/breadcrumb/SurgeonBCItem'
import { UsersBCItem } from 'components/breadcrumb/UsersBCItem'
import { PACPatientSearchBCItem } from 'components/breadcrumb/PACPatientSearchBCItem'
import { PACAdmissionBCItem } from 'components/breadcrumb/PACAdmissionBCItem'
import { FacilityBCItem } from 'components/breadcrumb/FacilityBCItem';
import { FacilityListBCItem } from 'components/breadcrumb/FacilityListBCItem';

import { withStyles } from '@material-ui/core/styles';
import NavigationUtil from 'util/NavigationUtil'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit
  }
});

class Breadcrumb extends Component {

  render() {
    const {
      classes,
      location
    } = this.props;

    const isPac = location.pathname && location.pathname.includes(NavigationUtil.pacdir);
    let homeLink;
    if(isPac){
      homeLink = NavigationUtil.pacdir+"/home";
    }else{
      homeLink = NavigationUtil.navdir+"/dashboard";
    }
    
    return (
      <div className={classes.root}>
        <BreadcrumbItem
          key="/"
          label=""
          icon="home"
          path={homeLink}
        />

        <Route
          location={location}
          component={PatientsBCItem}
          path={NavigationUtil.navdir+"/(patients|patientEdit)"} />

        <Route
          location={location}
          component={PatientBCItem}
          path={NavigationUtil.navdir+"/patients/:mrn/:externalSource"} />
        <Route
          location={location}
          component={PatientBCItem}
          path={NavigationUtil.navdir+"/patientEdit/:mrn/:externalSource"} />
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+"/surgery/:surgeryId"} />
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+'/surgery/:surgeryId/form/:questionGroupId/task/:taskId'} />
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+'/surgery/:surgeryId/form/:questionGroupId'} />
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+'/surgery/:surgeryId/form/:questionGroupId/:taskId'} />
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+"/surgery/:surgeryId/edit"} />
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+"/surgery/:surgeryId/riskaudit"} />        
        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path={NavigationUtil.navdir+"/surgery/:surgeryId/edit/:mrn"} />
        <Route
          location={location}
          component={SurgeryBCItem}
          path="*/surgery/:surgeryId/task/:id" />
        <Route
          location={location}
          component={SurgeryBCItem}
          path="*/surgery/:surgeryId/taskEdit/:id" />
        <Route
          location={location}
          component={TaskBCItem}
          path="*/surgery/:surgeryId/task/:id" />
        <Route
          location={location}
          component={TaskBCItem}
          path="*/surgery/:surgeryId/taskEdit/:id" />

        <Route
          location={location}
          component={SurgeryBCItem}
          exact={true}
          path="*/surgery/:surgeryId/admission/:id/conversation/:id" />

        <Route
          location={location}
          component={HospitalListBCItem}
          path={NavigationUtil.navdir+"/(hospital|hospitals)"} />

        <Route
          location={location}
          component={HospitalBCItem}
          path={NavigationUtil.navdir+"/hospital/:id"} />

        <Route
          location={location}
          component={TableMaintenanceBCItem}
          path={NavigationUtil.navdir+"/maintenance"} />

        <Route
          location={location}
          component={SurgeonsBCItem}
          path={NavigationUtil.navdir+"/(surgeons)"} />

        <Route
          location={location}
          component={SurgeonBCItem}
          path={NavigationUtil.navdir+"/surgeons/:id"} />

        <Route
          location={location}
          component={UsersBCItem}
          path={NavigationUtil.navdir+"/users"} />

        <Route
          location={location}
          component={PACPatientSearchBCItem}
          path={NavigationUtil.pacdir+"/patientSearch"} />

        <Route
          location={location}
          component={PACAdmissionBCItem}
          path={NavigationUtil.pacdir+"/admission/:id"} />

        <Route
          location={location}
          component={FacilityListBCItem}
          path={NavigationUtil.pacdir+"/facilities"} />

        <Route
          location={location}
          component={FacilityBCItem}
          path={NavigationUtil.pacdir+"/facilities/:id"} />

      </div>
    );
  }
}

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { router } = state;
  return {
    location: router.location
  };
}

const styled = withStyles(styles)(Breadcrumb);
const connected = connect(mapStateToProps)(styled);
export {connected as Breadcrumb};
