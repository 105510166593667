import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import StoreUtil from 'stores/StoreUtil';
import { DateUtil } from 'util/DateUtil';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class PatientBCItem extends Component {

  render() {
    const {
      patient,
      match
    } = this.props;

    let title = "";
    if (patient && StoreUtil.getData(patient)) {
      let patientData = StoreUtil.getData(patient);
      title = patientData.LastName+", "+
        patientData.FirstName +
        " - MRN: "+patientData.MedicalRecNum+
        " - DOB: "+DateUtil.formatFromDB(patientData.BirthDate);

    }
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          icon="face"
          path={NavigationUtil.navLink("/patients/"+match.params.mrn+"/"+match.params.externalSource)}
        />
      </React.Fragment>
    );
  }
}

PatientBCItem .propTypes = {
  patient: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state, props) {
  const { match } = props;
  const { patients } = state;

  return {
    patient : StoreUtil.get(patients, StoreUtil.COMMON_ITEM, match.params.mrn)
  };
}

const connected = connect(mapStateToProps)(PatientBCItem);
export {connected as PatientBCItem };
