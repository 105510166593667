import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { connect } from 'react-redux';

import { DynamicTable } from 'components/DynamicTable';

import { DateUtil } from 'util/DateUtil'
import { DataUtil } from 'util/DataUtil'

const styles = () => ({

});


class PACAdmissionsTable extends Component {

  constructor(props) {
    super(props);

    this.columns = [
      {label:"Last", key: "Surgery.Patient.LastName", sortable: true, sortDown: false, style:{width:160}},
      {label:"First", key: "Surgery.Patient.FirstName", sortable: true, sortDown: false, style:{width:160}},
      {label:"Admitted", key: "AdmissionDate", sortable: true, type:"date", sortDown: false,
        format:this.dateFormat},
      {label:"Anticipated Discharge", key: "AnticipatedDischargeDate", type:"date",
        sortable: true, sortDown: false,
        format:this.dateFormat},
      {label:"Anticipated LOS/NOV", key: "AnticipatedDischargeDate_",
        sortable: false, sortDown: false,
        filter: false, format:this.lengthOfStay, style:{width:150}},
      {label:"Discharged", key: "DischargeDate", sortable: true, type:"date",
        format: this.dateFormat},
      {label:"Actual LOS/NOV", key: "DischargeDate_", sortable: false, filter: false,
        format: this.lengthOfStay, style:{width:140}},
      {label:"Navigator", key: "Surgery.Surgeon.Navigator.LastName", sortable: true,
        format: this.navigatorFormat, style:{width:160}},
      {label:"Facility", key: "Facility.Name", sortable: true}
    ];
  }

  navigatorFormat(date, row) {
    if(row.Surgery
      && row.Surgery.Surgeon
      && row.Surgery.Surgeon.Navigator){
      // return DataUtil.formatNameForUser(row.Surgery.Surgeon.Navigator);
      return DataUtil.formatNameForUserLastFirstOrder(row.Surgery.Surgeon.Navigator);
    }

    return '';
  }

  lengthOfStay(date, row){
    if(!date){
      return '';
    }

    let periodStart = moment(row.AdmissionDate);
    let periodEnd = moment(date);
    const days = periodEnd.diff(periodStart, 'days');

    if(days != 1){
      return `${days} days`;
    }else{
      return `${days} day`;
    }
  }

  dateFormat(date) {
    return DateUtil.formatFromDB(date);
  }

  render() {
    const {
      isPTUser,
      ...inputProps
    } = this.props;

    if(isPTUser){
      let index;
      for(let columnIndex in this.columns){
        if(this.columns[columnIndex].label === "Anticipated Discharge"){
          index = columnIndex
        }
      }
      index && this.columns.splice(index, 1);
    }

    return (
      <DynamicTable
        columns={this.columns}
        noDataMessage={"No Admissions Found"}
        {...inputProps}
      />
    );
  }
}
PACAdmissionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.object,
  data: PropTypes.object.isRequired,
  header: PropTypes.any,
  onQueryChange: PropTypes.func,
  onRowClick: PropTypes.func,
  isPTUser: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const { user } = state;

  const isPTUser = DataUtil.isPTUser(user);

  return { user, isPTUser };
}

const styled = withStyles(styles)(PACAdmissionsTable);
const connected = connect(mapStateToProps)(styled);
export {connected as PACAdmissionsTable};