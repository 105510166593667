import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { NumberCard } from 'components/NumberCard';
import { getAlertCount } from 'actions/alertCountActions';
import NavigationUtil from 'util/NavigationUtil';

const styles = ({
  card: {

  }
});

class AlertsCountView extends React.Component {

  componentDidMount() {
    this.updateCount();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {
      this.updateCount();
    }
  }

  updateCount() {
    const {
      dispatch,
      viewAsUser
    } = this.props;

    let query = {
      where: {
        NavigatorUserId: viewAsUser.id
      },
      offset: 0
    };

    if(viewAsUser.Role == 2 || viewAsUser.Role == 3 || viewAsUser.Role == 8){
      query.where.AlertRole = 'Nav';
    } else {
      if(query && query.where && query.where.AlertRole){
        delete query.where.AlertRole;
      }

      if(query && query.where && query.where.NavigatorUserId){
        delete query.where.NavigatorUserId;
      }
    }
    
    dispatch(getAlertCount(query));
  }

  render() {
    const {
      alertCount
    } = this.props;
    
    return (
      <NumberCard
        title="Alerts"
        number={alertCount}
        action="View"
        path={NavigationUtil.navLink("/alertsdashboard")}
      />
    );
  }
}

AlertsCountView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  alertCount: PropTypes.number.isRequired,
  viewAsUser: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const {
    user,
    alertCount
  } = state;

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }

  return {
    alertCount,
    viewAsUser
  };
}

const styled = withStyles(styles)(AlertsCountView);
const connected = connect(mapStateToProps)(styled);
export { connected as AlertsCountView };
