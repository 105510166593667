import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DateUtil } from 'util/DateUtil';

const styles = ({
});

function genderFormat(gender) {
  if (gender == "female") {
    return "F";
  } else if (gender == "male") {
    return "M";
  }
  return "";
}

function ageFormat(birthdate) {
  return moment().diff(birthdate, 'years');
}

//this controls which columns are displayed and how they are looked up in data
const columns = [
  {label:"MRN", key: "MedicalRecNum", sortable: true, sortDown: false, style:{width:120}},
  {label:"Source", key: "ExternalSource", sortable: true, sortDown: false, style:{width:120}},
  {label:"First", key: "FirstName", sortable: true, sortDown: false},
  {label:"Last", key: "LastName", sortable: true, sortDown: false},
  {label:"M/F", key: "Gender", filter: false, style:{width:60},
    format:genderFormat},
  // TODO fix reuse of key BirthDate
  // since both DOB and Age have the same key we need to keep DOB before Age to enabled the daterange functionality
  {label:"DOB", key: "BirthDate", type:"date", sortable: true, sortDown: false, style:{width:180},
    format:DateUtil.formatFromDB},
  {label:"Age", key: "BirthDate", sortable: true, sortDown: false,
    filter: false, style:{width:60}, format:ageFormat},
  {label:"City", key: "City", sortable: true, sortDown: false, style:{width:100}},
  {label:"State", key: "State", sortable: true, sortDown: false, style:{width:100}}
];

class PatientTable extends Component {

  render() {
    return <DynamicTable {...this.props} columns={columns} classes={this.props.classes}/>;
  }
}

PatientTable.propTypes = DynamicTable.propTypes;

const styled = withStyles(styles)(PatientTable);
export {styled as PatientTable};
