import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';
import DataUtil from 'util/DataUtil';
import { updateNavUser, getNavUser, listNavUsers } from 'actions/navUserActions'
import { listFacilities } from 'actions/facilityActions';
import moment from 'moment';
import DateUtil from 'util/DateUtil';

const schemaEdit = {
  title: 'User',
  type: 'object',
  required: ['FirstName','LastName','Email'],
  properties: {
    FirstName: {type: 'string', title: 'First Name', minLength: 1},
    LastName: {type: 'string', title: 'Last Name', minLength: 2},
    Username:  {type: 'string', title: 'Username'},
    Email: {type: 'string', title: 'Email', minLength: 2},
    'Active': {
      'type': 'boolean',
      'title': 'Active'
    },

    RoleName: {
      'title': 'RoleName',
      'type': 'string'
    }
  }
};

const uiSchema = {
  FirstName: {
    "ui:disabled" : true
  },
  LastName: {
    "ui:disabled" : true
  },
  Username: {
    "ui:disabled" : true
  },
  Email: {
    "ui:disabled" : true
  },
  RoleName:{
    "ui:disabled" : true
  }
};

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class UserEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.addExtraFormErrors = this.addExtraFormErrors.bind(this);
  }

  componentDidMount() {
    const {
      isNewUser,
      newUser,
      userId,
      dispatch
    } = this.props;

    if(!isNewUser && StoreUtil.needsLoadNoCache(newUser)){
      dispatch(getNavUser(userId));
    }
     
    this.fetchInitialUsers();
    dispatch(listFacilities());
  }

  fetchInitialUsers() {
    this.setState({query: this.buildInitialQuery() }, this.fetchUsers.bind(this));
  }

  buildInitialQuery() {
    let query = {
      where: { },
      limit: 0,
      offset: 0,
      order:[["LastName","ASC"]]
    };

    return query;
  }

  fetchUsers() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listNavUsers(false, query));
  }

  onFormSubmit(data){
    const {isNewUser, userId, isEditable, dispatch, user, facilities} = this.props;
    const formData = data.formData;

    formData.Username = formData.Email;

    // set the facility id to be updated
    let facilityId;
    facilities.data.forEach(data => {
      if(data.Name === formData.FacilityUpdate){
        facilityId = data.id;
      }
    })

    if(isEditable && facilityId){
      const updateFormData = {
        id: formData.id,
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        Email: formData.Email,
        Username:  formData.Username,
        Active: formData.Active,
        FacilityId: facilityId,
        UpdatedById: user.id
      }

      return dispatch(updateNavUser(updateFormData));
    }

    if(!isNewUser){
      formData.id = userId;
    } else {
      delete formData.id;
    }

    // set the facility id to be updated
    facilities.data.map(data => {
      if(data.Name === formData.FacilityUpdate){
        delete formData.Facility;
        formData.FacilityId = data.id;
      }
    })

    dispatch(updateNavUser(formData));
  }

  onFormError(){
    
  }

  addExtraFormErrors(formData, errors){
    const {
      navUsers,
      isEditable
    } = this.props;

    let users = StoreUtil.getData(navUsers).rows;

    if (!(String(formData.Email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
    ) {
      errors.push({
        message: "Not a valid email address",
        name: "required",
        params: {
          missingProperty:'Email'
        },
        property:"."+'Email',
        stack: "Email not valid"
      })
    }

    users.some((newUser)=>{
      if((newUser.Email === formData.Email) && !isEditable){
        errors.push({
          message:"already exists.",
          name:"required",
          params:{
            missingProperty:'Email'
          },
          property:"."+'Email',
          stack:".Email already exists."
        })

        return true;
      }
    });

    return errors;
  }

  onFormChange(){
  }

  render() {
    const {
      classes,
      isNewUser,
      newUser,
      facilities,
      isEditable
    } = this.props;

    let userData = StoreUtil.getData(newUser);
    let usedUISchema = DataUtil.deepCopy(uiSchema);

    if(isNewUser){
      usedUISchema.FirstName["ui:disabled"] = false;
      usedUISchema.LastName["ui:disabled"] = false;
      usedUISchema.Email["ui:disabled"] = false;

      if(!userData){
        userData = {
          Role: 4,
          Active: true,
          IsLdapUser: false
        }
      }
    } else if(isEditable){
      usedUISchema.FirstName["ui:disabled"] = false;
      usedUISchema.LastName["ui:disabled"] = false;
      usedUISchema.Email["ui:disabled"] = false;
    }
    
    if(userData && userData.Role !== null){
      if(userData.Role === 1){
        userData.RoleName = 'Admin';
      }
      else if(userData.Role === 2 || userData.Role === 8){
        userData.RoleName = 'NAV';
      }
      else if(userData.Role === 4){
        userData.RoleName = 'PAC';
      }
      else{
        userData.RoleName = 'Unknown';
      }
    }

    let schema = DataUtil.deepCopy(schemaEdit);

    if(userData && userData.updatedAt){
      schema.description=`Last updated: ${
        moment(userData.updatedAt).format(DateUtil.DISPLAY_FORMAT)
      }`;
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(newUser, 'user');

    // adjust the form data to include the facilty
    if(facilities.data){
      usedUISchema.FacilityUpdate = {"ui:disabled" : false};
      schema.properties.FacilityUpdate = {type: 'string', title: 'Facility'};
      schema.properties.FacilityUpdate.enum = facilities.data.map(data => data.Name);
      if(!isNewUser && userData && userData.Facility){
        // this sets the dropdown value to the currently assigned facilty
        userData.FacilityUpdate = userData.Facility.Name;
      }
    }

    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={schema}
          uiSchema={usedUISchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={userData}
          status={status}
          statusIsError={failure}
          extraTransformError={this.addExtraFormErrors}
        />
      </div>
    );
  }
}

UserEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  newUser: PropTypes.object.isRequired,
  navUsers: PropTypes.object.isRequired,
  isNewUser: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  facilities: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const {
    navUsers,
    facilities,
    user
  } = state;

  const { match } = existingProps;
  let userId;
  try{
    userId = parseInt(match.params.userId);
    if(isNaN(userId)){
      userId = 0;
    }
  }catch(error){
    userId = 0;
  }
  
  const isNewUser = userId == 0;
  let newUser = null;
  let isEditable = false;
  if(!isNewUser){
    newUser = StoreUtil.get(navUsers, StoreUtil.COMMON_ITEM, userId);
    isEditable = ((user.Role === 7) || (user.Role === 8));
  }else{
    newUser = StoreUtil.get(navUsers, StoreUtil.COMMON_NEW_ITEM);
  }

  return {
    newUser,
    navUsers: StoreUtil.get(navUsers, StoreUtil.COMMON_TABLE),
    isNewUser,
    userId,
    facilities: StoreUtil.get(facilities, StoreUtil.COMMON_LIST),
    isEditable,
    user
  }
}

const styledPage = withStyles(styles)(UserEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as UserEditPage};
