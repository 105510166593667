import { success } from 'redux-saga-requests';

import {
  API_GET_SURGERY_ADD_LINFO
} from 'actions/surgeryAddlInfoActions';

export const surgeryAddlInfo = (state = [], action) => {
  if(action.type == success(API_GET_SURGERY_ADD_LINFO)) {
    return action.data;
  }
  return state;
}