import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { NumberCard } from 'components/NumberCard';
import { taskCount } from 'actions/tasksActions';
import NavigationUtil from 'util/NavigationUtil'

const styles = ({
  card: {

  }
});

class TasksCountView extends React.Component {

  componentDidMount() {
    this.updateCount();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {
      this.updateCount();
    }
  }

  updateCount() {
    const {
      dispatch,
      viewAsUser
    } = this.props;

    let query = {
      where: {
        EpisodesNavigatorUserId: viewAsUser.id,
        $or: [
          { Automated: 0 },
          { Priority: 'High' }
        ]
      },
      order: [
        ['TasksScheduledFor','ASC'], ['Status', 'DESC']
      ],
      limit: 50,
      offset: 0
    };

    dispatch(taskCount(query));
  }

  render() {
    const {
      taskCount
    } = this.props;

    return (
      <NumberCard
        title="Today's Tasks"
        number={taskCount}
        action="View"
        path={NavigationUtil.navLink("/tasks/today")}
      />
    );
  }
}

TasksCountView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  taskCount: PropTypes.number.isRequired,
  viewAsUser: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const {
    tasks,
    user
  } = state;

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }

  return {
    taskCount: tasks.todaysCount.count,
    viewAsUser
  };
}

const styled = withStyles(styles)(TasksCountView);
const connected = connect(mapStateToProps)(styled);
export { connected as TasksCountView };
