import React from 'react'
import dotProp from 'dot-prop-immutable'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { MaterialForm } from 'components/forms/MaterialForm'
import { LoadingView } from 'components/LoadingView'
import {
  getSurgeryDashView,
  listSurgeries,
  updateSurgery,
  searchCopySurgery,
  getSurgery
} from 'actions/surgeryActions'
import { getPatientDetailByMRN } from 'actions/patientActions'
import SurgeryUtil from 'util/SurgeryUtil'
import StoreUtil from 'stores/StoreUtil'
import DataUtil from 'util/DataUtil'
import { ConfirmDialog } from 'components/dialog/Confirm'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import { Redirect } from 'react-router-dom'
import NavigationUtil from 'util/NavigationUtil'
import moment from 'moment'
import DateUtil from 'util/DateUtil'
import { updateAdmission } from 'actions/admissionActions'
import {
  updateSurgeryAddlInfo,
  getSurgeryAddlInfo
} from 'actions/surgeryAddlInfoActions'
import { getQuestionOptions } from 'actions/questionGroupActions'

const schemaEdit = {
  type: 'object',
  // required: ['ProcedureName'],
  properties: {
    SurgeryDateTime: {
      type: 'string',
      format: 'date',
      title: 'DOS'
    },
    VisitType: {
      type: 'string',
      title: 'Visit Type',
      enum: ['SXI', 'SX', 'SXO', 'SXH', 'SXM']
    },
    SurgeonId: {
      type: 'number',
      title: 'Surgeon'
    },
    HospitalId: {
      type: 'number',
      title: 'Hospital'
    },
    Active: {
      // !. FIX cannot select false
      type: 'boolean',
      title: 'Active'
    },
    ProcedureTypeId: { title: 'Procedure', type: 'number' },
    Bmi: {
      type: 'number',
      title: 'BMI'
    },
    ProcedureNote: {
      type: 'string',
      title: 'Procedure Note'
    },
    MedicalRiskLevel: {
      type: 'string',
      title: 'Medical Risk Level',
      enum: ['Low', 'Moderate', 'High']
    },
    SocialRiskLevel: {
      type: 'string',
      title: 'Social Risk Level',
      enum: ['Low', 'Moderate', 'High']
    },
    RiskLevelReason: {
      type: 'string',
      title: 'Reason for changing risk level',
      description: 'Add a reason if changing risk levels'
    },
    DischargeDate: {
      title: 'Discharge Date',
      type: 'string',
      format: 'date'
    },
    AdmissionsDate: {
      title: 'Admissions Date',
      type: 'string',
      format: 'date'
    },
    DischargeDispositionId: { title: 'Discharge Disposition', type: 'number' },
    DispositionNotes: {
      title: 'Discharge Notes',
      type: 'string'
    },
    DischargeFacilityId: { type: 'number', title: 'Post Acute Facility' },
    PostAcuteLengthOfStay: {
      title: 'Post Acute LOS/NOV',
      type: 'number'
    },
    HospitalLengthofStay: {
      title: 'Hospital Length of Stay',
      type: 'number'
    },
    SecondaryDischargeDispositionId: {
      title: 'Secondary Discharge Disposition',
      type: 'number'
    }
  }
}

const schemeUI = {
  SurgeryDateTime: {
    classNames: 'two-column-field'
  },
  VisitType: {
    classNames: 'two-column-field'
  },
  SurgeonId: {
    classNames: 'two-column-field'
  },
  HospitalId: {
    classNames: 'two-column-field'
  },
  Active: {
    'ui:widget': 'select',
    classNames: 'skinny-column-field'
  },
  ProcedureTypeId: {
    classNames: 'two-column-field'
  },
  Bmi: {
    classNames: 'two-column-field'
  },
  MedicalRiskLevel: {
    classNames: 'two-column-field'
  },
  SocialRiskLevel: {
    classNames: 'two-column-field'
  },
  DischargeDate: {
    classNames: 'two-column-field'
  },
  AdmissionsDate: {
    classNames: 'two-column-field'
  },
  DischargeDispositionId: {
    classNames: 'two-column-field'
  },
  DispositionNotes: {
    classNames: 'two-column-field'
  },
  DischargeFacilityId: {
    classNames: 'two-column-field'
  },
  PostAcuteLengthOfStay: {
    classNames: 'two-column-field'
  },
  HospitalLengthofStay: {
    classNames: 'two-column-field'
  },
  SecondaryDischargeDispositionId: {
    classNames: 'two-column-field'
  }
}

const styles = {
  pageContainer: {
    width: '100%',
    marginTop: 20
  },
  lowRisk: {
    borderRadius: 5,
    backgroundColor: '#34a853',
    color: '#ffffff',
    padding: 4,
    textAlign: 'center'
  },
  header: {},
  modRisk: {
    borderRadius: 5,
    backgroundColor: '#fbbc05',
    color: '#ffffff',
    padding: 4,
    textAlign: 'center'
  },
  highRisk: {
    borderRadius: 5,
    backgroundColor: '#ea4335',
    color: '#ffffff',
    padding: 4,
    textAlign: 'center'
  }
}

class SurgeryEditPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmOpen: false,
      confirmText: '',
      riskChanged: false,
      showSecondaryDischarge: false,
      mrnRequested: false
    }

    this.onConfirm = this.onConfirm.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onFormError = this.onFormError.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
    this.onCopyClick = this.onCopyClick.bind(this)
    this.onDataTransform = this.onDataTransform.bind(this)
    this.getSecondaryDischargeOptions = this.getSecondaryDischargeOptions.bind(
      this
    )
    this.listSurgeriesRequest = this.listSurgeriesRequest.bind(this)
  }

  componentDidMount () {
    const {
      surgeryId,
      surgery,
      dispatch,
      dischargeFacilities,
      dischargeDispositions,
      hospitals,
      surgeons,
      procedureTypes,
      isNewSurgery,
      // patient,
      mrn,
      externalSource
    } = this.props

    SurgeryUtil.checkAndLoadFacilities(dischargeFacilities, dispatch)
    SurgeryUtil.checkAndLoadDispositions(dischargeDispositions, dispatch)
    SurgeryUtil.checkAndLoadProcedureTypes(procedureTypes, dispatch)
    SurgeryUtil.checkAndLoadHospitals(hospitals, dispatch)
    SurgeryUtil.checkAndLoadSurgeons(surgeons, dispatch)

    if (StoreUtil.needsLoadNoCache(surgery) && !isNewSurgery) {
      dispatch(getSurgery(surgeryId))
    }

    // if ((!patient || StoreUtil.needsLoadNoCache(patient)) && isNewSurgery) {
    //TODO Needs second argument for ExternalSouce
    if(mrn && externalSource) {
      dispatch(getPatientDetailByMRN(mrn, externalSource))
    }
    // fetch the surgeryAddlInfo data for the current surgery
    dispatch(getSurgeryAddlInfo(surgeryId))

    if (!mrn) {
      /* if the mrn is undefined (ex: when reloading page) request the
         surgery data before making the listSurgeries request.
         this will give the application access to the mrn and will
         be execuated in the componentDidUpdate lifecycle method */
      dispatch(getSurgeryDashView(surgeryId))
    } else {
      this.listSurgeriesRequest(dispatch, mrn, surgeryId)
    }

    if ((surgery.data && surgery.data.DischargeDispositionId)) {
      if (
        surgery.data.DischargeDispositionId == 8 ||
        surgery.data.DischargeDispositionId == 9
      ) {
        this.setState({ showSecondaryDischarge: true })
      }
    }
    const ptDropdownListId = 1224
    dispatch(getQuestionOptions(ptDropdownListId))
  }

  componentDidUpdate (prevProps, prevState) {
    const { surgeryId, mrn, dispatch, surgery } = this.props
    if (!mrn && !this.state.mrnRequested) {
      // indicates that a call was made for surgery data (to get the mrn)
      this.setState({ mrnRequested: true })
    } else if (mrn && this.state.mrnRequested) {
      // reset the flag and dispatch the request with the mrn
      this.setState({ mrnRequested: false })
      this.listSurgeriesRequest(dispatch, mrn, surgeryId)
    }
    // check on DischargeDispositionId

    if (surgery.data !== null && prevProps.surgery.data == null) {
      if (
        surgery.data &&
        (surgery.data.DischargeDispositionId == 8 ||
          surgery.data.DischargeDispositionId == 9)
      ) {
        this.setState({ showSecondaryDischarge: true })
      }
    }
  }

  listSurgeriesRequest (dispatch, mrn, surgeryId) {
    dispatch(
      listSurgeries({
        where: {
          MRN: mrn,
          id: { $ne: surgeryId },
          DOS: {
            $and: {
              $gt: moment()
                .subtract(1, 'years')
                .format('MM/DD/YYYY')
            }
          }
        },
        limit: 25,
        offset: 0
      })
    )
  }

  onCopyClick () {
    const { surgery, dispatch } = this.props

    if (!surgery) {
      return
    }

    const surgeryDetail = StoreUtil.getData(surgery)

    dispatch(
      searchCopySurgery(surgeryDetail.id, surgeryDetail.Patient.MedicalRecNum)
    )
  }

  getSecondaryDischargeOptions (
    hasSurgeryInfo,
    hasSelectedASecondaryDischargeDisposition,
    formData
  ) {
    let options = false

    if (hasSelectedASecondaryDischargeDisposition) {
      options = {
        // this query will add to the table
        SurgeryId: formData.id,
        SecondaryPostAcuteDispositionId:
          formData.SecondaryDischargeDispositionId,
        UpdatedById: this.props.user.id
      }
      // this query will update the table instead because we are including the id
      if (hasSurgeryInfo) options.id = this.props.surgeryAddlInfo[0].id

      const irfID = 8 // IRF discharge disposition id
      const snfID = 9 // SNF discharge disposition id
      // id conditions to show the Secondary Discharge Disposition field
      const secondaryDischargeDispositionIdTriggers = [irfID, snfID]
      // delete secondary discharge disposition if discharge desposition is changed to something to doesn't apply
      if (
        !secondaryDischargeDispositionIdTriggers.includes(
          formData.DischargeDispositionId
        )
      )
        options.SecondaryPostAcuteDispositionId = 0
    } else if (hasSurgeryInfo) {
      options = {
        // remove the secondary discharge facility by setting to 0 (false)
        id: this.props.surgeryAddlInfo[0].id,
        SurgeryId: formData.id,
        SecondaryPostAcuteDispositionId: 0,
        UpdatedById: this.props.user.id
      }
    }
    return options
  }

  onFormSubmit (data) {
    const { surgery, isNewSurgery, patient, externalSource } = this.props
    const surgeryData = surgery.data
    const { dispatch, procedureTypes} = this.props
    const formData = data.formData
    formData.ExternalSource = externalSource

    const los = moment(formData.DischargeDate).diff(
      formData.SurgeryDateTime,
      'days'
    )
    formData.HospitalLengthofStay = los

    if (
      !isNewSurgery &&
      (surgeryData.MedicalRiskLevel != formData.MedicalRiskLevel ||
        surgeryData.SocialRiskLevel != formData.SocialRiskLevel)
    ) {
      this.setState({ confirmOpen: true, formData: formData })
    } else {
      if (isNewSurgery) {
        let patientData = StoreUtil.getData(patient)
        formData.PatientId = patientData.id
        formData.ExternalSource = patientData.ExternalSource
        formData.NavigationEnabled = true
        formData.VisitStatus = 'pending'
        const selectedType = procedureTypes.data.find(procedure=>procedure.id==formData.ProcedureTypeId)
        const procedureName=selectedType.ProcedureType
        formData.ProcedureName = procedureName
      }

      if (formData.ProcedureTypeId == 0) {
        delete formData.ProcedureTypeId
      }
      const hasAdmissionFacility = Boolean(
        formData.Admissions && formData.Admissions.length
      )
      if (hasAdmissionFacility) {
        if (formData.AdmissionsDate) {
          let Admissions
          formData.Admissions.forEach(data => {
            if (data.SurgeryId === formData.id) {
              Admissions = data
            }
          })
          Admissions.AdmissionDate = formData.AdmissionsDate
          dispatch(updateAdmission(Admissions))
        }
      }
      delete formData.AdmissionsDate

      const hasSurgeryInfo = this.props.surgeryAddlInfo.length
      const hasSelectedASecondaryDischargeDisposition =
        formData.SecondaryDischargeDispositionId
      const secondaryDischargeOptions = this.getSecondaryDischargeOptions(
        hasSurgeryInfo,
        hasSelectedASecondaryDischargeDisposition,
        formData
      )

      if (secondaryDischargeOptions) {
        dispatch(updateSurgeryAddlInfo(secondaryDischargeOptions))
      }
   

      // delete unused field is the surgery update
      delete formData.SecondaryDischargeDispositionId

      dispatch(updateSurgery(formData, true))
    }
  }

  onConfirm () {
    const { surgery } = this.props
    const surgeryData = surgery.data

    this.setState({ confirmOpen: false })
    const { dispatch } = this.props
    const formData = this.state.formData

    if (
      surgeryData.MedicalRiskLevel != formData.MedicalRiskLevel ||
      surgeryData.SocialRiskLevel != formData.SocialRiskLevel
    ) {
      formData.RiskLevelManuallyUpdated = true
    }

    if (formData.ProcedureTypeId == 0) {
      delete formData.ProcedureTypeId
    }

    dispatch(updateSurgery(formData, true))
  }

  onCancel () {
    this.setState({ confirmOpen: false })
  }

  onFormError () {}

  onFormChange (form) {
    const { surgery, classes, isNewSurgery } = this.props
    const surgeryData = surgery.data
    const formData = form.formData

    const irfID = 8 // IRF discharge disposition id
    const snfID = 9 // SNF discharge disposition id
    // id conditions to show the Secondary Discharge Disposition field
    const secondaryDischargeDispositionIdTriggers = [irfID, snfID]
    this.setState({
      showSecondaryDischarge: secondaryDischargeDispositionIdTriggers.includes(
        formData.DischargeDispositionId
      )
    })

    if (!isNewSurgery) {
      if (
        surgeryData.MedicalRiskLevel != formData.MedicalRiskLevel ||
        surgeryData.SocialRiskLevel != formData.SocialRiskLevel ||
        (surgeryData.RiskLevelReason != '' && formData.RiskLevelReason == '')
      ) {
        if (!form.schema.required.includes('RiskLevelReason')) {
          form.schema.required.push('RiskLevelReason')
        }

        let medicalRiskChange = ''
        let medicalRiskFrom
        let medicalRiskTo
        let socialRiskChange = ''
        let socialRiskFrom
        let socialRiskTo

        if (surgeryData.MedicalRiskLevel != formData.MedicalRiskLevel) {
          if (surgeryData.MedicalRiskLevel == 'Low') {
            medicalRiskFrom = <span className={classes.lowRisk}>Low</span>
          } else if (surgeryData.MedicalRiskLevel == 'Moderate') {
            medicalRiskFrom = <span className={classes.modRisk}>Moderate</span>
          } else if (surgeryData.MedicalRiskLevel == 'High') {
            medicalRiskFrom = <span className={classes.highRisk}>High</span>
          }

          if (formData.MedicalRiskLevel == 'Low') {
            medicalRiskTo = <span className={classes.lowRisk}>Low</span>
          } else if (formData.MedicalRiskLevel == 'Moderate') {
            medicalRiskTo = <span className={classes.modRisk}>Moderate</span>
          } else if (formData.MedicalRiskLevel == 'High') {
            medicalRiskTo = <span className={classes.highRisk}>High</span>
          }

          medicalRiskChange = (
            <React.Fragment>
              <br />
              <br />
              <b>Medical Risk</b> changed from {medicalRiskFrom} to{' '}
              {medicalRiskTo}
            </React.Fragment>
          )
        }

        if (surgeryData.SocialRiskLevel != formData.SocialRiskLevel) {
          if (surgeryData.SocialRiskLevel == 'Low') {
            socialRiskFrom = <span className={classes.lowRisk}>Low</span>
          } else if (surgeryData.SocialRiskLevel == 'Moderate') {
            socialRiskFrom = <span className={classes.modRisk}>Moderate</span>
          } else if (surgeryData.SocialRiskLevel == 'High') {
            socialRiskFrom = <span className={classes.highRisk}>High</span>
          }

          if (formData.SocialRiskLevel == 'Low') {
            socialRiskTo = <span className={classes.lowRisk}>Low</span>
          } else if (formData.SocialRiskLevel == 'Moderate') {
            socialRiskTo = <span className={classes.modRisk}>Moderate</span>
          } else if (formData.SocialRiskLevel == 'High') {
            socialRiskTo = <span className={classes.highRisk}>High</span>
          }

          socialRiskChange = (
            <React.Fragment>
              <br />
              <br />
              <b>Social Risk</b> changed from {socialRiskFrom} to {socialRiskTo}
            </React.Fragment>
          )
        }

        let confirmText = (
          <React.Fragment>
            Surgery It appears that you have changed the risk level. Do you wish
            to proceed with this change?
            {medicalRiskChange}
            {socialRiskChange}
          </React.Fragment>
        )

        this.setState({ confirmText: confirmText })
      } else {
        if (!surgeryData.RiskLevelManuallyUpdated) {
          form.formData.RiskLevelManuallyUpdated = false
        }
        if(form.schema.required){
          DataUtil.remove(form.schema.required, 'RiskLevelReason') 
        }
      }
    }
  }

  onDataTransform (formData) {
    const { surgery, isNewSurgery } = this.props
    const surgeryData = StoreUtil.getData(surgery)

    if (!isNewSurgery) {
      if (
        (surgeryData.MedicalRiskLevel != formData.MedicalRiskLevel ||
          surgeryData.SocialRiskLevel != formData.SocialRiskLevel) &&
        surgeryData.RiskLevelReason == formData.RiskLevelReason
      ) {
        formData = dotProp.set(formData, 'RiskLevelReason', '')
      }
      // removed to prevent reason from being reset after erased
      // else if (((surgeryData.MedicalRiskLevel == formData.MedicalRiskLevel)
      // && (surgeryData.SocialRiskLevel == formData.SocialRiskLevel))
      // && formData.RiskLevelReason == ''
      // ){
      //   formData = dotProp.set(formData, "RiskLevelReason", surgeryData.RiskLevelReason);
      // }

      if (
        surgeryData.DischargeDate != formData.DischargeDate &&
        formData.AdmissionsDate
      ) {
        let los = moment(formData.DischargeDate).diff(
          moment(formData.AdmissionsDate),
          'days'
        )
        formData = dotProp.set(formData, 'HospitalLengthofStay', los)
      }
    }

    return formData
  }

  // setShowSecondaryDischarge

  render () {
    const {
      classes,
      surgeries,
      surgery,
      dischargeFacilities,
      dischargeDispositions,
      procedureTypes,
      isNewSurgery,
      hospitals,
      surgeons,
      user,
      surgeryAddlInfo,
      questionOptions,
      isPTSurgery
    } = this.props

    // if (surgery && this.state.showSecondaryDischarge === null && surgery.data) {
    //   this.setShowSecondaryDischarge = setTimeout(() => {
    //     const secondaryDischargeDispositionIdTriggers = [8, 9]
    //     this.setState({
    //       showSecondaryDischarge: secondaryDischargeDispositionIdTriggers.includes(
    //         surgery.data.DischargeDispositionId
    //       )
    //     })
    //   }, 200)
    // }

    let schema = { ...schemaEdit }

    // if (!isNewSurgery) {
    //   schema = schemaEdit
    // } else {
    //   schema = DataUtil.deepCopy(schemaEdit)
    // }

    //save happened, lets redirect
    if (isNewSurgery && StoreUtil.didJustSave(surgery)) {
      return (
        <Redirect
          to={NavigationUtil.userLink(
            user,
            '/surgery/' + StoreUtil.getData(surgery).id
          )}
        />
      )
    }

    if (
      !isNewSurgery &&
      (!surgery ||
        !StoreUtil.isLoadedOrFailed(dischargeFacilities) ||
        !StoreUtil.isLoadedOrFailed(dischargeDispositions) ||
        !StoreUtil.isLoadedOrFailed(procedureTypes))
    ) {
      //wait for load
      return <LoadingView />
    }

    if (!isNewSurgery) {
      delete schema.properties.SurgeryDateTime
      delete schema.properties.VisitType
      delete schema.properties.SurgeonId
      delete schema.properties.HospitalId
    } else {
      schema.required = [
        'Active',
        'ProcedureTypeId',
        'SurgeryDateTime',
        'VisitType',
        'SurgeonId',
        'HospitalId'
      ]

      //Add schema for hospitals
      if (StoreUtil.isLoaded(hospitals)) {
        let hospitalNames = []
        let hospitalIds = []
        const hospitalsData = StoreUtil.getData(hospitals)

        hospitalsData.forEach(hospital => {
          hospitalNames.push(hospital.Name)
          hospitalIds.push(hospital.id)
        })
        if (schema.properties.HospitalId) {
          schema.properties.HospitalId.enumNames = hospitalNames
          schema.properties.HospitalId.enum = hospitalIds
        }
      }

      //Add schema for Surgeons
      if (StoreUtil.isLoaded(surgeons)) {
        let surgeonNames = []
        let surgeonIds = []
        const surgeonData = StoreUtil.getData(surgeons)

        surgeonData.forEach(surgeon => {
          surgeonNames.push(surgeon.Name)
          surgeonIds.push(surgeon.id)
        })
        if (schema.properties.SurgeonId) {
          schema.properties.SurgeonId.enumNames = surgeonNames
          schema.properties.SurgeonId.enum = surgeonIds
        }
      }
    }

    let status
    let failure = false
    if (StoreUtil.isSaveFailed(surgery)) {
      status = 'There was an issue saving the surgery.  Please try again later.'
      failure = true
    } else if (StoreUtil.isLoadFailed(surgery)) {
      status =
        'There was an issue retrieving the surgery.  Please try again later.'
      failure = true
    } else if (StoreUtil.isSaved(surgery)) {
      status = 'Saved Successfully'
    } else if (StoreUtil.isSaving(surgery)) {
      status = 'Saving...'
    }

    let surgeryDetail = StoreUtil.getData(surgery)

    let updated = false

    if (surgeryDetail) {
      if (surgeryDetail.Tasks)
        surgeryDetail.Tasks.forEach(function (task) {
          if (task.UpdatedById != 0) {
            updated = true
          }
        })

      if (!updated && surgeryDetail.SurgeryPertinentPositives) {
        surgeryDetail.SurgeryPertinentPositives.forEach(function (spp) {
          if (spp.UpdatedById != 0) {
            updated = true
          }
        })
      }

      if (!updated && surgeryDetail.User && surgeryDetail.User.id != 0) {
        updated = true
      }

      if (
        !updated &&
        surgeryDetail.Answers &&
        surgeryDetail.Answers.length > 0
      ) {
        updated = true
      }
    } else {
      surgeryDetail = {}
    }

    let copyButton = (
      <p
        style={{
          fontSize: '1rem'
        }}
      >
        Episode modified. Copy button disabled.
      </p>
    )

    if (!updated && !isNewSurgery) {
      copyButton = (
        <Tooltip title='Click to copy from a previous surgery'>
          <Button
            className='btn'
            color='primary'
            margin='normal'
            variant='raised'
            size='large'
            onClick={this.onCopyClick}
          >
            Copy Previous
          </Button>
        </Tooltip>
      )
    }

    if (
      surgeries &&
      surgeries.table &&
      surgeries.table.data &&
      surgeries.table.data.rows.length === 0
    ) {
      copyButton = (
        <p
          style={{
            fontSize: '1rem'
          }}
        >
          No previous surgeries. Copy button disabled.
        </p>
      )
    }

    //Add schema for facilities
    if (StoreUtil.isLoaded(dischargeFacilities)) {
      let dischargeFacilityNames = []
      let dischargeFacilityIds = []
      const dischargeFacilitiesData = StoreUtil.getData(dischargeFacilities)
      const iterator =
        isPTSurgery && questionOptions.length
          ? questionOptions
          : dischargeFacilitiesData
      const DisplayKey =
        isPTSurgery && questionOptions.length ? 'Value' : 'Name'

      iterator.forEach(dischargeFacility => {
        dischargeFacilityNames.push(dischargeFacility[DisplayKey])
        dischargeFacilityIds.push(dischargeFacility.id)
      })

      schema.properties.DischargeFacilityId.enumNames = dischargeFacilityNames
      schema.properties.DischargeFacilityId.enum = dischargeFacilityIds
    }

    //Add schema for dispositions
    if (StoreUtil.isLoaded(dischargeDispositions)) {
      let dischargeDispositionNames = []
      let dischargeDispositionIds = []
      const dischargeDispositionsData = StoreUtil.getData(dischargeDispositions)

      dischargeDispositionsData.forEach(dischargeDisposition => {
        dischargeDispositionNames.push(dischargeDisposition.Text)
        dischargeDispositionIds.push(dischargeDisposition.id)
      })

      schema.properties.DischargeDispositionId.enumNames = dischargeDispositionNames
      schema.properties.DischargeDispositionId.enum = dischargeDispositionIds

      schema.properties.SecondaryDischargeDispositionId.enumNames = dischargeDispositionNames
      schema.properties.SecondaryDischargeDispositionId.enum = dischargeDispositionIds
    }

    //Add schema for procedure types
    if (StoreUtil.isLoaded(procedureTypes)) {
      let procedureTypeNames = []
      let procedureTypeIds = []
      const procedureTypeData = StoreUtil.getData(procedureTypes)

      if (surgeryDetail && !surgeryDetail.ProcedureTypeId && !isNewSurgery) {
        procedureTypeNames.push(surgeryDetail.ProcedureName)
        procedureTypeIds.push(0)
        surgeryDetail.ProcedureTypeId = 0
      }

      procedureTypeData.forEach(procedureType => {
        procedureTypeNames.push(procedureType.ProcedureType)
        procedureTypeIds.push(procedureType.id)
      })

      schema.properties.ProcedureTypeId.enumNames = procedureTypeNames
      schema.properties.ProcedureTypeId.enum = procedureTypeIds
    }

    let usedSchemeUI = DataUtil.deepCopy(schemeUI)

    if (!isNewSurgery) {
      let dischargeMin = moment(surgeryDetail.SurgeryDateTime).format(
        DateUtil.FORM_DATE_FORMAT
      )
      usedSchemeUI = dotProp.set(
        usedSchemeUI,
        'DischargeDate.ui:options.linked',
        dischargeMin
      )
    }

    let title = 'Surgery'

    schema.title = (
      <div className={classes.header}>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='flex-start'
        >
          <Typography className={classes.headerPageTitle} variant='display1'>
            {title}
          </Typography>
          {copyButton}
        </Grid>
      </div>
    )

    let AdmissionsDate = {}
    const hasAdmissionFacility = Boolean(
      surgeryDetail.Admissions && surgeryDetail.Admissions.length
    )
    const isTraumaCase = surgeryDetail.VisitType === 'SXT'
    const properties = JSON.parse(JSON.stringify(schema.properties)) // breaks reference and copies
    if (hasAdmissionFacility && isTraumaCase) {
      AdmissionsDate = {
        AdmissionsDate: surgeryDetail.Admissions[0].AdmissionDate
      }
    } else {
      delete properties.AdmissionsDate
    }

    // add secondary discharge facility to formdata if one exists in the database for this surgery
    let secondaryDischargeOptions = {}
    if (
      surgeryAddlInfo &&
      surgeryAddlInfo.length &&
      surgeryAddlInfo[0].SecondaryPostAcuteDispositionId
    ) {
      secondaryDischargeOptions.SecondaryDischargeDispositionId =
        surgeryAddlInfo[0].SecondaryPostAcuteDispositionId
    }

    if (surgeryDetail.DischargeDate){
      surgeryDetail.DischargeDate =  moment(surgeryDetail.DischargeDate).format(DateUtil.FORM_DATE_FORMAT)
    }
    const formData = {
      ...surgeryDetail,
      ...AdmissionsDate,
      ...secondaryDischargeOptions
    }
    const usedScheme = { ...schema, properties: { ...properties } }

    if (!this.state.showSecondaryDischarge) {
      delete usedScheme.properties.SecondaryDischargeDispositionId
      delete usedSchemeUI.SecondaryDischargeDispositionId
    }

    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={usedScheme}
          uiSchema={usedSchemeUI}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          onDataTransform={this.onDataTransform}
          formData={formData}
          allowSubmit={!StoreUtil.isSaving(surgery)}
          status={status}
          statusIsError={failure}
        />
        <ConfirmDialog
          title={'Confirm Risk Level Change'}
          contentText={this.state.confirmText}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          isOpen={this.state.confirmOpen}
          cancelText={'No'}
          confirmText={'Yes'}
        />
      </div>
    )
  }
}

SurgeryEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeries: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  surgeryId: PropTypes.number.isRequired,
  dischargeFacilities: PropTypes.object,
  dischargeDispositions: PropTypes.object,
  procedureTypes: PropTypes.object,
  isNewSurgery: PropTypes.bool,
  mrn: PropTypes.number,
  patient: PropTypes.object,
  surgeons: PropTypes.object,
  hospitals: PropTypes.object,
  user: PropTypes.object,
  surgeryAddlInfo: PropTypes.array,
  isPTSurgery: PropTypes.bool,
  questionOptions: PropTypes.any,
  externalSource: PropTypes.string
}

function mapStateToProps (state, existingProps) {
  const {
    surgeries,
    dischargeFacilities,
    dischargeDispositions,
    procedureTypes,
    patients,
    hospitals,
    surgeons,
    user,
    surgeryAddlInfo,
    questionOptions
  } = state

  const { match } = existingProps
  let surgeryId
  try {
    surgeryId = parseInt(match.params.surgeryId)
    if (isNaN(surgeryId)) {
      surgeryId = 0
    }
  } catch (error) {
    console.log(error)
    surgeryId = 0
  }

  const isNewSurgery = surgeryId == 0
  let surgery = null
  let patient
  let mrn
  let externalSource

  if (!isNewSurgery) {
    surgery = StoreUtil.get(surgeries, StoreUtil.COMMON_ITEM, surgeryId)
  } else {
    surgery = StoreUtil.get(surgeries, StoreUtil.COMMON_NEW_ITEM)
    mrn = parseInt(match.params.mrn)
    patient = StoreUtil.get(patients, StoreUtil.COMMON_ITEM, mrn)
  }

  if (
    surgeries &&
    surgeries.dashview &&
    surgeryId &&
    surgeries.dashview[surgeryId] &&
    !mrn &&
    surgeries.dashview[surgeryId].data
  ) {
    mrn = surgeries.dashview[surgeryId].data.MRN
    externalSource = surgeries.dashview[surgeryId].data.ExternalSource
  }

  const isPTSurgery = surgery && surgery.data && surgery.data.VisitType === 'PT'

  return {
    surgeries,
    surgery,
    surgeryId,
    isNewSurgery,
    mrn,
    externalSource,
    patient,
    user,
    surgeryAddlInfo,
    hospitals: StoreUtil.get(hospitals, StoreUtil.COMMON_LIST),
    surgeons: StoreUtil.get(surgeons, StoreUtil.COMMON_LIST),
    dischargeFacilities: StoreUtil.get(
      dischargeFacilities,
      StoreUtil.COMMON_LIST
    ),
    dischargeDispositions: StoreUtil.get(
      dischargeDispositions,
      StoreUtil.COMMON_LIST
    ),
    procedureTypes: StoreUtil.get(procedureTypes, StoreUtil.COMMON_LIST),
    isPTSurgery,
    questionOptions
  }
}

const styledPage = withStyles(styles)(SurgeryEditPage)
const connectedPage = connect(mapStateToProps)(styledPage)
export { connectedPage as SurgeryEditPage }
