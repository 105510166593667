
export const API_POSITIVES_FOR_SURGERY = 'API_POSITIVES_FOR_SURGERY';
export const API_POSITIVES_FOR_SURGERY_DETAILED = 'API_POSITIVES_FOR_SURGERY_DETAILED';
export const API_PERTINENT_POSITIVES = 'API_PERTINENT_POSITIVES';
export const API_UPDATE_PERTINENT_POSITIVES = 'API_UPDATE_PERTINENT_POSITIVES';

export const getPertinentPositives = ()=> {
  const options = {
    include: [
      {
        association:'PertinentPositiveValues',
        required:false
      }
    ],
    order: [
      ['Name', 'ASC'],
      ['PertinentPositiveValues', 'Value', 'ASC']
    ]
  }

  return {
    type: API_PERTINENT_POSITIVES,
    request: {
      url: '/PertinentPositives',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const getPertinentPositivesForSurgeryDetailed = (surgeryId)=> {
  const options = {
    where: {SurgeryId:surgeryId },
    include: [
      {
        association:'PertinentPositive',
        required:false,
        include:
        [
          {
            association:'QuestionGroup',
            required:false,
            include:[
              {
                association:'Questions',
                include:[
                  {
                    association:'QuestionOptions',
                    required:false
                  },
                  {
                    association:'Answers',
                    include:[
                      {
                        association:'AnswerValues',
                        required:false
                      }
                    ],
                    where: {SurgeryId: surgeryId},
                    required:false
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        association:'PertinentPositiveValue',
        required:false
      }
    ],
    order: [
      ['PertinentPositive', 'Name', 'ASC']
    ]
  }
  return {
    type: API_POSITIVES_FOR_SURGERY_DETAILED,
    id: surgeryId,
    request: {
      url: '/SurgeryPertinentPositives',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const getPertinentPositivesForSurgery = (surgeryId)=> {
  const options = {
    where: {SurgeryId:surgeryId },
    attributes:['id', 'SurgeryId', 'PertinentPositiveId','PertinentPositiveValueId']
  }
  return {
    type: API_POSITIVES_FOR_SURGERY,
    id: surgeryId,
    request: {
      url: '/SurgeryPertinentPositives',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updatePertinentPositivesForSurgery = (surgeryId, 
  positivesAdd, positivesDelete, positivesUpdate, backOnSuccess = true)=> {
  
  let action = {
    type: API_UPDATE_PERTINENT_POSITIVES,
    id: surgeryId,
    request:[],
    backOnSuccess
  }
  if(positivesAdd && positivesAdd.length > 0){
    action.request.push(
      {
        url: '/SurgeryPertinentPositives',
        method: 'PUT',
        data:positivesAdd
      }
    );
  }
  if(positivesUpdate && positivesUpdate.length > 0){
    action.request.push(
      {
        url: '/SurgeryPertinentPositives',
        method: 'PUT',
        data:positivesUpdate
      }
    );
  }

  if(positivesDelete && positivesDelete.length > 0){
    let deleteIds = [];
    positivesDelete.forEach((pp)=>{
      deleteIds.push(pp.id);
    });
    action.request.push(
      {
        url: '/SurgeryPertinentPositives',
        method: 'DELETE',
        data:deleteIds
      }
    );
  }
  
  return action;
};