export const API_GET_SURGICALTEAMS_LIST = 'API_GET_SURGICALTEAMS_LIST';

export const listSurgicalTeams = ()=> {
  return {
    type: API_GET_SURGICALTEAMS_LIST,
    request: {
      url: '/SurgicalTeams',
      method: 'GET'
    }
  }
};
