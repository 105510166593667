import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import { surveyPatient } from 'actions/patientActions';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

const schemaEdit = {
  type: 'object',
  title: '90 Day Post Op Survey',
  required: ['PostAcutePatientResponse'],
  properties: {
    PostAcutePatientResponse: {
      type: 'string',
      title: 'Since your surgery, have you had a visit to any of the following:',
      enum: [
        'Primary Care Physician',
        'Urgent Care/Walk-in Clinic',
        'Emergency Room',
        'Admission to the Hospital',
        'No Visit'
      ]
    }  
  }
};

const styles = ({
  pageContainer:{
    width: '90%',
    marginTop: 20
  },
  formContainer:{
    width: '100%',
    marginTop: 20
  }
});

class PatientFormPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
  }

  componentDidMount(){
    
  }

  onFormSubmit(data){
    const { surgeryId } = this.props;
    const {dispatch} = this.props;

    this.setState({ submitted: true });
    
    dispatch(surveyPatient(surgeryId, data.formData.PostAcutePatientResponse));
  }

  onFormError(){

  }
    
  render() {
    const {
      classes,
      surgeryId
    } = this.props;
    
    let page;

    if(this.state.submitted || surgeryId == 0){
      page = <React.Fragment>
        <Card className={classes.formContainer}>
          <CardHeader title="Thanks!" />	
          <CardContent>
            <Typography
              variant='body1'>
              Your survery answer has been recorded. Thank you for your time.
            </Typography>
          </CardContent>       
        </Card>
      </React.Fragment>
    } else {
      page = <MaterialForm
        schema={schemaEdit}
        onChange={this.onFormChange}
        onSubmit={this.onFormSubmit}
        onError={this.onFormError}
        onDataTransform={this.onDataTransform}
        status={status}
        showCancel={false}
      />
    }

    return (
      <div className={classes.pageContainer}>
        {page}
      </div>
    );
  }
}

PatientFormPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeryId : PropTypes.number.isRequired
};

function mapStateToProps(state, existingProps) {
  const { match } = existingProps;
  
  let surgeryId;

  try{
    surgeryId = parseInt(match.params.surgeryId);
    if(isNaN(surgeryId)){
      surgeryId = 0;
    }
  }catch(error){
    console.log(error);
    surgeryId = 0;
  }
  
  return {
    surgeryId
  }
}

const styledPage = withStyles(styles)(PatientFormPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PatientFormPage};
