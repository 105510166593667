import { success, error, abort } from 'redux-saga-requests';
import ReducerUtil from 'reducers/ReducerUtil'
import StoreUtil from 'stores/StoreUtil'
import DataUtil from 'util/DataUtil'
import dotProp from 'dot-prop-immutable';

import {
  API_MESSAGE_CONVERSATIONS,
  API_MESSAGE_LIST,
  API_MESSAGE_REPLY,
  API_MESSAGE_CONVERSATION,
  API_MESSAGE_RESOLVE,
  API_MESSAGE_CONVERSATIONS_BY_ADMISSION,
  API_MESSAGE_CREATE,
  API_GET_PLAN_CHANGE_REASONS,
  API_OPEN_MESSAGE_COUNT,
  API_MESSAGE_READ,
  API_MESSAGE_CONVERSATIONS_BY_SURGERY,
  API_GET_PLAN_CHANGE,
  API_UPDATE_PLAN_CHANGE
} from 'actions/messageActions';

export const messages = (state = {}, action) => {

  const planChangeGet = ReducerUtil.reduceSingleGet(state, action, API_GET_PLAN_CHANGE, "id", "planChange");
  if(planChangeGet.actionConsumed){
    return planChangeGet.state;
  }

  const planChangeUpdate = ReducerUtil.reduceUpdate(state, action, API_UPDATE_PLAN_CHANGE, 'id', false, "planChange");
  if(planChangeUpdate.actionConsumed) {
    return planChangeUpdate.state;
  }

  const conversations = ReducerUtil.reduceTableGet(state, action, API_MESSAGE_CONVERSATIONS, "conversations");
  if(conversations.actionConsumed){
    return conversations.state;
  }

  const surgeryConservations = ReducerUtil.reduceTableGet(state, action, 
    API_MESSAGE_CONVERSATIONS_BY_SURGERY, "surgeryConversations");
  if(surgeryConservations.actionConsumed){
    return surgeryConservations.state;
  }

  const messageList = ReducerUtil.reduceTableGet(state, action, API_MESSAGE_LIST, StoreUtil.COMMON_LIST);
  if(messageList.actionConsumed) {
    return messageList.state;
  }

  const reasonsList = ReducerUtil.reduceTableGet(state, action, API_GET_PLAN_CHANGE_REASONS, "planChangeReasons");
  if(reasonsList.actionConsumed) {
    return reasonsList.state;
  }

  const read = ReducerUtil.reduceUpdate(state, action, API_MESSAGE_READ);
  if(read.actionConsumed) {
    //we want to update the conversation to show user read it
    if(action.type == success(API_MESSAGE_READ)){
      let messagesListWrapper = StoreUtil.get(read.state, StoreUtil.COMMON_LIST);
      let messagesList = StoreUtil.getData(messagesListWrapper);
      if(messagesList && messagesList.rows){
        let message;
        let idx;
        for(let x=0;x<messagesList.rows.length;x++){
          if(messagesList.rows[x].id == action.meta.requestAction.id){
            message=messagesList.rows[x];
            idx=x;
          }

        }
        if(message){
          message.IsRead = true;
          message.ReadBy = action.meta.requestAction.user;
          message.ReadById = action.meta.requestAction.user.id;
          //touch it to change object
          message = Object.assign({}, message);
          let result = dotProp.set(read.state, `${StoreUtil.COMMON_LIST}.data.rows.${idx}`, message);
          result = StoreUtil.setSaved(messagesList, result, StoreUtil.COMMON_LIST);
          return result;
        }
      }
    }

    return read.state;
  }

  const reply = ReducerUtil.reduceUpdate(state, action, API_MESSAGE_REPLY, 'id', true);
  if(reply.actionConsumed) {
    return reply.state;
  }

  const create = ReducerUtil.reduceUpdate(state, action, API_MESSAGE_CREATE);
  if(create.actionConsumed) {
    return create.state;
  }

  const conversation = ReducerUtil.reduceSingleGet(state, action, API_MESSAGE_CONVERSATION, "id", "conversation");
  if(conversation.actionConsumed) {
    return conversation.state;
  }

  const messageCounts = ReducerUtil.reduceSingleGet(state,
    action,
    API_OPEN_MESSAGE_COUNT,
    "id",
    "counts",
    false,
    (action) =>{
      //add up the counts
      let conversations = action.data.rows;
      let planOpenCount = 0;
      let planUnreadCount = 0;
      let otherOpenCount = 0;
      let otherUnreadCount = 0;

      if(conversations && Array.isArray(conversations)){
        conversations.forEach((conversation)=>{
          if(conversation.ConversationType == DataUtil.CONVERSATION_TYPE_PLAN_CHANGE){
            planOpenCount++;
            if(conversation.IsRead === false){
              planUnreadCount++;
            }
          }else{
            otherOpenCount++;
            if(conversation.IsRead === false){
              otherUnreadCount++;
            }
          }
        });
      }

      let result = {
        id:action.meta.requestAction.id,
        planOpenCount,
        planUnreadCount,
        otherOpenCount,
        otherUnreadCount
      }

      return result;
    }
  );
  if(messageCounts.actionConsumed) {
    return messageCounts.state;
  }

  const admissionConvs = ReducerUtil.reduceSingleGet(state,
    action,
    API_MESSAGE_CONVERSATIONS_BY_ADMISSION,
    "id",
    "admission",
    true);
  if(admissionConvs.actionConsumed) {
    return admissionConvs.state;
  }


  switch (action.type) {
  case success(API_MESSAGE_RESOLVE): {
    let id = action.meta.requestAction.id;
    let loadedData = action.data;

    if(Array.isArray(loadedData)){
      loadedData = loadedData[0];
    }

    if(loadedData == 1) {
      let data = StoreUtil.get(state, "conversation", id);
      if (StoreUtil.isLoaded(data)) {
        data.data = Object.assign(data.data, {Status: "Resolved"});
        //mark the plan change resolved as well
        if(data.data.AdmissionPlanChanges){
          data.data = dotProp.set(data.data, "AdmissionPlanChanges.Status", "Resolved");
        }
      } else {
        data.data = {id:id, Status: "Resolved"};
      }
      return StoreUtil.setSaved(data.data, state, "conversation", id);
    }
    return StoreUtil.setSaveFailed(state, "conversation", id);
  }
  case error(API_MESSAGE_RESOLVE):
    return StoreUtil.setSaveFailed(state, "conversation", action.requestAction.id);
  case abort(API_MESSAGE_RESOLVE):
    return StoreUtil.setSaveAborted(state, "conversation", action.requestAction.id);
  case API_MESSAGE_RESOLVE:{
    console.log("SET SAVING!");
    return StoreUtil.setSaving(state, "conversation", action.id);
  }}

  return state;
}
