import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StoreUtil from 'stores/StoreUtil'
import Typography from '@material-ui/core/Typography';
import { MessagesCountView } from 'containers/messages/MessagesCountView';
import { getNavUser } from 'actions/navUserActions';
import { getFacilityName } from 'actions/facilityActions';
import DataUtil from 'util/DataUtil'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.unit * 5
  },
  header: {
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

class PACHUDView extends React.Component {

  componentDidMount() {
    const {
      userDetail,
      user,
      facility,
      dispatch
    } = this.props;

    if(StoreUtil.needsLoadLongCache(userDetail)){
      dispatch(getNavUser(user.id));
    }
    //need to load facility name, because not associated with user
    if(user.meta.nurseNavViewingPac 
      && user.FacilityId>0
      && StoreUtil.needsLoadLongCache(facility)){
      dispatch(getFacilityName(user.FacilityId));
    }
  }

  render() {
    const {
      classes,
      user,
      userDetail,
      facility
    } = this.props;

    let userName = DataUtil.formatNameForUser(user);

    let extraData = StoreUtil.getData(userDetail);
    if(extraData && extraData.Facility){
      userName += " - "+extraData.Facility.Name; 
    }else if(StoreUtil.getData(facility)){
      userName += " - "+StoreUtil.getData(facility).Name; 
    }

    return (
      <div className={classes.container}>
        <Typography
          variant="display1"
          className={classes.header}>
          {userName}
        </Typography>
        <div className={classes.cards}>
          <MessagesCountView type="change" pacMode={true} />
          <MessagesCountView type="other" pacMode={true} />
        </div>
      </div>
    );
  }
}

PACHUDView.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userDetail: PropTypes.object.isRequired,
  facility: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { 
    user, 
    navUsers,
    facilities
  } = state;

  return {
    user,
    userDetail: StoreUtil.get(navUsers, StoreUtil.COMMON_ITEM, user.id),
    facility: StoreUtil.get(facilities, 'name', user.FacilityId)
  };
}

const styledPage = withStyles(styles)(PACHUDView);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACHUDView};
