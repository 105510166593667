import ReducerUtil from 'reducers/ReducerUtil';
import DataUtil from 'util/DataUtil';

import {
  API_LIST_DISCHARGEFACILITIES,
  API_GET_DISCHARGEFACILITIES
} from 'actions/dischargeFacilityActions';

export const dischargeFacilities = (state = {}, action) => {
  const table = ReducerUtil.reduceTableGet(state, action, API_GET_DISCHARGEFACILITIES);
  if(table.actionConsumed){
    // if(action.data){
    //   const { rows } = action.data;
    //   DataUtil.sort(rows, "Name");
    // }
    return table.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_LIST_DISCHARGEFACILITIES)

  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "Name");
    const filtered = [];
    const names = [];
    if(list && list.state && list.state.list && list.state.list.data){
      list.state.list.data.forEach(data => {
        const trimmed = data.Name.trim().toUpperCase();
        if(!names.includes(trimmed)){
          filtered.push(data)
          names.push(trimmed)
        }
      });
    }
    if(list.state.list.data){
      list.state.list.data = filtered;
    }
    return list.state;
  }
  return state;
};
