import dotProp from 'dot-prop-immutable';
import ReducerUtil from 'reducers/ReducerUtil'
import { success } from 'redux-saga-requests';
import QuestionGroupUtil from 'util/QuestionGroupUtil';
import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil';

import {
  API_GET_QUESTION_GROUP,
  API_UPDATE_QUESTION_GROUP
} from 'actions/questionGroupActions';

export const questionGroup = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'questionGroup');
  if(logout.actionConsumed){
    return logout.state;
  }
  
  const groupGet = ReducerUtil.reduceSingleGet(state, action, API_GET_QUESTION_GROUP);
  if(groupGet.actionConsumed){
    let alteredState = groupGet.state;
    if(action.type == success(API_GET_QUESTION_GROUP)){
      //processed to state
      let formId = action.meta.requestAction.id;
      let formData = StoreUtil.get(alteredState,StoreUtil.COMMON_ITEM,formId);
      const { Questions } = formData.data;
      Questions.forEach(data => {
        if(data.Type === "drop-down"){
          DataUtil.sort(data.QuestionOptions, "Value");
        }
      })
      const processedData = QuestionGroupUtil.ConvertDatabaseToForm(formData.data);
      alteredState = dotProp.set(alteredState,
        `${StoreUtil.COMMON_ITEM}.${formId}.data.processed`,
        processedData);
    }
    return alteredState;
  }

  const updateAnswers = ReducerUtil.reduceUpdate(state, action, API_UPDATE_QUESTION_GROUP);
  if(updateAnswers.actionConsumed){
    return updateAnswers.state;
  }
  return state;
}