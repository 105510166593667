export const API_GET_REASONS = 'API_GET_REASONS';
export const API_GET_REASONS_LIST = 'API_GET_REASONS_LIST';
export const CREATE_REASON = 'CREATE_REASON';

export const listReasons = (options, asList = false)=> {

  let type;
  if(asList){
    type = API_GET_REASONS_LIST;
  }else{
    type = API_GET_REASONS;
    options.limit = (options.limit) ? options.limit : 0;
    options.offset = (options.offset) ? options.offset : 0;
  }

  return {
    type: type,
    request: {
      url: '/Reasons',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const createReason = (data) => {
  return {
    type: CREATE_REASON,
    request: {
      url: '/Reasons',
      method: 'PUT',
      data
    }
  }
};