import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FacilityTable } from 'components/FacilityTable';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

import { tableFacilities } from 'actions/facilityActions';
import { viewFacility } from 'actions/facilityActions';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import StoreUtil from 'stores/StoreUtil';
import NavigationUtil from 'util/NavigationUtil';
import { saveTablePreference } from 'actions/preferenceActions';

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const TABLE_NAME = "FacilityPage";

class FacilityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search != prevProps.location.search) {
      this.fetchInitialUsers();
    }
  }

  componentDidMount() {
    this.fetchInitialFacilities();
  }

  fetchInitialFacilities() {
    this.setState( { query: this.buildInitialQuery() }, this.fetchFacilities.bind(this));
  }

  buildInitialQuery() {
    const {
      preferenceQuery
    } = this.props;

    let query = {
      where: { },
      limit: 25,
      offset: 0
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    return query;
  }

  fetchFacilities() {
    const { dispatch } = this.props;
    const { query } = this.state;

    console.log("Fetching facilities with query: " + JSON.stringify(query));
    dispatch(tableFacilities(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;

    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState( { query: query }, this.fetchFacilities.bind(this));
  }

  onRowClick(facilities) {
    const { dispatch, user } = this.props;
    dispatch(viewFacility(user, facilities));
  }

  render() {
    const{ classes, facilities, user } = this.props;
    const { query } = this.state;

    return (
      <React.Fragment>
        <FacilityTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Facilities</Typography>
              <Button
                color="primary"
                size="small"
                component={Link} to={NavigationUtil.userLink(user, "/facilities/0")}>
                <Icon className={classes.leftIcon}>add</Icon>
                New Facility
              </Button>
            </div>
          }
          data={facilities}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
        />
      </React.Fragment>
    );
  }
}

FacilityPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  facilities: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const { facilities, user, preference } = state;

  return {
    facilities: StoreUtil.get(facilities, StoreUtil.COMMON_TABLE),
    user,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(FacilityPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export { connectedPage as FacilityPage };
