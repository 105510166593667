import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
});

//this controls which columns are displayed and how they are looked up in data
const columns = [
  {label:"Name", key: "Name", sortable: true, sortDown: false}
];

class HospitalTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <DynamicTable {...this.props} columns={columns}/>;
  }
}

HospitalTable.propTypes = DynamicTable.propTypes;

const styled = withStyles(styles)(HospitalTable);
export {styled as HospitalTable};