import { success, error, abort } from 'redux-saga-requests';
import ReducerUtil from 'reducers/ReducerUtil'
import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil';

import {
  SUCCESS,
  LOADING,
  FAILED
} from 'const'

import {
  API_TASKS_ID,
  API_TASKS_TODAY,
  API_TASKS_LIST,
  API_TASKS_TYPES_LIST,
  API_TASKS_UPDATE,
  API_TASKS_COUNT,
  API_TASKS_DELETE
} from 'actions/tasksActions';

export const tasks = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'tasks');
  if(logout.actionConsumed){
    return logout.state;
  }

  const tasksList = ReducerUtil.reduceTableGet(state, action, API_TASKS_LIST);
  if(tasksList.actionConsumed){
    return tasksList.state;
  }

  if (action.type === API_TASKS_COUNT) {
    state.todaysCount = Object.assign({}, state.todaysCount);
    state.todaysCount.meta.loading = LOADING;
    return state;
  } else if (action.type === success(API_TASKS_COUNT)){
    state.todaysCount = Object.assign({}, state.todaysCount);
    state.todaysCount.meta.loading = SUCCESS;
    state.todaysCount.count = action.data.count;
    return state;
  } else if (action.type === abort(API_TASKS_COUNT) || action.type === error(API_TASKS_COUNT)){
    state.todaysCount = Object.assign({}, state.todaysCount);
    state.todaysCount.meta.loading = FAILED;
    return state;
  }

  const typesList = ReducerUtil.reduceListGet(state, action, API_TASKS_TYPES_LIST, 'types');
  if(typesList.actionConsumed){
    return typesList.state;
  }

  const todayView = ReducerUtil.reduceTableGet(state, action, API_TASKS_TODAY, "todayView");
  if(todayView.actionConsumed){
    return todayView.state;
  }

  const task = ReducerUtil.reduceSingleGet(state, action, API_TASKS_ID);
  if(task.actionConsumed){
    return task.state;
  }

  const update = ReducerUtil.reduceUpdate(state, action, API_TASKS_UPDATE);
  if(update.actionConsumed){
    if(success(API_TASKS_UPDATE) == action.type && action.meta.requestAction.Status){
      let task;
      if(action.meta.requestAction.tableAction){
        task = StoreUtil.get(update.state, StoreUtil.COMMON_TABLE);  
      } else {
        task = StoreUtil.get(update.state, StoreUtil.COMMON_ITEM, action.meta.requestAction.id);
      }
      
      let taskData = DataUtil.deepCopy(StoreUtil.getData(task));
      taskData.Status = action.meta.requestAction.Status;
      
      let result;

      if(action.meta.requestAction.tableAction){
        result = StoreUtil.setLoaded(taskData, update.state, StoreUtil.COMMON_TABLE, action.meta.requestAction.id);
      } else {
        result = StoreUtil.setLoaded(taskData, update.state, StoreUtil.COMMON_ITEM, action.meta.requestAction.id);
      }

      return result;
    }

    return update.state;
  }

  const deleteTask = ReducerUtil.reduceUpdate(state, action, API_TASKS_DELETE);
  if(deleteTask.actionConsumed){
    if(success(API_TASKS_DELETE) == action.type && action.meta.requestAction.Status){
      let task = StoreUtil.get(state, StoreUtil.COMMON_TABLE, action.meta.requestAction.id);
      let taskData = StoreUtil.getData(task);
      
      taskData.Status = action.meta.requestAction.Status;
      let result = StoreUtil.setLoaded(taskData, deleteTask.state, StoreUtil.COMMON_TABLE, taskData.id);
      return result;
    }

    return deleteTask.state;
  }

  return state;
}
