import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  logo: {
    width: 45,
    height: 45,
    marginRight:15
  },
  testEnvHeader: {
    background: "#909090"
  }
});

class HeaderLogin extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes
    } = this.props;

    const version = process.env.RELEASE_VERSION;
    const testENV = process.env.IS_QA && " Test Environment"

    return (
      <AppBar position="static" className={process.env.IS_QA && classes.testEnvHeader}>
        <Toolbar>
          <Link to="/login">
            <img src={require("images/logo_small_inverted.png")} className={classes.logo}/>
          </Link>

          <Typography variant="title" color="inherit" className={classes.flex}>
            Ortho Navigator {version}{testENV}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
}

HeaderLogin.propTypes = {
  classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(HeaderLogin);
export {styled as HeaderLogin};
