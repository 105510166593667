import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { HUDView } from 'containers/HUDView'
import { DynamicTable } from 'components/DynamicTable'
import NavigationUtil from 'util/NavigationUtil'
import DateUtil from 'util/DateUtil'
import StoreUtil from 'stores/StoreUtil'
import { withStyles } from '@material-ui/core/styles'
import Lens from '@material-ui/icons/Lens'
import {
  getHSXEvents,
  updateHSXStatus,
  setSelectedHSXEvent,
  API_HSX_UPDATE_STATUS
} from 'actions/hsxActions'
import { getPostAcuteEventsForEpisodeId } from 'actions/postAcuteEventActions'
import { SelectSurgeriesDialog } from 'containers/alerts/SelectSurgeriesDialog'
import { ConfirmStartDialog } from 'containers/alerts/ConfirmStartDialog'
import { ConfirmRemoveDialog } from 'containers/alerts/ConfirmRemoveDialog'
import { ConfirmSendToNavDialog } from 'containers/alerts/ConfirmSendToNavDialog'
import CustomSnackBar from 'containers/alerts/CustomSnackBar'
import moment from 'moment'
import { getSurgery } from 'actions/surgeryActions'
import Typography from '@material-ui/core/Typography'
import { DownloadHelp } from 'components/DownloadHelp'
import { Grid } from '@material-ui/core'

const styles = {
  pageContainer: {
    width: '100%'
  }
}

const columns = [
  {
    label: 'MRN',
    key: 'MRN',
    sortable: true,
    sortDown: false,
    style: { width: 120 }
  },
  { label: 'First', key: 'FirstName', sortable: true, sortDown: false },
  { label: 'Last', key: 'LastName', sortable: true, sortDown: false },
  {
    label: 'DOB',
    key: 'BirthDate',
    type: 'date',
    sortable: true,
    sortDown: false,
    style: { width: 180 },
    format: DateUtil.formatFromDB
  },
  {
    label: 'Event',
    key: 'EventType',
    sortable: true,
    sortDown: false,
    style: { width: 100 }
  },
  {
    label: 'Event Date',
    key: 'EventDate',
    sortable: true,
    sortDown: false,
    style: { width: 100 },
    format: DateUtil.formatFromDB
  },
  {
    label: 'PAE Status',
    key: 'PartialPAE',
    sortable: true,
    sortDown: false,
    style: { width: 100 },
    format: (val) => {
      //* NOTE: PartialPAE should only ever be 0 or 2
      let icon = <Lens style={{ color: 'grey', width: '100%' }} />
      switch (val) {
      case 0:
        icon = <Lens style={{ color: 'red', width: '100%' }} />
        break
      case 1:
        icon = <Lens style={{ color: 'green', width: '100%' }} />
        break
      case 2:
        icon = <Lens style={{ color: 'orange', width: '100%' }} />
        break
      default:
        break
      }
      return icon
    }
  },
  {
    label: 'Description',
    key: 'Description',
    sortable: true,
    sortDown: false,
    style: { width: 218 }
  }
]

class HSXAlertsDashboardPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      openDialog: false,
      openConfirmDialog: false,
      openRemoveDialog: false,
      openSendToNavDialog: false,
      openSnackBar: false,
      associatedSurgeries: [],
      postAcuteEventsListRows: [],
      selectedSurgeryId: '',
      selectedSurgery: {},
      selectedRow: { id: null },
      query: { where: {}, limit: 25, offset: 0 }
    }

    this.onQueryChange = this.onQueryChange.bind(this)
    this.fetchHSXEvents = this.fetchHSXEvents.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleContinue = this.handleContinue.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.sendToNav = this.sendToNav.bind(this)
    this.handleSendToNavConfirm = this.handleSendToNavConfirm.bind(this)
    this.setSelectedSurgery = this.setSelectedSurgery.bind(this)
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this)
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this)
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this)
  }

  componentDidMount () {
    this.fetchHSXEvents()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      surgeries,
      updatedHsxEvent,
      viewAsUser,
      dispatch,
      postAcuteEventsList
    } = this.props
    const { selectedSurgeryId, query } = this.state
    if (selectedSurgeryId) {
      let surgery = StoreUtil.get(
        surgeries,
        StoreUtil.COMMON_ITEM,
        selectedSurgeryId
      )
      let surgeryId = surgery && surgery.data && surgery.data.id.toString()

      if (
        surgeryId == selectedSurgeryId &&
        prevState.selectedSurgery !== surgery
      ) {
        this.setState({ selectedSurgery: surgery })
      }
    }
    if (
      prevProps.updatedHsxEvent !== updatedHsxEvent &&
      updatedHsxEvent.success
    ) {
      this.setState({
        openSnackBar: true
      })
    }

    if (prevProps.viewAsUser !== viewAsUser) {
      let newQuery = {
        ...query,
        where: {},
        offset: 0
      }

      if (
        viewAsUser.Role == 2 ||
        viewAsUser.Role == 3 ||
        viewAsUser.Role == 8
      ) {
        newQuery.where.NavigatorUserId = viewAsUser.id
      }
      this.setState({ query: newQuery }, dispatch(getHSXEvents(newQuery)))
    }

    if (
      prevProps.postAcuteEventsList !== postAcuteEventsList &&
      StoreUtil.isLoaded(postAcuteEventsList)
    ) {
      this.setState({
        postAcuteEventsListRows: postAcuteEventsList.data.rows
      })
    }
  }

  fetchHSXEvents () {
    const { dispatch, viewAsUser } = this.props
    const { query } = this.state
    let newQuery = {
      ...query,
      where: {
        ...query.where
      }
    }

    if (viewAsUser.Role == 2 || viewAsUser.Role == 3 || viewAsUser.Role == 8) {
      newQuery.where.NavigatorUserId = viewAsUser.id
    } else {
      delete newQuery.where.NavigatorUserId
    }
    dispatch(getHSXEvents(newQuery))
  }

  onQueryChange (query) {
    this.setState({ query: query }, this.fetchHSXEvents.bind(this))
  }

  onRowClick (row) {
    const { SurgeryId, PartialPAE } = row
    const { history } = this.props

    if (SurgeryId > 0 && PartialPAE == 1) {
      const redirectUrl = NavigationUtil.navLink('/surgery/' + SurgeryId)
      history.push(redirectUrl)
    } else {
      this.setState({
        openDialog: true,
        selectedRow: row,
        associatedSurgeries: JSON.parse(row.Associated_Surgeries)
      })
    }
  }

  handleClose () {
    this.setState({
      openDialog: false,
      selectedSurgeryId: '',
      selectedRow: { id: null },
      postAcuteEventsListRows: []
    })
  }

  handleCloseConfirm () {
    this.setState({
      openDialog: false,
      openConfirmDialog: false,
      openRemoveDialog: false,
      openSendToNavDialog: false,
      selectedSurgeryId: '',
      selectedRow: { id: null },
      postAcuteEventsListRows: []
    })
  }

  handleCloseRemove () {
    this.setState({
      openRemoveDialog: false,
      selectedRow: { id: null }
    })
  }

  handleSnackBarClose () {
    const { dispatch } = this.props

    this.setState({
      openSnackBar: false,
      selectedSurgeryId: '',
      selectedSurgery: {},
      selectedRow: {}
    })

    dispatch({ type: API_HSX_UPDATE_STATUS, payload: {} })

    this.fetchHSXEvents()
  }

  handleContinue () {
    const { selectedSurgeryId } = this.state
    if (selectedSurgeryId) {
      this.setState({
        openDialog: false,
        openConfirmDialog: true
      })
    }
  }

  handleRemove () {
    this.setState({
      openDialog: false,
      openConfirmDialog: false,
      openRemoveDialog: true
    })
  }

  sendToNav () {
    this.setState({
      openDialog: false,
      openConfirmDialog: false,
      openSendToNavDialog: true
    })
  }

  handleSendToNavConfirm () {
    const { selectedSurgeryId, selectedRow } = this.state
    const { dispatch } = this.props
    const associatedSurgeries = JSON.parse(selectedRow.Associated_Surgeries)

    if (selectedSurgeryId) {
      dispatch(
        updateHSXStatus({
          id: selectedRow.id,
          SurgeryId: selectedSurgeryId,
          Status: 'PENDING NAV REVIEW',
          StartedAt: moment().format(),
          updatedAt: moment().format()
        })
      )
    } else {
      associatedSurgeries.forEach(row => {
        return dispatch(
          updateHSXStatus({
            id: selectedRow.id,
            SurgeryId: row.Surgery,
            Status: 'PENDING NAV REVIEW',
            StartedAt: moment().format(),
            updatedAt: moment().format()
          })
        )
      })
    }

    this.setState({
      openDialog: false,
      openConfirmDialog: false,
      openRemoveDialog: false,
      openSendToNavDialog: false,
      selectedSurgeryId: '',
      selectedRow: { id: null }
    })
  }

  handleRemoveConfirm () {
    const { selectedSurgeryId, selectedRow } = this.state
    const { dispatch } = this.props
    const associatedSurgeries = JSON.parse(selectedRow.Associated_Surgeries)

    if (selectedSurgeryId) {
      dispatch(
        updateHSXStatus({
          id: selectedRow.id,
          SurgeryId: selectedSurgeryId,
          Status: 'Removed',
          StartedAt: moment().format(),
          updatedAt: moment().format()
        })
      )
    } else {
      associatedSurgeries.forEach(row => {
        return dispatch(
          updateHSXStatus({
            id: selectedRow.id,
            SurgeryId: row.Surgery,
            Status: 'Removed',
            StartedAt: moment().format(),
            updatedAt: moment().format()
          })
        )
      })
    }

    this.setState({
      openDialog: false,
      openConfirmDialog: false,
      openRemoveDialog: false,
      selectedSurgeryId: '',
      selectedRow: { id: null }
    })
  }

  handleConfirm () {
    const { selectedSurgeryId, selectedRow } = this.state
    const { history, dispatch } = this.props
    const formData = {
      ...selectedRow
    }
    const redirectUrl = NavigationUtil.navLink(
      '/surgery/' + selectedSurgeryId + '/postAcute/0'
    )

    dispatch(
      updateHSXStatus({
        id: selectedRow.id,
        SurgeryId: selectedSurgeryId,
        Status: 'Started',
        StartedAt: moment().format(),
        updatedAt: moment().format()
      })
    )
    dispatch(setSelectedHSXEvent(formData))
    dispatch(getSurgery(selectedSurgeryId))

    history.push(redirectUrl)
  }

  setSelectedSurgery (surgery) {
    const { dispatch } = this.props
    if (surgery) {
      dispatch(getSurgery(surgery))

      dispatch(
        getPostAcuteEventsForEpisodeId(parseInt(surgery), {
          where: {
            SurgeryId: surgery
          },
          order: [['Date', 'DESC']],
          offset: 0,
          limit: 100
        })
      )

      this.setState({
        selectedSurgeryId: surgery
      })
    }
  }

  render () {
    const { classes, dispatch, hsxEvents } = this.props

    const {
      query,
      openDialog,
      openConfirmDialog,
      openSendToNavDialog,
      postAcuteEventsListRows,
      associatedSurgeries,
      selectedSurgery,
      selectedSurgeryId,
      selectedRow,
      openRemoveDialog,
      openSnackBar
    } = this.state

    return (
      <div className={classes.pageContainer}>
        <HUDView />
        <DynamicTable
          dispatch={dispatch}
          columns={columns}
          data={hsxEvents}
          header={
            <Grid container alignContent='center'>
              <Grid item>
                <Typography
                  variant='headline'
                  component='h3'
                  style={{ marginTop: 10 }}
                >
                  HIE Dashboard Page
                </Typography>
              </Grid>
              <Grid item>
                <DownloadHelp fileName='HSX_Usage_Document' />
              </Grid>
            </Grid>
          }
          query={query}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick.bind(this)}
          idKey='id'
        />
        {openDialog && selectedRow.id && (
          <SelectSurgeriesDialog
            associatedSurgeries={associatedSurgeries}
            handleClose={this.handleClose}
            handleContinue={this.handleContinue}
            handleRemove={this.handleRemove}
            sendToNav={this.sendToNav}
            open={openDialog}
            postAcuteEventsListRows={postAcuteEventsListRows}
            selectedRow={selectedRow}
            selectedSurgery={selectedSurgery.data || {}}
            setSelectedSurgery={this.setSelectedSurgery}
            selectedSurgeryId={selectedSurgeryId}
          />
        )}
        {openConfirmDialog && (
          <ConfirmStartDialog
            open={openConfirmDialog}
            selectedRow={selectedRow}
            selectedSurgeryId={selectedSurgeryId}
            selectedSurgery={selectedSurgery.data || {}}
            handleClose={this.handleCloseConfirm}
            handleConfirm={this.handleConfirm}
          />
        )}
        {openRemoveDialog && (
          <ConfirmRemoveDialog
            open={openRemoveDialog}
            selectedRow={selectedRow}
            selectedSurgeryId={selectedSurgeryId}
            selectedSurgery={selectedSurgery.data || {}}
            handleClose={this.handleCloseConfirm}
            handleRemoveConfirm={this.handleRemoveConfirm}
          />
        )}
        {openSendToNavDialog && (
          <ConfirmSendToNavDialog
            open={openSendToNavDialog}
            selectedRow={selectedRow}
            selectedSurgeryId={selectedSurgeryId}
            selectedSurgery={selectedSurgery.data || {}}
            handleClose={this.handleCloseConfirm}
            handleSendToNavConfirm={this.handleSendToNavConfirm}
          />
        )}
        {openSnackBar && (
          <CustomSnackBar
            open={openSnackBar}
            variant='success'
            handleClose={this.handleSnackBarClose}
            // className={classes.margin}
            message='Successful Update!'
          />
        )}
      </div>
    )
  }
}

HSXAlertsDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  hsxEvents: PropTypes.object,
  postAcuteEventsList: PropTypes.object,
  selectedSurgery: PropTypes.object,
  surgeries: PropTypes.object,
  updatedHsxEvent: PropTypes.object,
  viewAsUser: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  const { user, hsxEvents, surgeries, updatedHsxEvent, postAcuteEvents } = state
  let list = StoreUtil.get(postAcuteEvents, StoreUtil.COMMON_TABLE)

  var { viewAsUser } = user
  if (!viewAsUser) {
    viewAsUser = user
  }

  return {
    postAcuteEventsList: list,
    viewAsUser,
    hsxEvents,
    surgeries,
    updatedHsxEvent
  }
}

const styledPage = withStyles(styles)(HSXAlertsDashboardPage)
const connectedPage = connect(mapStateToProps)(styledPage)
export { connectedPage as HSXAlertsDashboardPage }
