import {
  closeSignOutWarningPopUp,
  signoutWarning,
  timeoutUser
} from 'actions/loginActions';
import { 
  take, 
  put, 
  call, 
  cancelled,
  cancel,
  fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

const TIMEOUT_PERIOD_MILLISECONDS = 60 * 60 * 1000; // 1hr
const TWO_MINS = 1000 * 60 * 2;
const TIME_UNTIL_TWO_MIN_WARNING = TIMEOUT_PERIOD_MILLISECONDS - TWO_MINS;

// function* waitAndSignOutUser() {
//   try {
//     while (true) {
//       yield call(delay, 10000);
//       console.log("waitAndSignOutUser AUTO SIGNING OUT USER!!");
//       yield put(logoutUser());
//     }
//   } finally {
//     if (yield cancelled()){
//       //was cancelled by user interaction, do nothing
//     }
//   }
// }

// function* activityWatchLoop(){
//   while (true){
//     // starts the task in the background
//     const signOutTask = yield fork(waitAndSignOutUser)

//     // wait for the user to do anything
//     yield take( (action) => {
//       return action && action.type;//most anything
//     });
//     //cancel sign out
//     yield cancel(signOutTask);
    
//   }
// }

function* waitForTwoMinuteWarning(){
  try {
    while (true) {
      yield call(delay, TIME_UNTIL_TWO_MIN_WARNING);
      console.log("waitForTwoMinuteWarning")
      yield put(signoutWarning());
    }
  } finally {
    if (yield cancelled()){
      // confirms the cancel of the saga
      // console.log("twoMinuteWarningTask cancelled")
    }
  }
}

function* signOutUser(){
  try {
    while(true){
      yield call(delay, TIMEOUT_PERIOD_MILLISECONDS);
      yield put({type:'API_LOGOUT'});
    }
  } finally {
    if (yield cancelled()){
      // confirms the cancel of the saga
      // console.log("signOutUser cancelled");
    }
  }
}

function* timeOutUser(){
  try {
    while(true){
      yield call(delay, TIMEOUT_PERIOD_MILLISECONDS);
      yield [put(timeoutUser()), put(closeSignOutWarningPopUp())];
    }
  } finally {
    if (yield cancelled()){
      // confirms the cancel of the saga
      // console.log("timeoutUser cancelled");
    }
  }
}

function* twoMinuteWarningWatchLoop(){
  while(true){
    const twoMinuteWarningTask = yield fork(waitForTwoMinuteWarning);

    // wait for user to do an action on the UI. This will trigger this statement
    // and move on to the next saga to cancel the twoMinuteWarningTask
    yield take((action) => {
      return action && action.type && ["TIMEOUT", "SIGN_OUT_WARNING"].indexOf(action.type) < 0;
    });

    yield cancel(twoMinuteWarningTask);
  }
}

function* signOutWatchLoop(){
  while(true){
    const signOutUserTask =  yield fork(signOutUser);

    // wait for user to do an action on the UI. This will trigger this statement
    // and move on to the next saga to cancel the twoMinuteWarningTask
    yield take((action) => {
      return action && action.type && ["SIGN_OUT_WARNING"].indexOf(action.type) < 0;
    });

    yield cancel(signOutUserTask);
  }
}

function* timeOutWatchLoop(){
  const excludedActions = [
    "SIGN_OUT_WARNING",
    "API_LOGOUT",
    "@@router/CALL_HISTORY_METHOD",
    "@@router/LOCATION_CHANGE",
    "API_LOGOUT_SUCCES"
  ];
  while(true){
    const timeOutUserTask =  yield fork(timeOutUser);

    // wait for user to do an action on the UI. This will trigger this statement
    // and move on to the next saga to cancel the twoMinuteWarningTask
    yield take((action) => {
      return action && action.type && excludedActions.indexOf(action.type) < 0;
    });

    yield cancel(timeOutUserTask);
  }
}

export const autoSignOutSaga = [
  twoMinuteWarningWatchLoop(),
  signOutWatchLoop(),
  timeOutWatchLoop()
];
