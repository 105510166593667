import { combineReducers } from 'redux'

import { user } from 'reducers/userReducer'
import { tasks } from 'reducers/tasksReducer'
import { postAcuteEvents } from 'reducers/postAcuteEventsReducer'
import { surgeries } from 'reducers/surgeriesReducer'
import { patients } from 'reducers/patientsReducer'
import { positives } from 'reducers/positivesReducer'
import { navUsers } from 'reducers/navUsersReducer'
import { surgeons } from 'reducers/surgeonReducer'
import { hospitals } from 'reducers/hospitalReducer'
import { messages } from 'reducers/messagesReducer'
import { questionGroup } from 'reducers/questionGroupReducer'
import { feedback } from 'reducers/feedbackReducer'
import { admissions } from 'reducers/admissionsReducer'
import { facilities } from 'reducers/facilityReducer'
import { reasons } from 'reducers/reasonsReducer'
import { hospitalVisits } from 'reducers/hospitalVisitReducer'
import { preference } from 'reducers/preferenceReducer'
import { readmissionFacilities } from 'reducers/readmissionFacilityReducer'
import { dischargeFacilities } from 'reducers/dischargeFacilityReducer'
import { dischargeDispositions } from 'reducers/dischargeDispositionReducer'
import { procedureTypes } from 'reducers/procedureTypeReducer'
import { exportData } from 'reducers/exportReducer'
import { riskScoreAudit } from 'reducers/riskScoreAuditReducer'
import { alerts } from 'reducers/alertsReducer'
import { surgicalTeams } from 'reducers/surgicalTeamReducer'
import { appMessages } from 'reducers/appMessagesReducer'
import { hasTimedOut } from 'reducers/timeOutReducer'
import { hasTriggeredSignOutWarning } from 'reducers/signOutWarningReducer'
import { discharge90Day } from 'reducers/discharge90DayReducer'
import { navTemplateMessages } from 'reducers/navTemplateMessagesReducer'
import { surgeryFlag } from 'reducers/surgeryFlagReducer'
import { surgeryAddlInfo } from 'reducers/surgeryAddlInfoReducer'
import { navMessagingLog } from 'reducers/navMessagingLogReducer'
import { alertCount } from 'reducers/alertCountReducer'
import { alertsAndCounts } from 'reducers/alertsAndCountsReducer'
import { liveUpdate } from 'reducers/liveUpdateReducer'
import { questionOptions } from 'reducers/questionOptionsReducer'
import {
  hsxEvents,
  selectedHsxEvent,
  updatedHsxEvent
} from 'reducers/hsxReducer'
import { hsxAlerts } from 'reducers/hsxAlertsReducer'

const rootReducer = combineReducers({
  user,
  navUsers,
  surgeons,
  patients,
  surgeries,
  hospitals,
  tasks,
  messages,
  postAcuteEvents,
  positives,
  questionGroup,
  feedback,
  admissions,
  facilities,
  reasons,
  hospitalVisits,
  preference,
  readmissionFacilities,
  dischargeFacilities,
  dischargeDispositions,
  procedureTypes,
  exportData,
  riskScoreAudit,
  alerts,
  surgicalTeams,
  appMessages,
  hasTimedOut,
  hasTriggeredSignOutWarning,
  discharge90Day,
  navTemplateMessages,
  surgeryFlag,
  surgeryAddlInfo,
  navMessagingLog,
  alertCount,
  alertsAndCounts,
  liveUpdate,
  questionOptions,
  hsxEvents,
  hsxAlerts,
  selectedHsxEvent,
  updatedHsxEvent
})
export default rootReducer
