import React from 'react';
import { PropTypes } from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Menu,
  Typography,
  Chip
} from '@material-ui/core';

class AlertsSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: 1
    };
    this.handleClickListItem = this.handleClickListItem.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickListItem(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleMenuItemClick(event, index) {
    const {
      queryAlert,
      showTable
    } = this.props;
    const selectedAlert = this.state.options[index];
    queryAlert(selectedAlert);
    showTable(index);
    this.setState({
      selectedIndex: index,
      anchorEl: null
    })
  }

  handleClose() {
    this.setState({
      anchorEl: null
    })
  }

  saveOptionsInState;

  render() {

    const {
      selectedIndex,
      anchorEl
    } = this.state;

    const {
      optionsWithCounts,
      alertCount
    } = this.props;

    const options = ["Alerts", "Nothing Selected"];
    const allOptions = Object.keys(optionsWithCounts).sort();
    const alertCounts = ["", ""];
    allOptions.forEach(data => {
      alertCounts.push(optionsWithCounts[data])
      options.push(data)
    });
    // TODO FIX this possible memory leak
    this.saveOptionsInState = setTimeout(() => this.setState({ options }), 500);

    const noAlertText = "There are no alerts for this user";

    if(options.length === 2){
      options[0] = "loading...";
      if(!alertCount){
        options[0] = noAlertText;
        options[1] = noAlertText;
      }
    }

    return (<div className={{width: 40}}>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={this.handleClickListItem}
        >
          <ListItemText
            primary={<Typography style={{color: "red"}}>
              {alertCount ? "CLICK TO SELECT AN ALERT" : noAlertText}</Typography>}
            secondary={options[selectedIndex]} />
        </ListItem>
      </List>
      {alertCount ? <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === 0}
            selected={index === selectedIndex}
            onClick={event => this.handleMenuItemClick(event, index)}
          >
            {alertCounts[index].length ? <Chip
              variant="outlined"
              size="small"
              label={alertCounts[index]}
              style={{marginRight: 10}}
            /> : null}
            {option}
          </MenuItem>
        ))}
      </Menu> : null}
    </div>);
  }
}

AlertsSelector.propTypes = {
  optionsWithCounts: PropTypes.object.isRequired,
  queryAlert: PropTypes.func.isRequired,
  showTable: PropTypes.func.isRequired,
  // dispatch: PropTypes.func.isRequired,
  viewAsUser: PropTypes.object,
  alertCount: PropTypes.number
};

export default AlertsSelector
