import React, {Component} from 'react';
import { MaintenanceTable } from 'components/MaintenanceTable';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  tableMaintenanceTable:  {
    width: 400,
    margin: 10
  }
});

class TableMaintenanceTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<div style={styles.tableMaintenanceTable}>
      <MaintenanceTable {...this.props}/>
    </div>)
  }
}

TableMaintenanceTable.propTypes = MaintenanceTable.propTypes;

const styled = withStyles(styles)(TableMaintenanceTable);
export {styled as TableMaintenanceTable};