import 'url-search-params-polyfill';

export const API_LOGIN = 'API_LOGIN';
export const API_TWOFACTOR = 'API_TWOFACTOR';
export const API_LOGOUT = 'API_LOGOUT';
export const API_FORGOT_PASSWORD = 'API_FORGOT_PASSWORD';
export const API_RESET_PASSWORD = 'API_RESET_PASSWORD';
export const API_RESEND_TWO_FACTOR = 'API_RESEND_TWO_FACTOR';
export const RESET_REDIRECT_URL = 'RESET_REDIRECT_URL';
export const VIEW_PAC = 'VIEW_PAC';
export const SELECT_PAC_FACILITY = 'SELECT_PAC_FACILITY';
export const OPEN_LOGOUT_ALERT = 'OPEN_LOGOUT_ALERT';
export const CLOSE_LOGOUT_POP_UP = "CLOSE_LOGOUT_POP_UP";
export const SIGN_OUT_WARNING = "SIGN_OUT_WARNING";
export const CLOSE_SIGN_OUT_WARNING = "CLOSE_SIGN_OUT_WARNING";
export const TIMEOUT = "TIMEOUT";

export const openLogOutAlert = ()=> {
  return {
    type: OPEN_LOGOUT_ALERT
  }
};

export const switchToPACInterace = (allowPacViewing)=> {
  return {
    type: VIEW_PAC,
    nurseNavViewingPac:allowPacViewing
  }
};

export const selectPACFacility = (facilityId)=> {
  return {
    type: SELECT_PAC_FACILITY,
    id:facilityId
  }
};

export const loginUser = (usernameFromUser, passwordFromUser, returnToURL)=> {
  const formData = new URLSearchParams();
  formData.append('username', usernameFromUser);
  formData.append('password', passwordFromUser);

  return {
    type: API_LOGIN,
    returnToURL,
    request: {
      url: '/login',
      method: 'POST',
      timeout: 5000,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data : formData
    }
  }
};


export const twoFactorConfirmUser = (codeFromUser) => {
  const formData = new URLSearchParams();
  formData.append('password', codeFromUser);

  return {
    type: API_TWOFACTOR,
    request: {
      url: '/twoFactorLogin',
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data : formData
    }
  }
};

export const closeSignOutWarningPopUp = () => {
  return {
    type: CLOSE_SIGN_OUT_WARNING
  }
}

export const closeLogOutPopUp = () => {
  return {
    type: CLOSE_LOGOUT_POP_UP
  }
}

export const signoutWarning = ()=> {
  return {
    type: SIGN_OUT_WARNING
  }
};

export const logoutUser = ()=> {
  return {
    type: API_LOGOUT,
    request: {
      url: '/logout',
      method: 'GET'
    }
  }
};

export const timeoutUser = () => {
  return {
    type: TIMEOUT
  }
}

export const forgotPassword = (emailAddress)=> {
  return {
    type: API_FORGOT_PASSWORD,
    request: {
      url: '/ForgotPassword',
      method: 'POST',
      data : {email:emailAddress}
    }
  }
};

export const resetPassword = (formData)=> {
  return {
    type: API_RESET_PASSWORD,
    request: {
      url: '/ResetPassword',
      method: 'POST',
      data : {password:formData.Password,
        username:formData.username,
        token: formData.token}
    }
  }
};

export const resendTwoFactor = ()=> {
  return {
    type: API_RESEND_TWO_FACTOR,
    request: {
      url: '/ResendTwoFactor',
      method: 'GET'
    }
  }
};
