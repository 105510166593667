import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { AdmitPatientDialog } from 'containers/pac/AdmitPatientDialog'
import { MaterialForm } from 'components/forms/MaterialForm';
import { PACPatientTable } from 'components/pac/PACPatientTable';
import { tablePatients } from 'actions/patientActions';
import { viewAdmission } from 'actions/admissionActions';
import StoreUtil from 'stores/StoreUtil'
import { withStyles } from '@material-ui/core/styles';
import DataUtil from 'util/DataUtil'
import { saveTablePreference } from 'actions/preferenceActions';

const styles = (theme) => ({
  pageContainer:{
    width: "100%",
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  formContainer:{
    marginBottom:theme.spacing.unit * 2
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }

});

const schema = {
  title: '',
  type: 'object',
  required: ['enteredLastName', 'enteredDOB'],
  properties: {
    enteredLastName: {
      type: 'string', 
      title: 'Last Name', 
      minLength: 1
    },
    enteredDOB: {
      type: 'string', 
      title: 'Birth Date',
      format: 'date'
    }
  }
};

const schemeUI = {
  enteredLastName:{
    classNames:'two-column-field'
  },
  enteredDOB:{
    classNames:'two-column-field',
    "ui:options": {
      "birth": true
    }
  }
};

const TABLE_NAME = "PACPatientSearchPage";

class PACPatientSearchPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: this.buildInitialQuery(),
      dialogOpen: false,
      patient:null
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  componentWillUnmount(){
    this.setState({patient:null},this.fetchPatients(null, null))
  }

  buildInitialQuery() {

    var startdate = moment();
    startdate = startdate.subtract(91, "days");

    var today = moment();
    today = today.subtract(1, "days");

    let query = {
      where: { },
      attributes:['id', 'LastName', 'FirstName','BirthDate'],
      limit: 25,
      offset: 0,
      include: [
        {
          association:'Surgeries',
          attributes:['id', 'SurgeryDateTime'],
          required: true,
          where : {
            Active:true,
            VisitStatus: {
              $ne: 'Patient Cancelled'
            }
          },
          include: [
            {
              association:'Surgeon',
              attributes:['id', 'Name'],
              include: [
                {
                  association:'User',
                  attributes:['id', 'Username', 'FirstName', 'LastName']
                }
              ]
            },
            {
              association:'Admissions',
              attributes:['id','DischargeDate'],
              required: false
            }
          ]
        }
      ]
    };

    const {
      preferenceQuery
    } = this.props;

    if(preferenceQuery){
      query = preferenceQuery
    }

    query.include[0].where["SurgeryDateTime"] = { $lte: today.toDate(), $gte: startdate.toDate() };
    // query.include[0].where["SurgeryDateTime"] = { $lte: today.toDate() };

    return query;
  }

  fetchPatients(lastName, DOB) {
    const { dispatch } = this.props;
    const { query } = this.state;

    query.where['LastName'] = lastName;
    query.where['BirthDate'] = DOB;

    dispatch(tablePatients(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchPatients);
  }

  onRowClick(patient) {
    //does the patient have an open admission?? if so send them to admission detail
    let admission = DataUtil.findAdmissionForPatient(patient);

    if(admission){
      const { dispatch } = this.props;
      dispatch(viewAdmission(admission));
    }else{
      this.setState({
        patient,
        dialogOpen: true
      });
    }
  }

  onFormSubmit(data){
    const {enteredLastName, enteredDOB} = data.formData
    this.fetchPatients(enteredLastName, enteredDOB);
  }

  onFormError(){
  }

  onFormChange(){

  }

  handleDialogClose(){
    this.setState({ dialogOpen: false });
  }

  render() {
    const {
      classes,
      patients
    } = this.props;

    const {
      query,
      patient
    } = this.state;

    return (
      <div className={classes.pageContainer}>
        <Typography
          variant="display1">
          Find Patients for Admission
        </Typography>

        <MaterialForm 
          className={classes.formContainer}
          schema={schema}
          uiSchema={schemeUI}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          inDialog={true}
          submitText="Find Patient"
          showCancel={false}
        />

        <PACPatientTable 
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Patients</Typography>
            </div>
          }
          data={patients}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          onAdmitClick={this.onRowClick.bind(this)}
        />

        <AdmitPatientDialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          patient={patient}
        />

      </div>
    );
  }
}

PACPatientSearchPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  patients: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const { patients, preference } = state;

  return {
    patients: StoreUtil.get(patients, StoreUtil.COMMON_TABLE),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(PACPatientSearchPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACPatientSearchPage};
