import ReducerUtil from 'reducers/ReducerUtil'

import {
  API_SURGERY_DASHVIEW,
  API_SURGERY_UPDATE,
  API_LIST_SURGERIES,
  API_GET_SURGERY
} from 'actions/surgeryActions';

export const surgeries = (state = {}, action) => {
  const result = ReducerUtil.simpleReduce(
    state, 
    action, 
    'surgeries',
    API_LIST_SURGERIES,
    API_GET_SURGERY,
    API_SURGERY_UPDATE
  );
  if(result.actionConsumed){
    return result.state;
  }

  //surgery dash view single call
  const dashview = ReducerUtil.reduceSingleGet(state, action, API_SURGERY_DASHVIEW, 'id', 'dashview');
  if(dashview.actionConsumed){
    return dashview.state;
  }

  return state;
}
