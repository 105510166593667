import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import DateUtil from 'util/DateUtil'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

function SelectSurgeriesDialog (props) {
  const {
    associatedSurgeries,
    open,
    handleClose,
    handleContinue,
    handleRemove,
    sendToNav,
    selectedSurgery,
    selectedSurgeryId,
    setSelectedSurgery,
    selectedRow,
    postAcuteEventsListRows
  } = props

  const handleSurgeryChange = event => {
    setSelectedSurgery(event.target.value)
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='associated_surgeries'
      >
        <DialogTitle id='associated_surgeries_title'>
          Select Surgery
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Select a Surgery ID for ${selectedRow.FirstName} ${
              selectedRow.LastName
            } (DOB: ${DateUtil.formatFromDB(
              selectedRow.BirthDate
            )}). With ${selectedRow.Description ||
              'no description provided'} on Event Date ${DateUtil.formatFromDB(
              selectedRow.EventDate
            )}`}
          </DialogContentText>
          <DialogContentText>
            {selectedSurgery.id &&
            selectedSurgeryId == selectedSurgery.id.toString()
              ? `Surgeon: ${selectedSurgery.Surgeon.Name}; Location: ${
                selectedSurgery.Hospital.Name
              }; Surgery Date: ${DateUtil.formatFromDB(
                selectedSurgery.SurgeryDateTime
              )}`
              : 'No Surgery Selected'}
          </DialogContentText>

          {selectedSurgery.id && postAcuteEventsListRows.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>EventType</TableCell>
                  <TableCell>Notes</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {postAcuteEventsListRows.map(row => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        {DateUtil.formatFromDB(row.Date)}
                      </TableCell>
                      <TableCell>{row.Source}</TableCell>
                      <TableCell>{row.EventType}</TableCell>
                      <TableCell>{row.Notes}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : selectedSurgery.id && postAcuteEventsListRows.length == 0 ? (
            <DialogContentText>No Existing Post-Acute Events</DialogContentText>
          ) : (
            <DialogContentText>
              Select Surgery to View Post-Acute Events
            </DialogContentText>
          )}

          <form noValidate>
            <FormControl required={true} fullWidth={true}>
              <InputLabel htmlFor='surgery'>Surgeries</InputLabel>
              <Select
                autoFocus
                value={selectedSurgeryId}
                onChange={handleSurgeryChange}
                inputProps={{
                  name: 'associated_surgeries',
                  id: 'associated_surgeries'
                }}
              >
                {associatedSurgeries.map(surgery => (
                  <MenuItem value={surgery.Surgery} key={surgery.Surgery}>
                    {`${surgery.Surgery} - ${DateUtil.formatFromDB(
                      surgery.Date
                    )} - ${surgery.Surgeon}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            justify='flex-start'
            alignItems='center'
          >
            <Grid item>
              <Button
                onClick={handleRemove}
                color='primary'
                // disabled={!Boolean(selectedSurgeryId)}
              >
                Remove
              </Button>
            </Grid>
            {selectedRow.PartialPAE == 0 && (
              <Grid item>
                <Button
                  onClick={sendToNav}
                  color='primary'
                  disabled={selectedRow.Status == 'PENDING NAV REVIEW'}
                >
                  SEND TO NAV
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction='row'
            justify='flex-end'
            alignItems='center'
          >
            <Grid item>
              <Button onClick={handleClose} color='secondary'>
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleContinue}
                color='primary'
                disabled={!(selectedSurgery && selectedSurgery.id)}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

SelectSurgeriesDialog.propTypes = {
  associatedSurgeries: PropTypes.array,
  handleClose: PropTypes.func,
  handleContinue: PropTypes.func,
  sendToNav: PropTypes.func,
  handleRemove: PropTypes.func,
  open: PropTypes.bool,
  postAcuteEventsListRows: PropTypes.array,
  selectedRow: PropTypes.shape({
    Associated_Surgeries: PropTypes.string,
    Description: PropTypes.string,
    EventDate: PropTypes.string,
    EventType: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    MRN: PropTypes.number,
    NavigatorUserId: PropTypes.number,
    PartialPAE: PropTypes.number,
    ReadmissionFacilityDisplayName: PropTypes.string,
    ReadmissionFacilityId: PropTypes.number,
    Status: PropTypes.string,
    SurgeonId: PropTypes.number,
    SurgeryDate: PropTypes.string,
    SurgeryId: PropTypes.number,
    id: PropTypes.number
  }),
  selectedSurgery: PropTypes.object,
  setSelectedSurgery: PropTypes.func,
  selectedSurgeryId: PropTypes.string
}

export { SelectSurgeriesDialog }
