import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { tableHospitalVisitsForEpisodeId } from 'actions/hospitalVisitActions';

import { HospitalVisitTable } from 'containers/postAcuteEvents/HospitalVisitTable';

import StoreUtil from 'stores/StoreUtil'
import { saveTablePreference } from 'actions/preferenceActions';
const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});
const TABLE_NAME = "SurgeryHospitalVisitsView";
class SurgeryHospitalVisitsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitial();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.surgery != null &&
      (prevProps.surgery == null || this.props.surgery.id != prevProps.surgery.id )) {
      this.fetchInitial();
    }
  }

  fetchInitial() {
    this.setState({query: this.buildInitialQuery() }, this.fetch.bind(this));
  }

  buildInitialQuery() {
    const {
      preferenceQuery
    } = this.props;

    let query = {
      where: { },
      order: [["Date", "DESC"]],
      offset: 0,
      limit:100
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    return query;
  }

  fetch() {
    const { dispatch, surgery } = this.props;
    const { query } = this.state;

    dispatch(tableHospitalVisitsForEpisodeId(surgery.id, query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetch.bind(this));
  }

  render() {
    const {
      query
    } = this.state;

    const {
      classes,
      list
    } = this.props;

    let listData = StoreUtil.getData(list);

    if(listData && listData.count > 0){
      return (
        <React.Fragment>
  
          <HospitalVisitTable
            header={
              <div className={classes.headerContainer}>
                <Typography className={classes.header} variant="headline">QCAP Readmissions</Typography>
              </div>
            }
            data={list}
            query={query}
            showFilters={false}
            onQueryChange={this.onQueryChange.bind(this)}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

SurgeryHospitalVisitsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
}

function mapStateToProps(state) {
  const { hospitalVisits, preference } = state;

  let list = StoreUtil.get(hospitalVisits, StoreUtil.COMMON_TABLE);
  return {
    list: list,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(SurgeryHospitalVisitsView);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryHospitalVisitsView };
