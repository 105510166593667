import moment from 'moment';

const currentYear = parseInt(moment().format('YY'));
moment.parseTwoDigitYear = function(yearString) {
  let year = parseInt(yearString);
  if(year <= (currentYear + 1)){
    return parseInt(yearString) + 2000;
  }else{
    return parseInt(yearString) + 1900;
  }
}

class DateUtil {

  static get DISPLAY_DATE_TIME_FORMAT(){
    return "MM/DD/YYYY hh:mm A";
  }

  static get DISPLAY_FORMAT(){
    return "MM/DD/YYYY";
  }

  static get FORM_DATE_FORMAT(){
    return "YYYY-MM-DD";
  }

  static get FORM_DATETIME_FORMAT(){
    return "YYYY-MM-DDTHH:mm:ss.SSSZ";
  }

  static stripTimeFromDBDate(data, ... fields){
    if(data && fields){
      fields.forEach((fieldName)=>{
        if(data[fieldName]
          && data[fieldName].includes("T")){
          //remove time from date if there
          data[fieldName] = data[fieldName].substring(0, data[fieldName].indexOf('T'));
        }
      });
    }
  }

  static parseDateFromUser(dateUserString){
    let date = moment(dateUserString, DateUtil.DISPLAY_FORMAT);
    return date;
  }

  static parseDateFromDB(dateDbString){
    let date;
    if (dateDbString && dateDbString.length > 10) {
      date = moment(dateDbString.substring(0,10)); //, SERVER_DATE_FORMAT
    } else if (dateDbString) {
      date = moment(dateDbString, DateUtil.DISPLAY_FORMAT);
    }

    if(isNaN(date)){
      date = moment(dateDbString);
    }
    return date;
  }

  static formatDateForUser(date){
    if(date){
      return moment(date).format(DateUtil.DISPLAY_FORMAT);
    }

    return '';
  }

  static formatHistoryDateTimeFromDB(date) {
    if (date) {
      let m = moment.utc(date).local().format().substring(0,20) + '00:00';
      return moment(m).format(DateUtil.DISPLAY_DATE_TIME_FORMAT);
    }
    return ''; 
  }

  static formatDateTimeFromDB(date) {
    if (date) {
      let m = moment(date, DateUtil.FORM_DATETIME_FORMAT);
      return m.format(DateUtil.DISPLAY_DATE_TIME_FORMAT);
    }
    return "";
  }

  static formatFromDB(date) {
    if (date) {
      let m = moment.utc(new Date(date), DateUtil.FORM_DATE_FORMAT);
      return m.format(DateUtil.DISPLAY_FORMAT);
    }
    return "";
  }

  static formatDisplayToDB(date) {
    if (date) {
      let m = moment.utc(new Date(date), DateUtil.DISPLAY_FORMAT);
      return m.format(DateUtil.FORM_DATE_FORMAT);
    }
    return "";
  }

  static formatToDB(date) {
    if (date) {
      return moment(date).format(DateUtil.FORM_DATE_FORMAT);
    }
    return "";
  }

  static formatNowForDB() {
    return moment().format();
  }

}

export {DateUtil as DateUtil}
export default DateUtil
