import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DateUtil from 'util/DateUtil'

function ConfirmStartDialog (props) {
  const {
    open,
    handleClose,
    handleContinue,
    selectedSurgery,
    selectedRow,
    handleConfirm,
    selectedSurgeryId
  } = props

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby='confirm_start'>
        <DialogTitle id='confirm_start_dialog'>
          Confirm Start of Editing
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`If you do not wish to start editing the event, ${
              selectedRow.Description || 'no description provided'
            }, with Event Date ${DateUtil.formatFromDB(
              selectedRow.EventDate
            )} for ${selectedRow.FirstName} ${
              selectedRow.LastName
            } (DOB: ${DateUtil.formatFromDB(selectedRow.BirthDate)}), cancel or
            close this dialog.`}
          </DialogContentText>
          <DialogContentText>
            {selectedSurgery.id &&
            selectedSurgeryId == selectedSurgery.id.toString()
              ? `Surgeon: ${selectedSurgery.Surgeon.Name}; Location: ${
                  selectedSurgery.Hospital.Name
                }; Surgery Date: ${DateUtil.formatFromDB(
                  selectedSurgery.SurgeryDateTime
                )}`
              : 'No Surgery Id Selected'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Close
          </Button>
          <Button onClick={handleConfirm} color='primary'>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export { ConfirmStartDialog }
