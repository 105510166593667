import DataUtil from 'util/DataUtil';
import DateUtil from 'util/DateUtil';

export const API_TABLE_HOSPITAL_EXPORT = 'API_TABLE_HOSPITAL_EXPORT';
export const API_TABLE_OLD_HOSPITAL_IMPORT = 'API_TABLE_OLD_HOSPITAL_IMPORT';
export const API_BULK_HOSPITAL_EXPORT = 'API_BULK_HOSPITAL_EXPORT';
export const API_BULK_SURGERIES_SAVE = 'API_BULK_SURGERIES_SAVE';
export const SET_WORKING_ROW_DATA = 'SET_WORKING_ROW_DATA';
export const SET_WORKING_UPLOAD_DATA = 'SET_WORKING_UPLOAD_DATA';
export const CLEAR_WORKING_UPLOAD_DATA = 'CLEAR_WORKING_UPLOAD_DATA';
export const CLEAR_ERROR_WORKING_UPLOAD_DATA = 'CLEAR_ERROR_WORKING_UPLOAD_DATA';
export const SET_WORKING_ENUM_MAP = 'SET_WORKING_ENUM_MAP';

export const updateBulkSurgeries = (list, workingData)=> {
  let importIds = [];
  if(workingData){
    for(let x=0; x<workingData.length; x++){
      if(workingData[x].importId){
        importIds.push(workingData[x].importId);
      }else{
        console.log("updateBulkSurgeries : FAILURE DELETING : IMPORT ID NOT SET :", workingData[x]);
      }
    }
  }
  return {
    type: API_BULK_SURGERIES_SAVE,
    request:[
      {
        url: '/Surgeries',
        method: 'PUT',
        data : list
      },
      {
        url: '/HospitalImport',
        method: 'DELETE',
        data: importIds
      }
    ]
  }
};

export const clearErrorWorkingData = (errorDataRows) => {
  let ids = [];
  if(errorDataRows){
    for(let x=0; x<errorDataRows.length; x++){
      if(errorDataRows[x].importId){
        ids.push(errorDataRows[x].importId);
      }else{
        console.log("clearErrorWorkingData : FAILURE DELETING : IMPORT ID NOT SET :", errorDataRows[x]);
      }
    }
  }
  return {
    type: CLEAR_ERROR_WORKING_UPLOAD_DATA,
    data:errorDataRows,
    request:{
      url: '/HospitalImport',
      method: 'DELETE',
      data: ids
    }
  }
}

export const clearWorkingUploadData = (dataRows) => {
  let ids = [];
  if(dataRows){
    for(let x=0; x<dataRows.length; x++){
      if(dataRows[x].importId){
        ids.push(dataRows[x].importId);
      }else{
        console.log("clearWorkingUploadData : FAILURE DELETING : IMPORT ID NOT SET :",dataRows[x]);
      }
    }
  }
  return {
    type: CLEAR_WORKING_UPLOAD_DATA,
    data:null,
    request:{
      url: '/HospitalImport',
      method: 'DELETE',
      data: ids
    }
  }
}

export const setWorkingRowData = (row) => {
  //set surgery id
  row['SurgeryId'] = row.id;

  //for those sent to server, remove surgery id
  let serverData;
  if(row.importId){
    serverData = DataUtil.deepCopy(row);
    delete serverData.invalidFields;
    serverData.id = serverData.importId;
  }else{
    console.log("setWorkingRowData : FAILURE UPDATING : IMPORT ID NOT SET :", row);
  }
  
  let action = {
    type: SET_WORKING_ROW_DATA,
    id:row.id,
    data:row
  }

  if(serverData){
    action.request = {
      url: '/HospitalImport',
      method: 'PUT',
      data:serverData
    }
  }

  return action;
}

export const setWorkingEnumMap = (enumMap) => {
  return {
    type: SET_WORKING_ENUM_MAP,
    enumMap
  }
}

const removeEmptyRowEntries = (data) => {
  const temp = data.map(entry => {
    let fieldsEmpty = true;
    const allKeys = Object.keys(entry);
    allKeys.forEach(keyValue => {
      const trimmed = entry[keyValue].trim();
      if(trimmed.length > 0 && keyValue !== "FileName"){
        fieldsEmpty = false;
      }
    })
    if(!fieldsEmpty){
      return entry
    }
  })
  return temp.filter(filtered => filtered);
}

// pre import validation
export const isValidImportData = (data, fn) => {
  let isValid = true;
  const invalidRows = [];
  // validate the import data before saving junk in the HospitalImport table
  data.forEach(d => {
    // furture validation can be writen here

    const invalidDateString = "Invalid date";
    const birthDate = DateUtil.formatDisplayToDB(d.BirthDate);
    const dateOfSurgery = DateUtil.formatDisplayToDB(d.DateofSurgery);
    if(birthDate === invalidDateString){
      isValid = false;
      invalidRows.push({
        patient: d.Name,
        reason: "Invalid birth date"
      })
    }
    if(dateOfSurgery === invalidDateString){
      isValid = false;
      invalidRows.push({
        patient: d.Name,
        reason: "Invalid date of surgery"
      })
    }
  })
  fn(invalidRows);
  return isValid;
}

export const setWorkingUploadData = (data) => {
  const filteredData = removeEmptyRowEntries(data);
  //set surgery id
  for(let x=0; x<filteredData.length; x++){
    filteredData[x]['SurgeryId'] = filteredData[x].id;
  }

  //for those sent to server, remove surgery id
  let serverData = DataUtil.deepCopy(filteredData);
  for(let x=0; x<serverData.length; x++){
    delete serverData[x].id;
  }

  return {
    type: SET_WORKING_UPLOAD_DATA,
    data: filteredData,
    request:{
      url: '/HospitalImport',
      method: 'PUT',
      data:serverData
    }
  }
}

export const bulkLoadHospitalExports = (rows) => {
  let options = {
    where:{
      $or: []
    }
  }
  
  rows.forEach((rowData)=>{
    options.where['$or'].push(
      {
        Name : rowData.Name,
        BirthDate : DateUtil.formatDisplayToDB(rowData.BirthDate),
        DateofSurgery : DateUtil.formatDisplayToDB(rowData.DateofSurgery)
      }
    );
  });

  return {
    type: API_BULK_HOSPITAL_EXPORT,
    request:{
      url: '/HospitalExport',
      method: 'POST',
      data: { opts:options }
    }
  }
};

export const tableHospitalExports = (options) => {
  return {
    type: API_TABLE_HOSPITAL_EXPORT,
    request:{
      url: '/HospitalExport',
      method: 'GET',
      params: { opts:options }
    }
  }
};

export const listOldHospitalImport = (userId) => {
  const options = {
    where:{
      UpdatedById:userId
    }
  }
  return {
    type: API_TABLE_OLD_HOSPITAL_IMPORT,
    request:{
      url: '/HospitalImport',
      method: 'GET',
      params: { opts:options }
    }
  }
};

