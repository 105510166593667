import ReducerUtil from 'reducers/ReducerUtil'
import StoreUtil from 'stores/StoreUtil'

import {
  API_POSITIVES_FOR_SURGERY,
  API_PERTINENT_POSITIVES,
  API_UPDATE_PERTINENT_POSITIVES,
  API_POSITIVES_FOR_SURGERY_DETAILED
} from 'actions/positivesActions';

export const positives = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'positives');
  if(logout.actionConsumed){
    return logout.state;
  }
  
  const groupGet = ReducerUtil.reduceSingleGet(state, action, API_POSITIVES_FOR_SURGERY,
    'id',
    StoreUtil.COMMON_ITEM,
    true);
  if(groupGet.actionConsumed){
    return groupGet.state;
  }

  const detailGet = ReducerUtil.reduceSingleGet(state, action, API_POSITIVES_FOR_SURGERY_DETAILED,
    'id',
    'detailed',
    true);
  if(detailGet.actionConsumed){
    return detailGet.state;
  }

  const listGet = ReducerUtil.reduceListGet(state, action, API_PERTINENT_POSITIVES);
  if(listGet.actionConsumed){
    return listGet.state;
  }

  const update = ReducerUtil.reduceUpdate(state, action, API_UPDATE_PERTINENT_POSITIVES);
  if(update.actionConsumed){
    return update.state;
  }
  return state;
}