import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { success } from 'redux-saga-requests';

import {
  API_SURGERY_COPY
} from 'actions/surgeryActions';

function* copySurgery(action) {
  yield put(push('/nursenav/surgery/'+action.meta.requestAction.id));
}

//TODO: Create case for failure
export const copySurgerySaga = [
  takeEvery(success(API_SURGERY_COPY), copySurgery)
];
