import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Breadcrumb } from 'components/breadcrumb/Breadcrumb';
import { withStyles } from '@material-ui/core/styles';

import {logoutUser, switchToPACInterace} from 'actions/loginActions'
import NavigationUtil from 'util/NavigationUtil'
import {AuthRule} from 'businessRule/AuthRule'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  logo: {
    width: 45,
    height: 45,
    marginRight:15
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  authContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  patientsButton: {
    color: 'white',
    borderColor: 'white'
  },
  subtoolbar: {
    backgroundColor: theme.palette.primary.light,
    minHeight: 30,
    height: 30,
    padding: 0
  },
  menuIconButton: {
    marginTop: 4,
    marginLeft: theme.spacing.unit
  },
  menuIcon: {
    fontSize: 28
  },
  menuItemIcon: {
    marginRight: theme.spacing.unit
  },
  tasksButton: {
    marginRight: theme.spacing.unit*2,
    height: 30,
    minHeight: 30,
    padding: 4,
    borderRadius: 13,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  testEnvHeader: {
    background: "#909090"
  }
});

class PACHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      anchorEl: null
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleNAVLink = this.handleNAVLink.bind(this);
    this.handleSwitchFacility = this.handleSwitchFacility.bind(this);
  }

  handleSwitchFacility(){
    const { dispatch } = this.props;
    dispatch(switchToPACInterace(true));
  }

  handleNAVLink(){
    const { dispatch } = this.props;
    dispatch(switchToPACInterace(false));
  }

  handleMenu(event){
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleLogout() {
    this.handleClose();

    const { dispatch } = this.props;
    dispatch(logoutUser());
  }

  render() {
    const {
      classes,
      user
    } = this.props;

    const {
      anchorEl
    } = this.state;
    const open = Boolean(anchorEl);

    const allowNAVLinking = AuthRule.canUserSwitchToPACView(user);

    const version = process.env.RELEASE_VERSION;
    const testENV = process.env.IS_QA && " Test Environment"

    return (
      <AppBar position="static" className={process.env.IS_QA && classes.testEnvHeader}>
        <Toolbar>
          <Link to={NavigationUtil.pacLink("/home")}>
            <img src={require("images/logo_small_inverted.png")} className={classes.logo}/>
          </Link>

          <Typography variant="title" color="inherit" className={classes.flex}>
            PAC Ortho Navigator {version}{testENV}
          </Typography>

          <div className={classes.authContainer}>
            <Button
              className={classes.patientsButton}
              variant="outlined"
              component={Link} to={NavigationUtil.pacLink("/patientSearch")}>
              Find Patients
            </Button>

            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
              className={classes.menuIconButton}
            >
              <Icon className={classes.menuIcon}>menu</Icon>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem
                component={Link}
                to={NavigationUtil.pacLink("/dashboard")}
                onClick={this.handleClose}>
                <Icon className={classes.menuItemIcon}>home</Icon>
                Dashboard
              </MenuItem>
              { AuthRule.doesUserHaveHelpDeskPermisson(user) &&  (
                <MenuItem
                  component={Link}
                  to={NavigationUtil.pacLink('/facilities')}
                  onClick={this.handleClose}>
                  <Icon className={classes.menuItemIcon}>business</Icon>
                  Facilities
                </MenuItem>
              )}
              { (allowNAVLinking) &&  (
                <MenuItem onClick={this.handleSwitchFacility}>
                  <Icon className={classes.menuItemIcon}>swap_horiz</Icon>
                  Switch Facility
                </MenuItem>
              )}
              { allowNAVLinking &&  (
                <MenuItem onClick={this.handleNAVLink}>
                  <Icon className={classes.menuItemIcon}>launch</Icon>
                  View Nurse Nav
                </MenuItem>
              )}
              <MenuItem onClick={this.handleLogout}>
                <Icon className={classes.menuItemIcon}>exit_to_app</Icon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <Toolbar className={classes.subtoolbar}>
          <Breadcrumb />
        </Toolbar>
      </AppBar>
    );
  }
}

PACHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { user } = state;
  
  return {
    user: user
  };
}

const styled = withStyles(styles)(PACHeader);
const connected = connect(mapStateToProps)(styled);
export {connected as PACHeader};
