import React from "react";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
//import FormControl from '@material-ui/core/FormControl';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {DateUtil} from 'util/DateUtil'

const styles = (theme) => ({
  container:{
    width:'100%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    '& > label': {
      transform: 'none',
      lineHeight:'120%',
      zIndex:10
    },
    '& > div': {
      backgroundColor:'#F8F8F8',
      width:'100%'
    }
  },
  cPathway:{
    paddingTop:theme.spacing.unit * 2,
    paddingBottom:theme.spacing.unit * 2,
    paddingLeft:theme.spacing.unit,
    paddingRight:theme.spacing.unit,
    margin:0,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between',
    '& > label': {
      width:'60%',
      position:'relative'
    },
    '& > div': {
      width:'35%',
      marginBottom:16,
      marginTop:16,
      backgroundColor:'transparent'
    },
    '& > p': {
      position:'absolute',
      right:theme.spacing.unit,
      bottom:theme.spacing.unit
    }
  },
  cPathwayAlt:{
    backgroundColor: '#F5F5F5'
  },
  wrapper:{
    position:'relative',
    width:'100%',
    maxWidth: 800
  },
  todayButton:{
    position:'absolute',
    right:35,
    top:12,
    zIndex:10,
    width:40,
    minWidth:40,
    maxWidth:40,
    color:'rgba(0, 0, 0, 0.54)'
  },
  readOnlyContainer:{
    '& > div': {
      backgroundColor:'#F8F8F8',
      width:'100%',
      '& > div': {
        display:'none'
      }
    }
  }
});

class DateWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      dateError:false
    }

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onTodayClick = this.onTodayClick.bind(this);
  }

  onTodayClick(){
    let newDate = moment();
    this.handleOnChange(newDate);
  }

  handleInputChange(event){
    const value = event.target.value;
    if(value
      && value.length == DateUtil.DISPLAY_FORMAT.length
      && value.indexOf('_') == -1){
      let newDate = moment(value, DateUtil.DISPLAY_FORMAT);
      if(newDate){
        this.handleOnChange(newDate);
      }
    } else if(value === ""){
      // allows date field to be cleared
      this.handleOnChange(null);
    }
  }

  handleOnChange(value) {
    const {
      onChange,
      options
    } = this.props;

    let formatted = value;
    if (formatted) {
      let format = DateUtil.FORM_DATE_FORMAT;
      if(options.csvMode){
        format = DateUtil.DISPLAY_FORMAT;
      }
      formatted = moment(value).format(format);
    }
    this.setState({
      dateError:false
    });
    onChange(formatted);
  }

  handleOnError() {
    const {
      onChange
    } = this.props;

    this.setState({
      dateError:true
    });
    onChange("");
  }

  render() {
    const {
      schema,
      id,
      value,
      required,
      disabled,
      readonly,
      label,
      autofocus,
      rawErrors,
      options,
      classes
    } = this.props;

    const {
      dateError
    } = this.state;

    let description = null;
    let hasError = dateError;

    if(rawErrors != null){
      description = rawErrors;
      hasError = true;
    }else if(schema.description && !dateError){
      hasError = false;
      description = schema.description;
    }

    let format = DateUtil.FORM_DATE_FORMAT;
    if(options.csvMode){
      format = DateUtil.DISPLAY_FORMAT;
    }

    let date = null;
    if (value && value.length > 10) {
      date = moment(value.substring(0,10)); //, SERVER_DATE_FORMAT
    } else if (value) {
      date = moment(value, format);
      if((options.pathwayMode || options.csvMode) && isNaN(date)){
        //try straight parse, because could be in many formats
        date = moment(value);
      }
    }
    let disableFuture = false;
    let disablePast = false;
    let minDate = new Date(1890,1,1);
    let maxDate = new Date(2100,1,1);

    if(options.birth){
      //birth mode!
      disableFuture = true;
      disablePast = false;
    }else if(options.future){
      disableFuture = false;
      disablePast = true;
    }else if(options.linked){
      minDate = options.linked;
      if (minDate.length > 10) {
        minDate = moment(minDate.substring(0,10)); //, SERVER_DATE_FORMAT
      } else {
        if(options.csvMode){
          minDate = moment(minDate, DateUtil.FORM_DATE_FORMAT);
        }else{
          minDate = moment(minDate, format);
        }
      }
      //must be after or equal linked date
      if(date && moment(minDate).isAfter(date)){
        if(options.csvMode){
          hasError = true;
        }else{
          date = minDate;
        }
      }

      if(options.linkedMax){
        maxDate = options.linkedMax;
        if (maxDate.length > 10) {
          maxDate = moment(maxDate.substring(0,10)); //, SERVER_DATE_FORMAT
        } else {
          maxDate = moment(maxDate, format);
        }

        //must be before or equal linked max date
        if(date && moment(date).isAfter(maxDate)){
          date = maxDate;
        }
      }
    }

    let extraTop = 16;
    let extraBottom = 8;

    let containerClsList;
    if(options.pathwayMode){
      if(options.altRow){
        containerClsList = `${classes.container} ${classes.cPathway} ${classes.cPathwayAlt}`;
      }else{
        containerClsList = `${classes.container}  ${classes.cPathway}`;
      }
      extraTop = 0;
      extraBottom = 0;
    }else{
      containerClsList = `${classes.container}`;
    }

    if(readonly){
      containerClsList += ` ${classes.readOnlyContainer}`;
    }

    const picker = (
      <InlineDatePicker
        className={containerClsList}
        id={id}
        required={required}
        error={hasError}
        clearable
        label={label}
        autoFocus={autofocus}
        disabled={disabled || readonly}
        value={date}
        onChange={this.handleOnChange}
        onError={this.handleOnError}
        //className={classes.filterInputField}
        format={DateUtil.DISPLAY_FORMAT}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        //style={column.style}
        description={description}
        style={{marginTop:extraTop,marginBottom:extraBottom}}
        keyboard
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        disableOpenOnEnter={true}
        onInputChange={this.handleInputChange}
      />
    );

    if(options.todayButton){
      return (
        <div className={classes.wrapper}>
          <Button
            tabIndex={-1}
            size="small"
            className={classes.todayButton}
            onClick={this.onTodayClick}>
            <Icon>grade</Icon>
          </Button>
          {picker}
        </div>
      );
    }else{
      return picker;
    }
  }
}
DateWidget.defaultProps = {
  autofocus: false
};

DateWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  options: PropTypes.object,
  id: PropTypes.string.isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.any,
  label: PropTypes.any,
  classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(DateWidget);
export {styled as DateWidget};
