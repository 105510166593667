import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import FormUtil from 'util/FormUtil';

import {
  getPertinentPositivesForSurgery,
  getPertinentPositives,
  updatePertinentPositivesForSurgery } from 'actions/positivesActions';
import { LoadingView } from 'components/LoadingView'
import StoreUtil from 'stores/StoreUtil';
import NavigationUtil from 'util/NavigationUtil'

const schemaEdit = {
  title: 'Pertinent Positives',
  type: 'object',
  properties:{
    medical: {
      title: 'Medical Pertinent Positives',
      type: 'object',
      properties: {}
    },
    social: {
      title: 'Social Pertinent Positives',
      type: 'object',
      properties: {}
    }
  }
};

const schemaUIEdit = {
  medical:{},
  social:{}
}

const styles = ({
  pageContainer:{
    // width: '100%',
    padding: 15,
    marginTop: 20,
    backgroundColor: 'lightgrey'
  }
});

class PertinentPositivesEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    const {
      surgeryId,
      surgeryPositives,
      allPositives,
      dispatch
    } = this.props;

    if(StoreUtil.needsLoadNoCache(surgeryPositives)){
      dispatch(getPertinentPositivesForSurgery(surgeryId));
    }
    if(StoreUtil.needsLoadLongCache(allPositives)){
      dispatch(getPertinentPositives(surgeryId));
    }
  }

  onFormSubmit(data){
    const {
      dispatch,
      surgeryId,
      allPositives,
      surgeryPositives
    } = this.props;

    const positiveList = StoreUtil.getData(allPositives);
    if(!positiveList){
      return;
    }

    //populate ids if data already existed
    let existingIdMap = {}
    let existingValueMap = {}
    const surgeryPositiveList = StoreUtil.getData(surgeryPositives);
    if(surgeryPositiveList && surgeryPositiveList.length > 0){
      surgeryPositiveList.forEach((existingPositive)=>{
        existingIdMap[existingPositive.PertinentPositiveId] = existingPositive.id;
        existingValueMap[existingPositive.PertinentPositiveId] = existingPositive.PertinentPositiveValueId;
      });
    }

    const formData = data.formData;
    //create an array of positives, {id:, SurgeryId, PertinentPositiveId, Value}
    let deleteList = [];
    let updateList = [];
    let addList = [];
    positiveList.forEach((positive)=>{
      let value = null;
      if(formData[positive.Type.toLowerCase()].hasOwnProperty(positive.id+"")){

        if(formData[positive.Type.toLowerCase()][positive.id]){
          value = formData[positive.Type.toLowerCase()][positive.id];
        }
      }
      let result = {
        SurgeryId:surgeryId,
        PertinentPositiveId:positive.id,
        PertinentPositiveValueId:value
      };
      if(existingIdMap.hasOwnProperty(positive.id)){
        result.id = existingIdMap[positive.id];
      }
      if(result.id && value == null){
        //its a delete!!
        deleteList.push(result);
      }else if(result.id){
        //its an update!!
        if(existingValueMap[positive.id] != value){
          updateList.push(result)
        }else{
          //update... but nothing changed
        }
      }else if(value != null){
        //its an add!
        addList.push(result);
      }
    });

    if(addList.length < 1 && deleteList.length < 1 && updateList.length < 1){
      //nothing to change, send user back to surgery
      dispatch(push(NavigationUtil.navLink("/surgery/"+surgeryId)));
    }else{
      dispatch(updatePertinentPositivesForSurgery(surgeryId, addList, deleteList, updateList));
    }
  }

  onFormError(errors){
    console.log(errors);
  }

  onFormChange(){
  }

  render() {
    const {
      classes,
      surgeryPositives,
      allPositives
    } = this.props;

    if(!StoreUtil.getData(allPositives)
      && !StoreUtil.getData(surgeryPositives)){
      //wait for load
      return (<LoadingView />);
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(surgeryPositives, 'Pertinent Positives');

    const positiveList = StoreUtil.getData(allPositives);
    const surgeryPositiveList = StoreUtil.getData(surgeryPositives);

    let formData = {};
    let existingIdMap = {}
    if(surgeryPositiveList && surgeryPositiveList.length > 0){
      surgeryPositiveList.forEach((existingPositive)=>{
        existingIdMap[existingPositive.PertinentPositiveId] = existingPositive.PertinentPositiveValueId;
      });
    }

    let schema = schemaEdit;
    let schemaUI = schemaUIEdit;
    if(positiveList){
      let orders = {};
      // fields that will be input boxes
      const inputBoxSocialHistoryIds = [];
      positiveList.forEach((positive)=>{
        if(positive.Type){
          let type = positive.Type.toLowerCase();
          if(!schema.properties[type]){
            console.log("UNKNOWN Pertinent Positive Type :"+positive.Type);
          }else{
            let enumIds = [];
            let enumNames = [];
            if(positive.PertinentPositiveValues
              && Array.isArray(positive.PertinentPositiveValues)){
              positive.PertinentPositiveValues.forEach((option)=>{
                enumIds.push(option.id);
                enumNames.push(option.Value);
              })
            }

            if(enumIds.length < 1){
              enumIds.push(-1);
              enumNames.push("ERROR MISSING VALUES");
            }

            if(type === "social" && inputBoxSocialHistoryIds.includes(positive.id)){
              // input box field get a type of string
              schema.properties[type].properties[positive.id+""] = {
                type: 'string',
                title: positive.Name
              }
            } else {
              // drop down box field get a type of integer and enum values
              schema.properties[type].properties[positive.id+""] = {
                type: 'integer',
                title: positive.Name,
                enum:enumIds,
                enumNames:enumNames
              }
            }

          }
          if(existingIdMap[positive.id]){
            if(!formData[type]){
              formData[type] = {};
            }

            formData[type][positive.id] = existingIdMap[positive.id];
          }
          schemaUI[type][positive.id] = {};
          schemaUI[type][positive.id]['ui:options'] = {};
          schemaUI[type][positive.id]['ui:options'].positivesMode = true;

          // the width of the field on the form
          // form-group takes up the full width
          // two-column-field takes up half the width
          if(type === "social" && inputBoxSocialHistoryIds.includes(positive.id)){
            schemaUI[type][positive.id]['classNames']="form-group positives";
          } else {
            schemaUI[type][positive.id]['classNames']="two-column-field positives";
          }
          
          if(!orders[type]){
            orders[type] = [];
          }
          orders[type].push(positive.id); 
        }
      });

      for(var x in orders){
        schemaUI[x]["ui:order"] = orders[x];
      }
      console.log(schemaUI[x]["ui:order"]);
    }

    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={schema}
          uiSchema={schemaUI}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={formData}
          allowSubmit={!StoreUtil.isSaving(surgeryPositives)}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

PertinentPositivesEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeryPositives : PropTypes.object.isRequired,
  allPositives : PropTypes.object.isRequired,
  surgeryId : PropTypes.number.isRequired
};

function mapStateToProps(state, existingProps) {
  const {
    positives
  } = state;

  const { match } = existingProps;
  let surgeryId;
  try{
    surgeryId = parseInt(match.params.surgeryId);
    if(isNaN(surgeryId)){
      surgeryId = 0;
    }
  }catch(error){
    console.log(error);
    surgeryId = 0;
  }

  let surgeryPositives = StoreUtil.get(positives, StoreUtil.COMMON_ITEM, surgeryId);
  let allPositives = StoreUtil.get(positives, StoreUtil.COMMON_LIST);

  return {
    allPositives,
    surgeryPositives,
    surgeryId
  }
}

const styledPage = withStyles(styles)(PertinentPositivesEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PertinentPositivesEditPage};
