import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { HUDView } from 'containers/HUDView';
import { DynamicTable } from 'components/DynamicTable';
import { tableAlerts } from 'actions/alertActions';
import { viewAlert } from 'actions/alertActions';
import StoreUtil from 'stores/StoreUtil';
import DateUtil from 'util/DateUtil';
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';

import AlertsSelector from 'components/alerts/AlertsSelector';
import { getAlertsAndCounts } from 'actions/alertsAndCountsActions';

const styles = ({
  pageContainer:{
    width: "100%"
  }
});

const columns = [
  {label:"Name", key: "DisplayText", sortable: true, sortDown: false},
  {label:"Facilty", key: "PostAcuteFacility", sortable: true, sortDown: false},
  {label:"DOS", key: "SurgeryDateTime", type:"date", sortable: true, sortDown: false, 
    filter: true, format:DateUtil.formatFromDB}
];

const TABLE_NAME = "AlertsDashboardPage";
class AlertsDashboardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {},
      hasFetchedAlerts: false
    };

    this.onQueryChange = this.onQueryChange.bind(this);
    this.queryAlert = this.queryAlert.bind(this);
    this.showTable = this.showTable.bind(this);
    this.fetchAlertDashboardDropdownData = this.fetchAlertDashboardDropdownData.bind(this);
  }

  componentDidMount() {
    // this is taken out to prevent extra calls to the db
    // this.fetchInitialAlerts();

    this.fetchAlertDashboardDropdownData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewAsUser != null &&
    (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {
      // not used in the app because it was replaced with the call for the alert number
      // this.fetchInitialAlerts();

      // call for updated dropdown data
      this.fetchAlertDashboardDropdownData();
      // clears the table off the screen
      this.setState({
        hasFetchedAlerts: false
      })
    }
  }

  fetchInitialAlerts() {
    this.setState({query: this.buildInitialQuery() }, this.fetchAlerts.bind(this));
  }

  buildInitialQuery() {
    const {
      preferenceQuery,
      viewAsUser
    } = this.props;
    
    let query;

    if(preferenceQuery){
      query = preferenceQuery;
    } else{
      query = {
        limit: 50,
        offset: 0,
        order: [
          ['SurgeryDateTime', 'ASC']
        ]
      };
    }

    if(query.where){
      query.where.NavigatorUserId = viewAsUser.id;
    } else {
      query = {
        where: {
          NavigatorUserId: viewAsUser.id
        }
      }
    }

    if(viewAsUser.Role == 2 || viewAsUser.Role == 3 || viewAsUser.Role == 8){
      query.where.AlertRole = 'Nav';
    } else {
      if(query && query.where && query.where.AlertRole){
        delete query.where.AlertRole;
      }

      if(query && query.where && query.where.NavigatorUserId){
        delete query.where.NavigatorUserId;
      }
    }

    //Add limit and offset if missing
    if(!query.limit){
      query.limit = 50;
    }

    if(!query.offset){
      query.offset = 0;
    }

    return query;
  }

  fetchAlerts() {
    const { dispatch } = this.props;
    const { query } = this.state;
    
    dispatch(tableAlerts(query));
  }

  fetchAlertDashboardDropdownData() {
    const { dispatch, viewAsUser } = this.props;
    
    const options = (viewAsUser.Role === 7) ? {
      where: { NavigatorUserId: null }
    } : {
      where: { NavigatorUserId: viewAsUser.id }
    }

    dispatch(getAlertsAndCounts(options));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchInitialAlerts.bind(this));
  }

  onRowClick(alert) {
    const { dispatch } = this.props;
    
    dispatch(viewAlert(alert.Area, alert.ReferenceId))
  }

  showTable(index) {
    this.setState({ hasFetchedAlerts: (index !== 1) });
  }

  queryAlert(data) {

    const {
      dispatch,
      viewAsUser
    } = this.props;

    const where = (viewAsUser.Role === 7) ? {
      AlertText: data
    } : {
      NavigatorUserId: viewAsUser.id,
      AlertText: data
    };

    this.setState({
      query: { ...this.state.query, ...{
        where,
        limit: 25,
        offset: 0,
        order: [
          ['SurgeryDateTime', 'ASC']
        ]
      }
      }
    })

    dispatch(tableAlerts({
      where,
      limit: 25,
      offset: 0,
      order: [
        ['SurgeryDateTime', 'ASC']
      ],
      attributes: {
        exclude: [
          "AdminAndNavAlertAndCount",
          "AlertCount",
          "AlertRole",
          "AlertText",
          "NavAlertAndCount",
          "NavigatorUserId",
          "id"
        ]
      }
    }));
  }

  render() {
    const {
      classes,
      alerts,
      dispatch,
      viewAsUser,
      alertsAndCounts,
      alertCount
    } = this.props;

    const {
      query
    } = this.state;

    let header = 'Alerts';

    // separate the different categories
    let alertTextCount = {};
    let alertTypes = [];
    const optionsWithCounts = {}
    const role = viewAsUser.Role;
    if(alertsAndCounts && Object.keys(alertsAndCounts).length){
      // if rowCounts exists we will use the counts from it
      const { AdminAndNavAlertAndCount, NavAlertAndCount } = alertsAndCounts;
      const dataToUse = (role === 7) ? AdminAndNavAlertAndCount : NavAlertAndCount;
      const trimmed = dataToUse ? dataToUse.trim() : "";
      const split = trimmed.split("|").filter(data => data !== "");
      
      split.forEach(data => {
        const trimmedData = data.trim();
        const splittingIndex = trimmedData.lastIndexOf(" ");
        const text = trimmedData.substring(0, splittingIndex);
        const count = trimmedData.substring(splittingIndex + 1, trimmedData.length);

        optionsWithCounts[text] = count;

        const toInt = parseInt(count.trim())
        let list = [{ DisplayText: "loading..." }];
        alertTextCount[text.trim()] = { count: toInt, list }
      });

      if(alerts.data && alerts.data.rows.length){
        let displayList = true;
        let temp;
        alerts.data.rows.forEach(data => {
          if(!temp){
            temp = data.AlertText;
          } else {
            if(data.AlertText !== temp){
              displayList = false;
            }
          }
        })
        if(displayList && alertTextCount[temp]){
          alertTextCount[temp].list = alerts.data.rows;
        }
      }
      
      alertTypes = Object.keys(alertTextCount);
      alertTypes.sort();
    }

    const usedColumns = [...columns];
    if(alerts && alerts.data && alerts.data.rows[0]
      && (alerts.data.rows[0].PostAcuteFacility.length === 0)){
      let index = null;
      usedColumns.forEach((data, i) => {
        if(data.label === "Facilty"){
          index = i;
        }
      })
      usedColumns.splice(index, 1);
    }

    return (
      <div className={classes.pageContainer}>

        <HUDView />

        <AlertsSelector
          showTable={this.showTable}
          queryAlert={this.queryAlert}
          optionsWithCounts={optionsWithCounts}
          alertCount={alertCount}/>

        {this.state.hasFetchedAlerts && <DynamicTable
          user={viewAsUser.id}
          role={viewAsUser.Role}
          dispatch={dispatch}
          columns={usedColumns}
          data={alerts}
          header={header}
          query={query}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick.bind(this)}
          idKey="id"
        />}

      </div>
    );
  }
}

AlertsDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  alerts: PropTypes.object,
  viewAsUser: PropTypes.object.isRequired,
  user: PropTypes.number,
  preferenceQuery: PropTypes.object,
  alertsAndCounts: PropTypes.object,
  alertCount: PropTypes.number
};

function mapStateToProps(state) {
  const {
    alerts,
    user,
    preference,
    alertCount,
    alertsAndCounts
  } = state;

  var { viewAsUser } = user;
  if(!viewAsUser){
    viewAsUser = user;
  }

  let alertTable = StoreUtil.get(alerts, StoreUtil.COMMON_TABLE);

  return {
    alerts: alertTable,
    viewAsUser,
    preferenceQuery:preference.tables[TABLE_NAME],
    alertsAndCounts,
    alertCount
  };
}

const styledPage = withStyles(styles)(AlertsDashboardPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as AlertsDashboardPage};
