import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const styles = (theme) => ({
  container: {
    display:'flex',
    flexDirection: 'column',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },
  listContainer: {
    paddingLeft:10
  },
  header: {
    display:'flex',
    alignItems:'center',
    height:15,
    background:fade(theme.palette.common.black, 0.04),
    paddingTop:10,
    paddingBottom:10,
    paddingLeft:10,
    paddingRight:10
  },
  table: {
    minWidth: 100
  }
});

class DetailDataTable extends React.Component {

  render() {
    let {
      classes,
      rows,
      user
    } = this.props;

    const tableHeaders = ["Sender", "Recipient", "Subject", "Sent"]

    return (<div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="subheading">Navigator Messaging (last 5 messages)</Typography>
      </div>
      <div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaders.map((data, index) => {
                if(index !== 0) return <TableCell key={index} align="right">{data}</TableCell>
                return <TableCell key={index}>{data}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rows && rows.length) ? rows.map((data, index) => {

              // this removes the additional time added by sequelize
              {/* const offset = moment().utcOffset();
              const property = (offset < 0) ? "subtract" : "add";
              const minutes = Math.abs(offset); */}
              const time = moment(data.DateTimeSent).format('MM/DD/YYYY HH:mmA');

              return (<TableRow key={index}>
                <TableCell align="right">{user.FirstName}</TableCell>
                <TableCell align="right">{data.FacilityName}</TableCell>
                <TableCell align="right">{data.Subject}</TableCell>
                <TableCell align="right">
                  {time}
                </TableCell>
              </TableRow>)
            }) : (<TableRow>
              <TableCell align="right">...</TableCell>
              <TableCell align="right">...</TableCell>
              <TableCell align="right">...</TableCell>
              <TableCell align="right">...</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </div>
    </div>);
  }
}

DetailDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  rows: PropTypes.array,
  user: PropTypes.object
};

function mapStateToProps() {
  return { };
}

const styled = withStyles(styles)(DetailDataTable);
const connected = connect(mapStateToProps)(styled);
export { connected as DetailDataTable };
