import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';
import Paper from '@material-ui/core/Paper';

import DataUtil from 'util/DataUtil';
import StoreUtil from 'stores/StoreUtil';
import { getAdmission } from 'actions/admissionActions';

import { LoadingView } from 'components/LoadingView';
import { DetailDataGroup } from 'components/detail/DetailDataGroup';
import DateUtil from 'util/DateUtil';

import { AdmissionConversationView } from 'containers/messages/AdmissionConversationView'
import { QuestionGroupDetailView } from 'containers/forms/QuestionGroupDetailView'
import { AdmissionHospitalVisitsView } from 'containers/admissions/AdmissionHospitalVisitsView'

const styles = (theme) => ({
  container: {
    width:'100%',
    boxSizing: 'border-box'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    padding:theme.spacing.unit
  },
  headerPageTitle:{
    marginRight: theme.spacing.unit * 2
  },
  spacer:{
    height: theme.spacing.unit * 2
  },
  active: {
    backgroundColor: "#34a853",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#34a853", 0.4)
    },
    alignSelf:'center'
  },
  inactive: {
    backgroundColor: "#ea4335",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#ea4335", 0.4)
    },
    alignSelf:'center'
  },
  dataContainerHeader:{
    padding: theme.spacing.unit * 2,
    paddingBottom : 0
  },
  dataContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap',
    padding : theme.spacing.unit*2
  },
  actionContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
    borderColor:fade(theme.palette.common.black, 0.04),
    borderStyle: 'solid',
    borderWidth: 2,
    paddingTop:theme.spacing.unit,
    paddingBottom:theme.spacing.unit
  },
  actionButton:{

  }
});

const admissionSchema = {
  properties:{
    AdmissionDate:{
      title: 'Admitted',
      type: 'string',
      format: 'date'
    },
    'AnticipatedDischargeDate':{
      title: 'Anticipated Discharge',
      type: 'string',
      format: 'date'
    },
    'Discharged':{
      title: 'Discharged',
      type: 'string',
      format: 'date'
    },
    'Facility.Name':{
      title: 'Facility',
      type: 'string'
    }
  }
}

class AdmissionDetailPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    //check if data load needed
    const {
      admission,
      admissionId,
      dispatch
    } = this.props;

    if(StoreUtil.needsLoadNoCache(admission)){
      dispatch(getAdmission(admissionId));
    }
  }

  render() {

    //find admission
    const { admission, classes, admissionId } = this.props;

    if(StoreUtil.isLoadFailed(admission)){
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              className={classes.headerPageTitle}
              variant='title' color='error'>
              There was an issue loading the admission.  Please try again later.
            </Typography>
          </div>
        </div>
      );
    } else if(!StoreUtil.getData(admission)){
      return (
        <LoadingView />
      );
    }else{
      let data = StoreUtil.getData(admission);
      let patientData;
      let patientHeader = '';
      if(data.Surgery){
        if(data.Surgery.Patients){
          patientData = data.Surgery.Patients;
          const patientDOB = DateUtil.formatFromDB(patientData.BirthDate);
          patientHeader = `${patientData.LastName}, ${patientData.FirstName} DOB: ${patientDOB}`;
        }
      }
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              className={classes.headerPageTitle}
              variant="display1">
              Admission {patientHeader}
            </Typography>
          </div>
          <Paper>
            <div className={classes.dataContainerHeader}>
              <Typography variant="headline">Admission Information</Typography>
            </div>
            <div className={classes.dataContainer}>
              <DetailDataGroup
                schema={admissionSchema}
                data={data}
              />
            </div>
          </Paper>
          <div className={classes.spacer} />
          <Paper>
            <AdmissionConversationView
              admission={data}
              isPAC={false}
            />
          </Paper>
          <div className={classes.spacer} />
          <AdmissionHospitalVisitsView admission={data} isPAC={false} />
          <div className={classes.spacer} />
          <QuestionGroupDetailView
            questionGroupId={DataUtil.INITIAL_EVAL_QUESTION_GROUP_ID}
            surgeryId={data.SurgeryId}
            admissionId={admissionId}
            title={"Initial Eval"}
          />
          <div className={classes.spacer} />
          <QuestionGroupDetailView
            questionGroupId={DataUtil.DISCHARGE_QUESTION_GROUP_ID}
            surgeryId={data.SurgeryId}
            admissionId={admissionId}
            title={"Discharge"}
          />
        </div>
      );
    }
  }
}

AdmissionDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  admission : PropTypes.object.isRequired,
  admissionId : PropTypes.string.isRequired
}

function mapStateToProps(state, existingProps) {
  const {
    admissions
  } = state;
  //find surgery

  const { match } = existingProps;
  const admissionId = match.params.id;
  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);

  return {
    admissionId,
    admission
  };
}

const styled = withStyles(styles)(AdmissionDetailPage);
const connected = connect(mapStateToProps)(styled);
export { connected as AdmissionDetailPage };
