
class TableUtil {

  static clearFilterStyles(){
    return {
      backgroundColor: "rgb(12, 34, 79)",
      color: "#fff"
    }
  }

  static GetSavedQuery(preference, tab = -1){
    if(tab < 0){
      return preference;
    }
    //deep copy
    let query = JSON.parse(JSON.stringify(preference));

    if(query.tabs 
      && query.tabs[tab]){
      query.offset = query.tabs[tab].offset;
    }else{
      query.offset = 0;
    }

    return query;
  }

  static UpdateQueryForStore(query, tab = -1){
    let state;
    if(tab > -1){
      //store offset (page) separately

      //deep copy
      state = JSON.parse(JSON.stringify(query));
      if(!state.tabs){
        state.tabs = {};
      }
      if(!state.tabs[tab]){
        state.tabs[tab] = {};
      }
      state.tabs[tab].offset = state.offset;
      state.offset = 0;
    }else{
      state = query;
    }
    
    return state;
  }
}

export {TableUtil as TableUtil}
export default TableUtil
