import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

import { ConversationDetailView } from 'containers/messages/ConversationDetailView';

const styles = theme => ({
  pageContainer:{
    width: '100%',
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  }
});

class PACMessagesPage extends React.Component {

  render() {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.pageContainer}>
        <ConversationDetailView isPAC={true} />
      </div>
    );
  }
}
PACMessagesPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledPage = withStyles(styles)(PACMessagesPage);
export {styledPage as PACMessagesPage};
