import React, {Component} from 'react';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class TableMaintenanceBCItem extends Component {

  render() {
    let title = "Table Maintenance";
    
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          path={NavigationUtil.navLink("/maintenance/")}
        />
      </React.Fragment>
    );
  }
}

TableMaintenanceBCItem.propTypes = {};

function mapStateToProps() {
  return { }
}

const connected = connect(mapStateToProps)(TableMaintenanceBCItem);
export {connected as TableMaintenanceBCItem };
