import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';

import AuthRule from 'businessRule/AuthRule'
import StoreUtil from 'stores/StoreUtil'
import { tableFacilities } from 'actions/facilityActions';
import { selectPACFacility } from 'actions/loginActions';
import NavigationUtil from 'util/NavigationUtil'
import { FacilityTable } from 'components/FacilityTable';
import { saveTablePreference } from 'actions/preferenceActions';

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  },
  header: {
    position:'absolute',
    top: theme.spacing.unit*2,
    left: theme.spacing.unit
  }
});
const TABLE_NAME = "PACSelectFacilityPage";
class PACSelectFacilityPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
  }

  componentDidMount() {
    this.fetchInitialSurgeries();

    const {
      facilities,
      dispatch
    } = this.props;

    const { query } = this.state;

    if(StoreUtil.needsLoadLongCache(facilities)){
      dispatch(tableFacilities(query));        
    }
  }

  fetchInitialSurgeries() {
    this.setState({query: this.buildInitialQuery() }, this.fetchSurgeries.bind(this));
  }

  buildInitialQuery() {
    let query = {
      where: { },
      limit: 25,
      offset: 0
    };

    const {
      preferenceQuery
    } = this.props;

    if(preferenceQuery){
      query = preferenceQuery
    }

    return query;
  }

  fetchSurgeries() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(tableFacilities(query));
  }

  onTabChange(event, tab) {
    this.setState({ tab }, this.fetchInitialSurgeries.bind(this));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetchSurgeries.bind(this));
  }

  onRowClick(facility) {
    const { dispatch } = this.props;

    dispatch(selectPACFacility(facility.id));
  }

  render() {
    const {
      classes,
      facilities,
      user
    } = this.props;

    const hasFacilityId = user.FacilityId !== null
      && user.FacilityId !== undefined
      && user.FacilityId > -1;
    if(!AuthRule.canUserSwitchToPACView(user)){
      if(hasFacilityId){
        return (<Redirect to={NavigationUtil.pacLink('/home')} push={false} />);
      }else{
        return (
          <Typography
            variant="display1"
            color="error">
            Access denied.  Please contact your administrator.
          </Typography>
        );
      }
    }else if(hasFacilityId){
      //already has a facility
      return (<Redirect to={NavigationUtil.pacLink('/home')} push={false} />);
    }

    const {
      query
    } = this.state;


    return (
      <div className={classes.pageContainer}>
        <Typography
          variant="display1">
          Select Facility to View As
        </Typography>
        <FacilityTable
          data={facilities}
          query={query}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
        />
      </div>
    );
  }
}

PACSelectFacilityPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  facilities: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const {
    user,
    facilities,
    preference
  } = state;

  return {
    facilities: StoreUtil.get(facilities, StoreUtil.COMMON_TABLE),
    user,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(PACSelectFacilityPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACSelectFacilityPage};
