import React from 'react'
import { PropTypes } from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import axios from 'axios'

class DownloadHelp extends React.Component {
  render () {
    const { fileName } = this.props;
    return (
      <label htmlFor='icon-button-file'>
        <IconButton
          color='primary'
          aria-label='upload picture'
          component='span'
          onClick={() => {
            axios({
              url: `${window.location
                .toString()
                .replace(/^(.*\/\/[^/?#]*).*$/, '$1')}/${fileName}.pdf`, // your url
              method: 'GET',
              responseType: 'blob'
            }).then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${fileName}.pdf`) // or any other extension
              document.body.appendChild(link)
              link.click()
              setTimeout(() => window.URL.revokeObjectURL(url), 100)
            })
          }}
        >
          <HelpOutlineIcon />
        </IconButton>
      </label>
    )
  }
}

DownloadHelp.propTypes = {
  fileName: PropTypes.string
}

export { DownloadHelp }
