import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import blue from '@material-ui/core/colors/blue';
import StoreUtil from 'stores/StoreUtil'
import { listNavUsers } from 'actions/navUserActions'

const styles = {
  container: {
    minWidth:400,
    maxHeight:450
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
};

class NavUserViewAsDialog extends React.Component {

  constructor(props) {
    super(props);
    this.handleListItemClick = this.handleListItemClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount(){
    const { users, dispatch, user } = this.props;

    if(StoreUtil.needsLoadLongCache(users)){
      dispatch(listNavUsers(true, null, user.id));
    }
  }

  handleClose(){
    this.props.onClose(this.props.selectedUser);
  }

  handleListItemClick(clickedUser){
    if(!clickedUser){
      clickedUser = this.props.selectedUser;
    }
    this.props.onClose(clickedUser);
  }

  render() {
    const { classes, users, open, selectedUser } = this.props;

    let userData = StoreUtil.getData(users);
    let userList;
    if(userData){
      userList = userData;
    }else{
      userList = [];//shouldn't need loading state, loads well before shown
    }

    return (
      <Dialog
        onClose={this.handleClose} 
        aria-labelledby="simple-dialog-title" 
        open={open}>
        <DialogTitle id="simple-dialog-title">Select Navigator</DialogTitle>
        <div className={classes.container} >
          <List>
            {userList.map(
              user => {
                let name = user.Username;
                if(user.LastName && user.FirstName){
                  name = user.LastName.trim()+", "+user.FirstName.trim();
                }
                let extraProps = null;
                if(user.id == selectedUser.id){
                  extraProps = {
                    color: 'primary'
                  }
                }
                return (
                  <ListItem 
                    button onClick={() => this.handleListItemClick(user)} 
                    key={user.id}>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} primaryTypographyProps={extraProps} />
                  </ListItem>
                )
              }
            )}
          </List>
        </div>
      </Dialog>
    );
  }
}

NavUserViewAsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUser: PropTypes.object.isRequired,
  users : PropTypes.object.isRequired,
  open : PropTypes.bool.isRequired,
  dispatch : PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { navUsers, user } = state;

  return {
    users : StoreUtil.get(navUsers, StoreUtil.COMMON_LIST),
    user
  };
}

const styled = withStyles(styles)(NavUserViewAsDialog);
const connected = connect(mapStateToProps)(styled);

export { connected as NavUserViewAsDialog}