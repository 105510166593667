import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CSVUtil from 'util/CSVUtil';
import { MaterialForm } from "components/forms/MaterialForm";

const styles = (theme) => ({
  container: {
    padding:theme.spacing.unit * 2
  }
});

class HospitalRowEditDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formData:null
    }
    this.handleClose = this.handleClose.bind(this);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.addExtraFormErrors = this.addExtraFormErrors.bind(this);
  }

  componentDidUpdate() {
    const {
      workingData
    } = this.props;

    const {
      formData
    } = this.state;

    //data changed, clear changed form
    if (formData
      && workingData 
      && formData.id != workingData.id) {
      this.setState({
        formData:null
      });
    }
  }

  handleClose(){
    this.props.onClose();
  }

  onFormSubmit(data){
    const formData = data.formData;
    this.props.onClose(formData);
  }

  onFormError(){
  }

  onFormChange(data){
    this.setState({
      formData:data.formData
    });
  }

  addExtraFormErrors(formData, errors){
    const {
      surgeryData,
      workingData
    } = this.props;

    CSVUtil.HOSPITAL_HEADERS.forEach((header)=>{
      let invalid = workingData.invalidFields.includes(header.key);
      if(!header.alterable && invalid){
        //check if value equals source
        if(CSVUtil.didFieldChange(header, formData[header.key], surgeryData[header.key])){
          //errors[header.key].addError("Does not match original value.");
          errors.push({
            message:"does not match original value.",
            name:"required",
            params:{
              missingProperty:header.key
            },
            property:"."+header.key,
            stack:"."+header.key+" is a required property"
          })
        }
      }
    });

    return errors;
  }

  updateRequireBasedOnData(){
    const {
      workingData,
      enumMap,
      surgeryData
    } = this.props;

    const {
      formData
    } = this.state;
    if(!formData){
      return workingData.invalidFields;
    }

    return CSVUtil.checkRowForInvalidFields(
      CSVUtil.HOSPITAL_HEADERS,
      formData,
      surgeryData,
      'DateofSurgery',
      enumMap);
  }

  render() {
    const {
      classes,
      open,
      workingData,
      enumMap,
      surgeryData
    } = this.props;

    if(!workingData){
      return '';
    }

    let invalidFields = this.updateRequireBasedOnData();

    let schema = {
      type: 'object',
      properties: {},
      required:invalidFields
    };
    let uiSchema = {};
    let minDate = surgeryData.DateofSurgery;

    CSVUtil.HOSPITAL_HEADERS.forEach((header)=>{
      schema.properties[header.key] = {
        title:header.name,
        type:"string"
      };
      uiSchema[header.key] = {};
      uiSchema[header.key]['ui:options'] = {};
      let invalid = workingData.invalidFields.includes(header.key);

      if(header.enum && invalid){
        schema.properties[header.key].enum = enumMap[header.enum];
        if(workingData[header.key]){
          uiSchema[header.key]["ui:placeholder"] = workingData[header.key] + "(invalid)";
          workingData[header.key] = '';
        }
      }
      if(header.format == "date"){
        schema.properties[header.key].format = "date";
        uiSchema[header.key]['ui:options'].csvMode = true;
        uiSchema[header.key]['ui:options'].linked = minDate;
      }
      //if its not in the invalid fields, don't let them touch it
      if(!invalid){
        uiSchema[header.key]['ui:disabled'] = true;
        schema.properties[header.key].format = null;
      }
      if(!header.longContent){
        uiSchema[header.key]['classNames'] = 'two-column-field';
      }
    });

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth = {'md'}>
        <DialogTitle id="simple-dialog-title">Please fix all errors</DialogTitle>
        <div className={classes.container} >
          <MaterialForm
            extraTransformError={this.addExtraFormErrors}
            schema={schema}
            uiSchema={uiSchema}
            onChange={this.onFormChange}
            onSubmit={this.onFormSubmit}
            onError={this.onFormError}
            validateImmediate={true}
            formData={workingData}
            backOnCancel={false}
            onCancel={this.handleClose}
          />
        </div>
      </Dialog>
    );
  }
}

HospitalRowEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  workingData: PropTypes.object,
  surgeryData : PropTypes.object,
  open : PropTypes.bool.isRequired,
  enumMap: PropTypes.object
};


const styled = withStyles(styles)(HospitalRowEditDialog);
export { styled as HospitalRowEditDialog}
