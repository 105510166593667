import QuestionGroupUtil from 'util/QuestionGroupUtil';

export const API_GET_QUESTION_GROUP = 'API_GET_QUESTION_GROUP';
export const API_UPDATE_QUESTION_GROUP = 'API_UPDATE_QUESTION_GROUP';
export const API_GET_QUESTION_OPTIONS = 'API_GET_QUESTION_OPTIONS';
export const API_SET_QUESTION_OPTIONS = "API_SET_QUESTION_OPTIONS";

export const getQuestionOptions = (id) => {
  return {
    type: API_GET_QUESTION_OPTIONS,
    request: {
      url: '/QuestionOptions',
      method: 'GET',
      params: {
        opts: {
          where: {
            QuestionId: {$eq: id}
          }
        }
      }
    }
  }
}

export const getQuestionGroup = (questionGroupId, surgeryId, admissionId = null)=> {
  let formId = QuestionGroupUtil.getFormStoreId(questionGroupId, surgeryId, admissionId);
  return {
    type: API_GET_QUESTION_GROUP,
    id:formId,
    request: {
      url: '/HandleSurgeryQuestions',
      method: 'POST',
      data :{
        QuestionGroupId:questionGroupId,
        SurgeryId:surgeryId,
        AdmissionId:admissionId
      }
    }
  }
};

export const updateQuestionGroupAnswers = 
  (questionGroupId, surgeryId, answers, admissionId = null, taskId = null, backOnSuccess = true)=> {
    let formId = QuestionGroupUtil.getFormStoreId(questionGroupId, surgeryId, admissionId);

    const {
      answersUpdate,
      answersNew,
      answersDelete
    } = answers;

    let action = {
      type: API_UPDATE_QUESTION_GROUP,
      id: formId,
      taskId: taskId,
      backOnSuccess,
      request:[]
    }
    if(answersNew && answersNew.length > 0){
      action.request.push(
        {
          url: '/AnswerValues',
          method: 'PUT',
          data:answersNew
        }
      );
    }
    if(answersUpdate && answersUpdate.length > 0){
      action.request.push(
        {
          url: '/AnswerValues',
          method: 'PUT',
          data:answersUpdate
        }
      );
    }

    if(answersDelete && answersDelete.length > 0){
      action.request.push(
        {
          url: '/AnswerValues',
          method: 'DELETE',
          data:answersDelete
        }
      );
    }

    return action;
  };
