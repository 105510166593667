import AuthRule from 'businessRule/AuthRule'

class NavigationUtil{

  static get nav(){
    return 'nursenav';
  }

  static get pac(){
    return 'pacnav';
  }

  static get helpDesk(){
    return 'helpdesk';
  }

  static get navdir(){
    return '/'+NavigationUtil.nav;
  }

  static get pacdir(){
    return '/'+NavigationUtil.pac;
  }

  static get helpDeskDir(){
    return '/'+NavigationUtil.helpDesk;
  }

  static getCurrentApp(user){
    const loginRedirectNeeded = AuthRule.doesHaveLoginRedirect(user);
    if(!loginRedirectNeeded 
      && AuthRule.doesUserHaveNurseNavPermission(user) 
      && !user.meta.nurseNavViewingPac){
      return NavigationUtil.nav;
    }else if(!loginRedirectNeeded 
      && AuthRule.doesUserHavePACPermission(user)){
      //pac
      return NavigationUtil.pac;
    }else if(AuthRule.doesUserHaveHelpDeskPermisson(user)){
      return NavigationUtil.helpDesk;
    }else{
      return null;
    }
  }

  static userLink(user, path){
    const currentApp = NavigationUtil.getCurrentApp(user);
    switch(currentApp){
    case NavigationUtil.nav:
      return NavigationUtil.navLink(path);
    case NavigationUtil.pac:
      return NavigationUtil.pacLink(path);
    case NavigationUtil.helpDesk:
      return NavigationUtil.helpDeskLink(path);
    default:
      return "/";
    }
  }

  static helpDeskLink(path){
    if(!path.startsWith('/')){
      path = '/' + path;
    }

    if(!path.startsWith(NavigationUtil.helpDeskDir)){
      path = NavigationUtil.helpDeskDir + path;
    }

    return path;
  }

  static navLink(path){
    if(!path.startsWith('/')){
      path = '/' + path;
    }

    if(!path.startsWith(NavigationUtil.navdir)){
      path = NavigationUtil.navdir + path;
    }

    return path;
  }

  static pacLink(path){
    if(!path.startsWith('/')){
      path = '/' + path;
    }

    if(!path.startsWith(NavigationUtil.pacdir)){
      path = NavigationUtil.pacdir + path;
    }

    return path;
  }
}

export {NavigationUtil as NavigationUtil}
export default NavigationUtil