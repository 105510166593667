import React, {Component} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import StoreUtil from 'stores/StoreUtil';
import { DateUtil } from 'util/DateUtil';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'
import { getAdmission } from 'actions/admissionActions';

class PACAdmissionBCItem extends Component {

  componentDidMount(){
    const {
      match,
      admission,
      dispatch
    } = this.props;

    if(StoreUtil.needsLoadLongCache(admission)){
      dispatch(getAdmission(match.params.id));
    }
  }

  render() {
    const {
      admission,
      match
    } = this.props;

    let title = "";
    const adData = StoreUtil.getData(admission);
    
    if (admission && adData && adData.Surgery) {
      let patientData = adData.Surgery.Patient;
      title = patientData.LastName+", "+
        patientData.FirstName +
        " - DOB: "+DateUtil.formatFromDB(patientData.BirthDate);

    }
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label={title}
          icon="face"
          path={NavigationUtil.pacLink("/admission/"+match.params.id)}
        />
      </React.Fragment>
    );
  }
}

PACAdmissionBCItem .propTypes = {
  admission: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  admissionId: PropTypes.number.isRequired
};

function mapStateToProps(state, props) {
  const { match } = props;
  const { admissions } = state;

  let admissionId;
  try{
    admissionId = parseInt(match.params.id);
    if(isNaN(admissionId)){
      admissionId = 0;
    }
  }catch(error){
    console.log(error);
    admissionId = 0;
  }

  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);

  return {
    admissionId,
    admission
  };
}

const connected = connect(mapStateToProps)(PACAdmissionBCItem);
export {connected as PACAdmissionBCItem };
