import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {QuestionGroupView} from 'containers/forms/QuestionGroupView';
import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil';
import { getAdmission, createAdmission, updateSurgeryTableWithLOS } from 'actions/admissionActions';
import { LoadingView } from 'components/LoadingView';
import Typography from '@material-ui/core/Typography';
import QuestionGroupUtil from 'util/QuestionGroupUtil';

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20,
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  errorContainer:{
  }
});

class PACQuestionGroupPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dischargeDataFailure: false,
      dischargeDate : null
    };
    this.onQuestionGroupSubmit = this.onQuestionGroupSubmit.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      admission, 
      admissionId,
      dispatch,
      questionGroupId
    } = this.props;

    if((questionGroupId == DataUtil.DISCHARGE_QUESTION_GROUP_ID 
        || questionGroupId == DataUtil.INITIAL_EVAL_QUESTION_GROUP_ID)
      && admission != null && StoreUtil.needsLoadNoCache(admission)){
      dispatch(getAdmission(admissionId));
    }
  }

  componentDidUpdate() {
    const {
      dispatch,
      questionGroup,
      admissionId,
      questionGroupId,
      admission,
      surgeryId
    } = this.props;

    const {
      dischargeDate 
    } = this.state;

    //on discharge form, when submitted
    //we need to also saved discharge date in admitted table
    if (questionGroupId == DataUtil.DISCHARGE_QUESTION_GROUP_ID
      && StoreUtil.didJustSave(questionGroup) 
      && dischargeDate
      && !StoreUtil.isSaving(admission)
      && !StoreUtil.didJustSave(admission)) {
      const admissionUpdate = {
        id:admissionId,
        DischargeDate:dischargeDate
      };
      dispatch(createAdmission(admissionUpdate, true));
      dispatch(updateSurgeryTableWithLOS(admission.data.AdmissionDate, admissionUpdate.DischargeDate, surgeryId));
      this.setState({
        dischargeDataFailure: false,
        dischargeDate : null
      });
    }
  }

  onQuestionGroupSubmit(formData){
    const {
      questionGroupId
    } = this.props;

    //save the discharge date
    if(questionGroupId == DataUtil.DISCHARGE_QUESTION_GROUP_ID){
      let dischargeDate = formData[DataUtil.DISCHARGE_QUESTION_DATE_ID];
      if(!dischargeDate){
        //shouldn't be possible
        this.setState({
          dischargeDataFailure: true,
          dischargeDate : null
        });
      }else{
        this.setState({
          dischargeDataFailure: false,
          dischargeDate : dischargeDate
        });
      }
    } 
  }


  render() {
    const {
      classes,
      surgeryId,
      questionGroupId,
      admission,
      admissionId,
      user
    } = this.props;

    const {
      dischargeDataFailure
    } = this.state;

    let readyOnlyOnEdit = !dischargeDataFailure;

    let errorMessage;
    if(questionGroupId == DataUtil.DISCHARGE_QUESTION_GROUP_ID){
      if(!StoreUtil.isLoadedOrFailed(admission)){
        //wait for admission
        return (
          <LoadingView />
        );
      }else if(StoreUtil.isLoadFailed(admission)){
        return (
          <Typography color='error' variant='subheading'>
            Admission could not be found.
          </Typography>
        );
      }else if(dischargeDataFailure || StoreUtil.isSaveFailed(admission)){
        readyOnlyOnEdit = false;
        errorMessage = "There was an issue saving the discharge data.  Please try again.";
      }else{
        //allow edit if no discharge date
        readyOnlyOnEdit = (StoreUtil.getData(admission) && StoreUtil.getData(admission).DischargeDate);
      }
    }

    const admissionData = StoreUtil.getData(admission);

    let overrideStatusError = null;
    if(errorMessage){
      overrideStatusError = errorMessage;
    }
    if(readyOnlyOnEdit){
      readyOnlyOnEdit = true;
    }else {
      readyOnlyOnEdit = false;
    }

    readyOnlyOnEdit = false;
    
    let minDate = null;
    if(admissionData && admissionData.AdmissionDate){
      minDate = admissionData.AdmissionDate;
    }

    let onSubmitCallback = null;
    if(questionGroupId == DataUtil.DISCHARGE_QUESTION_GROUP_ID){
      onSubmitCallback = this.onQuestionGroupSubmit;
    }
    
    return (
      <div className={classes.pageContainer}>
        {errorMessage && <Typography 
          className={classes.errorContainer}
          color='error' 
          variant='subheading'>{errorMessage}</Typography>}
        <QuestionGroupView
          surgeryId={surgeryId}
          admissionId={admissionId}
          questionGroupId={questionGroupId}
          readyOnlyOnEdit={readyOnlyOnEdit}
          onQuestionGroupSubmit={onSubmitCallback}
          overrideStatusError={overrideStatusError}
          minDate={minDate}
          admission={admission}
          user={user}
        />
      </div>
    );
  }
}

PACQuestionGroupPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeryId: PropTypes.number.isRequired,
  questionGroupId: PropTypes.number.isRequired,
  admissionId: PropTypes.number.isRequired,
  admission: PropTypes.object.isRequired,
  questionGroup: PropTypes.object,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const { admissions, questionGroup, user } = state;
  const { match } = existingProps;
  let surgeryId;
  let questionGroupId;
  let admissionId;

  try{
    surgeryId = parseInt(match.params.surgeryId);
    if(isNaN(surgeryId)){
      surgeryId = 0;
    }
    questionGroupId = parseInt(match.params.questionGroupId);
    if(isNaN(questionGroupId)){
      questionGroupId = 0;
    }

    admissionId = parseInt(match.params.admissionId);
    if(isNaN(admissionId)){
      admissionId = 0;
    }
  }catch(error){
    console.log(error);
    surgeryId = 0;
    questionGroupId = 0;
    admissionId = 0;
  }
  let admission;
  if(admissionId > 0){
    admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);
  }

  let formId = QuestionGroupUtil.getFormStoreId(questionGroupId, surgeryId, admissionId);

  return {
    surgeryId,
    questionGroupId,
    admissionId,
    admission,
    questionGroup: StoreUtil.get(questionGroup, StoreUtil.COMMON_ITEM, formId).AdmissionDate,
    user
  }

}

const styledPage = withStyles(styles)(PACQuestionGroupPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACQuestionGroupPage};