import React from 'react';
import { PropTypes } from 'prop-types';

import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  form: {
    width: 400
  },
  textField: {
  },
  buttonContainer:{
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit
  },
  button:{
    width: 150
  }
});

class TwoFactorForm extends React.Component {

  render() {
    const {
      onSubmit,
      onChange,
      onResendCode,
      showAsError,
      errors,
      user,
      classes,
      processing
    } = this.props;

    let color;
    if(showAsError){
      color = 'error'
    }

    return (
      <form action="/" onSubmit={onSubmit} className={classes.form}>
        <CardHeader title="Confirmation Code" >
        </CardHeader>  
        <CardContent>
          {errors 
            && <Typography variant="subheading" color={color}>{errors}</Typography>}

          <TextField
            autoFocus={true}
            type="number"
            name="code"
            label="Enter Email Code"
            placeholder="Enter Email Code"
            error={errors != null && errors.length > 0}
            onChange={onChange}
            value={user.code}
            className={classes.textField}
            margin="normal"
            fullWidth
          />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              color="primary"
              onClick={onResendCode} >
              Resend Code
            </Button>
            <Button 
              className={classes.button}
              type="submit" 
              color="primary"
              margin="normal"
              variant="raised"
              disabled={user.code.length < 3 || processing}>Send Code</Button>
          </div>
        </CardContent>
      </form>
    );
  }
}

TwoFactorForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onResendCode: PropTypes.func.isRequired,
  errors: PropTypes.string,
  showAsError: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(TwoFactorForm);
export {styled as TwoFactorForm};

