import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getPostAcuteEventsForEpisodeId, viewPostAcuteEvent } from 'actions/postAcuteEventActions';
import { PostAcuteEventsTable } from 'containers/postAcuteEvents/PostAcuteEventsTable';
import StoreUtil from 'stores/StoreUtil'
import NavigationUtil from 'util/NavigationUtil'
import { saveTablePreference } from 'actions/preferenceActions';

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const TABLE_NAME = "SurgeryPostAcuteEventsView";

class SurgeryPostAcuteEventsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitialPostAcuteEvents();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.surgery != null &&
      (prevProps.surgery == null || this.props.surgery.id != prevProps.surgery.id )) {
      this.fetchInitialPostAcuteEvents();
    }
  }

  fetchInitialPostAcuteEvents() {
    this.setState({query: this.buildInitialQuery() }, this.fetchPostAcuteEvents.bind(this));
  }

  buildInitialQuery() {
    const {
      surgery,
      preferenceQuery,
      source,
      dispatch
    } = this.props;

    let query = {
      where: {
        SurgeryId: surgery.id
      },
      order: [["Date", "DESC"]],
      offset: 0,
      limit:100
    };

    if(preferenceQuery){
      query = preferenceQuery
    } else {
      dispatch(saveTablePreference(TABLE_NAME, query));
    }

    if(source){
      query.where.Source = source;
    } else{
      delete query.where.Source;
    }
    
    query.where["SurgeryId"] = surgery.id;

    return query;
  }

  fetchPostAcuteEvents() {
    const { dispatch, surgery } = this.props;
    const { query } = this.state;

    dispatch(getPostAcuteEventsForEpisodeId(surgery.id, query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetchPostAcuteEvents.bind(this));
  }

  onRowClick(postAcuteEvent) {
    const {
      dispatch,
      surgery,
      questionGroupId,
      taskId
    } = this.props;

    dispatch(viewPostAcuteEvent(surgery.id, postAcuteEvent.id, questionGroupId, taskId));
  }

  render() {
    const {
      query
    } = this.state;

    const {
      classes,
      surgery,
      list,
      source,
      showAddButton
    } = this.props;

    let linkTo = NavigationUtil.navLink("/surgery/"+surgery.id+"/postAcute/0");
    
    let newButton;
    
    if(showAddButton){
      newButton = <Button
        color="primary"
        size="small"
        component={Link} to={linkTo}>
        <Icon className={classes.leftIcon}>add</Icon>
          Add Post Acute Event
      </Button>;
    } 

    if(source){
      linkTo = linkTo + '/' + source;
    }

    return (
      <React.Fragment>

        <PostAcuteEventsTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Post Acute Events</Typography>
              {newButton}
            </div>
          }
          data={list}
          query={query}
          source={source}
          showFilters={false}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
        />
      </React.Fragment>
    );
  }
}

SurgeryPostAcuteEventsView.defaultProps = {
  showAddButton: true
};

SurgeryPostAcuteEventsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  source: PropTypes.string,
  showAddButton: PropTypes.bool,
  questionGroupId: PropTypes.string,
  taskId: PropTypes.string
}

function mapStateToProps(state) {
  const { postAcuteEvents, preference } = state;

  let list = StoreUtil.get(postAcuteEvents, StoreUtil.COMMON_TABLE);
  return {
    list: list,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(SurgeryPostAcuteEventsView);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryPostAcuteEventsView };
