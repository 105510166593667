import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { ConfirmDialog } from 'components/dialog/Confirm';
import NavigationUtil from 'util/NavigationUtil';
import DataUtil from 'util/DataUtil';
import StoreUtil from 'stores/StoreUtil';
import { getAdmission } from 'actions/admissionActions';
import {
  listConversationsForAdmission,
  viewCreatePlanChange,
  viewConversation } from 'actions/messageActions';

import { LoadingView } from 'components/LoadingView';
import { DetailDataGroup } from 'components/detail/DetailDataGroup';
import DateUtil from 'util/DateUtil';

import { AdmissionConversationView } from 'containers/messages/AdmissionConversationView'
import { AdmissionHospitalVisitsView } from 'containers/admissions/AdmissionHospitalVisitsView'

const styles = (theme) => ({
  container: {
    width:'100%',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    padding:theme.spacing.unit
  },
  headerPageTitle:{
    marginRight: theme.spacing.unit * 2
  },
  spacer:{
    height: theme.spacing.unit * 2
  },
  active: {
    backgroundColor: "#34a853",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#34a853", 0.4)
    },
    alignSelf:'center'
  },
  inactive: {
    backgroundColor: "#ea4335",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#ea4335", 0.4)
    },
    alignSelf:'center'
  },
  dataContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap',
    padding : theme.spacing.unit*2
  },
  actionContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-around',
    borderColor:fade(theme.palette.common.black, 0.04),
    borderStyle: 'solid',
    borderWidth: 2,
    paddingTop:theme.spacing.unit,
    paddingBottom:theme.spacing.unit
  },
  actionButton:{

  }
});

const personalSchema = {
  title:'Patient Information',
  properties:{
    'BirthDate':{
      title: 'Birth Date',
      format: 'age',
      type: 'date'
    },
    Gender:{
      title: 'Gender',
      type: 'string'
    },
    HomePhone:{
      title: 'Home Phone',
      format: 'phone',
      type: 'string'
    },
    CellPhone:{
      title: 'Cell Phone',
      format: 'phone',
      type: 'string'
    },
    'Address1,Address2':{
      title: 'Address',
      type: 'string'
    },
    'City,State,Zip':{
      title: 'City State Zip',
      type: 'string'
    }
  }
};
const healthcareSchema = {
  title:'Care Information',
  properties:{
    CarePartnerName:{
      title: 'Care Partner Name',
      type: 'string'
    },
    CarePartnerRelation:{
      title: 'Care Partner Relation',
      type: 'string'
    },
    CarePartnerPhoneNumber:{
      title: 'Care Partner Phone',
      type: 'string',
      format:'phone'
    },
    Payor:{
      title: 'Insurance',
      type: 'string'
    }
  }
};

const surgerySchema = {
  title:'Surgery Information',
  properties:{
    SurgeryDateTime:{
      title: 'DOS',
      type: 'string',
      format: 'date'
    },
    'Surgeon.Name':{
      title: 'Surgeon',
      type: 'string'
    },
    ProcedureName:{
      title: 'Procedure',
      type: 'string'
    },
    'Hospital.Name':{
      title: 'Hospital',
      type: 'string'
    },
    'Surgeon.Navigator.FirstName,Surgeon.Navigator.LastName':{
      title: 'Navigator',
      type: 'string'
    }
  }
}

const admissionSchema = {
  title:'Admission Information',
  properties:{
    AdmissionDate:{
      title: 'Admitted',
      type: 'string',
      format: 'date'
    },
    'AnticipatedDischargeDate':{
      title: 'Anticipated Discharge',
      type: 'string',
      format: 'date'
    },
    'DischargeDate':{
      title: 'Discharged',
      type: 'string',
      format: 'date'
    },
    'Facility.Name':{
      title: 'Facility',
      type: 'string'
    }
  }
}
class PACAdmissionDetailPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dischargeCheckOpen:false
    }
    this.onMessageClick = this.onMessageClick.bind(this);
    this.onPlanChangeClick = this.onPlanChangeClick.bind(this);
    this.onInitialEvalClick = this.onInitialEvalClick.bind(this);
    this.onDischargeClick = this.onDischargeClick.bind(this);
    this.onEDClick = this.onEDClick.bind(this);

    this.onDischargeCheckConfirm = this.onDischargeCheckConfirm.bind(this);
    this.onDischargeCheckCancel = this.onDischargeCheckCancel.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      admission,
      admissionId,
      dispatch,
      conversations
    } = this.props;

    if(StoreUtil.needsLoadNoCache(admission)){
      dispatch(getAdmission(admissionId));
    }

    if(StoreUtil.needsLoadNoCache(conversations)){
      dispatch(listConversationsForAdmission(admissionId));
    }
  }

  onEDClick(event,dischargeAfter = false){
    const {
      admission,
      dispatch
    } = this.props;

    const isPTSurgery = DataUtil.isPTSurgeryByAdmission(admission);

    if(admission && admission.data && admission.data.Surgery && admission.data.Surgery.VisitType === "PT"){
      dischargeAfter = false; 
    }

    if(isPTSurgery && dischargeAfter){
      const admissionData = StoreUtil.getData(admission);
      if(!admissionData){
        return;
      }

      //send the user to the question group form
      const path =
        `/admission/${admissionData.id}`
        +`/surgery/${admissionData.Surgery.id}`
        +`/form/${DataUtil.DISCHARGE_QUESTION_GROUP_ID}`;
  
      const url = NavigationUtil.pacLink(path);
      return dispatch(push(url));
    }

    const admissionData = StoreUtil.getData(admission);
    if(!admissionData){
      return;
    }
    //send the user to the hospital visit form
    let visitId = 0;

    /*
    UPDATE Oct 31 - always send to new form
    if(admissionData.HospitalVisit
      && admissionData.HospitalVisit.id){
      visitId = admissionData.HospitalVisit.id;
    }
    */
    let path =
      `/admission/${admissionData.id}`
      +`/ed/${visitId}`;

    if(dischargeAfter){
      path += "/discharge";
    }
    const url = NavigationUtil.pacLink(path);
    dispatch(push(url));
  }

  onDischargeClick(){
    const {
      admission,
      hospitalVisits
    } = this.props;
    const admissionData = StoreUtil.getData(admission);
    if(!admissionData){
      return;
    }
    
    //check if user needs to do a ed
    let edVisitData = StoreUtil.getData(hospitalVisits);
    let hasEDVisit = edVisitData && edVisitData.count > 0;

    if(hasEDVisit){
      this.sendUserToDischarge();
    }else{
      //prompt user for ED visit
      this.setState({dischargeCheckOpen:true});
    }
  }

  onDischargeCheckConfirm(){
    this.setState({dischargeCheckOpen:false});
    this.onEDClick(null,true);
  }

  onDischargeCheckCancel(){
    this.setState({dischargeCheckOpen:false});
    this.sendUserToDischarge();
  }

  sendUserToDischarge(){
    const {
      admission,
      dispatch
    } = this.props;
    const admissionData = StoreUtil.getData(admission);
    if(!admissionData){
      return;
    }

    const isPTSurgery = DataUtil.isPTSurgeryByAdmission(admission) ;
    const form = isPTSurgery ? DataUtil.DISCHARGE_QUESTION_PT_GROUP_ID : DataUtil.DISCHARGE_QUESTION_GROUP_ID;

    //send the user to the question group form
    const path =
      `/admission/${admissionData.id}`
      +`/surgery/${admissionData.Surgery.id}`
      +`/form/${form}`;

    const url = NavigationUtil.pacLink(path);
    dispatch(push(url));
  }

  onInitialEvalClick(isPTSurgery){
    const {
      admission,
      dispatch
    } = this.props;
    const admissionData = StoreUtil.getData(admission);
    if(!admissionData){
      return;
    }

    const form = isPTSurgery ? DataUtil.INITIAL_EVAL_PT_QUESTION_GROUP_ID
      : DataUtil.INITIAL_EVAL_QUESTION_GROUP_ID;

    //send the user to the question group form
    const path =
      `/admission/${admissionData.id}`
      +`/surgery/${admissionData.Surgery.id}`
      +`/form/${form}`;

    const url = NavigationUtil.pacLink(path);
    dispatch(push(url));
  }

  onPlanChangeClick(){
    const {
      dispatch,
      admissionId,
      conversations
    } = this.props;

    let openPlanConvoId = -1;
    let list = StoreUtil.getData(conversations);
    if(list && Array.isArray(list)){
      const openPlanConvo = list.find((conversation)=>{
        return conversation.ConversationType == DataUtil.CONVERSATION_TYPE_PLAN_CHANGE
          && !DataUtil.isConversationResolved(conversation)
      });

      if(openPlanConvo){
        openPlanConvoId = openPlanConvo.id;
      }
    }

    if(openPlanConvoId > -1){
      dispatch(viewConversation(-1, admissionId, openPlanConvoId, true));
    }else{
      dispatch(viewCreatePlanChange(admissionId));
    }
  }

  onMessageClick(){
    const {
      dispatch,
      conversations,
      admissionId
    } = this.props;

    let conversationId = DataUtil.CONVERSATION_TYPE_OTHER;
    //find if conv of type other exists that is open
    let list = StoreUtil.getData(conversations);
    if(list && Array.isArray(list)){
      const requestConvo = list.find((conversation)=>{
        return conversation.ConversationType == DataUtil.CONVERSATION_TYPE_OTHER
          && !DataUtil.isConversationResolved(conversation)
      });

      if(requestConvo){
        conversationId = requestConvo.id;
      }
    }

    dispatch(viewConversation(-1, admissionId, conversationId, true));
  }

  render() {

    //find admission
    const { admission, classes } = this.props;

    const isPTSurgery = DataUtil.isPTSurgeryByAdmission(admission);

    if(StoreUtil.isLoadFailed(admission)){
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              className={classes.headerPageTitle}
              variant='title' color='error'>
              There was an issue loading the admission.  Please try again later.
            </Typography>
          </div>
        </div>
      );
    } else if(!StoreUtil.getData(admission)){
      return (
        <LoadingView />
      );
    }else{
      let data = StoreUtil.getData(admission);
      let patientData;
      let surgeryData;
      let patientHeader = '';
      if(data.Surgery){
        surgeryData = data.Surgery;
        if(data.Surgery.Patient){
          patientData = data.Surgery.Patient;
          const patientDOB = DateUtil.formatFromDB(patientData.BirthDate);
          patientHeader = `${patientData.LastName}, ${patientData.FirstName} DOB: ${patientDOB}`;
        }
      }

      let dialogMessage = 'You have not yet recorded any post-acute events for this patient.'+
        '\nAre there any events you need to report?';
      
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              className={classes.headerPageTitle}
              variant="display1">
              {patientHeader}
            </Typography>
          </div>
          <Paper>
            <div className={classes.dataContainer}>
              <DetailDataGroup
                schema={personalSchema}
                data={patientData}
              />
              <DetailDataGroup
                schema={healthcareSchema}
                data={patientData}
              />
              <DetailDataGroup
                schema={surgerySchema}
                data={surgeryData}
              />
              <DetailDataGroup
                schema={admissionSchema}
                data={data}
              />
            </div>
          </Paper>
          <div className={classes.spacer} />
          <Paper className={classes.actionContainer}>
            <Button
              className={classes.actionButton}
              variant="contained"
              color={!isPTSurgery && "primary"}
              onClick={this.onInitialEvalClick.bind(this, isPTSurgery)}>
              Initial Eval
            </Button>
            { !isPTSurgery &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color={!isPTSurgery && "primary"}
                onClick={this.onEDClick}>
                ED Visit
              </Button>
            }
            { !isPTSurgery &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color={!isPTSurgery && "primary"}
                onClick={this.onDischargeClick}>
                Discharge Patient
              </Button>
            }
            
            <Button
              className={classes.actionButton}
              variant="contained"
              color={!isPTSurgery && "primary"}
              onClick={this.onPlanChangeClick}>
              Plan Change Rationale
            </Button>

            { !isPTSurgery &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color={!isPTSurgery && "primary"}
                onClick={this.onMessageClick}>
                Message
              </Button>
            }

            { isPTSurgery &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color={!isPTSurgery && "primary"}
                onClick={this.onDischargeClick}>
                Discharge form
              </Button>
            }

            {/* this button is last on the PT Surgery */}
            { isPTSurgery &&
              <Button
                className={classes.actionButton}
                variant="contained"
                color={!isPTSurgery && "primary"}
                onClick={this.onEDClick}>
                ED Visit
              </Button>
            }

          </Paper>
          <div className={classes.spacer} />
          {!isPTSurgery && <AdmissionConversationView
            admission={data}
          />}
          <div className={classes.spacer} />
          <AdmissionHospitalVisitsView admission={data} isPAC={true} />
          <ConfirmDialog
            title={'Post-Acute Event Confirm'}
            contentText={dialogMessage}
            onConfirm={this.onDischargeCheckConfirm}
            onCancel={this.onDischargeCheckCancel}
            isOpen={this.state.dischargeCheckOpen}
            cancelText={'No'}
            confirmText={'Yes'}
          />
        </div>
      );
    }
  }
}

PACAdmissionDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  admission : PropTypes.object.isRequired,
  admissionId : PropTypes.string.isRequired,
  conversations : PropTypes.object.isRequired,
  hospitalVisits : PropTypes.object.isRequired
}

function mapStateToProps(state, existingProps) {
  const {
    admissions,
    messages,
    hospitalVisits
  } = state;
  //find surgery

  const { match } = existingProps;
  const admissionId = match.params.id;
  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);
  const conversations = StoreUtil.get(messages, "admission", admissionId);

  return {
    admissionId,
    admission,
    conversations,
    hospitalVisits:StoreUtil.get(hospitalVisits, "admission")
  };
}

const styled = withStyles(styles)(PACAdmissionDetailPage);
const connected = connect(mapStateToProps)(styled);
export { connected as PACAdmissionDetailPage };
