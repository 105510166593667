import ReducerUtil from 'reducers/ReducerUtil'
import DataUtil from 'util/DataUtil';

import {
  API_GET_SURGICALTEAMS_LIST
} from 'actions/surgicalTeamActions';

export const surgicalTeams = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'surgicalTeams');
  if(logout.actionConsumed){
    return logout.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_GET_SURGICALTEAMS_LIST);
  if(list.actionConsumed){
    DataUtil.sort(list.state.list.data, "Name");
    return list.state;
  }

  return state;
}
