import ReducerUtil from 'reducers/ReducerUtil'
import DataUtil from 'util/DataUtil';

import {
  API_LIST_FACILITIES,
  API_TABLE_FACILITIES,
  API_GET_FACILITY,
  API_UPDATE_FACILITY_NAME,
  API_GET_FACILITY_NAME
} from 'actions/facilityActions'

export const facilities = (state = {}, action) => {
  const result = ReducerUtil.simpleReduce(
    state,
    action,
    'facilities',
    API_TABLE_FACILITIES,
    API_GET_FACILITY,
    API_UPDATE_FACILITY_NAME
  );

  if(result.actionConsumed){
    return result.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_LIST_FACILITIES)
  if(list.actionConsumed){
    if(list.state.list.data && list.state.list.data.length){
      DataUtil.sort(list.state.list.data, "Name");
    }
    return list.state;
  }

  const facName = ReducerUtil.reduceSingleGet(state, action, API_GET_FACILITY_NAME, 'id', 'name');
  if(facName.actionConsumed){
    return facName.state;
  }

  return state;
}
