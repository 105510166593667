import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { MaterialForm } from "components/forms/MaterialForm";
import StoreUtil from 'stores/StoreUtil';
import { createMessage } from 'actions/messageActions';
import { getAdmission } from 'actions/admissionActions';
import { Redirect } from 'react-router-dom';
import FormUtil from 'util/FormUtil';
import NavigationUtil from 'util/NavigationUtil'
import dotProp from 'dot-prop-immutable';
import DateUtil from 'util/DateUtil';
import { listReasons } from 'actions/reasonActions';
import DataUtil from 'util/DataUtil';

const schema = {
  title: '',
  type: 'object',
  required: ['LosExtension','Message', 'ReasonsForExtension'],
  properties: {
    LosExtension: {
      type: 'number',
      title: 'LOS Extension',
      enum: [
        1,
        2,
        3
      ],
      enumNames: [
        "1 day",
        "2 days",
        "3 days"
      ]
    },
    AnticipatedDischargeDate: {
      type: 'string',
      title: 'Anticipated Discharge Date',
      format: 'date'
    },
    ReasonsForExtension:{
      title: 'Reasons',
      type: "array",
      items: {
        type: 'number'
      }
    },
    Message: {
      type: 'string',
      title: 'Message'
    }
  }
};

const uiSchema = {
  LosExtension:{
    classNames:'two-column-field'
  },
  AnticipatedDischargeDate:{
    classNames:'two-column-field',
    "ui:readonly": true
  },
  ReasonsForExtension:{
    classNames:'two-column-field'
  },
  Message:{
    "ui:widget": "textarea"
  }
};

const schemaPT = {
  title: '',
  type: 'object',
  required: ['NumberOfVisits', 'Reasons'],
  properties: {
    NumberOfVisits: {
      type: 'string',
      title: 'Number Of Extended Visits'
    },
    Reasons: {
      type: 'string',
      title: 'Reasons'
    }
  }
};

const uiSchemaPT = {
  NumberOfVisits:{
    classNames:'two-column-field'
  },
  Reasons:{
    "ui:widget": "textarea"
  }
};

const styles = (theme) => ({
  pageContainer:{
    width: 800 + (theme.spacing.unit*4),
    maxWidth: 800 + (theme.spacing.unit*4),
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  reasonContainer:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'center',
    width:'100%'
  },
  reasonHeader:{
    width:'50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    marginTop:theme.spacing.unit*2
  },
  reasonItem:{
    width:'50%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    paddingLeft:theme.spacing.unit*2,
    boxSizing: 'border-box',
    paddingRight:theme.spacing.unit,
    backgroundColor:'#F8F8F8'
  },
  inputContainer:{
    width:'80%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    position:'relative'
  },
  inputLabel:{
    transform: 'none',
    lineHeight:'120%',
    zIndex:10,
    position:'relative'
  }
});

class PACPlanChangeRationalePage extends React.Component {

  constructor(props) {
    super(props);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onDataTransform = this.onDataTransform.bind(this);
  }

  componentDidMount(){
    const {
      dispatch,
      reasons,
      admissionId,
      admission
    } = this.props;

    if(StoreUtil.needsLoadLongCache(admission)){
      dispatch(getAdmission(admissionId));
    }

    if(StoreUtil.needsLoadNoCache(reasons)){
      let query = {
        where:{
          Type:"Extension"
        }
      };
      dispatch(listReasons(query,true));
    }
  }

  onFormSubmit(data){
    const { admissionId, dispatch } = this.props;
    const formData = data.formData;

    formData.ConversationType = "plan_change_rationale";
    formData.AdmissionId = admissionId;

    let reasons = [];

    formData.ReasonsForExtension.map((item) => {
      if(item){
        reasons.push(item)
      }
    })

    formData.ReasonsForExtension = reasons;

    dispatch(createMessage(formData));
  }

  onDataTransform(formData){
    const {admission} = this.props;
    
    let newDate = moment(admission.AnticipatedDischargeDate).add(formData.LosExtension, 'days').toDate();
    newDate = moment(newDate).format(DateUtil.FORM_DATE_FORMAT);

    //Initialize seconrdary reasons
    if(!formData.ReasonsForExtension){
      formData = dotProp.set(formData, 'ReasonsForExtension', []);
    }

    formData = dotProp.set(formData, "AnticipatedDischargeDate", newDate);

    return formData;
  }

  onFormError(){
  }

  render() {
    const {
      classes,
      message,
      admission,
      admissionId,
      reasons,
      isPTSurgery
    } = this.props;

    //save happened, lets redirect
    if (StoreUtil.didJustSave(message)) {
      return (<Redirect to={NavigationUtil.pacLink(
        `/admission/${admissionId}/conversation/${StoreUtil.getData(message).id}/`) } />);
    }

    let patientStr = "";
    let data = StoreUtil.getData(admission);
    if(data && data.Surgery && data.Surgery.Patient){
      let patientData = data.Surgery.Patient;
      patientStr = `${patientData.FirstName} ${patientData.LastName}`;
    }
    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(message, 'plan change');

    const usedUiSchema = isPTSurgery ? uiSchemaPT : uiSchema;

    let usedSchema = isPTSurgery ? schemaPT : schema;
    if(StoreUtil.getData(reasons) && !isPTSurgery){
      //enum for reason
      let reasonIds = [];
      let reasonNames = [];
      const reasonList = StoreUtil.getData(reasons);
      reasonList.forEach((reason)=>{
        reasonIds.push(reason.id);
        reasonNames.push(reason.Text);
      });

      usedSchema = dotProp.set(usedSchema, 'properties.ReasonsForExtension.items.enumNames', reasonNames);
      usedSchema = dotProp.set(usedSchema, 'properties.ReasonsForExtension.items.enum', reasonIds);
    }
    
    return (
      <div className={classes.pageContainer}>
        <div className={classes.header}>
          <Typography
            className={classes.headerPageTitle}
            variant="display1">
            Plan Change Rationale - {patientStr}
          </Typography>
        </div>
        <MaterialForm
          schema={usedSchema}
          uiSchema={usedUiSchema}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          onDataTransform={this.onDataTransform}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

PACPlanChangeRationalePage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  admissionId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  admission: PropTypes.object.isRequired,
  reasons: PropTypes.object.isRequired,
  isPTSurgery: PropTypes.bool.isRequired
};

function mapStateToProps(state, existingProps) {
  const{
    messages,
    admissions,
    reasons
  } = state;

  const { match } = existingProps;
  let admissionId;
  try{
    admissionId = parseInt(match.params.admissionId);
  }catch(error){
    console.log(error);
  }

  let message = StoreUtil.get(messages, StoreUtil.COMMON_NEW_ITEM);
  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_ITEM, admissionId);

  const isPTSurgery = DataUtil.isPTSurgeryByAdmission(admissions.item[admissionId]);

  return {
    reasons : StoreUtil.get(reasons, StoreUtil.COMMON_LIST),
    message,
    admissionId,
    admission,
    isPTSurgery
  }
}

const styledPage = withStyles(styles)(PACPlanChangeRationalePage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PACPlanChangeRationalePage};
