import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import DataUtil from 'util/DataUtil';
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';
// import DateUtil from 'util/DateUtil';

import { getPatientDetailByMRN, updatePatient } from 'actions/patientActions'

const schemaEdit = {
  title: 'Patient',
  type: 'object',
  required: ['MedicalRecNum','FirstName', 'LastName', 'BirthDate', 'Gender', 
    'MartialStatus', 'Address1', 'State',
    'Zip'],
  properties: {
    MedicalRecNum: {
      type: 'number',
      title: 'MRN'
    },
    FirstName: {
      type: 'string', 
      title: 'First Name'
    },
    LastName: {
      type: 'string', 
      title: 'Last Name'
    },
    BirthDate: {
      type: 'string', 
      title: 'Birth Date',
      format: 'date'
    },
    Gender: {
      type: 'string', 
      title: 'Gender',
      enum:[
        'female',
        'male'
      ]
    },
    MartialStatus: {
      type: 'string', 
      title: 'Marital Status',
      enum:[
        'Married',
        'Single'
      ]
    },
    Address1: {
      type: 'string', 
      title: 'Street Address'
    },
    Address2: {
      type: 'string', 
      title: 'Apartment, unit, floor, etc'
    },
    City: {
      type: 'string', 
      title: 'City'
    },
    State:{
      type: 'string',
      title: 'State',
      enumNames:[
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        "Federated States Of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming"
      ],
      enum:[
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY"
      ]
    },
    "Zip":{
      type: 'string',
      title: 'Zip',
      minLength: 5,
      maxLength: 5
    },
    HomePhone: {
      type: 'string', 
      title: 'Home Phone',
      "minLength": 10
    },
    CellPhone: {
      type: 'string', 
      title: 'Cell Phone',
      "minLength": 10
    },
    WorkPhone: {
      type: 'string', 
      title: 'Work Phone',
      "minLength": 10
    },
    Email: {
      type: 'string', 
      title: 'Email',
      "format": "email"
    },
    PrimaryCareProvider:{
      type: 'string', 
      title: 'Primary Care Provider'
    },
    CarePartnerName: {
      type: 'string', 
      title: 'Care Partner Name'
    },
    CarePartnerRelation: {
      type: 'string', 
      title: 'Care Partner Relation'
    },
    CarePartnerPhoneNumber: {
      type: 'string', 
      title: 'Care Partner Phone Number',
      "minLength": 10
    },
    Payor: {
      type: 'string', 
      title: 'Payor'
    }
  }
};

const schemeUI = {
  FirstName:{
    classNames:'two-column-field'
  },
  LastName:{
    classNames:'two-column-field'
  },
  BirthDate:{
    classNames:'two-column-field',
    "ui:options": {
      "birth": true
    }
  },
  Gender:{
    classNames:'two-column-field'
  },
  MartialStatus:{
    classNames:'two-column-field'
  },
  City:{
    classNames:'two-column-field'
  },
  State:{
    classNames:'two-column-field'
  },
  Zip:{
    classNames:'two-column-field'
  },
  HomePhone:{
    classNames:'two-column-field',
    "ui:options": {
      "inputType": "tel"
    }
  },
  CellPhone:{
    classNames:'two-column-field',
    "ui:options": {
      "inputType": "tel"
    }
  },
  WorkPhone:{
    classNames:'two-column-field',
    "ui:options": {
      "inputType": "tel"
    }
  },
  PrimaryCareProvider:{
    classNames:'two-column-field'
  },
  CarePartnerName:{
    classNames:'two-column-field'
  },
  CarePartnerRelation:{
    classNames:'two-column-field'
  },
  CarePartnerPhoneNumber:{
    classNames:'two-column-field',
    "ui:options": {
      "inputType": "tel"
    }
  },
  Payor:{
    classNames:'two-column-field'
  }
}

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class PatientEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {
      mrn, 
      // patient,
      isNewPatient,
      externalSource,
      dispatch} = this.props;
    
    // if(StoreUtil.needsLoadNoCache(patient) && !isNewPatient){
    if(!isNewPatient && mrn && externalSource){
      dispatch(getPatientDetailByMRN(mrn, externalSource));
    }
  }

  onFormSubmit(data){
    const {dispatch, mrn, backOnSuccess, isNewPatient, externalSource} = this.props;
    const formData = data.formData;

    if(!isNewPatient){
      formData.MedicalRecNum = mrn;
      formData.ExternalSource = externalSource
    }
    dispatch(updatePatient(formData, backOnSuccess));

  }

  onFormError(){
  }

  onFormChange(){
    
  }

  render() {
    const {
      classes,
      patient,
      isNewPatient
    } = this.props;

    let schema = DataUtil.deepCopy(schemaEdit);
    
    const patientData = StoreUtil.getData(patient);

    if(StoreUtil.isLoaded(patient) && !isNewPatient){
      schema.title = 'Patient ' +patientData.LastName 
        +', '+patientData.FirstName
        + ' (MRN: '+patientData.MedicalRecNum+')';

      delete schema.properties.MedicalRecNum;
      schema.required.splice(schema.required.indexOf('MedicalRecordNum'), 1);
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(patient, 'patient');

    // DateUtil.stripTimeFromDBDate(patientData, 'BirthDate');
    
    return (
      <div className={classes.pageContainer}>
        <MaterialForm 
          schema={schema}
          uiSchema={schemeUI}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError} 
          formData={patientData}
          allowSubmit={!StoreUtil.isSaving(patient)}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

PatientEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired, 
  patient : PropTypes.object.isRequired,
  mrn : PropTypes.number.isRequired,
  externalSource : PropTypes.string.isRequired,
  backOnSuccess: PropTypes.bool,
  isNewPatient: PropTypes.bool
};

function mapStateToProps(state, existingProps) {
  const {
    patients
  } = state;

  const { match } = existingProps;
  let mrn;
  let externalSource;
  let backOnSuccess = false;
  try{
    mrn = parseInt(match.params.mrn);
    externalSource = match.params.externalSource;
    if(isNaN(mrn)){
      mrn = 0;
    }
  }catch(error){
    console.log(error);
    mrn = 0;
  }

  if(match && match.params && match.params.surgeryId){
    backOnSuccess = true;
  }

  const isNewPatient = mrn == 0;
  let patient = null;
  if(!isNewPatient){
    patient = StoreUtil.get(patients, StoreUtil.COMMON_ITEM, mrn)
  }else{
    patient = StoreUtil.get(patients, StoreUtil.COMMON_NEW_ITEM, mrn)
  }

  return {
    patient,
    isNewPatient,
    mrn,
    externalSource,
    backOnSuccess
  }
}

const styledPage = withStyles(styles)(PatientEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as PatientEditPage};
