import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';


import { tableConversationsBySurgery, viewConversation } from 'actions/messageActions';
import { ConversationsTable } from 'components/ConversationsTable';
import { saveTablePreference } from 'actions/preferenceActions';
import StoreUtil from 'stores/StoreUtil'

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});
const TABLE_NAME = "SurgeryConversationsView";
class SurgeryConversationsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitial();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.surgery != null &&
      (prevProps.surgery == null || this.props.surgery.id != prevProps.surgery.id )) {
      this.fetchInitial();
    }
  }

  fetchInitial() {
    this.setState({query: this.buildInitialQuery() }, this.fetch.bind(this));
  }

  buildInitialQuery() {
    const {
      surgery,
      preferenceQuery
    } = this.props;

    let query = {
      where: {},
      order:[['LastMessageDate','DESC']],
      offset: 0,
      limit:25
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    query.where["SurgeryId"] = surgery.id;

    return query;
  }

  fetch() {
    const { dispatch, surgery } = this.props;
    const { query } = this.state;

    dispatch(tableConversationsBySurgery(surgery.id, query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;

    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetch.bind(this));
  }

  onRowClick(conversation) {
    const {
      dispatch,
      surgery
    } = this.props;
    dispatch(viewConversation(surgery.id, conversation.AdmissionId, conversation.id, false));
  }

  render() {
    const {
      query
    } = this.state;

    const {
      list
    } = this.props;

    let listData = StoreUtil.getData(list);
    
    if(listData && listData.count > 0){
      return (
        <React.Fragment>
          <ConversationsTable
            header={"Post-Acute Care Facility Requests"}
            data={list}
            query={query}
            showFilters={false}
            onQueryChange={this.onQueryChange.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            isPlanMode={false}
            isAdmissionMode={true}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }    
  }
}
SurgeryConversationsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  surgery: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const { messages, preference } = state;

  return {
    list: StoreUtil.get(messages, "surgeryConversations"),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(SurgeryConversationsView);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryConversationsView };