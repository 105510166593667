import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

import { LoadingView } from 'components/LoadingView';
import { MessageView } from 'containers/messages/MessageView';
import { MessageComposeView } from 'containers/messages/MessageComposeView';
import StoreUtil from 'stores/StoreUtil';
import DateUtil from 'util/DateUtil';
import DataUtil from 'util/DataUtil';

import { listMessages, loadConversationById, resolveConversation } from 'actions/messageActions';
import { MessagesHeaderView } from 'components/message/MessagesHeaderView'


const styles = theme => ({
  pageContainer:{
    width: "100%"
  },
  backButton: {
    marginBottom: theme.spacing.unit*2
  },
  card: {
    marginBottom: theme.spacing.unit*2
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

class ConversationDetailView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.loadIfNeeded();
  }

  loadIfNeeded(){
    const {
      isNew
    } = this.props;

    if(!isNew){
      this.fetchInitialMessages();
      this.fetchConversation();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      message,
      messages
    } = this.props;

    if (this.props.match.params.conversationId != prevProps.match.params.conversationId ||
      this.props.match.params.messageId != prevProps.match.params.messageId) {
      this.loadIfNeeded();
    }

    //check if there is a new sent message, in which case, lets reload the list
    const lastMessageSent = StoreUtil.getLastSaveTime(message);
    const lastListLoad = StoreUtil.getLastLoadTime(messages);
    if(lastMessageSent && lastListLoad
      && lastMessageSent > lastListLoad
      && !StoreUtil.isLoading(messages)){
      this.fetchInitialMessages();
    }
  }

  fetchInitialMessages() {
    this.setState({query: this.buildInitialQuery() }, this.fetchMessages.bind(this));
  }

  fetchConversation() {
    const {
      dispatch,
      match
    } = this.props;

    dispatch(loadConversationById(parseInt(match.params.conversationId)));
  }

  buildInitialQuery() {
    const {
      //viewAsUser,
      match
    } = this.props;

    let query = {
      include: [
        {
          association: 'ReadBy',
          attributes: [
            'id',
            'FirstName',
            'LastName',
            'Username'
          ]
        },
        {
          association: 'Sender',
          attributes: [
            'id',
            'FirstName',
            'LastName',
            'Username'
          ]
        },
        {
          association: 'Conversation',
          attributes: [
            'status'
          ]
        }
      ],
      where: {
        ConversationId: match.params.conversationId
      },
      limit: 5000,
      offset: 0
    };

    return query;
  }

  fetchMessages() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listMessages(query));
  }

  onResolveClick() {
    const {
      dispatch,
      match
    } = this.props;

    dispatch(resolveConversation(parseInt(match.params.conversationId)));
  }

  goBack() {
    const {
      history
    } = this.props;

    history.goBack();
  }

  render() {
    const {  
      classes,
      messages,
      conversation,
      match,
      isNew,
      isPAC
    } = this.props;

    if(!isNew 
      && (StoreUtil.isLoadFailed(messages) || StoreUtil.isLoadFailed(conversation))){
      return (
        <Typography color="error">
          There was an issue loading the conversation.  Please try again later.
        </Typography>
      );
    }

    let messagesData = StoreUtil.getData(messages);
    let conversationData = StoreUtil.getData(conversation);
    if (!isNew && (!messagesData ||
        !conversationData)) {
      return (<LoadingView />);
    }

    let surgeryId;
    let admissionId = parseInt(match.params.admissionId);
    if(isPAC){
      surgeryId = -1;
    }else{
      surgeryId = parseInt(match.params.surgeryId);
    }

    if(isNew){
      return (
        <div className={classes.pageContainer}>
          <MessageComposeView 
            surgeryId={surgeryId}
            admissionId={admissionId}
            newConversationType={match.params.conversationId}
            isPAC={isPAC} />
        </div>
      );
    }else{
      return (
        <div className={classes.pageContainer}>
          <MessagesHeaderView
            conversation={conversation}
            isPAC={isPAC}
            surgeryId={surgeryId}
          />

          {(messagesData.count > 0) ?
            (messagesData.rows.map(message => {
              return (<MessageView
                key={message.id+"-"+message.IsRead}
                message={message}
              />);
            }))
            : //else no messages
            (
              <Card className={classes.card} key="no-messages">
                <CardContent>
                  <Typography variant="body1">
                    No Messages Found
                  </Typography>
                </CardContent>
              </Card>
            )
          }
          {(DataUtil.isConversationResolved(conversationData)) ?
            (
              <Card className={classes.card} key="resolved">
                <CardContent>
                  <Typography variant="body1">
                    Resolved by
                    {" "+conversationData.User.FirstName}
                    {" "+conversationData.User.LastName+" "}
                    on
                    {" "+DateUtil.formatFromDB(conversationData.updatedAt)}
                  </Typography>
                </CardContent>
              </Card>
            )
            : //unresolved, allow reply
            (
              <React.Fragment>
                <MessageComposeView
                  conversationId={parseInt(match.params.conversationId)}
                  surgeryId={surgeryId}
                  admissionId={admissionId}
                  isPAC={isPAC} />
                {!isPAC && 
                  <Button
                    color="primary"
                    size="small"
                    onClick={this.onResolveClick.bind(this)}
                    variant="contained">
                    <Icon className={classes.leftIcon}>check_circle</Icon>
                    Mark as Resolved
                  </Button>
                }
              </React.Fragment>
            )
          }
        </div>
      );
    }
  }
}

ConversationDetailView.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  message: PropTypes.object,
  isNew : PropTypes.bool.isRequired,
  isPAC : PropTypes.bool.isRequired
};

function mapStateToProps(state, props) {
  const {
    messages,
    user
  } = state;

  const {
    match,
    isPAC
  } = props;

  var { viewAsUser } = user;
  if(viewAsUser == null || isPAC){
    viewAsUser = user;
  }

  let isNew = match.params.conversationId == DataUtil.CONVERSATION_TYPE_OTHER 
    || match.params.conversationId == DataUtil.CONVERSATION_TYPE_PLAN_CHANGE ;

  let conversationId = 0;
  if(!isNew){
    conversationId = parseInt(match.params.conversationId)
    if(isNaN(conversationId)){
      conversationId = 0;
    }
  }

  let conversation;
  if(isNew){
    conversation = StoreUtil.get(messages, "conversation", StoreUtil.COMMON_NEW_ITEM)
  }else{
    conversation = StoreUtil.get(messages, "conversation", conversationId)
  }

  return {
    messages: StoreUtil.get(messages, StoreUtil.COMMON_LIST),
    conversation,
    message: StoreUtil.get(messages, StoreUtil.COMMON_ITEM, conversationId),
    viewAsUser,
    isNew
  };
}

const styledPage = withStyles(styles)(ConversationDetailView);
const connected = connect(mapStateToProps)(styledPage);
const routed = withRouter(connected);
export {routed as ConversationDetailView};
