import { takeEvery, put } from 'redux-saga/effects';
import { goBack, push } from 'connected-react-router';

function* takeUserBack() {
  yield put(goBack());
}

function* navigateUser(action) {
  yield put(push(action.meta.requestAction.redirectOnSuccess));
}

export const navigateOnSuccessSaga = [
  takeEvery( (action) => {
    const isSuccess = action.type.endsWith("_SUCCESS")
    if(!isSuccess){
      return false;
    }
    return action.meta
      && action.meta.requestAction 
      && action.meta.requestAction.backOnSuccess 
      && action.meta.requestAction.backOnSuccess ===true;
  }, takeUserBack),
  takeEvery( (action) => {
    const isSuccess = action.type.endsWith("_SUCCESS")
    if(!isSuccess){
      return false;
    }
    return action.meta
      && action.meta.requestAction 
      && action.meta.requestAction.redirectOnSuccess 
      && action.meta.requestAction.redirectOnSuccess.length > 1;
  }, navigateUser)
];
