import React, {Component} from 'react';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class PatientsBCItem extends Component {

  render() {
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label="Patients"
          path={NavigationUtil.navLink("/patients")}
        />
      </React.Fragment>
    );
  }
}

PatientsBCItem.propTypes = {
};


export {PatientsBCItem as PatientsBCItem };
