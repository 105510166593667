import { push } from 'connected-react-router';
import NavigationUtil from 'util/NavigationUtil'
import moment from 'moment';

export const API_ADMISSION_CREATE = 'API_ADMISSION_CREATE';
export const API_GET_ADMISSIONS = 'API_GET_ADMISSIONS';
export const API_GET_ADMISSION = 'API_GET_ADMISSION';
export const UPDATE_SURGERY_LOS = 'UPDATE_SURGERY_LOS';
export const UPDATE_ADMISSION = 'UPDATE_ADMISSION';

const lengthOfStay = (admissionDate, dischargeDate) => {
  let periodStart = moment(admissionDate);
  let periodEnd = moment(dischargeDate);
  const days = periodEnd.diff(periodStart, 'days');
  return days;
}

export const updateSurgeryTableWithLOS = (admissionDate, dischargeDate, surgeryId) => {
  const los = lengthOfStay(admissionDate, dischargeDate);
  return {
    type: UPDATE_SURGERY_LOS,
    request: {
      url: '/UpdateSurgeryLOS',
      method: 'POST',
      data: { los, surgeryId }
    }
  }
}

export const createAdmission = (formData, backOnSuccess = false)=> {
  let id = -1;
  if(formData.id){
    id = formData.id;
  }
  return {
    type: API_ADMISSION_CREATE,
    id:id,
    backOnSuccess,
    request:{
      url: '/Admissions',
      method: 'PUT',
      data : formData
    }
  }
};

export const updateAdmission = (data)=> {
  return {
    type: UPDATE_ADMISSION,
    request: {
      url: '/Admissions',
      method: 'PUT',
      data,
      params: {
        opts: {
          where: {
            id: data.id
          }
        }
      }
    }
  }
}

export const listAdmissions = (options, navigatorId, facilityOptions = {})=> {
  if(navigatorId && navigatorId > 0 && !options.include){

    options.include = [{
      association: 'Facility',
      attributes: ['Name'],
      ...facilityOptions
    },
    {
      association: 'Surgery',
      attributes: ['SurgeryDateTime', 'VisitType'],
      required: true,
      include: [ {
        association: 'Patient',
        attributes: [ 'FirstName', 'LastName', 'MedicalRecNum', 'ExternalSource']
      },
      {
        association: 'Surgeon',
        attributes: ['id'],
        required: true,
        include: [
          {
            association: 'Navigator',
            attributes:['id'],
            where: {
              id: navigatorId
            }
          }
        ]
      }
      ]
    }
    ]
  }

  return {
    type: API_GET_ADMISSIONS,
    request:{
      url: '/Admissions',
      method: 'GET',
      params: { opts:options }
    }
  }
};

export const getAdmission = (admissionId)=> {
  const options = {
    where: {id:admissionId},
    include: [
      {
        association:'Surgery',
        attributes:['id', 'SurgeryDateTime', 'ProcedureName', 'VisitType'],
        required: true,
        include: [
          {
            association:'Surgeon',
            attributes:['id', 'Name'],
            include: [
              {
                association:'Navigator',
                attributes:['id', 'Username', 'FirstName', 'LastName']
              }
            ],
            required: true
          },
          {
            association:'Patient',
            attributes:['id', 'LastName', 'FirstName','BirthDate', 'Gender',
              'HomePhone','CellPhone','Address1','Address2',
              'City','State','Zip','CarePartnerName',
              'CarePartnerRelation','CarePartnerPhoneNumber','Payor'],
            required: true
          },
          {
            association:'Hospital',
            attributes:['id', 'Name'],
            required: false
          }
        ]
      },
      {
        association:'Facility',
        attributes:['id', 'Name']
      }
    ]
  }

  return {
    type: API_GET_ADMISSION,
    id: admissionId,
    request: {
      url: '/Admissions',
      method: 'GET',
      params: {opts:options}
    }
  }
};

export const viewAdmission = (admission, isPAC = true)=> {
  if(isPAC){
    return push(NavigationUtil.pacLink('/admission/'+admission.id));    
  }else{
    return push(NavigationUtil.navLink(`/surgery/${admission.SurgeryId}/admission/${admission.id}`));
  }
  
};
