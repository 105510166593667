import React from 'react';
import { PropTypes } from 'prop-types';
import DateUtil from 'util/DateUtil'
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display:'flex',
    marginTop: theme.spacing.unit * .75
  },
  nameField: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
    width: 150,
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize : theme.typography.fontSize + 1
  },
  valueField: {
    marginTop: 0,
    marginBottom: 0,
    width: 220,
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'lighter',
    fontSize : theme.typography.fontSize + 1
  },
  valueFieldWide: {
    width: 440
  }
});

class DetailDataField extends React.Component {

  calculateAge(birthday){ // birthday is a date
    var birthdayFormatted = moment(birthday, 'MM/DD/YYYY').format('YYYY-MM-DD');
    return moment().diff(birthdayFormatted, 'years');
  }

  daysUntil(date){ 
    var now = moment().startOf('day');
    let daysUntil = moment(date).diff(now,'days');
    return daysUntil;
  }

  render() {
    let {
      classes,
      value,
      fieldDef,
      wideValue
    } = this.props;

    if (value == null){
      value = '';
    }else if(fieldDef.format){
      switch(fieldDef.format){
      case 'age':{
        // const birth = DateUtil.parseDateFromDB(value);
        const age = this.calculateAge(value);
        value = DateUtil.formatDateForUser(value) + ' (Age:'+age+')';
        break;
      }
      case 'date':{
        // const date = DateUtil.parseDateFromDB(value);
        // value = DateUtil.formatDateForUser(date);
        break;
      }
      case 'daysUntil':{
        // const date = DateUtil.parseDateFromDB(value);
        let daysUntil = this.daysUntil(value);
        if(daysUntil < 0){
          daysUntil = Math.abs(daysUntil);
          if(daysUntil == 1){
            value = daysUntil +' day ago';
          }else{
            value = daysUntil +' days ago';
          }
        }else{
          if(daysUntil == 1){
            value = daysUntil +' day';
          }else{
            value = daysUntil +' days';
          }
        }
        
        
        break;
      }
      }
    } else if(fieldDef.enum && Array.isArray(fieldDef.enum)) {
      let i = fieldDef.enum.indexOf(value);
      if (i >= 0) {
        value = fieldDef.enumNames[i];
      }
    }else if(fieldDef.type){
      //parse to specific types needed?  or just rely on tostring defaults
    }
    
    let valueClasses;
    if(wideValue){
      valueClasses = `${classes.valueField} ${classes.valueFieldWide}`;
    }else{
      valueClasses = `${classes.valueField}`;
    }
    return (
      <div className={classes.container}>
        <p className={classes.nameField}>{fieldDef.title}:</p>
        <p className={valueClasses}>{value}</p>
      </div>
    );
  }
}

DetailDataField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldDef : PropTypes.object.isRequired,
  value : PropTypes.any,
  wideValue: PropTypes.bool
};

const styled = withStyles(styles)(DetailDataField);
export {styled as DetailDataField};

