import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';
import Typography from '@material-ui/core/Typography';
import { LoadingView } from 'components/LoadingView';
import { resetPassword } from 'actions/loginActions';
import Grid from '@material-ui/core/Grid';
import owaspPasswordStrengthTest from 'owasp-password-strength-test';

owaspPasswordStrengthTest.config({
  allowPassphrases       : false,
  maxLength              : 128,
  minLength              : 8,
  minPhraseLength        : 20,
  minOptionalTestsToPass : 4
});

const schemaEdit = {
  title: 'Reset My Password',
  type: 'object',
  required: ['Password', 'Password2'],
  properties: {
    Password: {type: 'string', title: 'Password'},
    Password2: {type: 'string', title: 'Confirm Password'}
  }
};

const uiScheme = {
  Password: {
    'ui:widget': 'password'
  },
  Password2: {
    'ui:widget': 'password'
  }
}

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  },
  errorMessages:{
    color: 'red'
  }
});

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: []
    }

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(data){
    var postBody = new URLSearchParams(this.props.location.search);
    data.formData.username = postBody.get('username');
    data.formData.token = postBody.get('token');
    if (data.formData.Password === data.formData.Password2) {
      let result = owaspPasswordStrengthTest.test(data.formData.Password);

      if(result){
        if(result.errors.length > 0) {
          let errors = result.errors.map((error, index) =>
            <li key={index}>{error}</li>
          );
          this.setState({ errors: errors });
        } else {
          this.props.dispatch(resetPassword(data.formData));
        }
      }
    } else {
      this.setState({ errors: [ 'Passwords do not match.' ] });
    }
  }

  render() {
    if(this.props 
      && this.props.location 
      && this.props.location.pathname
      && this.props.location.pathname == '/setupAccount'){
      schemaEdit.title = 'Setup Account';
    }

    let message;
    let returnLink;

    if(this.props.resetPassword.result === 'Success'){
      message = 'Password set successfully';
      returnLink = <a href='https://orthonavigator.net'>{'Click Here'}</a>
    }
    if(this.props.resetPassword.result === 'Failure'){
      message = 'No password match';
    }

    if (this.props.resetPassword.isCalling) {
      return (<LoadingView />);
    }

    return (
      <div className={this.props.classes.pageContainer}>
        {message && <Typography variant='title' color='error'>{message}{returnLink}</Typography>}
        <MaterialForm
          schema={schemaEdit}
          uiSchema={uiScheme}
          onSubmit={this.onFormSubmit}
        />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <ul className={this.props.classes.errorMessages}>{this.state.errors}</ul>
        </Grid>
        
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  resetPassword : PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    resetPassword: state.user.resetPassword
  }
}

const styledPage = withStyles(styles)(ResetPasswordPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as ResetPasswordPage};