import { push } from 'connected-react-router';
import NavigationUtil from 'util/NavigationUtil'

export const API_SURGERY_UPDATE = 'API_SURGERY_UPDATE';
export const API_LIST_SURGERIES = 'API_LIST_SURGERIES';
export const API_GET_SURGERY = 'API_GET_SURGERY';
export const API_SURGERY_DASHVIEW = 'API_SURGERY_DASHVIEW';
export const API_SURGERY_COPY = 'API_SURGERY_COPY';

// ! surgeries now get ExteranlSource
export const getSurgery = (surgeryId)=> {
  const options = {
    include: [
      {
        association: 'Surgeon',
        include: [
          {
            association: 'Navigator',
            attributes:['FirstName', 'LastName']
          }
        ]
      },
      {
        association: 'DischargeDisposition',
        attributes: [ 'Text' ]
      },
      {
        association: 'Tasks',
        attributes: ['UpdatedById']
      },
      {
        association: 'SurgeryPertinentPositives',
        attributes: ['UpdatedById']
      },
      {
        association: 'Answers',
        attributes: ['UpdatedById']
      },
      {
        association: 'ProcedureType',
        attributes: ['ProcedureType']
      },
      {
        association: 'DischargeFacility',
        attributes: ['Name']
      },
      'Hospital',
      'User',
      'Admissions',
      {
        association:'Patient',
        required:false,
        attributes:['id','MedicalRecNum','BirthDate', 'LastName', 'FirstName', 'ExternalSource']
      }],
    where: {id: surgeryId}
  }
  return {
    type: API_GET_SURGERY,
    id: surgeryId,
    request: {
      url: '/Surgeries',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updateSurgery = (formData, backOnSuccess = false)=> {
  let options = null;
  let id = 0;
  if(formData.id && formData.id > 0){
    id = formData.id;
    options = {
      where: {id:id}
    }
  }

  return {
    type: API_SURGERY_UPDATE,
    id: id,
    surgery: formData,
    backOnSuccess,
    request:{
      url: '/Surgeries',
      method: 'PUT',
      params : {opts:options},
      data : formData
    }
  }
};

export const listSurgeries = (options)=> {
  return {
    type: API_LIST_SURGERIES,
    request: {
      url: '/SurgeryDashboardView',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const getSurgeryDashView = (surgeryId)=> {
  const options = {
    where: {id:surgeryId }
  }
  return {
    type: API_SURGERY_DASHVIEW,
    id: surgeryId,
    request: {
      url: '/SurgeryDashboardView',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const viewSurgery = (surgery)=> {
  return push(NavigationUtil.navLink('/surgery/'+surgery.id));
};

export const searchCopySurgery = (surgeryId, patientId)=> {
  return push(NavigationUtil.navLink('/surgery/'+surgeryId+'/copy/'+patientId));
};

export const copySurgeryData = (toSurgeryId, fromSurgeryId)=> {
  return {
    type: API_SURGERY_COPY,
    id: toSurgeryId,
    request: {
      url: '/CopySurgeryData',
      method: 'POST',
      data : {
        toId: toSurgeryId,
        fromId: fromSurgeryId
      }
    }
  }
};

export const updateRiskScore = (surgeryId)=> {
  return {
    type: API_SURGERY_COPY,
    id: surgeryId,
    request: {
      url: '/UpdateRiskScore',
      method: 'POST',
      data : {
        id: surgeryId
      }
    }
  }
};
