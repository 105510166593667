import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';

import StoreUtil from 'stores/StoreUtil'
import { SurgeryTable } from 'components/SurgeryTable';
import { HUDView } from 'containers/HUDView';
import { listSurgeries, viewSurgery } from 'actions/surgeryActions';
import { saveTablePreference } from 'actions/preferenceActions';
import TableUtil from 'util/TableUtil';
import NavigationUtil from 'util/NavigationUtil';
import DataUtil from 'util/DataUtil';
import { getSurgeryFlags } from 'actions/surgeryFlagActions';

const styles = ({
  pageContainer:{
    width: '100%'
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  }
});

const TABLE_NAME = "DashboardPage";

class DashboardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    const {dispatch} = this.props;
    this.fetchInitialSurgeries();
    dispatch(getSurgeryFlags())
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if ((this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id ))
      || prevProps.tab != this.props.tab) {
      this.fetchInitialSurgeries();
    }
  }

  fetchInitialSurgeries() {
    this.setState({query: this.buildInitialQuery() }, this.fetchSurgeries.bind(this));
  }

  buildInitialQuery(clearingFilters = false) {
    const {
      viewAsUser,
      preferenceQuery,
      tab
    } = this.props;

    let query = {
      where: { },
      limit: 25,
      offset: 0
    };

    query.order =[["DOS", "DESC"],["ID", "DESC"]];

    if(preferenceQuery && !clearingFilters){
      query = TableUtil.GetSavedQuery(preferenceQuery, tab);
    }

    if (viewAsUser) {
      query.where["NavigatorUserId"] = viewAsUser.id;
    }

    // Clear query filters
    delete query.$or;
    delete query.where["Active"];
    delete query.where["VisitStatus"];

    switch(tab){
    case 0:
      query.where["Active"] = true;
      query.where["VisitStatus"] = { $ne: 'Patient Cancelled' };
      break;
    case 1:
      query.where["Active"] = false;
      query.where["VisitStatus"] = { $ne: 'Patient Cancelled' };
      break;
    case 2:
      query.where["VisitStatus"] = 'Patient Cancelled';
      break;
    case 3:
      query.where["Active"] = true;
      query.where["VisitStatus"] = { $ne: 'Patient Cancelled' };
      query.where.$or = [
        { MedicalRiskLevel: { $eq: '' } },
        { SocialRiskLevel: {$eq: ''} }
      ]
    }

    return query;
  }

  fetchSurgeries() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listSurgeries(query));
  }

  clearFilters(){
    const clearingFilters = true;
    const query = this.buildInitialQuery(clearingFilters);
    this.onQueryChange(query);
  }

  onTabChange(event, tabNew) {
    const { tab, dispatch } = this.props;
    const clearFiltersTab  = 4;
    if(tab == tabNew){
      return;
    }
    if(tabNew === clearFiltersTab){
      return this.clearFilters();
    }
    dispatch(push(NavigationUtil.navLink(`/dashboard/${tabNew}`)));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;

    dispatch(saveTablePreference(TABLE_NAME, query, this.state.tab));
    this.setState({query: query }, this.fetchSurgeries.bind(this));
  }

  onRowClick(surgery) {
    const { dispatch } = this.props;
    dispatch(viewSurgery(surgery));
  }

  render() {
    const {
      classes,
      surgeries,
      tab,
      user,
      surgeryFlag
    } = this.props;

    const {
      query
    } = this.state;

    const isPTUser = DataUtil.isPTUser(user);
    const label = isPTUser ? "Missing Pac Forms" : "Missing Risk Levels";

    return (
      <div className={classes.pageContainer}>

        <HUDView />

        <Tabs value={tab} onChange={this.onTabChange}>
          <Tab label="Active" />
          <Tab label="Inactive" />
          <Tab label="Cancelled" />
          <Tab label={label} />
          <Tab label="Clear Filters" style={TableUtil.clearFilterStyles()}/>
        </Tabs>
        <SurgeryTable
          data={surgeries}
          query={query}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
          noDataMessage="No surgeries found. Try changing filters or select a different Navigator."
          isPTUser={isPTUser}
          surgeryFlag={surgeryFlag}
        />
      </div>
    );
  }
}
DashboardPage.defaultProps = {
  tab : 0
};

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  surgeries: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  tab: PropTypes.number.isRequired,
  surgeryFlag: PropTypes.array.isRequired
};

function mapStateToProps(state, existingProps) {
  const { user, surgeries, preference, surgeryFlag } = state;

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }

  const { match } = existingProps;
  let tab = parseInt(match.params.tab);
  if(!tab){
    tab = 0;
  }

  return {
    surgeries: StoreUtil.get(surgeries, StoreUtil.COMMON_TABLE),
    viewAsUser,
    preferenceQuery:preference.tables[TABLE_NAME],
    tab,
    user,
    surgeryFlag
  };
}

const styledPage = withStyles(styles)(DashboardPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as DashboardPage};
