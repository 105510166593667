import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';
import { DateUtil } from 'util/DateUtil';

const styles = ({
  lowRisk: {
    borderRadius: 5,
    backgroundColor: "#34a853",
    color: "#ffffff",
    padding: 4,
    textAlign:"center"
  },
  modRisk: {
    borderRadius: 5,
    backgroundColor: "#fbbc05",
    color: "#ffffff",
    padding: 4,
    textAlign:"center"
  },
  highRisk: {
    borderRadius: 5,
    backgroundColor: "#ea4335",
    color: "#ffffff",
    padding: 4,
    textAlign:"center"
  }
});

//hack to reference css statically
function riskFormat(risk, surgery, classes) {
  if (risk == "1") {
    return (<div className={classes.lowRisk}>Low</div>);
  } else if (risk == "2") {
    return (<div className={classes.modRisk}>Moderate</div>);
  } else if (risk == "3") {
    return (<div className={classes.highRisk}>High</div>);
  }
  return "";
}

//this controls which columns are displayed and how they are looked up in data
const columns = [
  {label:"MRN", key: "MRN", sortable: true, sortDown: false, style:{width:80}},
  {label:"Source", key: "ExternalSource", sortable: true, sortDown: false, style:{width:80}},
  {label:"Last", key: "PatientLastName", sortable: true, sortDown: false, style:{width:140}},
  {label:"First", key: "PatientFirstName", sortable: true, sortDown: false, style:{width:140}},
  {label:"M/F", key: "PatientGender", sortable: true, filter: true, style:{width:30}},
  {label:"Age", key: "PatientAge", sortable: true, sortDown: false,
    filter: true, style:{width:30}},
  {label:"DOS", key: "DOS", type:"date", sortable: true, sortDown: false, style:{width:240},
    format:DateUtil.formatFromDB},
  {label:"T/L", key: "TL", filterExact: true, sortable: true, sortDown: false, filter: true, style:{width:30}},
  {label:"Dispo", key: "Discharge", sortable: true, sortDown: false, style:{width:75}},
  {label:"Provider", key: "SurgeonName", sortable: true, sortDown: false, style:{width:100}},
  {label:"Procedure", key: "ProcedureName", sortable: true, sortDown: false, style:{width:220}},
  {label:"Location", key: "HospitalName", sortable: true, sortDown: false},
  {label:"Payor", key: "PayorName", sortable: true, sortDown: false, style:{width:100}},
  {label:"Med Risk", key: "MedicalRiskLevelNumeric", filterKey: "MedicalRiskLevel",
    sortable: true, sortDown: false, style:{width:75}, format:riskFormat},
  {label:"Soc Risk", key: "SocialRiskLevelNumeric", filterKey: "SocialRiskLevel",
    sortable: true, sortDown: false, style:{width:75}, format:riskFormat},
  {label:"Status", key: "VisitStatus", filter: false, style:{width:75}},
  {label:"Tasks", key: "Tasks", numeric: true, sortable: true,
    sortDown: false, filter: false, style:{width:50}}
];

const columnsForPTUser = [
  {label:"MRN", key: "MRN", sortable: true, sortDown: false, style:{width:80}},
  {label:"Last", key: "PatientLastName", sortable: true, sortDown: false},
  {label:"First", key: "PatientFirstName", sortable: true, sortDown: false, style:{width:60}},
  {label:"DOS", key: "DOS", type:"date", sortable: true, sortDown: false, style:{width:180},
    format:DateUtil.formatFromDB},
  {label:"A/V", key: "PTAnticipatedNumberOfVisits", sortable: true, sortDown: false, style:{width:50}},
  {label:"N/V", key: "PTNumberOfVisits", sortable: true, sortDown: false, style:{width:50}},
  {label:"T/L", key: "TL", filterExact: true, sortable: true, sortDown: false, filter: true, style:{width:40}},
  {label:"PT Location", key: "PTName", sortable: true, sortDown: false, style:{width:120}},
  {label:"Surgeon", key: "SurgeonName", sortable: true, sortDown: false, style:{width:100}},
  {label:"Procedure", key: "ProcedureName", sortable: true, sortDown: false, style:{width:75}},
  {label:"Location", key: "HospitalName", sortable: true, sortDown: false, style:{width:150}},
  {label:"Payor", key: "PayorName", sortable: true, sortDown: false, style:{width:100}},
  {label:"Status", key: "VisitStatus", filter: false, style:{width:75}},
  {label:"Tasks", key: "Tasks", numeric: true, sortable: true,
    sortDown: false, filter: false, style:{width:50}}
];

class SurgeryTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      navUsers,
      data,
      ...dynamicTableProps
    } = this.props;

    const dashboardColumns = dynamicTableProps.isPTUser ? columnsForPTUser : columns;

    let useDataObj = false;
    let usedColumns = false;
    // prepare the data with a Navigator column with a newData replacement
    if(navUsers && navUsers.list && navUsers.list.data && navUsers.list.data.length &&
       data && data.data && data.data.rows && data.data.rows.length){
      const newData = data.data.rows.map(data => {
        let navigator;
        navUsers.list.data.forEach(d => {
          if(d.id === data.NavigatorUserId){
            navigator = `${d.LastName}, ${d.FirstName}`;
          }
        })
        return { ...data, Navigator: navigator }
      })

      useDataObj = JSON.parse(JSON.stringify(data))
      usedColumns = JSON.parse(JSON.stringify(dashboardColumns))
      useDataObj.data.rows = newData;
      usedColumns.push({ label:"Navigator", key: "Navigator", sortable: true, sortDown: false })
    }

    return (
      <DynamicTable
        columns={usedColumns || dashboardColumns}
        rowClasses={classes}
        data={useDataObj || data}
        {...dynamicTableProps}
      />
    );
  }
}

SurgeryTable.propTypes = {
  ...DynamicTable.propTypes
}
const styled = withStyles(styles)(SurgeryTable);
export {styled as SurgeryTable};
