import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';

import { Footer } from 'components/Footer';
import { Header } from 'containers/Header';
import { NurseNavContentArea } from 'containers/nurseNav/NurseNavContentArea';

const styles = () =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
});

class NurseNavRoot extends Component {
  render() {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.container}>
        <Header />
        <NurseNavContentArea />
        <Footer className={classes.footer}/>
      </div>
    );
  }
}

NurseNavRoot.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledApp = withStyles(styles)(NurseNavRoot);
export {styledApp as NurseNavRoot};
