import { success } from 'redux-saga-requests';

import {
  LIST_MESSAGING_LOG
} from 'actions/navMessagingLogActions';

export const navMessagingLog = (state = {}, action) => {
  if(action.type === success(LIST_MESSAGING_LOG)) {
    return action.data;
  }

  return state;
}