import ReducerUtil from 'reducers/ReducerUtil';

import {
  API_PATIENT_DETAIL,
  API_PATIENT_TABLE,
  // API_PATIENT_EHR,
  API_PATIENT_UPDATE,
  API_PATIENT_LIST
} from 'actions/patientActions';

export const patients = (state = {}, action) => {
  const result = ReducerUtil.simpleReduce(
    state, 
    action, 
    'patients',
    API_PATIENT_TABLE,
    API_PATIENT_DETAIL,
    API_PATIENT_UPDATE,
    'mrn',
  );
  if(result.actionConsumed){
    return result.state;
  }

  const list = ReducerUtil.reduceListGet(state, action, API_PATIENT_LIST);
  if(list.actionConsumed){
    return list.state;
  }

  // ! NOTE this action is not called
  // ehr data single call
  // const ehrData = ReducerUtil.reduceSingleGet(state, action, API_PATIENT_EHR, 'id', 'ehr');
  // if(ehrData.actionConsumed){
  //   return ehrData.state;
  // }

  return state;
}
