import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Icon,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';



import { TableMaintenanceTable } from 'components/TableMaintenanceTable';
import StoreUtil from 'stores/StoreUtil'
import {
  listReasons,
  createReason
} from 'actions/reasonActions';
import {
  listReadmissionFacilities,
  createReadmissionFacility
} from 'actions/readmissionFacilityActions';
import {
  listDischargeFacilities,
  createDischargeFacility
} from 'actions/dischargeFacilityActions';
import {
  listProcedureTypes,
  createProcedureType
} from 'actions/procedureTypeActions';

const TABLE_NAME = "TableMaintenancePage";

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

const options = [
  'Available Tables',
  'Reasons',
  'Readmission Facilities',
  'Discharge Facilities',
  'Procedure Types'
];

class TableMaintenancePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {},
      selectedTable: "reasons",
      reasonsQuery: false,
      readmissionFacilityQuery: false,
      dischargeFacilityQuery: false,
      procedureTypeQuery: false,
      open: false,
      additionType: null,
      input: null,
      inputLabel: null,
      type: null,
      anchorEl: null,
      selectedIndex: 1
    };
    this.getTableData = this.getTableData.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.handleAcceptAddition = this.handleAcceptAddition.bind(this);
    this.handleRejectionAddition = this.handleRejectionAddition.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.setInputValue = this.setInputValue.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.handleClickListItem = this.handleClickListItem.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    this.setState({query: this.buildInitialQuery() }, this.fetchTableData.bind(this));
  }

  buildInitialQuery() {

    let query = {
      where: { },
      limit: 25,
      offset: 0
    };

    return query;
  }

  fetchTableData() {
    const { dispatch } = this.props;
    const { query } = this.state;

    // fetch all data to be shown on the table maintenance page
    dispatch(listReasons({
      order: [["Text", "ASC"]],
      ...query
    },false));
    dispatch(listReadmissionFacilities({
      order: [["Name", "ASC"]],
      ...query
    }));
    dispatch(listDischargeFacilities({
      order: [["Name", "ASC"]],
      ...query
    }));
    dispatch(listProcedureTypes({
      order: [["ProcedureType", "ASC"]],
      ...query
    }));
  }

  onQueryChange(query, table) {
    const {
      dispatch
    } = this.props;
    if(table === "reasons"){
      this.setState({
        reasonsQuery: query
      }, () => dispatch(listReasons({
        order: [["Text", "ASC"]],
        ...query
      },false)));
    } else if(table === "readmissionFacilities"){
      this.setState({
        readmissionFacilityQuery: query
      }, () => dispatch(listReadmissionFacilities({
        order: [["Name", "ASC"]],
        ...query
      })));
    } else if(table === "dischargeFacilities"){
      this.setState({
        dischargeFacilityQuery: query
      }, () => dispatch(listDischargeFacilities({
        order: [["Name", "ASC"]],
        ...query
      })));
    } else if(table === "procedureTypes"){
      this.setState({
        procedureTypeQuery: query
      }, () => dispatch(listProcedureTypes({
        order: [["ProcedureType", "ASC"]],
        ...query
      })));
    }
  }

  getTableData() {
    const {
      reasons,
      readmissionFacilities,
      dischargeFacilities,
      procedureTypes
    } = this.props;
    return [
      {
        title: "Reasons",
        data: reasons,
        columns: [ {label:"Reason", key: "Text", style:{width:200}, sortable: true}, {label:"Type", key: "Type"} ],
        key: "reasons",
        query: this.state.reasonsQuery || JSON.parse(JSON.stringify(this.state.query))
      },
      {
        title: "Readmission Facilities",
        data: readmissionFacilities,
        columns: [ {label:"Facility", key: "Name", sortable: true} ],
        key: "readmissionFacilities",
        query: this.state.readmissionFacilityQuery || JSON.parse(JSON.stringify(this.state.query))
      },
      {
        title: "Discharge Facilities",
        data: dischargeFacilities,
        columns: [ {label:"Facility", key: "Name", sortable: true} ],
        key: "dischargeFacilities",
        query: this.state.dischargeFacilityQuery || JSON.parse(JSON.stringify(this.state.query))
      },
      {
        title: "Procedure Types",
        data: procedureTypes,
        columns: [ {label:"Procedure", key: "ProcedureType", sortable: true} ],
        key: "procedureTypes",
        query: this.state.procedureTypeQuery || JSON.parse(JSON.stringify(this.state.query))
      }
    ]
  }

  handleAddButtonClick(additionType) {
    let inputLabel = null;
    if(additionType === "reasons"){
      inputLabel = "Reason";
    } else if(additionType === "readmissionFacilities"){
      inputLabel = "Facility";
    } else if(additionType === "dischargeFacilities"){
      inputLabel = "Facility";
    } else if(additionType === "procedureTypes"){
      inputLabel = "Procedure";
    }
    this.setState({
      open: true,
      additionType,
      inputLabel
    })
  }

  handleAcceptAddition() {
    const { user, dispatch } = this.props;
    
    if(this.state.additionType === "reasons"
      && this.state.input.trim().length
      && this.state.type.trim().length){
      dispatch(createReason({
        Text: this.state.input,
        Type: this.state.type,
        UpdatedById: user.id
      }))
    } else if(this.state.additionType === "readmissionFacilities"
      && this.state.input.trim().length){
      dispatch(createReadmissionFacility({
        Name: this.state.input,
        UpdatedById: user.id
      }))
    } else if(this.state.additionType === "dischargeFacilities"
      && this.state.input.trim().length){
      dispatch(createDischargeFacility({
        Name: this.state.input,
        UpdatedById: user.id
      }))
    } else if(this.state.additionType === "procedureTypes"
      && this.state.input.trim().length){
      dispatch(createProcedureType({
        ProcedureType: this.state.input,
        UpdatedById: user.id
      }))
    }
    this.setState({
      open: false,
      additionType: null,
      input: null,
      type: null,
      inputLabel: null
    })
  }

  handleRejectionAddition() {
    this.setState({
      open: false,
      additionType: null,
      type: null,
      inputLabel: null,
      input: null
    })
  }

  setInputValue(e) {
    this.setState({input: e.target.value})
  }

  onDropdownChange(e) {
    this.setState({type: e.target.value})
  }

  handleClickListItem(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleMenuItemClick(event, index) {
    
    let openTableKey;
    if(options[index] === "Reasons"){
      openTableKey = "reasons";
    } else if(options[index] === "Readmission Facilities"){
      openTableKey = "readmissionFacilities";
    } else if(options[index] === "Discharge Facilities"){
      openTableKey = "dischargeFacilities";
    } else if(options[index] === "Procedure Types"){
      openTableKey = "procedureTypes";
    }

    this.setState({
      selectedIndex: index,
      selectedTable: openTableKey,
      anchorEl: null
    })
  }

  handleClose() {
    this.setState({
      anchorEl: null
    })
  }

  render() {

    const {
      classes
    } = this.props;

    const {
      selectedIndex,
      anchorEl
    } = this.state;

    const tableData = this.getTableData();

    const dialogBoxLabel = (additionType) => {
      if(additionType === "readmissionFacilities"){
        return "Readmission Facilities Addition Form"
      } else if(additionType === "dischargeFacilities"){
        return "Discharge Facilities Addition Form"
      } else if(additionType === "reasons"){
        return "Reasons Addition Form"
      } else if(additionType === "procedureTypes"){
        return "Procedure Addition Form"
      }
    }

    const dialog = (<Dialog open={this.state.open} onClose={this.handleRejectionAddition}>
      <DialogTitle>{dialogBoxLabel(this.state.additionType)}</DialogTitle>
      <form>
        <TextField
          label={this.state.inputLabel}
          style={{
            width: 200,
            margin: 20
          }}
          value={this.state.input}
          onChange={this.setInputValue.bind(this)}
          margin="normal"
        />
        {this.state.additionType === "reasons" && <FormControl
          style={{width: 100, margin: 20}}
          variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            value={this.state.type}
            onChange={this.onDropdownChange}
          >
            <MenuItem value="Readmit">
              <em>Readmit</em>
            </MenuItem>
            <MenuItem value="Comp">
              <em>Comp</em>
            </MenuItem>
          </Select>
        </FormControl>}
      </form>
      <DialogActions>
        <Button onClick={this.handleAcceptAddition} color="primary"> ADD </Button>
        <Button onClick={this.handleRejectionAddition} color="primary"> CANCEL </Button>
      </DialogActions>
    </Dialog>);

    return (<div>
      <div className={{width: 40}}>
        <List component="nav" aria-label="Device settings">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            onClick={this.handleClickListItem}
          >
            <ListItemText
              primary={<Typography style={{color: "red"}}>
                {"Click to select a different table to maintain"}</Typography>}
              secondary={options[selectedIndex]} />
          </ListItem>
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              disabled={index === 0}
              selected={index === selectedIndex}
              onClick={event => this.handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div style={{
        display: "flex"
      }}>
        {dialog}
        {tableData.map((data, index) => {
          
          if(data.key !== this.state.selectedTable) return <div></div>
          
          return (<TableMaintenanceTable
            header={
              <div className={classes.headerContainer}>
                <Typography className={classes.header} variant="headline">{data.title}</Typography>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => this.handleAddButtonClick(data.key)}>
                  <Icon className={classes.leftIcon}>add</Icon>
                  ADD
                </Button>
              </div>
            }
            data = {data.data}
            query = {data.query}
            columns={data.columns}
            key={index}
            onQueryChange={this.onQueryChange}
            tableKey={data.key}
          />)
        })}
      </div>
    </div>);
  }
}

TableMaintenancePage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  reasons: PropTypes.object.isRequired,
  readmissionFacilities: PropTypes.object.isRequired,
  dischargeFacilities: PropTypes.object.isRequired,
  procedureTypes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const {
    user,
    preference,
    reasons,
    readmissionFacilities,
    dischargeFacilities,
    procedureTypes
  } = state;

  return {
    user,
    preferenceQuery:preference.tables[TABLE_NAME],
    reasons: StoreUtil.get(reasons, StoreUtil.COMMON_TABLE),
    readmissionFacilities: StoreUtil.get(readmissionFacilities, StoreUtil.COMMON_TABLE),
    dischargeFacilities: StoreUtil.get(dischargeFacilities, StoreUtil.COMMON_TABLE),
    procedureTypes: StoreUtil.get(procedureTypes, StoreUtil.COMMON_TABLE)
  };
}

const styledPage = withStyles(styles)(TableMaintenancePage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as TableMaintenancePage};
