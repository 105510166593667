import NavigationUtil from 'util/NavigationUtil'

class AuthRule{

  static get HELP_DESK(){
    return 1;
  } 

  static get NAV(){
    return 2;
  }

  static get PAC(){
    return 4;
  }

  static doesHaveLoginRedirect(user){
    if(user != null && user.meta && user.meta.authenticated && user.Role > -1
      && user.meta.returnToURL){
      return user.meta.returnToURL != '/login' && user.meta.returnToURL != '/loginCode';
    }
    return false;
  }

  static getUserLoginRedirectURL(user){
    if(AuthRule.doesHaveLoginRedirect(user)){
      return user.meta.returnToURL;
    }else if(AuthRule.doesUserHaveNurseNavPermission(user) && !user.nurseNavViewingPac){
      //nurse nav
      return NavigationUtil.navLink('/dashboard');
    }else if(AuthRule.doesUserHavePACPermission(user)){
      //pac
      return NavigationUtil.pacLink('/home');
    }

    return null;
  }

  static doesUserHaveNurseNavPermission(user){
    const result = user != null && user.meta &&  user.meta.authenticated && user.Role > -1
      && (((user.Role & AuthRule.NAV) > 0) || (user.Role === 8));
    return result;
  }

  static doesUserHavePACPermission(user){
    const result = user != null  && user.meta && user.meta.authenticated && user.Role > -1
    && (((user.Role & AuthRule.PAC) > 0) || (user.Role === 8));
    return result;
  }

  static doesUserHaveHelpDeskPermisson(user){
    const result = user != null  && user.meta && user.meta.authenticated && user.Role > -1
    && (((user.Role & AuthRule.HELP_DESK) > 0) || (user.Role === 8));
    return result;
  }

  static canUserSwitchToPACView(user){
    return AuthRule.doesUserHaveNurseNavPermission(user) 
      && AuthRule.doesUserHavePACPermission(user);
  }
}

export {AuthRule as AuthRule}
export default AuthRule
