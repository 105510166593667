import { takeEvery, put } from 'redux-saga/effects';
import { success} from 'redux-saga-requests';

import {
  listNavMessagingLog,
  CREATE_MESSAGING_LOG
} from 'actions/navMessagingLogActions';

function* updateNavMessaging(data) {
  yield put(listNavMessagingLog({
    limit: 5,
    offset: 0,
    order: [["DateTimeSent","DESC"]],
    where: { SurgeryId: data.data.SurgeryId }
  }));
}

export const navMessaging = [
  takeEvery(success(CREATE_MESSAGING_LOG), updateNavMessaging)
];