import ReducerUtil from 'reducers/ReducerUtil'

import {
  API_POST_ACUTE_EVENTS_FOR_EPISODE,
  API_GET_POST_ACUTE_EVENT,
  API_UPDATE_POST_ACUTE_EVENT
} from 'actions/postAcuteEventActions';

export const postAcuteEvents = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'postAcuteEvents');
  if(logout.actionConsumed){
    return logout.state;
  }
  
  const tableGet = ReducerUtil.reduceTableGet(state, action, API_POST_ACUTE_EVENTS_FOR_EPISODE);
  if(tableGet.actionConsumed){
    return tableGet.state;
  }

  const singleGet = ReducerUtil.reduceSingleGet(state, action, API_GET_POST_ACUTE_EVENT);
  if(singleGet.actionConsumed){
    return singleGet.state;
  }

  const update = ReducerUtil.reduceUpdate(state, action, API_UPDATE_POST_ACUTE_EVENT);
  if(update.actionConsumed){
    return update.state;
  }

  return state;
}
