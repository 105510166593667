import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from 'components/forms/MaterialForm';

const styles = ({
  pageContainer:{
    width: '100%',
    marginTop: 20
  }
});

class FormDialog extends React.Component {    
  onFormSubmit(formData){
    const {
      onSubmit
    } = this.props;
    
    onSubmit(formData);
  }
  
  onFormError(err){
    console.log("this is an error", err);
  }

  render() {
    const {
      classes,
      title,
      isOpen,
      formData,
      onCancel,
      schemaEdit,
      uiSchema
    } = this.props;
    

    let useSchemaEdit = schemaEdit;

    useSchemaEdit.title = title;
    
    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={this.handleClose}
        >
          <DialogContent>
            <MaterialForm className={classes.content}
              schema={useSchemaEdit}
              uiSchema={uiSchema}
              onSubmit={this.onFormSubmit.bind(this)}
              onError={this.onFormError.bind(this)}
              formData={formData}
              status={status}
              backOnCancel={false}
              onCancel={onCancel}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

FormDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  formData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  schemaEdit: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired
}

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(FormDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as FormDialog };
