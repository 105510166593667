import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import StoreUtil from 'stores/StoreUtil';

import { LoadingView } from 'components/LoadingView'
import { getPatientDetailByMRN } from 'actions/patientActions'
import { DetailDataGroup } from 'components/detail/DetailDataGroup'
import { DetailDataTable } from 'components/detail/DetailDataTable'
import NavigationUtil from 'util/NavigationUtil'

import {
  listNavMessagingLog
} from 'actions/navMessagingLogActions';

const styles = theme =>({
  container: {
    display:'flex',
    flexDirection:'column',
    padding : theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    marginBottom:theme.spacing.unit,
    boxSizing: 'border-box'
  },
  headerPatient: {
    marginRight: theme.spacing.unit,
    flex: 1
  },
  listContainer:{
    minHeight:200,
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap'
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconButton: {
    marginLeft: theme.spacing.unit
  }
});

const contactSchema = {
  title:'Contact Information',
  properties:{
    HomePhone:{
      title: 'Home Phone',
      format: 'phone',
      type: 'string'
    },
    CellPhone:{
      title: 'Cell Phone',
      format: 'phone',
      type: 'string'
    },
    Address1:{
      title: 'Address 1',
      type: 'string'
    },
    Address2:{
      title: 'Address 2',
      type: 'string'
    },
    City:{
      title: 'City',
      type: 'string'
    },
    State:{
      title: 'State',
      type: 'string'
    },
    Zip:{
      title: 'Zipcode',
      type: 'string',
      format: 'zipcode'
    },
    Email:{
      title: 'Email',
      type: 'string',
      format: 'email'
    }
  }
};

const healthcareSchema = {
  title:'Care Information',
  properties:{
    CarePartnerName:{
      title: 'Care Partner Name',
      type: 'string'
    },
    CarePartnerRelation:{
      title: 'Care Partner Relation',
      type: 'string'
    },
    CarePartnerPhoneNumber:{
      title: 'Care Partner Phone',
      type: 'string',
      format:'phone'
    },
    PrimaryCareProvider:{
      title: 'PCP',
      type: 'string'
    },
    Payor:{
      title: 'Payor',
      type: 'string'
    }
  }
}

class PatientDetailView extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    //check if data load needed
    const {
      patientMRN,
      externalSource,
      dispatch,
      // patient,
      surgeryId
    } = this.props;

    //make the calls on every mount

    if(patientMRN && externalSource) {
      // const mrn = patient.data && patient.data.MedicalRecNum;
      dispatch(getPatientDetailByMRN(patientMRN, externalSource));
    }

    if(surgeryId){
      dispatch(listNavMessagingLog({
        limit: 5,
        offset: 0,
        order: [["DateTimeSent","DESC"]],
        where: { SurgeryId: surgeryId }
      }))
    }
  }

  render() {
    const {
      classes,
      patientMRN,
      externalSource,
      surgeryAge,
      surgeryId,
      showDetailsButton,
      patient,
      navMessagingLog,
      user
    } = this.props;

    if(!patient || !StoreUtil.getData(patient)){
      return (
        <LoadingView />
      );
    }else{
      if(StoreUtil.isLoadFailed(patient)){
        return (<Typography variant='title' color='error'>
          There was an issue loading the patient.  Please try again later.
        </Typography>);
      }

      const patientData = StoreUtil.getData(patient);

      //Add surgery age to patient getData
      patientData.SurgeryAge = surgeryAge;
      
      let personalSchema = {
        title:'Personal Information',
        properties:{
          BirthDate:{
            title: 'Birth Date',
            format: 'age',
            type: 'date'
          },
          SurgeryAge:{
            title: 'Age at DOS',
            type: 'string'
          },
          Gender:{
            title: 'Gender',
            type: 'string'
          },
          MartialStatus:{
            title: 'Marital Status',
            type: 'string'
          }
        }
      };
      
      if(!patientData.SurgeryAge){
        personalSchema = {
          title:'Personal Information',
          properties:{
            BirthDate:{
              title: 'Birth Date',
              format: 'age',
              type: 'date'
            },
            Gender:{
              title: 'Gender',
              type: 'string'
            },
            MartialStatus:{
              title: 'Marital Status',
              type: 'string'
            }
          }
        }; 
      }

      let editLink;
      
      if(surgeryId && surgeryId > 0){
        editLink = NavigationUtil.navLink('/surgery/'+surgeryId+'/patientEdit/'+patientMRN)
      }else{
        editLink = NavigationUtil.navLink("/patientEdit/"+patientMRN+'/'+externalSource);
      }

      return (
        <Paper className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography
              className={classes.headerPatient}
              variant="headline">
              {patientData.LastName}, {patientData.FirstName}
              <Typography>MRN: {patientData.MedicalRecNum}</Typography>
            </Typography>

            {showDetailsButton &&
              (<Button
                color="primary"
                size="small"
                className={classes.iconButton}
                component={Link} to={NavigationUtil.navLink("/patients/"+patientMRN+'/'+externalSource)}>
                <Icon className={classes.leftIcon}>pageview</Icon>
                Patient Details
              </Button>)
            }

            <Button
              color="primary"
              size="small"
              className={classes.iconButton}
              component={Link} to={editLink}>
              <Icon className={classes.leftIcon}>edit_icon</Icon>
              Edit Patient
            </Button>
          </div>
          <div className={classes.listContainer}>
            <DetailDataGroup
              schema={personalSchema}
              data={patientData}
            />
            <DetailDataGroup
              schema={contactSchema}
              data={patientData}
            />
            <DetailDataGroup
              schema={healthcareSchema}
              data={patientData}
            />
            {surgeryId && <DetailDataTable rows={navMessagingLog.rows} user={user}/>}
          </div>
        </Paper>
      );
    }
  }
}

PatientDetailView.defaultProps = {
  showDetailsButton: true
}

PatientDetailView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  patientMRN: PropTypes.number.isRequired,
  externalSource: PropTypes.string.isRequired,
  surgeryAge: PropTypes.number,
  surgeryId: PropTypes.number,
  showDetailsButton: PropTypes.bool,
  patient: PropTypes.object,
  navMessagingLog: PropTypes.object.isRequired,
  user: PropTypes.object
  
}

function mapStateToProps(state, existingProps) {
  const { patientMRN, externalSource } = existingProps;
  const {
    patients,
    navMessagingLog,
    user
  } = state;

  return {
    // TODO this may not work if we have multiple patients with the same MRN
    patient : StoreUtil.get(patients, StoreUtil.COMMON_ITEM, patientMRN),
    patientMRN,
    externalSource,
    navMessagingLog,
    user
  };
}

const styled = withStyles(styles)(PatientDetailView);
const connected = connect(mapStateToProps)(styled);
export { connected as PatientDetailView };
