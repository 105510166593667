import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import NavigationUtil from 'util/NavigationUtil'

import { listConversations, viewConversation } from 'actions/messageActions';
import { ConversationsTable } from 'components/ConversationsTable';
import { saveTablePreference } from 'actions/preferenceActions';
import StoreUtil from 'stores/StoreUtil'

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});
const TABLE_NAME = "AdmissionConversationView";
class AdmissionConversationView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitial();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.admission != null &&
      (prevProps.admission == null || this.props.admission.id != prevProps.admission.id )) {
      this.fetchInitial();
    }
  }

  fetchInitial() {
    this.setState({query: this.buildInitialQuery() }, this.fetch.bind(this));
  }

  buildInitialQuery() {
    const {
      admission,
      preferenceQuery
    } = this.props;

    let query = {
      where: {},
      order:[['LastMessageDate','DESC']],
      offset: 0,
      limit:25
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    query.where["AdmissionId"] = admission.id;


    return query;
  }

  fetch() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listConversations(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;

    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetch.bind(this));
  }

  onRowClick(conversation) {
    const {
      dispatch,
      admission,
      isPAC
    } = this.props;
    dispatch(viewConversation(admission.SurgeryId, admission.id, conversation.id, isPAC));
  }

  render() {
    const {
      query
    } = this.state;

    const {
      classes,
      list,
      admission,
      isPAC
    } = this.props;

    let surgeryId = admission.SurgeryId;
    let admissionId = admission.id;

    return (
      <React.Fragment>

        <ConversationsTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">Navigator Requests</Typography>
              {!isPAC &&
                <Button
                  color="primary"
                  size="small"
                  component={Link} 
                  to={NavigationUtil.navLink(`/surgery/${surgeryId}/admission/${admissionId}/newMessage`)}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  New Message
                </Button>
              }
            </div>
          }
          data={list}
          query={query}
          showFilters={false}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          isPlanMode={false}
          isAdmissionMode={true}
        />
      </React.Fragment>
    );
  }
}
AdmissionConversationView.defaultProps = {
  isPAC: true
};
AdmissionConversationView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  admission: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  isPAC: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const { messages, preference } = state;

  return {
    list: StoreUtil.get(messages, "conversations"),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(AdmissionConversationView);
const connected = connect(mapStateToProps)(styled);
export { connected as AdmissionConversationView };