import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { OverdueDischargesCountView } from 'containers/admissions/OverdueDischargesCountView';
import { Pac90DayForm } from 'containers/pac/Pac90DayForm';
import { TasksCountView } from 'containers/tasks/TasksCountView';
import { MessagesCountView } from 'containers/messages/MessagesCountView';
import { AlertsCountView } from 'containers/alerts/AlertsCountView';
import { HSXAlertsCountView } from 'containers/alerts/HSXAlertsCountView';
import DataUtil from 'util/DataUtil';

const styles = ({
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  }
});

class HUDView extends React.Component {

  render() {
    const {
      classes,
      user
    } = this.props;

    const isPTUser = DataUtil.isPTUser(user);

    return (
      <div className={classes.cards}>
        {!isPTUser && <HSXAlertsCountView />}
        {!isPTUser && <AlertsCountView />}
        {isPTUser ? <Pac90DayForm /> : <OverdueDischargesCountView />}
        {!isPTUser && <MessagesCountView type="change"/>}
        {!isPTUser && <MessagesCountView type="other" />}
        {!isPTUser && <TasksCountView />}
      </div>
    );
  }
}

HUDView.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

const styledPage = withStyles(styles)(HUDView);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as HUDView};
