import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';
import DateUtil from 'util/DateUtil';

const styles = ({
});


//this controls which columns are displayed and how they are looked up in data
const columns = [
  {label:"ID", key: "id", sortable: false, sortDown: false, style:{width:80}},
  {label:"Navigator", key: "Navigator", sortable: true, sortDown: false, style:{width:160}},
  {label:"Name", key: "Name", sortable: true, sortDown: false},
  {label:"Age", key: "Age", sortable: true, sortDown: false, style:{width:40}},
  {label:"Birth Date", key: "BirthDate", sortable: true,  
    style:{width:120}, format:DateUtil.formatFromDB},
  {label:"Date of Surgery", key: "DateofSurgery", sortable: true, 
    sortDown: false,style:{width:120}, format:DateUtil.formatFromDB},
  {label:"Surgeon", key: "Surgeon", sortable: true, sortDown: false, style:{width:160}},
  {label:"Procedure", key: "Procedure", sortable: true, sortDown: false}
];

class DataExportTable extends Component {

  render() {
    return (
      <DynamicTable 
        {...this.props} 
        columns={columns} 
        classes={this.props.classes}
        showFilters={false}
      />
    );
  }
}

DataExportTable.propTypes = DynamicTable.propTypes;

const styled = withStyles(styles)(DataExportTable);
export {styled as DataExportTable};