import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import NavigationUtil from 'util/NavigationUtil'

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    position: 'relative',
    padding:theme.spacing.unit*2,
    boxSizing: 'border-box'
  },
  cards: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 40
  },
  header: {
    position:'absolute',
    top: theme.spacing.unit*2,
    left: theme.spacing.unit*2
  }
});

class HelpDeskDashboardPage extends React.Component {

  render() {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.pageContainer}>
        <List>
          <ListItem
            component={Link}
            to={NavigationUtil.helpDeskLink("/users")}
            onClick={this.handleClose}>
            <Icon className={classes.menuItemIcon}>account_circle</Icon>
            Users
          </ListItem>
          <ListItem
            component={Link}
            to={NavigationUtil.helpDeskLink('/surgeons')}
            onClick={this.handleClose}>
            <Icon className={classes.menuItemIcon}>supervisor_account</Icon>
            Surgeons
          </ListItem>
          <ListItem
            component={Link}
            to={NavigationUtil.helpDeskLink('/hospitals')}
            onClick={this.handleClose}>
            <Icon className={classes.menuItemIcon}>local_hospital</Icon>
            Hospitals
          </ListItem>
          <ListItem
            component={Link}
            to={NavigationUtil.helpDeskLink('/facilities')}
            onClick={this.handleClose}>
            <Icon className={classes.menuItemIcon}>business</Icon>
            Facilities
          </ListItem>
        </List>
      </div>
    );
  }
}

HelpDeskDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps() {
  return {};
}

const styledPage = withStyles(styles)(HelpDeskDashboardPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as HelpDeskDashboardPage};
