import { push } from 'connected-react-router';

export const API_MESSAGE_CREATE = 'API_MESSAGE_CREATE';
export const API_MESSAGE_REPLY = 'API_MESSAGE_REPLY';
export const API_MESSAGE_READ = 'API_MESSAGE_READ';
export const API_MESSAGE_RESOLVE = 'API_MESSAGE_RESOLVE';
export const API_MESSAGE_CONVERSATIONS = 'API_MESSAGE_CONVERSATIONS';
export const API_MESSAGE_CONVERSATION = 'API_MESSAGE_CONVERSATION';
export const API_MESSAGE_LIST = 'API_MESSAGE_LIST';

export const API_MESSAGE_CONVERSATIONS_BY_ADMISSION = 'API_MESSAGE_CONVERSATIONS_BY_ADMISSION';
export const API_GET_PLAN_CHANGE_REASONS = 'API_GET_PLAN_CHANGE_REASONS';

export const API_OPEN_MESSAGE_COUNT = 'API_OPEN_MESSAGE_COUNT';
export const API_MESSAGE_CONVERSATIONS_BY_SURGERY = 'API_MESSAGE_CONVERSATIONS_BY_SURGERY';
export const API_UPDATE_PLAN_CHANGE = 'API_UPDATE_PLAN_CHANGE';
export const API_GET_PLAN_CHANGE = 'API_GET_PLAN_CHANGE';

import NavigationUtil from 'util/NavigationUtil'

export const getPlanChange = (planChangeId)=> {
  
  let options = {
    where: {id:planChangeId},
    include:[
      {
        association:"AdmissionPlanChangeReasons",
        required:false,
        include:[
          {
            association:"Reason",
            required:false
          }
        ]
      },
      {
        association:"Conversation"
      }
    ]
  };

  return {
    type: API_GET_PLAN_CHANGE,
    id: planChangeId,
    request: {
      url: '/AdmissionPlanChanges',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const updatePlanChange = (formData)=> {
  let id = formData.id;
  let options = {
    where: {id:id}
  };

  return {
    type: API_UPDATE_PLAN_CHANGE,
    id: formData.id,
    backOnSuccess:true,
    request: {
      url: '/AdmissionPlanChanges',
      method: 'PUT',
      params : {opts:options},
      data : formData
    }
  }
};

export const tableConversationsBySurgery = (surgeryId, options)=> {
  return {
    type: API_MESSAGE_CONVERSATIONS_BY_SURGERY,
    id: surgeryId,
    request: {
      url: '/MessageDashboardView',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listPlanChangeReasons = (options)=> {
  return {
    type: API_GET_PLAN_CHANGE_REASONS,
    request: {
      url: '/Reasons',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listConversations = (options)=> {
  return {
    type: API_MESSAGE_CONVERSATIONS,
    request: {
      url: '/MessageDashboardView',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listConversationsForAdmission = (admissionId)=> {
  const options = {
    where: {
      AdmissionId: admissionId
    }
  }

  return {
    type: API_MESSAGE_CONVERSATIONS_BY_ADMISSION,
    id:admissionId,
    request: {
      url: '/MessageDashboardView',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listMessages = (options)=> {
  return {
    type: API_MESSAGE_LIST,
    request: {
      url: '/Messages',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const createMessage = (data)=> {
  return {
    type: API_MESSAGE_CREATE,
    request: {
      url: '/CreateMessage',
      method: 'POST',
      data:data
    }
  }
};

export const replyToMessage = (conversationId, body)=> {
  let data = {
    Message: body,
    ConversationId:conversationId
  };
  return {
    type: API_MESSAGE_REPLY,
    id:conversationId,
    request: {
      url: '/ReplyToMessage',
      method: 'POST',
      data : data
    }
  }
};

export const markMessageRead = (data, user)=> {
  const options = {
    where: {
      id: data.id
    }
  }
  return {
    type: API_MESSAGE_READ,
    id: data.id,
    user:user,
    request: {
      url: '/Messages',
      method: 'PUT',
      data: data,
      params : {opts:options}
    }
  }
};

export const loadConversationById = (id)=> {
  return {
    type: API_MESSAGE_CONVERSATION,
    id: id,
    request: {
      url: '/Conversations',
      method: 'GET',
      params : {
        opts: {
          include: [
            {
              association: "User",
              attributes: [
                "id",
                "FirstName",
                "LastName"
              ]
            },
            {
              association: "AdmissionPlanChanges",
              attributes: ["id","Status", "LosExtension"],
              include:[
                {
                  association: "AdmissionPlanChangeReasons",
                  attributes: ["id"],
                  include:[
                    {
                      association: "Reason",
                      attributes: [
                        "id",
                        "Text",
                        "Type"
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          where: {
            id: id
          }
        }
      }
    }
  }
};

export const resolveConversation = (conversationId)=> {
  return {
    type: API_MESSAGE_RESOLVE,
    id: conversationId,
    request: {
      url: '/ResolveMessage',
      method: 'PUT',
      data: {id: conversationId}
    }
  };
};

export const viewCreatePlanChange = (admissionId, isPAC = true, surgeryId = -1, planChangeId = -1) => {
  let url;
  if(isPAC){
    url = NavigationUtil.pacLink(`/admission/${admissionId}/planchange`);
  }else{
    url = NavigationUtil.navLink(`/surgery/${surgeryId}/admission/${admissionId}/planchange/${planChangeId}`);
  }
  
  return push(url);
};

export const viewConversation = (surgeryId, admissionId, conversationId, isPAC = false) => {
  let url;
  if(!isPAC){
    url = NavigationUtil.navLink(`/surgery/${surgeryId}/admission/${admissionId}/conversation/${conversationId}`);
  }else{
    url = NavigationUtil.pacLink(`/admission/${admissionId}/conversation/${conversationId}`);
  }
  return push(url);
};

export const openMessageCount = (userId, facilityId, isPAC = false) => {
  let opts = {
    attributes: ["id", "ConversationType", "IsRead"],
    where: { 
      Status: { $ne: 'Resolved' }
    },
    limit: 0,
    offset: 0
  }

  if(isPAC){
    opts.where.FacilityId = facilityId;
  }else{
    opts.where.NavigatorUserId = userId;
  }

  return {
    id:userId,
    type: API_OPEN_MESSAGE_COUNT,
    request: {
      url: '/MessageDashboardView',
      method: 'GET',
      params : {
        opts: opts
      }
    }
  }
};
