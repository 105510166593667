import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { UserTable } from 'components/UserTable';
import { listNavUsers, viewUser } from 'actions/navUserActions';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import StoreUtil from 'stores/StoreUtil'
import { saveTablePreference } from 'actions/preferenceActions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import NavigationUtil from 'util/NavigationUtil'

const styles = (theme) => ({
  pageContainer:{
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  error: {
    color: 'red'
  }
});
const TABLE_NAME = "UsersPage";
class UsersPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };

    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    //has the url query string changed and do we need to request new patients?
    if (this.props.location.search != prevProps.location.search) {
      this.fetchInitialUsers();
    }
  }

  componentDidMount() {
    this.fetchInitialUsers();
  }

  fetchInitialUsers() {
    this.setState({query: this.buildInitialQuery() }, this.fetchUsers.bind(this));
  }

  buildInitialQuery() {
    const {
      location,
      preferenceQuery
    } = this.props;

    let query = {
      where: { },
      include: [
        {
          association: 'Facility',
          required: false
        }
      ],
      limit: 25,
      offset: 0,
      order:[["LastName","ASC"]]
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    if (location.search && location.search.length > 0) {
      let search = location.search;

      if (search.substring(0,1) == "?") {
        search = search.substring(1,search.length);
      }
      if (parseInt(search) > 0) {
        query.where["MedicalRecNum"] = search;

      } else {
        query.where["LastName"] = {$like:"%"+search+"%"};
      }
    }

    return query;
  }

  fetchUsers() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listNavUsers(false, query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchUsers.bind(this));
  }

  onRowClick(navUser) {
    const { dispatch, user } = this.props;
    dispatch(viewUser(user,navUser));
  }

  render() {

    const {
      classes,
      users,
      user,
      appMessages
    } = this.props;

    const {
      query
    } = this.state;

    let linkTo = NavigationUtil.helpDeskLink("/users/0");
    
    if(user.Role === 3 || 7){
      linkTo = NavigationUtil.navLink("/users/0"); 
    }

    return (
      <React.Fragment>
        {appMessages && appMessages.message &&
        <Typography className={classes.error}>
          {appMessages.message}
        </Typography>
        }
        <UserTable
          header={
            <div className={classes.headerContainer}>
              <Typography variant="title" color="inherit" className={classes.header} gutterBottom>
                Users
              </Typography>
              <Button
                color="primary"
                size="small"
                component={Link} to={linkTo}>
                <Icon className={classes.leftIcon}>add</Icon>
                  Add User
              </Button>
            </div>
          }
          data = {users}
          query = {query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick}
        />
      </React.Fragment>
    );
  }
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object,
  users: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  appMessages: PropTypes.object
};

function mapStateToProps(state) {
  const { 
    navUsers, 
    user, 
    preference, 
    appMessages 
  } = state;
  return {
    users : StoreUtil.get(navUsers, StoreUtil.COMMON_TABLE),
    user,
    preferenceQuery:preference.tables[TABLE_NAME],
    appMessages
  };
}

const styledPage = withStyles(styles)(UsersPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as UsersPage};
