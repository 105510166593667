import React from 'react';
import { PropTypes } from 'prop-types';

import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  form: {
    width: 400
  },
  textField: {
  },
  buttonContainer:{
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit
  },
  button:{
    width: 150
  },
  forgotPass:{
    //textDecoration: 'none'
  }
});

class LoginForm extends React.Component {

  render() {
    const {
      onSubmit,
      onChange,
      errors,
      user,
      classes,
      processing
    } = this.props;

    let buttonText = "Submit";
    if(processing){
      buttonText = "Loading";
    }
    return (
      <form action="/" onSubmit={onSubmit} className={classes.form}>
        <CardHeader title="Login" />	
        <CardContent>
          {errors 
            && <Typography variant="subheading" color="error">{errors}</Typography>}

          <TextField
            autoFocus={true}
            name="email"
            label="Username"
            placeholder="Username"
            onChange={onChange}
            value={user.email}
            className={classes.textField}
            margin="normal"
            fullWidth
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            placeholder="Password"
            onChange={onChange}
            value={user.password}
            className={classes.textField}
            margin="normal"
            fullWidth
          />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button,classes.forgotPass}
              color="primary"
              component={Link} to="/forgotPassword">
              Forgot Password
            </Button>
            <Button
              className={classes.button}
              type="submit" 
              color="primary"
              margin="normal"
              variant="raised"
              disabled={user.email.length < 2 
                || user.password.length < 2 || processing}>{buttonText}</Button>
          </div>
        </CardContent>
      </form>
    );
  }
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

const styledLoginPage = withStyles(styles)(LoginForm);
export {styledLoginPage as LoginForm};

