import React from 'react';
import PropTypes from 'prop-types';

import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const styles = theme => ({
  card: {
    width: 200,
    marginRight: theme.spacing.unit,
    cursor: "pointer"
  },
  red: {
    width: 200,
    marginRight: theme.spacing.unit,
    cursor: "pointer",
    backgroundColor: "red"
  },
  orange: {
    width: 200,
    marginRight: theme.spacing.unit,
    cursor: "pointer",
    backgroundColor: "orange"
  },
  content: {
    paddingBottom: 16
  },
  title: {

  },
  seperator: {
    fontSize: 42,
    color: theme.palette.grey.A200,
    fontWeight: 300
  },
  number: {
    fontSize: 36,
    textAlign: "center"
  }
});

class NumberCard extends React.Component {

  onClick() {
    const {
      dispatch,
      path
    } = this.props;

    dispatch(push(path));
  }

  render() {
    const {
      classes,
      title,
      number,
      anotherNumber,
      cardColor
    } = this.props;

    let cardClass = classes.card;

    if(cardColor){
      cardClass = classes[cardColor];
    }

    let numberStr = number;
    if (anotherNumber !== undefined) {
      numberStr = (
        <React.Fragment>
          {number}
          <span className={classes.seperator}> | </span>
          {anotherNumber}
        </React.Fragment>
      );
    } else {
      numberStr = number;
    }
    
    return (
      <Card className={cardClass} onClick={this.onClick.bind(this)}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>
          <Typography variant="headline" component="h1" className={classes.number}>
            {numberStr}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

NumberCard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  anotherNumber: PropTypes.number,
  path: PropTypes.string.isRequired,
  cardColor: PropTypes.string
}

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(NumberCard);
const connected = connect(mapStateToProps)(styled);
export { connected as NumberCard }; 