import { error } from 'redux-saga-requests';

import {
  API_SEND_INVITE
} from 'actions/navUserActions';

export const appMessages = (state = {}, action) => {
  switch (action.type) {
  case error(API_SEND_INVITE):
    return Object.assign({}, state, {
      inviteEmailSuccess: false,
      message: "Failed to send email."
    });
  }

  return state;
}
