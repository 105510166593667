import { push } from 'connected-react-router';
import NavigationUtil from 'util/NavigationUtil'
import DateUtil from 'util/DateUtil'
import TaskUtil from 'containers/tasks/TaskUtil'

export const API_TASKS_TODAY = 'API_TASKS_TODAY';
export const API_TASKS_LIST = 'API_TASKS_LIST';
export const API_TASKS_ID = 'API_TASKS_ID';
export const API_TASKS_TYPES_LIST = 'API_TASKS_TYPES_LIST';
export const API_TASKS_UPDATE = 'API_TASKS_UPDATE';
export const API_TASKS_COUNT = 'API_TASKS_COUNT';
export const API_TASKS_START = 'API_TASKS_START';
export const API_TASKS_DELETE = 'API_TASKS_DELETE';

export const tasksToday = (options)=> {
  return {
    type: API_TASKS_TODAY,
    request: {
      url: '/TodaysTasksView',
      method: 'GET',
      params : { opts: options }
    }
  }
};

export const listTasks = (options)=> {
  return {
    type: API_TASKS_LIST,
    request: {
      url: '/Tasks',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listTaskTypes = ()=> {
  return {
    type: API_TASKS_TYPES_LIST,
    request: {
      url: '/TaskTypes',
      method: 'GET',
      params : {
        opts: {
          where: {
            Hidden: false
          }
        }
      }
    }
  }
};

export const loadTaskById = (id)=> {
  return {
    type: API_TASKS_ID,
    id: id,
    request: {
      url: '/Tasks',
      method: 'GET',
      params : {
        opts: {
          where: {
            id: id
          }
        }
      }
    }
  }
};

export const updateTask = (formData, backOnSuccess = true, resolve = false )=> {
  let options = null;

  if(formData.id && formData.id > 0){
    options = {
      where: { id: formData.id }
    }
  } else {
    formData.Status = TaskUtil.Status.NOT_STARTED;
  }
  if(resolve){
    formData.Status = TaskUtil.Status.RESOLVED;
  }

  if(formData.id === 0){
    delete formData.id;
  }

  return {
    type: API_TASKS_UPDATE,
    id: formData.id,
    task: formData,
    backOnSuccess,
    request:{
      url: '/Tasks',
      method: 'PUT',
      params : {opts:options},
      data : formData
    }
  }
}

export const resolveTask = (taskId, backOnSuccess = false) => {

  let options = {
    where: { id: taskId }
  };
  
  let data = {
    id: taskId,
    Status: TaskUtil.Status.RESOLVED,
    ResolvedAt: DateUtil.formatNowForDB()
  };

  return {
    type: API_TASKS_UPDATE,
    id: taskId,
    backOnSuccess,
    request:{
      url: '/Tasks',
      method: 'PUT',
      params : {opts:options},
      data : data
    }
  }
}

export const startTask = (taskId, tableAction = false) => {

  let options = {
    where: { id: taskId }
  };

  let data = {
    id: taskId,
    Status: TaskUtil.Status.IN_PROGRESS,
    ResolvedAt: null
  };

  return {
    type: API_TASKS_UPDATE,
    id: taskId,
    tableAction,
    Status: TaskUtil.Status.IN_PROGRESS,
    request:{
      url: '/Tasks',
      method: 'PUT',
      params : {opts:options},
      data : data
    }
  }
}

export const editPathway = (task, toEpisode = false) => {
  let link;

  let surgeryId;
  let questionGroupId;
  let taskId;
  let taskType;

  if(task.id){
    taskId = task.id;
  } else {
    taskId = task.TaskId
  }

  surgeryId = task.SurgeryId;

  if(task.QuestionGroupId){
    questionGroupId = task.QuestionGroupId;
  } else if (task.TaskType && task.TaskType.QuestionGroupId){
    questionGroupId = task.TaskType.QuestionGroupId;
  } else {
    questionGroupId = null;
  }
 
  if (task.TaskTypeName) {
    taskType = task.TaskTypeName;
  } else {
    taskType = task.TaskType.Name;
  }
  
  if(toEpisode){
    link = NavigationUtil.navLink(
      '/surgery/'+surgeryId);
  }else if(task && (taskType == '90 Day Post-Op' || taskType =='180 Day Post-Op') ){
    link = NavigationUtil.navLink(
      '/surgery/'+surgeryId+'/form/'+questionGroupId+'/task');
  } else if(task && task.TaskType && task.TaskType.QuestionGroupId){
    link = NavigationUtil.navLink(
      '/surgery/'+surgeryId+'/form/'+questionGroupId);
  } else if (task && task.QuestionGroupId) {
    link = NavigationUtil.navLink(
      '/surgery/'+surgeryId+'/form/'+questionGroupId);
  } else {
    link = NavigationUtil.navLink(
      '/surgery/'+surgeryId);
  }

  if(!toEpisode){
    link = link+'/'+taskId
  }
  
  return push(link);
}

export const deleteTask = (taskId) => {
  return {
    type: API_TASKS_DELETE,
    id: taskId,
    request:{
      url: '/Tasks',
      method: 'DELETE',
      data : [taskId]
    }
  }
}

export const editTask = (surgeryId, taskId) => {
  return push(NavigationUtil.navLink("/surgery/"+surgeryId+"/taskEdit/"+taskId));
};

export const taskCount = (options)=> {
  return {
    type: API_TASKS_COUNT,
    request: {
      url: '/TodaysTasksView',
      method: 'GET',
      params : { opts: options }
    }
  }
};
