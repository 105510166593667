import React from 'react';
import DataUtil from 'util/DataUtil';

class QuestionGroupUtil {

  static renderResponses(responses, classes){
    if(!responses || responses.length < 1){
      return (
        <p className={classes.answerSingle}>
          --
        </p>
      );
    }
    return responses.map((response, index)=>{
      let isLast = index == responses.length-1;
      return (
        <p key={response.key} className={classes.answerSingle}>
          {response.question} : <b>{response.answer}</b>
          {!isLast &&
            <span  className={classes.answerDiv} >|</span>
          }
        </p>
      );
    })
  }

  static getFormStoreId(questionGroupId, surgeryId, admissionId){
    let id = questionGroupId+"0"+surgeryId;
    if(admissionId){
      id += +"0"+admissionId;
    }
    return id;
  }

  static alterSchemaForState(processedForm, readOnlyOnEdit, currentAnswers){
    const {
      schema,
      schemaUI,
      hasAnswers,
      dependentQuestions
    } = processedForm;

    const needsReadOnlyChanges = readOnlyOnEdit && hasAnswers;
    const needsChildQuestionChanges = dependentQuestions && dependentQuestions.length > 0;

    if(!needsReadOnlyChanges && !needsChildQuestionChanges){
      return schemaUI;
    }

    let newSchema = DataUtil.deepCopy(schemaUI);

    if(needsReadOnlyChanges){
      //disable all for view only
      for(let key in schema.properties){
        newSchema[key]['ui:disabled'] = true;
      }
    }
    
    if(!needsChildQuestionChanges){
      return newSchema;
    }

    for(let x=0; x<dependentQuestions.length; x++){
      const {
        parentQuestionId,
        childQuestionId,
        valueNeeded
      } = dependentQuestions[x];

      if(valueNeeded != currentAnswers[parentQuestionId]){
        //hide the child!!
        newSchema[childQuestionId]['ui:widget'] = 'hidden';
      }
    }
    return newSchema;
  }

  static ConvertDatabaseToForm(databaseData) {
    if(!databaseData){
      return {
        schema: {},
        answers: {}
      };
    }
    let title = databaseData.Name;
    if(title){
      title = title.toUpperCase();
    }
    let schema = {
      title: title,
      type: 'object'
    };

    let schemaUI = {};

    let required = [];
    let properties = {};
    let answers = {}
    const useAltRows = databaseData.Questions.length > 4;

    let altBit = false;

    let answersSeen = false;

    //let sequenceOffset = 0;
    databaseData.Questions.sort((a,b)=>{
      return a.Sequence - b.Sequence;
    });

    let dependentQuestions = [];
    let questionOptionsMap = {};
    let uiOrder = [];
    databaseData.Questions.forEach( question => {
      let type;
      let format;
      let isHeaderField = false;
      let isMultiValueType = false;
      let isIntegerField = false;
      let qType = question.Type;
      if(!qType){
        //null types are treated as headers
        qType = 'string-qn';
        isHeaderField = true;
        //sequenceOffset++;
      }
      let title;
      if(isHeaderField){
        title = question.Question;
      }else{
        title = question.Question;
      }
      let questionProp = {
        title: title
      }

      schemaUI[question.id] = {};
      schemaUI[question.id]['ui:options'] = {};

      switch(qType){
      case 'numeric-qn':{
        type = 'number';
        break;
      }
      case 'string-qn':{
        type = 'string';
        break;
      }
      case 'date':
      case 'date-qn':{
        type = 'string';
        format = 'date';
        break;
      }
      case 'text-qn':{
        type = 'string';
        schemaUI[question.id]["ui:widget"] = "textarea";
        break;
      }
      case 'single-sel':{
        type = 'string';
        questionProp.enum = question.QuestionOptions.map(option =>{
          return option.Value;
        });
        schemaUI[question.id]["ui:widget"] = "radio";
        schemaUI[question.id]["ui:options"] = {"inline": true};
        break;
      }
      case 'drop-down':{
        type = 'string';
        questionProp.enum = question.QuestionOptions.map(option =>{
          return option.Value;
        });
        schemaUI[question.id]["ui:placeholder"]="Choose an option";
        break;
      }
      case 'year-qn':{
        //show drop with last 50 years?
        /*let yearList = new Array();
        let thisYear = new Date().getFullYear();
        let oldestYear = thisYear - 50;
        for(var x=thisYear; x >= oldestYear; x--){
          yearList.push(x);
        }
        questionProp.enum = yearList;*/
        type= "array";
        schemaUI[question.id]['ui:options'].arrayAddLabel = "Add Year(s)";
        questionProp.items = {
          type: 'integer',
          "minimum": 1900,
          "maximum": 2200
        }
        isMultiValueType = true;
        isIntegerField = true;
        break;
      }
      case 'multi-sel':{
        type= "array";
        questionProp.uniqueItems = true;
        questionProp.items = {
          type: 'string'
        }
        questionProp.items.enum = question.QuestionOptions.map(option =>{
          return option.Value;
        });
        schemaUI[question.id]["ui:widget"] = "checkboxes";
        isMultiValueType = true;
        break;
      }
      default:{
        console.log("UNKNOWN FORM QUESTION TYPE!! "+question.Type);
      }
      }
      questionProp.type = type;
      if(format){
        questionProp.format = format;
      }
      if(!schemaUI[question.id]){
        schemaUI[question.id] = {}
      }

      schemaUI[question.id]['ui:options'].pathwayMode = true;
      if(!isHeaderField && useAltRows && altBit){
        schemaUI[question.id]['ui:options'].altRow = true;
      }
      if(isHeaderField){
        schemaUI[question.id]['ui:readonly'] = true;
      }
      if(!isHeaderField){
        altBit = !altBit;
      }

      /**
        some questions only show if other questions have certain
        answers.  Otherwise they are hidden
      */
      if(question.QuestionOptionId){
        //add it to array to process at end, after props are mapped
        dependentQuestions.push(question);
      }

      //save all question options to a map to ref later
      if(question.QuestionOptions && Array.isArray(question.QuestionOptions)){
        for(let x=0; x<question.QuestionOptions.length; x++){
          questionOptionsMap[question.QuestionOptions[x].id] = {
            question,
            value: question.QuestionOptions[x].Value
          };
        }
      }

      properties[question.id] = questionProp;

      uiOrder.push(question.id);

      if(isMultiValueType){
        let values = [];
        if(question.Answers 
          && question.Answers.length > 0
          && question.Answers[0].AnswerValues 
          && question.Answers[0].AnswerValues.length > 0){
          for(let x=0; x<question.Answers[0].AnswerValues.length; x++){
            let answerVal = question.Answers[0].AnswerValues[x];
            if(answerVal && answerVal.Value){
              if(!answersSeen){
                answersSeen = true;
              }
              if(isIntegerField){
                try{
                  values.push(parseInt(answerVal.Value));
                }catch(e){
                  console.log(e);
                }
              }else{
                values.push(answerVal.Value);
              }
            }
          }
        }
        answers[question.id] = values;
      }else{
        if(question.Answers 
          && question.Answers.length > 0
          && question.Answers[0].AnswerValues 
          && question.Answers[0].AnswerValues.length > 0
          && question.Answers[0].AnswerValues[0].Value){
          if(isIntegerField){
            try{
              answers[question.id] = parseInt(question.Answers[0].AnswerValues[0].Value);
            }catch(e){
              console.log(e);
            }
          }else{
            answers[question.id] = question.Answers[0].AnswerValues[0].Value;
          }
          if(!answersSeen && answers[question.id]){
            answersSeen = true;
          }
        }else{
          answers[question.id] = "";
        }
      }
      

      if(question.Required){
        //needs to be a string for the library to work for some reason
        required.push(question.id+"");
      }
    }); 

    let dependentQuestion;
    let requiredQuestion;
    let requiredValue;
    let processedDependents = [];
    for(let x=0; x< dependentQuestions.length; x++){
      dependentQuestion = dependentQuestions[x];
      if(questionOptionsMap[dependentQuestion.QuestionOptionId]){
        requiredQuestion = questionOptionsMap[dependentQuestion.QuestionOptionId].question;
        requiredValue = questionOptionsMap[dependentQuestion.QuestionOptionId].value;
        processedDependents.push({
          parentQuestionId: requiredQuestion.id,
          childQuestionId: dependentQuestion.id,
          valueNeeded: requiredValue
        });
      }else{
        console.log("Dependency on a question option that doesn't exist! ",dependentQuestion.QuestionOptionId);
      }
      
    }

    schema.properties = properties;
    schema.required = required;
    
    schemaUI["ui:order"] = uiOrder;
    
    return { 
      schema, 
      schemaUI, 
      answers,
      hasAnswers:answersSeen ,
      dependentQuestions:processedDependents
    };
  }

  static ConvertFormDataToDatabase(databaseData, formData) {
    //formData is an object of "question id":"answer value"
    //we must here change to an array of {id:answer value id, value:answer value}
    let answersUpdate = [];
    let answersNew = [];
    let answersDelete = [];

    databaseData.Questions.forEach( question => {
      let isMultiValueType = question.Type == 'year-qn' 
        || question.Type == 'multi-sel';
      if(isMultiValueType){
        //let existingMap = {};
        let startingValuesNum = 0;
        let answerId = 0;
        if(question.Answers 
          && question.Answers.length > 0
          && question.Answers[0].AnswerValues 
          && question.Answers[0].AnswerValues.length > 0){
          startingValuesNum = question.Answers[0].AnswerValues.length;
          answerId = question.Answers[0].id;
        }else{
          console.log("MAJOR ERROR, no answer on a question ",question);
          return;
        }
        //new answers
        let newValues = formData[question.id];
        //remove blanks
        let usedNewValues = [];
        if(newValues && newValues.length>0){
          for(let x=0; x<newValues.length; x++){
            if(newValues[x]){
              usedNewValues.push(newValues[x]);
            }
          }
        }

        let max = Math.max(startingValuesNum, usedNewValues.length);

        for(let x=0; x<max; x++){
          if(x < usedNewValues.length && x< startingValuesNum){
            //updates!
            answersUpdate.push({
              id:question.Answers[0].AnswerValues[x].id,
              Value:usedNewValues[x]
            });
          }else if(x < usedNewValues.length){
            //adds!
            answersNew.push({
              Value:usedNewValues[x],
              AnswerId:answerId
            })
          }else{
            //extra answer values, delete, but leave at least one
            if(startingValuesNum == 1){
              if(question.Answers[0].AnswerValues[x].Value){
                answersUpdate.push({
                  id:question.Answers[0].AnswerValues[x].id,
                  Value:null
                });
              }
            }else{
              answersDelete.push(question.Answers[0].AnswerValues[x].id);
            }
          }
        }
      }else{
        if(question.Answers 
          && question.Answers.length > 0
          && question.Answers[0].AnswerValues 
          && question.Answers[0].AnswerValues.length > 0
          && formData[question.id] != question.Answers[0].AnswerValues[0].Value){
          let newValue = formData[question.id];
          if(newValue === undefined){
            newValue = null;
          }
          answersUpdate.push({
            id:question.Answers[0].AnswerValues[0].id,
            Value:newValue
          })
        }
      }
      
    }); 


    return {
      answersUpdate,
      answersNew,
      answersDelete,
      totalChanges: answersUpdate.length + answersNew.length + answersDelete.length
    }
  }
}

export {QuestionGroupUtil as QuestionGroupUtil}
export default QuestionGroupUtil