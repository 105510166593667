import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import { LoginForm } from "components/LoginForm";
import { loginUser, closeLogOutPopUp } from "actions/loginActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import AuthRule from "businessRule/AuthRule";

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: 400,
    marginTop: 30
  },
  helpContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: 400,
    marginTop: 30,
    textAlign: "center"
  }
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // set the initial component state
    this.state = {
      userInput: {
        email: "",
        password: "",
        code: ""
      }
    };

    this.processFormLogin = this.processFormLogin.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let params = (new URL(document.location)).searchParams;
    let username = params.get('Username')

    if (username){
      this.setState({
        userInput: {
          email: username,
          password: "",
          code: ""
        }
      })
    }
  }


  handleClose() {
    const { dispatch } = this.props;
    dispatch(closeLogOutPopUp());
  }

  processFormLogin(event) {
    event.preventDefault();

    const { email, password } = this.state.userInput;
    const { dispatch, location } = this.props;

    if (email && password) {
      dispatch(loginUser(email, password, location.pathname));
    }
  }

  changeUser(event) {
    const field = event.target.name;
    const userInput = this.state.userInput;
    userInput[field] = event.target.value;

    this.setState({
      userInput
    });
  }

  render() {
    const { user, classes, hasTimedOut } = this.props;

    //already signed in??
    const redirectURL = AuthRule.getUserLoginRedirectURL(user);
    if (redirectURL) {
      return <Redirect to={redirectURL} push={false} />;
    }

    //go to two factor??
    if (
      user.twoFactorRequired &&
      new Date().getTime() - user.meta.loginCallTime < 3000
    ) {
      //make sure they did login recently

      return <Redirect to="/loginCode" push={false} />;
    }

    const callLoading =
      user.meta &&
      user.meta.loginIsCalling &&
      user.meta.loginCallTime + 10000 < new Date().getTime();
    const form = (
      <LoginForm
        onSubmit={this.processFormLogin}
        onChange={this.changeUser}
        errors={user.meta && user.meta.errorMessage}
        user={this.state.userInput}
        processing={callLoading || false}
      />
    );

    const logOutAlert = (
      <React.Fragment>
        <Dialog open={hasTimedOut} onClose={this.handleClose}>
          <DialogTitle>You have been signed out due to inactivity</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {" "}
              OK{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {logOutAlert}
        <Card className={classes.container}>{form}</Card>
        <Card className={classes.helpContainer}>
          <Typography variant="body1">
            <Icon>{"contact_support"}</Icon>
            <br />
            {"Need Help?"}
            <br />
            {"(267) 339-3698"}
            <br />
            {
              "Remote Support is Available Monday through Friday from 7:30am to 5:00pm."
            }
          </Typography>
        </Card>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  hasTimedOut: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  const { user, hasTimedOut } = state;
  return {
    user,
    hasTimedOut
  };
}

const styledLoginPage = withStyles(styles)(LoginPage);
const connectedLoginPage = connect(mapStateToProps)(styledLoginPage);
export { connectedLoginPage as LoginPage };
