import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { goBack } from "connected-react-router";
import { connect } from "react-redux";
import Form from "react-jsonschema-form";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { BaseInput } from "components/forms/BaseInput";
import { SelectWidget } from "components/forms/SelectWidget";
import { CheckboxWidget } from "components/forms/CheckboxWidget";
import { DateWidget } from "components/forms/DateWidget";
import { CheckboxesWidget } from "components/forms/CheckboxesWidget";
import { RadioWidget } from "components/forms/RadioWidget";
import { TitleField } from "components/forms/TitleField";
import { TextareaWidget } from "components/forms/TextareaWidget";
import { MaterialArrayFieldTemplate } from "components/forms/MaterialArrayFieldTemplate";

const styles = (theme) => ({
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
  form: {
    width: 800,
    maxWidth: 800
  },
  formDialog: {
    width: 500,
    maxWidth: 500
  },
  button: {
    marginLeft: theme.spacing.unit * 2
  },
  submitContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end"
  }
});

function FieldTemplate(propsTemplate) {
  const { classNames, children, hidden } = propsTemplate;
  if (hidden) {
    return children;
  }

  return <div className={classNames}>{children}</div>;
}

function ObjectFieldTemplate(propsTemplate) {
  const { TitleField, DescriptionField } = propsTemplate;

  return (
    <Fragment>
      {(propsTemplate.uiSchema["ui:title"] || propsTemplate.title) && (
        <TitleField
          id={`${propsTemplate.idSchema.$id}__title`}
          title={propsTemplate.title || propsTemplate.uiSchema["ui:title"]}
          required={propsTemplate.required}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.description && (
        <DescriptionField
          id={`${propsTemplate.idSchema.$id}__description`}
          description={propsTemplate.description}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.properties.map((prop) => prop.content)}
    </Fragment>
  );
}

const customFields = { TitleField };
const customWidgets = {
  BaseInput,
  SelectWidget,
  CheckboxWidget,
  CheckboxesWidget,
  RadioWidget,
  DateWidget,
  TextareaWidget
};

const uiSchemaDefault = {
  "ui:options": {
    displayLabel: false
  }
};

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenSubmittedWithErrors: false,
      errorMessage: null
    };

    this.onFormError = this.onFormError.bind(this);
    this.onPrimaryClick = this.onPrimaryClick.bind(this);
    this.onSecondaryClick = this.onSecondaryClick.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.transformFormErrors = this.transformFormErrors.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  onFormChange(data) {
    const {
      onChange,
      onDataTransform,
      onPostAcuteEventFormChange
    } = this.props;

    let formData = data.formData;

    // All fields that are blank return undefined, change to null so that the
    // database is updated correctly
    Object.keys(formData).map((key) => {
      if (formData[key] === undefined) {
        formData[key] = null;
      }
    });

    if (onDataTransform) {
      formData = onDataTransform(formData);
    }

    this.setState({
      localFormData: formData,
      errorMessage: null
    });

    data.formData = formData;

    if (onPostAcuteEventFormChange) {
      onPostAcuteEventFormChange(data);
    }

    if (onChange) {
      onChange(data);
    }
  }

  onFormError(data) {
    const { onError } = this.props;

    const { hasBeenSubmittedWithErrors, errorMessage } = this.state;

    let newErrorMessage;
    if (data && data.length > 0) {
      newErrorMessage = this.getMessageFromError(data[0]);
    }

    if (!hasBeenSubmittedWithErrors || errorMessage != newErrorMessage) {
      this.setState({
        hasBeenSubmittedWithErrors: true,
        errorMessage: newErrorMessage
      });
    }

    if (onError) {
      onError(data);
    }
  }

  scrubProperNameInError(schema, propertyName) {
    /*there seems to be a bug in form code that property is ['propery']
    instead of just property*/
    if (propertyName.startsWith("['") && propertyName.endsWith("']")) {
      let propNameParsed = propertyName.replace("['", "");
      propNameParsed = propNameParsed.replace("']", "");
      if (schema.properties && schema.properties[propNameParsed]) {
        propertyName = propNameParsed;
      }
    } else if (propertyName.startsWith(".")) {
      let propNameParsed = propertyName.substring(1, propertyName.length);
      if (schema.properties && schema.properties[propNameParsed]) {
        propertyName = propNameParsed;
      }
    }

    return propertyName;
  }

  onPrimaryClick() {
    const { submitText } = this.props;

    if (submitText) {
      this.form.state.submitSource = submitText;
    } else {
      this.form.state.submitSource = "Save";
    }
  }

  onSecondaryClick() {
    const { secondaryText } = this.props;

    this.form.state.submitSource = secondaryText;
  }

  onCancelClick() {
    const { dispatch, backOnCancel, onCancel } = this.props;

    if (backOnCancel) {
      dispatch(goBack());
    } else if (onCancel) {
      onCancel();
    }
  }

  findNameOfProperty(property) {
    const { schema } = this.props;

    const propertyName = this.scrubProperNameInError(schema, property);

    if (
      schema &&
      schema.properties &&
      schema.properties[propertyName] &&
      schema.properties[propertyName].title
    ) {
      return schema.properties[propertyName].title;
    }

    return "";
  }

  getMessageFromError(error) {
    if (error && error.property && error.message) {
      const fieldName = this.findNameOfProperty(error.property);
      let message = fieldName + " " + error.message;
      return message;
    }

    return "";
  }

  transformFormErrors(errors) {
    if (!errors) {
      return;
    }

    const { schema, formData, extraTransformError } = this.props;

    const { localFormData } = this.state;

    let usedFormData = formData;
    if (localFormData) {
      usedFormData = localFormData;
    }

    let usedErrors = [];
    let errorMap = {};
    if (errors) {
      errors.forEach((oldError) => {
        let propertyName = this.scrubProperNameInError(
          schema,
          oldError.property
        );

        if (!errorMap[propertyName]) {
          errorMap[propertyName] = [oldError.name];
        } else {
          errorMap[propertyName].push(oldError.name);
        }

        if (oldError.name == "format") {
          //format errors is only valid if the field is required!!
          //this allows empty values, needed for non-required items
          if (schema.required && schema.required.includes(propertyName)) {
            usedErrors.push(oldError);
          }
        } else if (oldError.name == "enum") {
          //error is only valid if the field is required!!
          //this allows empty enum values, needed for non-required enums

          if (schema.required && schema.required.includes(propertyName)) {
            usedErrors.push(oldError);
          }
        } else if (oldError.name == "minLength") {
          if (
            (usedFormData && usedFormData[propertyName]) ||
            (schema.required && schema.required.includes(propertyName))
          ) {
            usedErrors.push(oldError);
          }
        } else if (
          oldError.name == "type" &&
          oldError.params &&
          oldError.params.type == "number"
        ) {
          if (usedFormData && usedFormData[propertyName]) {
            //parse it and see if a number
            let parsed = parseInt(usedFormData[propertyName]);
            if (isNaN(parsed)) {
              usedErrors.push(oldError);
            }
          }
        } else if (
          oldError.name == "type" &&
          oldError.params &&
          oldError.params.type == "string"
        ) {
          const isRequired =
            schema.required && schema.required.includes(propertyName);
          if (isRequired || (usedFormData && usedFormData[propertyName])) {
            usedErrors.push(oldError);
          }
        } else {
          usedErrors.push(oldError);
        }
      });

      //check on the required, sometimes not there
      if (schema.required && schema.required.length > 0) {
        schema.required.forEach((requiredField) => {
          if (
            usedFormData &&
            (!usedFormData[requiredField] ||
              (Array.isArray(usedFormData[requiredField]) &&
                (usedFormData[requiredField].length == 0 ||
                  !usedFormData[requiredField][0])))
          ) {
            //empty required field... make sure in errors
            if (
              !errorMap[requiredField] ||
              !errorMap[requiredField].includes("required")
            ) {
              usedErrors.push({
                message: "is a required property",
                name: "required",
                params: {
                  missingProperty: requiredField
                },
                property: "." + requiredField,
                stack: "." + requiredField + " is a required property"
              });
            }
          }
        });
      }
    }

    if (extraTransformError) {
      usedErrors = extraTransformError(usedFormData, usedErrors);
    }

    /* since the usedErrors can have empty error data we will return a
       filtered version until we figure out the route cause of the empty errors
       return usedErrors;
       -------------------------------------------------------------------------
       remove any empty required fields */
    const filteredErrors = usedErrors.filter((data) => {
      return (
        data.params.missingProperty && data.params.missingProperty.trim() !== ""
      );
    });
    return filteredErrors;
  }

  render() {
    const {
      classes,
      status,
      allowSubmit,
      statusIsError,
      schema,
      uiSchema,
      onError,
      onChange,
      formData,
      inDialog,
      submitText,
      secondaryText,
      validateImmediate,
      onDataTransform,
      key,
      showCancel,
      onClear,
      ...otherProps
    } = this.props;

    const {
      hasBeenSubmittedWithErrors,
      localFormData,
      errorMessage
    } = this.state;

    if (onError || onChange || onDataTransform) {
      //needs to be grabbed so not in otherProps
    }

    let usedStatus = status;
    const disableSubmit = allowSubmit === false;
    let statusColor = "textSecondary";
    if (statusIsError === true || errorMessage) {
      statusColor = "error";
    }
    if (errorMessage) {
      usedStatus = errorMessage;
    }

    let usedUISchema = uiSchema;
    if (usedUISchema) {
      usedUISchema["ui:options"] = {
        displayLabel: false
      };
    } else {
      usedUISchema = uiSchemaDefault;
    }

    let usedFormData = formData;
    if (localFormData) {
      usedFormData = localFormData;
    }

    let formCls = classes.form;
    if (inDialog) {
      formCls = classes.formDialog;
    }

    let usedSubmitText;
    if (submitText) {
      usedSubmitText = submitText;
    } else {
      usedSubmitText = "Save";
    }

    let leftButtonStyle = {
      marginLeft: "0"
    };

    let secondaryButton;

    if (secondaryText) {
      secondaryButton = (
        <Button
          className={classes.button}
          color="primary"
          margin="normal"
          variant="raised"
          type="submit"
          onClick={this.onSecondaryClick}
          style={leftButtonStyle}
          disabled={disableSubmit}
        >
          {secondaryText}
        </Button>
      );
    }
    // key cannot be passed down as prop
    let formKey;

    if (!key) {
      formKey = "form";
    } else {
      formKey = key;
    }

    return (
      <div className={classes.formContainer}>
        <Form
          key={formKey}
          ObjectFieldTemplate={ObjectFieldTemplate}
          FieldTemplate={FieldTemplate}
          ArrayFieldTemplate={MaterialArrayFieldTemplate}
          className={formCls}
          fields={customFields}
          widgets={customWidgets}
          showErrorList={false}
          schema={schema}
          uiSchema={usedUISchema}
          liveValidate={validateImmediate || hasBeenSubmittedWithErrors}
          onChange={this.onFormChange}
          onError={this.onFormError}
          formData={usedFormData}
          {...otherProps}
          noHtml5Validate={true}
          ref={(form) => {
            this.form = form;
          }}
          transformErrors={this.transformFormErrors}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            <div className={classes.submitContainer}>{secondaryButton}</div>
            <div className={classes.submitContainer}>
              {usedStatus && (
                <Typography color={statusColor} variant="subheading">
                  {usedStatus}
                </Typography>
              )}
              {onClear && (
                <Button
                  className={classes.button}
                  color="default"
                  margin="normal"
                  variant="raised"
                  onClick={onClear}
                >
                  Clear
                </Button>
              )}
              {showCancel && (
                <Button
                  className={classes.button}
                  color="default"
                  margin="normal"
                  variant="raised"
                  onClick={this.onCancelClick}
                >
                  Cancel
                </Button>
              )}
              <Button
                className={classes.button}
                type="submit"
                color="primary"
                margin="normal"
                variant="raised"
                onClick={this.onPrimaryClick}
                disabled={disableSubmit}
              >
                {usedSubmitText}
              </Button>
            </div>
          </Grid>
        </Form>
      </div>
    );
  }
}

MaterialForm.defaultProps = {
  showCancel: true,
  backOnCancel: true
};

MaterialForm.propTypes = {
  classes: PropTypes.object.isRequired,
  status: PropTypes.string,
  statusIsError: PropTypes.bool,
  allowSubmit: PropTypes.bool,
  validateImmediate: PropTypes.bool,
  uiSchema: PropTypes.object,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  formData: PropTypes.object,
  inDialog: PropTypes.bool,
  submitText: PropTypes.string,
  secondaryText: PropTypes.string,
  onDataTransform: PropTypes.func,
  key: PropTypes.string,
  extraTransformError: PropTypes.func,
  showCancel: PropTypes.bool,
  backOnCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onPostAcuteEventFormChange: PropTypes.func
};

const styledPage = withStyles(styles)(MaterialForm);
const connectedPage = connect()(styledPage);
export { connectedPage as MaterialForm };
