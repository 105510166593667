import {
  TIMEOUT,
  CLOSE_LOGOUT_POP_UP
} from 'actions/loginActions';

export const hasTimedOut = (state = false, action) => {
  switch(action.type){
  case TIMEOUT:
    return true;
  case CLOSE_LOGOUT_POP_UP:
    return false;
  default:
    return state;
  }
}