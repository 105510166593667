export const API_LIST_DISCHARGEFACILITIES = 'API_LIST_DISCHARGEFACILITIES';
export const API_GET_DISCHARGEFACILITIES = 'API_GET_DISCHARGEFACILITIES';
export const CREATE_DISCARGE_FACILITY = 'CREATE_DISCARGE_FACILITY';

export const listDischargeFacilities = (options = {}, asList = false) => {
  
  const type = (asList) ? API_LIST_DISCHARGEFACILITIES : API_GET_DISCHARGEFACILITIES;
  return {
    type,
    request: {
      url: '/DischargeFacilities',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const createDischargeFacility = (data) => {
  return {
    type: CREATE_DISCARGE_FACILITY,
    request: {
      url: '/DischargeFacilities',
      method: 'PUT',
      data
    }
  }
};
