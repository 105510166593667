export const API_CREATE_SURGERY_FLAG = 'API_CREATE_SURGERY_FLAG';
export const API_GET_SURGERY_FLAG = 'API_GET_SURGERY_FLAG';
export const API_REMOVE_SURGERY_FLAG = 'API_REMOVE_SURGERY_FLAG';
export const API_SEND_SURGERY_FLAG_EMAIL = 'API_SEND_SURGERY_FLAG_EMAIL';

export const getSurgeryFlags = () => {
  return {
    type: API_GET_SURGERY_FLAG,
    request: {
      url: '/SurgeryFlag',
      method: 'GET'
    }
  }
};

export const createSurgeryFlag = (data) => {
  return {
    type: API_CREATE_SURGERY_FLAG,
    request:{
      url: '/SurgeryFlag',
      method: 'PUT',
      data: data
    }
  }
};

export const removeSurgeryFlag = (data) => {
  return {
    type: API_REMOVE_SURGERY_FLAG,
    request:{
      url: '/SurgeryFlag',
      method: 'PUT',
      data: data
    }
  }
};

export const sendSHREmail = (mrn, {emails, notes}) => {
  return {
    type: API_SEND_SURGERY_FLAG_EMAIL,
    request:{
      url: '/SendSHREmail',
      method: 'POST',
      data: {
        mrn,
        emails,
        notes
      }
    }
  }
}

