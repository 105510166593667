import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { NumberCard } from 'components/NumberCard'
import NavigationUtil from 'util/NavigationUtil'
import { getHSXalerts } from 'actions/hsxActions'

const styles = {
  card: {}
}

class HSXAlertsCountView extends React.Component {
  componentDidMount () {
    this.updateCount()
  }

  componentDidUpdate (prevProps) {
    //view as user changed
    if (
      this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null ||
        this.props.viewAsUser.id != prevProps.viewAsUser.id)
    ) {
      this.updateCount()
    }
  }

  updateCount () {
    const { dispatch, viewAsUser } = this.props

    let query = {
      where: {},
      offset: 0
    }

    if (viewAsUser.Role == 2 || viewAsUser.Role == 3 || viewAsUser.Role == 8) {
      query.where.AlertRole = 'Nav'
      query.where.NavigatorUserId = viewAsUser.id
    } else {
      query.where.AlertRole = 'Admin'
    }

    dispatch(getHSXalerts(query))
  }

  render () {
    const { NumberOfEvents } = this.props

    return (
      <NumberCard
        title='HIE Alerts'
        number={NumberOfEvents}
        action='View'
        path={NavigationUtil.navLink('/hsxalertsdashboard')}
      />
    )
  }
}

HSXAlertsCountView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  hsxAlerts: PropTypes.array,
  NumberOfEvents: PropTypes.number,
  viewAsUser: PropTypes.object
}

function mapStateToProps (state) {
  const { user, hsxAlerts } = state

  var { viewAsUser } = user
  var NumberOfEvents = 0

  if (viewAsUser == null) {
    viewAsUser = user
  }

  if (hsxAlerts.length > 0) {
    NumberOfEvents = hsxAlerts[0].NumberOfEvents
  }

  return {
    hsxAlerts,
    NumberOfEvents,
    viewAsUser
  }
}

const styled = withStyles(styles)(HSXAlertsCountView)
const connected = connect(mapStateToProps)(styled)
export { connected as HSXAlertsCountView }
