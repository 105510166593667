import React from 'react';
import { ConversationDetailView } from 'containers/messages/ConversationDetailView';

class MessagesPage extends React.Component {

  render() {
    return (
      <ConversationDetailView isPAC={false} />
    );
  }
}

export {MessagesPage as MessagesPage};
