import { takeEvery, put } from 'redux-saga/effects';
const ADD_MISSING_DISCHARGE_FACILITY = "ADD_MISSING_DISCHARGE_FACILITY";

function* updateMissingFacility(action) {
  const dischargeFacilityId = action.data.user.id;
  const { data } = action.meta.requestAction.request;

  yield put({
    type: ADD_MISSING_DISCHARGE_FACILITY,
    request:{
      url: '/AddMissingDischargeFacilityId',
      method: 'POST',
      data: {
        id: data.id,
        facilitieName: data.facilitieName,
        dischargeFacilityId 
      }
    }
  });
}

export const missingFacility = [
  takeEvery(("ADD_DISCHARGE_FACILITY_SUCCESS"), updateMissingFacility)
];