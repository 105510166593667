import NavigationUtil from 'util/NavigationUtil'
import { push } from 'connected-react-router';

export const API_POST_ACUTE_EVENTS_FOR_EPISODE = 'API_POST_ACUTE_EVENTS_FOR_EPISODE';
export const API_GET_POST_ACUTE_EVENT = 'API_GET_POST_ACUTE_EVENT';
export const API_UPDATE_POST_ACUTE_EVENT = 'API_UPDATE_POST_ACUTE_EVENT';

export const getPostAcuteEventsForEpisodeId = (surgeryId, options)=> {
  options.include = [
    {
      association: "ReadmissionFacility",
      attributes: [
        "id",
        "Name"
      ]
    },
    {
      association: "PrimaryReason",
      attributes: [
        "id",
        "Text"
      ]
    },
    {
      association: "SecondaryReasons",
      attributes: ["id"],
      include:[
        {
          association: "Reasons",
          attributes: [
            "id",
            "Text"
          ]
        }
      ]
    }]

  if(options && options.where){
    options.where.Deleted = false;
  } else {
    options.where = {
      Deleted: false
    }
  }

  return {
    type: API_POST_ACUTE_EVENTS_FOR_EPISODE,
    id: surgeryId,
    request: {
      url: '/PostAcuteEvents',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const viewPostAcuteEvent = (surgeryId, postAcuteEventId, questionGroupId = null, taskId = null) => {
  let url;

  if(questionGroupId && taskId){
    url = NavigationUtil.navLink('/surgery/'+surgeryId+'/form/'+questionGroupId+'/task/'+taskId+'/'+postAcuteEventId);
  } else {
    url = NavigationUtil.navLink('/surgery/'+surgeryId+'/postAcute/'+postAcuteEventId);
  }
  
  return push(url);
};

export const getPostAcuteEvent = (postAcuteEventId)=> {
  const options = {
    where: {id:postAcuteEventId},
    include: {
      association:'SecondaryReasons'
    }
  }

  return {
    type: API_GET_POST_ACUTE_EVENT,
    id: postAcuteEventId,
    request: {
      url: '/PostAcuteEvents',
      method: 'GET',
      params: {opts:options}
    }
  }
};

export const updatePostAcuteEvent = (formData, secondaryReasons, redirectUrl = null)=> {

  let id = 0;
  if(formData.id && formData.id > 0){
    id = formData.id;
  }
  
  let resultOptions = {
    include: {
      association:"SecondaryReasons"
    }
  };

  let body = {
    modelName:"PostAcuteEvents",
    modelData: formData,
    relationModelName:"PostAcuteEventSecondaryReasons",
    relationData:secondaryReasons,
    relationModelForeignKey:"PostAcuteEventId",
    modelResultOption: resultOptions
  }

  return {
    type: API_UPDATE_POST_ACUTE_EVENT,
    id: id,
    postAcuteEvent: formData,
    redirectOnSuccess : redirectUrl,
    request:{
      url: '/UpsertAndRelate',
      method: 'PUT',
      data : body
    }
  }

};
