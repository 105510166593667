import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CSVUtil from 'util/CSVUtil'
import { 
  setWorkingUploadData,
  isValidImportData,
  clearWorkingUploadData,
  bulkLoadHospitalExports,
  updateBulkSurgeries,
  setWorkingRowData,
  setWorkingEnumMap,
  listOldHospitalImport,
  clearErrorWorkingData
} from 'actions/exportActions';

import { DynamicTable } from 'components/DynamicTable';
import StoreUtil from 'stores/StoreUtil';
import SurgeryUtil from 'util/SurgeryUtil';
import { LoadingView } from 'components/LoadingView'
import { HospitalRowEditDialog } from 'containers/export/HospitalRowEditDialog';
import { getObjWithLowerCaseKeys } from 'reducers/exportReducer';

const styles = (theme) => ({
  pageContainer:{
    width: '100%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  header:{
    alignSelf:'flex-start',
    marginBottom: theme.spacing.unit * 2
  },
  dropAreaContent:{
    textAlign:'center',
    width:'100%',
    marginTop:50
  },
  tableHeader:{
    width:'100%',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    marginBottom: theme.spacing.unit
  },
  table:{
    width:'100%'
  },
  tableAction:{
    display:'flex',
    flexDirection:'row'
  },
  tableActionSubmit:{
    marginLeft: theme.spacing.unit
  },
  tableActionDownload:{
    marginLeft: theme.spacing.unit
  }
});

const columns = [
  {label:"Navigator", key: "Navigator", sortable: false, sortDown: false},
  {label:"Name", key: "Name", sortable: false, sortDown: false},
  {label:"Age", key: "Age", sortable: false, sortDown: false, style:{width:40}},
  {label:"Birth Date", key: "BirthDate", sortable: false},
  {label:"Date of Surgery", key: "DateofSurgery", sortable: false, 
    sortDown: false},
  {label:"Surgeon", key: "Surgeon", sortable: false, sortDown: false},
  {label:"Procedure", key: "Procedure", sortable: false, sortDown: false},
  {label:"FYI", key: "FYI", sortable: false, sortDown: false, style:{width:400}},
  {label:"Reason", key: "Reason", sortable: false, sortDown: false, style:{width:200}},
  {label:"Discharge Disposition", key: "DischargeDisposition", sortable: false, sortDown: false},
  {label:"Post Acute Facility", key: "PostAcuteFacility", sortable: false, sortDown: false},
  {label:"Discharge Date", key: "DischargeDate", sortable: false, sortDown: false},
  {label:"Discharge Notes", key: "DispositionNotes", sortable: false, sortDown: false}
];

const query = {
  order:[["DateofSurgery", "DESC"]]
};


//Post Acute LOS/NOV Unit Actual Discharge Date Discharge Notes
class HospitalImportPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      dialogOpen: false,
      selectedData:null,
      uploadError: false
    };

    this.onDrop = this.onDrop.bind(this);
    this.onResult = this.onResult.bind(this);
    this.clearData = this.clearData.bind(this);
    this.submitData = this.submitData.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.downloadBadData = this.downloadBadData.bind(this);
    this.correctErrors = this.correctErrors.bind(this);
    this.displayInvalidRowError = this.displayInvalidRowError.bind(this);
  }

  componentDidMount(){
    const {
      dispatch,
      dischargeFacilities,
      dischargeDispositions,
      previousImportData,
      user
    } = this.props;

    SurgeryUtil.checkAndLoadFacilities(dischargeFacilities, dispatch);
    SurgeryUtil.checkAndLoadDispositions(dischargeDispositions, dispatch);

    //load existing working data
    if(StoreUtil.needsLoadNoCache(previousImportData)){
      dispatch(listOldHospitalImport(user.id));
    }
    this.loadSurgeries();
  }

  componentDidUpdate() {
    const {
      dischargeFacilities,
      dischargeDispositions,
      enumMap,
      dispatch
    } = this.props;
    
    this.loadSurgeries();

    //create and save enum map after fac and disp loaded
    let facLastLoad = StoreUtil.getLastLoadTime(dischargeFacilities);
    let dispLastLoad = StoreUtil.getLastLoadTime(dischargeDispositions);
    if((!enumMap 
      || (facLastLoad && dispLastLoad && enumMap.loadTime < facLastLoad && enumMap.loadTime < dispLastLoad))
      && StoreUtil.getData(dischargeFacilities) 
      && StoreUtil.getData(dischargeDispositions)){
      let disps = StoreUtil.getData(dischargeDispositions);
      let facs = StoreUtil.getData(dischargeFacilities);

      //create enum map for working data
      let dispsValues = disps.map((disposition) => {
        return disposition.Text.toUpperCase();
      });
      let facValues = facs.map((facility) => {
        return facility.Name.toUpperCase();
      });
      let facCamelValues = facs.map((facility) => {
        return facility.Name;
      });
      let dispsIds = disps.map((disposition) => {
        return disposition.id;
      });
      let facIds = facs.map((facility) => {
        return facility.id;
      });
      let map = {
        disposition:dispsValues,
        dispositionId:dispsIds,
        facility:facValues,
        facilityId:facIds,
        facilityCamel:facCamelValues,
        loadTime:(new Date()).getTime()
      }
      dispatch(setWorkingEnumMap(map));
    }
  }

  loadSurgeries(){
    const{
      unprocessedData,
      dispatch,
      surgeryBulk,
      workingData,
      dischargeFacilities,
      dischargeDispositions
    } = this.props;
    if(!workingData || !unprocessedData || workingData.processed){
      return
    }
    if(StoreUtil.isLoading(surgeryBulk)
      || StoreUtil.isLoading(dischargeFacilities)
      || StoreUtil.isLoading(dischargeDispositions)){
      return;
    }
    let usedData = unprocessedData;
    
    dispatch(bulkLoadHospitalExports(usedData));
  }

  onResult(result, fileName){
    //save in the store 
    if(result && Array.isArray(result)){
      const {
        dispatch
      } = this.props;

      //add a filename
      let timestamp = Math.floor( ((new Date()).getTime() / 1000) );
      let uniqueFileName = timestamp+ fileName;
      for(let x=0; x<result.length; x++){
        result[x].FileName = uniqueFileName;
      }

      /*if the import data is valid we will run dispatch(setWorkingUploadData(result))
        which will save to the Hospital Import table and continue as normal

       if the import data is NOT valid we will pass the invalid rows to the this.displayInvalidRowError
       function to display the error on the screen and the isValidImportData function will return false;
       which stops execution of the dispatch, therefore preventing bad data from going into the Hospital Import table*/
      isValidImportData(result, this.displayInvalidRowError) && dispatch(setWorkingUploadData(result));
    }else{
      //failure
      this.setState({
        error:result
      });
    }
  }

  displayInvalidRowError(errors) {
    if(errors.length){
      let errorStrings = [];
      errors.forEach(errorsMessage => {
        errorStrings.push(`${errorsMessage.reason} for patient ${errorsMessage.patient}`);
      })
      const displayError = errorStrings.join(" : ");
      const error = `${displayError}. Please fix errors or remove patients from the CSV to upload. Thanks!`
      this.setState({uploadError: error});
    }
  }

  onDrop(acceptedFiles) {

    // reset the upload error flag before checking the csv for errors
    this.setState({uploadError: false});

    if(!acceptedFiles || acceptedFiles.length != 1){
      return;
    }
    let file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsString = reader.result;
      let result = CSVUtil.parseCSV(CSVUtil.HOSPITAL_HEADERS, fileAsString);
      this.onResult(result, file.name);
    };
    reader.onabort = () =>{
      this.onResult("Upload Failed.  Please try again");
    }
    reader.onerror = () =>{
      this.onResult("Upload could not be completed.  Please try again");
    }

    reader.readAsBinaryString(file);
  }

  downloadBadData(){
    const {
      workingData,
      dispatch
    } = this.props;

    let data = workingData.data;
    if(!data || workingData.invalidRows < 1){
      return;
    }
    //grab invalid rows
    let invalidRows = [];
    data.forEach((row)=>{
      if(row.invalid){
        invalidRows.push(row);
      }
    });

    let csv = CSVUtil.createCSV(CSVUtil.HOSPITAL_HEADERS, invalidRows);
    if(!csv){
      console.log("ERROR creating csv");
      return;
    }
    //download data from browser
    //headers
    let downloadLink = document.createElement("a");
    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "surgeriesWithErrors.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    //now remove the bad surgeries from the working data
    dispatch(clearErrorWorkingData(invalidRows));
  }

  clearData(){
    const {
      dispatch,
      workingData
    } = this.props;

    dispatch(clearWorkingUploadData(workingData.data));
  }

  submitData(){
    const {
      dispatch,
      workingData,
      enumMap,
      surgeryBulk,
      dischargeFacilities
    } = this.props;

    //convert date into only altered fields
    let dbSurgeryList = CSVUtil.prepareCSVWorkingDataForDB(CSVUtil.HOSPITAL_HEADERS, 
      workingData.data, enumMap, StoreUtil.getData(surgeryBulk));
    this.correctErrors(workingData.data, dischargeFacilities.data, dbSurgeryList);
    dispatch(updateBulkSurgeries(dbSurgeryList,workingData.data));
    CSVUtil.addMissingFacilityAndUpdateDB(dbSurgeryList, workingData.data, dispatch);
  }

  correctErrors(surgeryData, dischargeFacilities, dbSurgeryList){
    // corrects case related discharge facility errors
    const nameMapping = [];
    const idMapping = {};
    surgeryData.forEach((data, index) => {
      const normalizedName = data.PostAcuteFacility.trim().toUpperCase();
      nameMapping.push(normalizedName);
      idMapping[`${normalizedName}`] = index;
    });
    dischargeFacilities.forEach(data => {
      const normalizedName = data.Name.trim().toUpperCase();
      if(nameMapping.includes(normalizedName)){
        const facilityIndex = idMapping[normalizedName];
        surgeryData[facilityIndex].PostAcuteFacility = data.Name;
        dbSurgeryList[facilityIndex].DischargeFacilityId = data.id;
      }
    })
    // other corrects can be made here in the future
  }

  onRowClick(row){
    if(row.invalid){
      this.setState({
        dialogOpen: true,
        selectedData:row
      });
    }
  }

  handleDialogClose(changedData){
    if(changedData){
      const {
        dispatch
      } = this.props;

      dispatch(setWorkingRowData(changedData));
    }

    this.setState({
      dialogOpen: false,
      selectedData:null
    });
  }

  render() {
    const {
      classes,
      workingData,
      enumMap,
      surgeryBulkUpdate,
      surgeryBulk
    } = this.props;

    const {
      error,
      dialogOpen,
      selectedData,
      uploadError
    } = this.state;

    let content;

    let surgeryLoadFailure = StoreUtil.isLoadFailed(surgeryBulk);

    if(workingData && !surgeryLoadFailure){
      if(workingData.processed){
        let invalidImport = !workingData.valid;
        let invalidCount = workingData.invalidRows;

        let shownData = workingData.data;
        CSVUtil.findAndReplaceNameFields(shownData, surgeryBulk);
        if(invalidImport){
          shownData = {
            placeholder:true,
            showLoading:false,
            showNoData:true
          };
        }
        let statusColor;
        let statusText;
        let isValid = invalidCount == 0 && !invalidImport;
        let hasErrorRecords = invalidCount > 0;
        if(!isValid){
          statusColor = "error";
          if(invalidImport){
            if(workingData.hasCollision){
              statusText = "File has more than one row with same DOB, DOS and patient name.  It cannot be used.";
            }else{
              const errorPatients = workingData.invalidPatients.join(" | ");
              const directions = `These patients must be corrected before importing. Thanks`;
              statusText = `Remove the following patients from the CSV: ${errorPatients}. ${directions}`;
            }
          }else{
            if(invalidCount != 1){
              statusText = `${invalidCount} rows have errors.  Click on row to edit.`;
            }else{
              statusText = `${invalidCount} row has errors.  Click on row to edit.`;
            }
          }
        }else{
          statusColor = "textPrimary";
          statusText = "Data ready for submission"
        }

        let selectedSurgery;
        let byHash = StoreUtil.getData(surgeryBulk);
        if(selectedData && byHash){
          const lowerCaseByHash = getObjWithLowerCaseKeys(byHash)
          selectedSurgery = lowerCaseByHash[selectedData.hash.toLowerCase()];
        }

        content = (
          <React.Fragment>
            <div className={classes.tableHeader}>
              <Typography
                variant="subheading"
                color={statusColor}>
                {statusText}
              </Typography>
              <div className={classes.tableAction}>
                <Button
                  margin="normal"
                  variant="raised"
                  onClick={this.clearData}>
                  Clear Upload Data
                </Button>
                <Button
                  className={classes.tableActionDownload}
                  margin="normal"
                  variant="raised"
                  disabled={!hasErrorRecords}
                  onClick={this.downloadBadData}>
                  Download Error Records
                </Button>
                <Button
                  className={classes.tableActionSubmit}
                  color="primary"
                  margin="normal"
                  variant="raised"
                  disabled={!isValid}
                  onClick={this.submitData}>
                  Save Upload Data
                </Button>
              </div>
            </div>
            <div className={classes.table}>
              <DynamicTable 
                columns={columns} 
                showFilters={false}
                data={shownData}
                onRowClick={this.onRowClick}
                query={query}
              />
            </div>
            <HospitalRowEditDialog
              open={dialogOpen}
              onClose={this.handleDialogClose}
              workingData={selectedData}
              surgeryData={selectedSurgery}
              enumMap={enumMap}
            />
          </React.Fragment>
        );
      }else{
        content = (
          <React.Fragment>
            <div className={classes.tableHeader}>
              <Typography
                variant="subheading">
                Processing
              </Typography>
              <div className={classes.tableAction}>
                <Button
                  margin="normal"
                  variant="raised"
                  onClick={this.clearData}>
                  Clear Upload Data
                </Button>
              </div>
            </div>
            <LoadingView />
          </React.Fragment>
        );
      }
      
    }else{
      let message;
      if(StoreUtil.didJustSave(surgeryBulkUpdate)){
        message = "Upload Saved Successfully!";
      }
      let usedError;
      if(error){
        usedError = error;
      }else if(surgeryLoadFailure){
        usedError = "Data corrupt, and contains has non-existent surgeries.  Please upload another file.";
      }
      content = (
        <React.Fragment>
          {message && (
            <Typography
              variant="headline">
              {message}
            </Typography>
          )}
          {usedError && (
            <Typography
              variant="subheading"
              color="error">
              {usedError}
            </Typography>
          )}
          {uploadError && (
            <Typography
              variant="subheading"
              color="error">
              {uploadError}
            </Typography>
          )}
          <Dropzone
            onDrop={this.onDrop}
            multiple={false}
          >
            <div className={classes.dropAreaContent}>
              <Typography
                variant="body1">
                Drag and Drop <br/>or
              </Typography>
              <Button
                color="primary"
                margin="normal"
                variant="raised"
                type="submit">
                Select File
              </Button>
            </div>
          </Dropzone>
        </React.Fragment>
      );
    }

    return (
      <div className={classes.pageContainer}>
        <Typography
          className={classes.header}
          variant="display1">
          Import Hospital Surgeries
        </Typography>
        {content}
      </div>
    );
  }
}

HospitalImportPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  workingData: PropTypes.object,
  dischargeFacilities: PropTypes.object.isRequired,
  dischargeDispositions: PropTypes.object.isRequired,
  surgeryBulk:PropTypes.object.isRequired,
  surgeryBulkUpdate:PropTypes.object.isRequired,
  enumMap:PropTypes.object,
  unprocessedData:PropTypes.array,
  previousImportData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  const {  
    exportData,
    dischargeFacilities,
    dischargeDispositions,
    user
  } = state;

  let usedUser = user;
  if(user.viewAsUser){
    usedUser = user.viewAsUser;
  }

  return {
    workingData:exportData.workingData,
    enumMap:exportData.enumMap,
    unprocessedData:exportData.unprocessedData,
    dischargeFacilities: StoreUtil.get(dischargeFacilities, StoreUtil.COMMON_LIST),
    dischargeDispositions: StoreUtil.get(dischargeDispositions, StoreUtil.COMMON_LIST),
    surgeryBulk: StoreUtil.get(exportData, StoreUtil.COMMON_BULK),
    surgeryBulkUpdate: StoreUtil.get(exportData,"surgeryBulkUpdate"),
    previousImportData: StoreUtil.get(exportData, StoreUtil.COMMON_LIST),
    user:usedUser
  };
}

const styledPage = withStyles(styles)(HospitalImportPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as HospitalImportPage};
