import React from 'react';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getQuestionGroup } from 'actions/questionGroupActions';
import { LoadingView } from 'components/LoadingView';
import StoreUtil from 'stores/StoreUtil';

import QuestionGroupUtil from 'util/QuestionGroupUtil';
import { DetailDataGroup } from 'components/detail/DetailDataGroup'

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2
  },
  container: {
    width:'100%',
    display:'flex',
    flexDirection:'column',
    margin : theme.spacing.unit
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  listContainer:{
    minHeight:100,
    width:'100%',
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap'
  }
});

class QuestionGroupDetailView extends React.Component {

  componentDidMount(){
    //check if data load needed
    const {
      questionGroup,
      questionGroupId,
      surgeryId,
      admissionId,
      dispatch
    } = this.props;

    if(StoreUtil.needsLoadNoCache(questionGroup)){
      dispatch(getQuestionGroup(questionGroupId, surgeryId, admissionId));
    }
  }


  render() {
    const { classes,
      questionGroup,
      title
    } = this.props;

    if(!StoreUtil.getData(questionGroup)){
      return (<LoadingView />);
    }else if(StoreUtil.isLoadFailed(questionGroup)){
      return (
        <Typography
          className={classes.headerContainer}
          variant='title' color='error'>
          There was an issue loading.
        </Typography>
      );

    }else{

      const databaseData = StoreUtil.getData(questionGroup);

      //get form schema
      const {
        schema, 
        answers
      } = QuestionGroupUtil.ConvertDatabaseToForm(databaseData);

      let name ='';
      if(title){
        name = title;
      }else if(databaseData && databaseData.name){
        name = databaseData.name;
      }

      let hasAnswers = false;
      for(let x in answers){
        if(answers[x]){
          hasAnswers = true;
          break;
        }
      }
      

      //group
      let groups = [];
      if(hasAnswers){
        let curGroup = {properties:{}};
        for(let key in schema.properties){
          if(schema.properties[key].title.match(/^\d/)){
            curGroup.properties[key] = schema.properties[key];
          }else{
            //new header
            let answer ='';
            if(answers[key]){
              answer = answers[key];
            }
            curGroup = {
              title:schema.properties[key].title+" "+answer,
              properties:{}
            };
            groups.push(curGroup);
          }
        }
      }
      

      return (
        <Paper className={classes.root}>
          <div className={classes.headerContainer}>
            <Typography variant="headline">{name}</Typography>
          </div>
          {hasAnswers && 
            <div className={classes.listContainer}>
              {groups.map((groupSchema, index) => (
                <DetailDataGroup
                  key={index}
                  schema={groupSchema}
                  data={answers}
                />
              ))}
            </div>
          }
          {!hasAnswers && 
            <Typography variant="headline">--</Typography>
          }
        </Paper>
      );
    }
  }
}
QuestionGroupDetailView.defaultProps = {
  readyOnlyOnEdit: false
}

QuestionGroupDetailView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgeryId : PropTypes.number.isRequired,
  questionGroupId : PropTypes.number.isRequired,
  questionGroup: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  admissionId: PropTypes.number
}

function mapStateToProps(state, existingProps) {
  const { questionGroupId, surgeryId, admissionId } = existingProps;
  const { questionGroup } = state;

  let formId = QuestionGroupUtil.getFormStoreId(questionGroupId, surgeryId, admissionId);
  let group = StoreUtil.get(questionGroup, StoreUtil.COMMON_ITEM, formId);
  
  return {
    questionGroup: group
  };
}

const styled = withStyles(styles)(QuestionGroupDetailView);
const connected = connect(mapStateToProps)(styled);
export { connected as QuestionGroupDetailView };
export default QuestionGroupDetailView;