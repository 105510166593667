import {
  SIGN_OUT_WARNING,
  CLOSE_SIGN_OUT_WARNING
} from 'actions/loginActions';

export const hasTriggeredSignOutWarning = (state = false, action) => {
  switch(action.type){
  case SIGN_OUT_WARNING:
    return true;
  case CLOSE_SIGN_OUT_WARNING:
    return false;
  default:
    return state;
  }
}