import { listReadmissionFacilities } from 'actions/readmissionFacilityActions';
import { listReasons } from 'actions/reasonActions';
import StoreUtil from 'stores/StoreUtil'

class PostAcuteUtil {

  static checkAndLoadFacilities(readmissionFacilities, dispatch) {
    if (!readmissionFacilities || StoreUtil.needsLoadLongCache(readmissionFacilities)) {
      dispatch(listReadmissionFacilities({}, true));
    }
  }

  static checkAndLoadReasons(reasons, dispatch, types) {
    let options = '';

    if (!reasons || StoreUtil.needsLoadNoCache(reasons)) {
      if(types && types.length > 0){
        options = {
          where: { Type: types }
        };
      }

      dispatch(listReasons(options));
    }
  }

  static getReadmissionFacilityById(readmissionFacilities, readmissionFacilityId) {
    if (readmissionFacilities) {
      let typesArray = readmissionFacilities;

      //check if it actual array or data obj
      if (!Array.isArray(typesArray) && typesArray.data && Array.isArray(typesArray.data)) {
        typesArray = typesArray.data;
      }

      if (Array.isArray(typesArray)) {
        return typesArray.find((readmissionFacility) => {
          return (readmissionFacility.id == readmissionFacilityId);
        });
      }
    }
    return null;
  }

}

export { PostAcuteUtil as PostAcuteUtil }
export default PostAcuteUtil
