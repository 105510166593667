import { push } from 'connected-react-router';

export const API_LIST_FACILITIES = 'API_LIST_FACILITIES';
export const API_TABLE_FACILITIES = 'API_TABLE_FACILITIES';
export const API_GET_FACILITY = 'API_GET_FACILITY';
export const API_GET_FACILITY_NAME = 'API_GET_FACILITY_NAME';
export const API_UPDATE_FACILITY_NAME = 'API_UPDATE_FACILITY_NAME';
export const API_GET_FACILITY_ID = 'API_GET_FACILITY_ID';
export const ADD_DISCHARGE_FACILITY = 'ADD_DISCHARGE_FACILITY';
import NavigationUtil from 'util/NavigationUtil';

export const addFacility = (id, facilitieName) => {
  return {
    type: ADD_DISCHARGE_FACILITY,
    request:{
      url: '/AddDischargeFacilities',
      method: 'POST',
      data: {
        id,
        facilitieName
      }
    }
  }
}

export const listFacilities = ()=> {
  return {
    type: API_LIST_FACILITIES,
    request: {
      url: '/Facilities',
      method: 'GET'
    }
  }
};

export const tableFacilities = (options)=> {
  if(!options.order){
    options.order = [];
    options.order.push(["Name", "ASC"])
  }
  options.where = {
    ...options.where,
    "Deleted" : false
  }

  return {
    type: API_TABLE_FACILITIES,
    request: {
      url: '/Facilities',
      method: 'GET',
      params: { opts: options }
    }
  }
};

export const getFacilityName = (facilityId)=> {
  const options = {
    where: { id: facilityId },
    attributes:['id','Name']
  }

  return {
    type: API_GET_FACILITY_NAME,
    id: facilityId,
    request: {
      url: '/Facilities',
      method: 'GET',
      params: { opts: options }
    }
  }
};

export const getFacility = (facilityId)=> {
  const options = {
    where: { id: facilityId }
  }

  return {
    type: API_GET_FACILITY,
    id: facilityId,
    request: {
      url: '/Facilities',
      method: 'GET',
      params: { opts: options }
    }
  }
};

export const updateFacility = (formData)=> {
  let options = null;
  let facilityId = 0;

  if (formData.id && formData.id > 0) {
    facilityId = formData.id;
    options = {
      where: { id: facilityId }
    }
  }

  return {
    type: API_UPDATE_FACILITY_NAME,
    id: facilityId,
    facility: formData,
    request: {
      url: '/Facilities',
      method: 'PUT',
      params: { opts: options },
      data: formData
    }
  }
}

export const viewFacility = (user, facility)=> {
  return push(NavigationUtil.userLink(user, '/facilities/' + facility.id))
}
