import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { NumberCard } from 'components/NumberCard';
import { listAdmissions } from 'actions/admissionActions';
import NavigationUtil from 'util/NavigationUtil'
import moment from 'moment';

const styles = ({
  card: {

  }
});

class OverdueDischargesCountView extends React.Component {

  componentDidMount() {
    this.updateCount();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {
      this.updateCount();
    }
  }

  updateCount() {
    const {
      dispatch,
      viewAsUser
    } = this.props;

    let query = {
      where: {
        AnticipatedDischargeDate: { $lt: moment().format('L') },
        DischargeDate: null
      },
      order: [
        ['AnticipatedDischargeDate','ASC']
      ],
      limit: 50,
      offset: 0
    };

    const facilityOptions = {
      where: {
        Name: { $ne: 'Novacare' }
      }
    }

    dispatch(listAdmissions(query, viewAsUser.id, facilityOptions));
  }

  render() {
    const {
      overdueDischargesCount
    } = this.props;

    let cardColor;

    if(overdueDischargesCount > 0){
      cardColor = 'red';
    }

    return (
      <NumberCard
        title="Overdue Discharges"
        number={overdueDischargesCount}
        action="View"
        path={NavigationUtil.navLink("/overduedischarges")}
        cardColor={cardColor}
      />
    );
  }
}

OverdueDischargesCountView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  overdueDischargesCount: PropTypes.number.isRequired,
  viewAsUser: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const {
    admissions,
    user
  } = state;

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }

  let count = 0;
  if(admissions &&
    admissions.table &&
    admissions.table.data &&
    admissions.table.data.count){
    count = admissions.table.data.count;
  }

  return {
    overdueDischargesCount: count,
    viewAsUser
  };
}

const styled = withStyles(styles)(OverdueDischargesCountView);
const connected = connect(mapStateToProps)(styled);
export { connected as OverdueDischargesCountView };
