import React from 'react';
import { PropTypes } from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  footer: {
    display:'flex',
    justifyContent: 'space-between',
    width : '100%',
    padding: theme.spacing.unit*2,
    paddingTop: theme.spacing.unit,
    boxSizing: 'border-box'
  }
});

class Footer extends React.Component {

  render() {
    const {
      classes
    } = this.props;

    let year = (new Date()).getFullYear();

    const version = process.env.RELEASE_VERSION;
    const testENV = process.env.IS_QA && " Test Environment"

    return (
      <footer className={classes.footer}>
        <Typography variant="caption">Rothman Ortho Navigator {version}{testENV}</Typography>
        <Typography variant="caption">Copyright {year}</Typography>
      </footer>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(Footer);
export {styled as Footer};

