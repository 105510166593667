import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';
import { push } from 'connected-react-router';

import { SurgeryAdmissionsTable } from 'components/admissions/SurgeryAdmissionsTable';
import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil';
import NavigationUtil from 'util/NavigationUtil';
import { listAdmissions, viewAdmission } from 'actions/admissionActions';

import { viewConversation } from 'actions/messageActions';

const styles = () => ({
});

const TABLE_NAME = "SurgeryAdmissionsView";

class SurgeryAdmissionsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitialTasks();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.surgery != null &&
      (prevProps.surgery == null || this.props.surgery.id != prevProps.surgery.id )) {
      this.fetchInitialTasks();
    }
  }

  fetchInitialTasks() {
    this.setState({query: this.buildInitialQuery() }, this.fetch.bind(this));
  }

  buildInitialQuery() {
    const {
      surgery,
      preferenceQuery
    } = this.props;

    let query = {
      where: {},
      attributes:['id', 'FacilityId', 'SurgeryId', 'AdmissionDate', 'AnticipatedDischargeDate','DischargeDate'],
      limit: 25,
      offset: 0,
      include: [
        {
          association: 'Facility',
          attributes:['Name']
        },
        {
          association:'Answers',
          required:false,
          include:[
            {
              association:'AnswerValues',
              required:false
            },
            {
              association:'Question',
              required:false,
              include:[
                {
                  association:'QuestionOptions',
                  required:false
                }
              ]
            }
          ]
        },
        {
          association:'Conversations',
          attributes:['id', 'Status', 'ConversationType'],
          required:false,
          where:{
            ConversationType:DataUtil.CONVERSATION_TYPE_OTHER
          }
        }
      ],
      order:[["AdmissionDate","DESC"]]
    };

    if(preferenceQuery){
      query = preferenceQuery;
    }

    query.where["SurgeryId"] = surgery.id;

    return query;
  }

  fetch() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listAdmissions(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetch.bind(this));
  }

  onRowClick(admission) {
    const {
      dispatch
    } = this.props;
    dispatch(viewAdmission(admission, false));
  }

  onMessageClick(admission){
    const {
      dispatch
    } = this.props;

    let foundOpen = false;
    if(admission.Conversations 
      && admission.Conversations.length>0){
      //find open convo
      let open = admission.Conversations.find( (item)=>{
        return !DataUtil.isConversationResolved(item)
          && item.ConversationType == DataUtil.CONVERSATION_TYPE_OTHER;
      });

      if(open && open.id){
        foundOpen = true;
        //send user to open conversation
        dispatch(viewConversation(admission.SurgeryId, admission.id, open.id));
      }
    }

    if(!foundOpen){
      //send user to new conversation
      let link = NavigationUtil.navLink(`/surgery/${admission.SurgeryId}/admission/${admission.id}/newMessage`);
      dispatch(push(link));
    }
  }

  render() {

    const {
      query
    } = this.state;

    const {
      admissions
    } = this.props;

    let admissionsData = StoreUtil.getData(admissions);

    if(admissionsData && admissionsData.count > 0){
      return (
        <React.Fragment>
          <SurgeryAdmissionsTable
            header={"Post-Acute Care Plan"}
            data={admissions}
            query={query}
            showFilters={false}
            onQueryChange={this.onQueryChange.bind(this)}
            onRowClick={this.onRowClick.bind(this)}
            onMessageClick={this.onMessageClick.bind(this)}
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

SurgeryAdmissionsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  admissions: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
}

function mapStateToProps(state) {
  const { admissions, preference } = state;

  return {
    admissions: StoreUtil.get(admissions, StoreUtil.COMMON_TABLE),
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(SurgeryAdmissionsView);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryAdmissionsView };
