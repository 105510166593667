import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import dotProp from 'dot-prop-immutable';

import { MaterialForm } from "components/forms/MaterialForm";
import {createAdmission} from 'actions/admissionActions';
import StoreUtil from 'stores/StoreUtil';
import FormUtil from 'util/FormUtil';
import DateUtil from 'util/DateUtil';
import { NavigationUtil } from 'util/NavigationUtil'
import { DataUtil } from 'util/DataUtil'

const styles = (theme) => ({
  container: {
    padding:theme.spacing.unit * 2
  }
});

const schema = {
  title: '',
  type: 'object',
  required: ['AdmissionDate', 'AnticipatedDischargeDate'],
  properties: {
    AdmissionDate: {
      type: 'string', 
      title: 'Admission Date',
      format: 'date'
    },
    AnticipatedDischargeDate: {
      type: 'string', 
      title: 'Anticipated D/C Date', 
      format: 'date'
    }
  }
};

const uiSchema = {
  AdmissionDate:{
    "ui:options": {
      todayButton: true,
      link: null,
      linkMax: null
    }
  },
  AnticipatedDischargeDate:{
    "ui:disabled" : true,
    "ui:options": {
      link:null,
      linkMax:null
    }
  }
};

class AdmitPatientDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      AdmissionDate: null
    }
    this.handleClose = this.handleClose.bind(this);

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }


  handleClose(){
    const {
      admission
    } = this.props;

    this.props.onClose(admission);
  }

  onFormSubmit(data){
    const {
      dispatch,
      user,
      patient
    } = this.props;

    // let mostRecentSurgeryId;
    // let mostRecentSurgeryDate;

    // patient.Surgeries.forEach((surgery) => {
    //   if(!mostRecentSurgeryDate || moment(surgery.SurgeryDateTime) > moment(mostRecentSurgeryDate)){
    //     mostRecentSurgeryDate = surgery.SurgeryDateTime;
    //     mostRecentSurgeryId = surgery.id;
    //   }
    // })

    let formData = data.formData;
    formData.FacilityId = user.FacilityId;
    formData.SurgeryId = patient.Surgeries[0].id; // mostRecentSurgeryId
    delete formData.id;
    
    dispatch(createAdmission(formData));
  }

  onFormError(error){
    console.log(error);
  }

  onFormChange(form){
    if(form.formData){
      if(form.formData.AdmissionDate != this.state.AdmissionDate){
        this.setState({AdmissionDate: form.formData.AdmissionDate});
      }
    }
  }

  render() {
    const { 
      classes, 
      open,
      admission,
      patient,
      facility,
      isPTUser
    } = this.props;

    const {
      AdmissionDate
    } = this.state;

    if(StoreUtil.didJustSave(admission) && open){
      this.handleClose();
      return (<Redirect to={NavigationUtil.pacLink('/home')} push={true} />);
    }

    let usedUISchema = uiSchema;

    if(patient && patient.Surgeries){
      const surgeryDateTime = patient.Surgeries[0].SurgeryDateTime;
      const admissionMin = moment(surgeryDateTime).format(DateUtil.FORM_DATE_FORMAT);
      usedUISchema = dotProp.set(usedUISchema,'AdmissionDate.ui:options.linked', admissionMin);
      const admissionMax = moment(surgeryDateTime).add(91, 'day').format(DateUtil.FORM_DATE_FORMAT);
      usedUISchema = dotProp.set(usedUISchema,'AdmissionDate.ui:options.linkedMax', admissionMax);
    }
    
    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(admission, 'admission');

    let startData = {
      AdmissionDate: DateUtil.formatToDB(AdmissionDate)
    }

    if(startData.AdmissionDate){
      //date must be within x amount of days of admission
      //nova care only has a 2 day admission range from the date of admission
      const admissionDischargeRange = (facility === "Novacare") ? 2 : 5;
      const newMin = moment(startData.AdmissionDate).format(DateUtil.FORM_DATE_FORMAT);
      usedUISchema = dotProp.set(usedUISchema,'AnticipatedDischargeDate.ui:options.linked', newMin);
      const maxDate = moment(startData.AdmissionDate).add(admissionDischargeRange, 'days');
      const newMax = moment(maxDate).format(DateUtil.FORM_DATE_FORMAT);
      usedUISchema = dotProp.set(usedUISchema,'AnticipatedDischargeDate.ui:options.linkedMax', newMax);
      delete usedUISchema.AnticipatedDischargeDate["ui:disabled"];
    }

    let patientName = '';
    if(patient){
      patientName = patient.FirstName+" "+patient.LastName;
    }

    let usedUISchemaRef = JSON.parse(JSON.stringify(usedUISchema)); // breaks reference and copies
    let usedSchemaRef = JSON.parse(JSON.stringify(schema)); // breaks reference and copies
    if(isPTUser){
      // remove the anticipated discharge date
      delete usedUISchemaRef.AnticipatedDischargeDate;
      delete usedSchemaRef.properties.AnticipatedDischargeDate;
      const index = usedSchemaRef.required.indexOf("AnticipatedDischargeDate");
      usedSchemaRef.required.splice(index, 1)
    }

    return (
      <Dialog
        onClose={this.handleClose} 
        aria-labelledby="simple-dialog-title" 
        open={open}>
        <div className={classes.container} >
          <Typography
            className={classes.headerPageTitle}
            variant="display1">
            Admit Patient
          </Typography>
          <Typography
            className={classes.headerPageTitle}
            variant="headline">
            You are about to Admit : {patientName}
          </Typography>
          <MaterialForm
            schema={usedSchemaRef}
            uiSchema={usedUISchemaRef}
            onChange={this.onFormChange}
            onSubmit={this.onFormSubmit}
            onError={this.onFormError}
            status={status}
            statusIsError={failure}
            inDialog={true}
            formData={startData}
          />
        </div>
      </Dialog>
    );
  }
}

AdmitPatientDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open : PropTypes.bool.isRequired,
  dispatch : PropTypes.func.isRequired,
  user : PropTypes.object.isRequired,
  patient : PropTypes.object,
  admission: PropTypes.object.isRequired,
  facility: PropTypes.string,
  isPTUser: PropTypes.bool
};

function mapStateToProps(state) {
  const { 
    user,
    admissions,
    facilities
  } = state;

  const isPTUser = DataUtil.isPTUser(user);

  const facilityId = user.FacilityId;
  // the current facility selected
  const facility = facilities && facilities.name && facilities.name[facilityId] &&
                        facilities.name[facilityId].data && facilities.name[facilityId].data.Name;

  const admission = StoreUtil.get(admissions, StoreUtil.COMMON_NEW_ITEM);
  return {
    user,
    admission,
    facility,
    isPTUser
  };
}

const styled = withStyles(styles)(AdmitPatientDialog);
const connected = connect(mapStateToProps)(styled);

export { connected as AdmitPatientDialog}
