import React, {Component} from 'react';
import { DynamicTable } from 'components/DynamicTable';
import { withStyles } from '@material-ui/core/styles';
import { DateUtil } from 'util/DateUtil';

const styles = ({

});

class RiskScoreAuditTable extends Component {
  constructor(props) {
    super(props);

    //this controls which columns are displayed and how they are looked up in data
    this.columns = [
      {label:"MedicalRiskScore", key: "MedicalRiskScore", sortable: true, sortDown: false},
      {label:"MedicalRiskLevel", key: "MedicalRiskLevel", sortable: true, sortDown: false},
      {label:"SocialRiskScore", key: "SocialRiskScore", sortable: true, sortDown: false},
      {label:"SocialRiskLevel", key: "SocialRiskLevel", sortable: true, sortDown: false},
      {label:"RiskLevelReason", key: "RiskLevelReason", sortable: true, sortDown: false},
      {label:"User", key: "User", sortable: true, sortDown: false},
      {label:"Updated At", key: "updatedAt", sortable: true, sortDown: false, format:this.dateFormat}
    ];
  }
  
  dateFormat(date) {
    return DateUtil.formatHistoryDateTimeFromDB(date);
  }

  render() {
    return <DynamicTable {...this.props} columns={this.columns} classes={this.props.classes}/>;
  }
}

RiskScoreAuditTable.propTypes = DynamicTable.propTypes;

const styled = withStyles(styles)(RiskScoreAuditTable);
export {styled as RiskScoreAuditTable};