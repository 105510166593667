import React, { Fragment, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { HeaderLogin } from 'containers/login/HeaderLogin';
import { Footer } from 'components/Footer';

import { LoginPage } from 'containers/login/LoginPage';
import { TwoFactorPage } from 'containers/login/TwoFactorPage';
import { ForgotPasswordPage } from 'containers/login/ForgotPasswordPage';
import { ResetPasswordPage } from 'containers/login/ResetPasswordPage';
import { PatientFormPage } from 'containers/patients/PatientFormPage';

const styles = () =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width:"100%"
  }
});

class LoginRoot extends Component {
  render() {
    const {
      classes
    } = this.props;

    return (
      <div className={classes.container}>
        <HeaderLogin />
        <Fragment>
          <Switch>
            <Route
              path='/forgotPassword'
              component={ForgotPasswordPage}
              exact={true} />
            <Route
              path='/resetPassword'
              component={ResetPasswordPage}
              exact={true} />
            <Route
              path='/setupAccount'
              component={ResetPasswordPage}
              exact={true} />
            <Route
              path='/loginCode'
              component={TwoFactorPage}
              exact={true} />
            <Route
              path='/postOpForm/:surgeryId'
              component={PatientFormPage}
              exact={true} />
            <Route
              component={LoginPage}
              exact={true} />
          </Switch>
        </Fragment>
        <Footer className={classes.footer}/>
      </div>
    );
  }
}

LoginRoot.propTypes = {
  classes: PropTypes.object.isRequired
};

const styledApp = withStyles(styles)(LoginRoot);
export {styledApp as LoginRoot};
