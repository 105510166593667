export const API_LIST_READMISSIONFACILITIES = 'API_LIST_READMISSIONFACILITIES';
export const API_GET_READMISSIONFACILITIES = 'API_GET_READMISSIONFACILITIES';
export const CREATE_READMISSION_FACILITY = 'CREATE_READMISSION_FACILITY';

export const listReadmissionFacilities = (options, asList = false)=> {
  
  const type = (asList) ? API_LIST_READMISSIONFACILITIES : API_GET_READMISSIONFACILITIES;
  return {
    type,
    request: {
      url: '/ReadmissionFacilities',
      method: 'GET',
      params : {opts:options}
    }
  }
};

export const listPTReadmissionFacilities = ()=> {
  const options = {
    where: {
      Name: {
        $like: "%-PT%"
      }
    }
  };

  return {
    type: API_LIST_READMISSIONFACILITIES,
    request: {
      url: '/ReadmissionFacilities',
      method: 'GET',
      params: { opts: options }
    }
  }
};

export const createReadmissionFacility = (data) => {
  return {
    type: CREATE_READMISSION_FACILITY,
    request: {
      url: '/ReadmissionFacilities',
      method: 'PUT',
      data
    }
  }
};