import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { darken } from '@material-ui/core/styles/colorManipulator';
import StoreUtil from 'stores/StoreUtil';
import DateUtil from 'util/DateUtil';
import { getSurgeryDashView, updateSurgery } from 'actions/surgeryActions';

import { PertinentPositivesView } from 'containers/positives/PertinentPositivesView';
import { PatientDetailView } from 'containers/patients/PatientDetailView';
import { SurgeryDetailView } from 'containers/surgeries/SurgeryDetailView';
import { SurgeryTasksView } from 'containers/tasks/SurgeryTasksView';
import { SurgeryPostAcuteEventsView } from 'containers/postAcuteEvents/SurgeryPostAcuteEventsView';
import { SurgeryHospitalVisitsView } from 'containers/postAcuteEvents/SurgeryHospitalVisitsView';
import { LoadingView } from 'components/LoadingView';
import { SurgeryAdmissionsView } from 'containers/admissions/SurgeryAdmissionsView'
import { SurgeryConversationsView } from 'containers/messages/SurgeryConversationsView'
import DataUtil from 'util/DataUtil';

const styles = (theme) => ({
  container: {
    width:'100%'
  },
  header:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  headerPageTitle:{
    marginRight: theme.spacing.unit * 2
  },
  spacer:{
    height: theme.spacing.unit * 2
  },
  active: {
    backgroundColor: "#34a853",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#34a853", 0.4)
    },
    alignSelf:'center'
  },
  inactive: {
    backgroundColor: "#ea4335",
    marginLeft:theme.spacing.unit * 2,
    '&:hover': {
      background: darken("#ea4335", 0.4)
    },
    alignSelf:'center'
  }
});

class SurgeryDetailPage extends React.Component {

  constructor(props) {
    super(props);

    this.onActiveClick = this.onActiveClick.bind(this);
  }

  componentDidMount(){
    //check if data load needed
    const {surgery, surgeryId, dispatch} = this.props;

    if(StoreUtil.needsLoadNoCache(surgery)){
      dispatch(getSurgeryDashView(surgeryId));
    }
  }

  componentDidUpdate(){
    const {surgerySave, surgery, dispatch, surgeryId} = this.props;
    if(StoreUtil.didJustSave(surgerySave) && !StoreUtil.isLoading(surgery)){
      const lastSaveTime = StoreUtil.getLastSaveTime(surgerySave);
      const lastLoadTime = StoreUtil.getLastLoadTime(surgery);

      if(lastSaveTime != null && lastLoadTime != null){
        const wasSavedAfterLoad = (lastSaveTime > lastLoadTime);
        if(wasSavedAfterLoad){
          dispatch(getSurgeryDashView(surgeryId));
        }
      }
    }
  }

  onActiveClick(){
    const {
      surgery,
      surgeryId,
      dispatch
    } = this.props;

    if(!StoreUtil.isLoaded(surgery)){
      return;
    }

    let nextActive;
    if(StoreUtil.getData(surgery).Active){
      nextActive = false;
    }else{
      nextActive = true;
    }

    let update = {
      id: surgeryId,
      Active : nextActive
    }
    dispatch(updateSurgery(update));
  }

  render() {

    //find surgery
    const { surgery, classes, tab, isPTSurgery, dispatch } = this.props;

    const headingText = isPTSurgery ? "Knee Scope" : "Surgery";

    if(!StoreUtil.getData(surgery)){
      return (
        <LoadingView />
      );
    }else if(StoreUtil.isLoadFailed(surgery)){
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              className={classes.headerPageTitle}
              variant='title' color='error'>
              There was an issue loading the surgery.
            </Typography>
          </div>
        </div>
      );
    }else{
      let surgeryData = StoreUtil.getData(surgery);
      let surgeryDate;
      if(surgeryData.DOS){
        surgeryDate = DateUtil.formatFromDB(surgeryData.DOS);
      }
      let activeTag;
      let activeCls;
      let toolTipText;
      if(surgeryData.Active){
        toolTipText = "Click to Deactivate";
        activeTag = "Active";
        activeCls = classes.active;
      }else{
        activeTag = "Inactive";
        toolTipText = "Click to Activate";
        activeCls = classes.inactive;
      }

      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              className={classes.headerPageTitle}
              variant="display1">
              {headingText}
            </Typography>
            <Typography variant="title">DOS: {surgeryDate}</Typography>
            <Tooltip title={toolTipText}>
              <Button
                className={activeCls}
                color="primary"
                margin="normal"
                variant="raised"
                size="large"
                onClick={this.onActiveClick}
              >{activeTag}</Button>
            </Tooltip>
          </div>
          <div className={classes.spacer} />
          <PatientDetailView 
            externalSource={surgeryData.ExternalSource}
            patientMRN={surgeryData.MRN} 
            surgeryId={surgeryData.id} 
            surgeryAge={surgeryData.PatientSurgeryAge} />
          <div className={classes.spacer} />
          <SurgeryDetailView surgery={surgeryData} dispatch={dispatch} />
          <div className={classes.spacer} />
          {!isPTSurgery && (<div>
            <PertinentPositivesView surgery={surgeryData} />
            <div className={classes.spacer} />
          </div>)}
          <SurgeryTasksView surgery={surgeryData} tab={tab} />
          <div className={classes.spacer} />
          <SurgeryConversationsView surgery={surgeryData} />
          <div className={classes.spacer} />
          <SurgeryAdmissionsView surgery={surgeryData} />
          <div className={classes.spacer} />
          <SurgeryPostAcuteEventsView surgery={surgeryData} />
          <div className={classes.spacer} />
          <SurgeryHospitalVisitsView surgery={surgeryData} />
        </div>
      );
    }
  }
}

SurgeryDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery : PropTypes.object.isRequired,
  surgeryId : PropTypes.string.isRequired,
  surgerySave : PropTypes.object.isRequired,
  tab: PropTypes.number,
  isPTSurgery: PropTypes.bool.isRequired
}

function mapStateToProps(state, existingProps) {
  const { surgeries } = state;
  //find surgery

  const { match } = existingProps;
  const surgeryId = match.params.surgeryId;
  let tab = 0;
  if(match && match.params && match.params.tab){
    tab = match.params.tab;
  }

  const surgery = StoreUtil.get(surgeries, 'dashview', surgeryId);
  let selectedTab;

  if(tab){
    selectedTab = parseInt(tab);
  } else {
    selectedTab = 0;
  }

  const surgerySave = StoreUtil.get(surgeries, StoreUtil.COMMON_ITEM, surgeryId);

  const isPTSurgery = DataUtil.isPTSurgeryBySurgery(surgeries, surgeryId);

  return {
    surgeryId,
    surgery,
    surgerySave,
    tab: selectedTab,
    isPTSurgery
  };
}

const styled = withStyles(styles)(SurgeryDetailPage);
const connected = connect(mapStateToProps)(styled);
export { connected as SurgeryDetailPage };
