import React, {Component} from 'react';

import { BreadcrumbItem } from 'components/breadcrumb/BreadcrumbItem';

import ChevronRight from '@material-ui/icons/ChevronRight';
import NavigationUtil from 'util/NavigationUtil'

class SurgeonsBCItem extends Component {

  render() {
    return (
      <React.Fragment>
        <ChevronRight/>
        <BreadcrumbItem
          label="Surgeons"
          icon="supervisor_account"
          path={NavigationUtil.navLink("/surgeons")}
        />
      </React.Fragment>
    );
  }
}

SurgeonsBCItem.propTypes = {
};


export {SurgeonsBCItem as SurgeonsBCItem };