import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MaterialForm } from "components/forms/MaterialForm";
import StoreUtil from 'stores/StoreUtil';
import { getFacility, updateFacility } from 'actions/facilityActions'
import { Redirect } from 'react-router-dom';
import FormUtil from 'util/FormUtil';
import NavigationUtil from 'util/NavigationUtil'

const schema = {
  title: 'Facility',
  type: 'object',
  required: ['Name'],
  properties: {
    Name: {
      type: 'string',
      title: 'Name',
      minLength: 1,
      description: 'Type the facility\'s name'
    },
    FacilityType: {
      type: 'string',
      title: 'Type',
      enum: [
        'IRF',
        'Part B',
        'SNF'
      ],
      description: 'Select the facility\'s type'
    },
    Phone: {
      type: 'string',
      title: 'Phone',
      minLength: 1,
      description: 'Type the facility\'s phone number'
    },
    Address1: {
      type: 'string',
      title: 'Address 1',
      minLength: 1,
      description: 'Type the facility\'s address line 1'
    },
    Address2: {
      type: 'string',
      title: 'Address 2',
      minLength: 1,
      description: 'Type the facility\'s address line 2' },
    City: {
      type: 'string',
      title: 'City',
      minLength: 1,
      description: 'Type the facility\'s city'
    },
    State: {
      type: 'string',
      title: 'State',
      minLength: 2,
      description: 'Type the facility\'s state'
    },
    Zip: {
      type: 'string',
      title: 'Zip',
      minLength: 5,
      description: 'Type the facility\'s zip code'
    },
    PrimaryContactFirstName: {
      type: 'string',
      title: 'Primary Contact First Name',
      minLength: 1,
      description: 'Type the facility primary contact\'s first name'
    },
    PrimaryContactLastName: {
      type: 'string',
      title: 'Primary Contact Last Name',
      minLength: 1,
      description: 'Type the facility primary contact\'s last name'
    },
    PrimaryContactEmail: {
      type: 'string',
      title: 'Primary Contact Email',
      minLength: 3,
      description: 'Type the facility\'s name',
      format: 'email'
    }
  }
};

const uiSchema = {

};

const styles = ({
  pageContainer: {
    width: '100%',
    marginTop: 20
  }
});

class FacilityEditPage extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
  }

  componentDidMount(){
    const{
      isNewFacility,
      facilityId,
      facility,
      dispatch
    } = this.props;

    if(!isNewFacility && StoreUtil.needsLoadNoCache(facility)){
      dispatch(getFacility(facilityId));
    }
  }

  onFormSubmit(data){
    const {isNewFacility, facilityId, dispatch} = this.props;
    const formData = data.formData;

    if(!isNewFacility){
      formData.id = facilityId;
    }else{
      delete formData.id;
    }

    dispatch(updateFacility(formData));
  }

  onFormError(err){
    console.log('on form error!!', err);
  }

  onFormChange(){}

  render() {
    const {
      classes,
      facility,
      isNewFacility,
      user
    } = this.props;

    //save happened, lets redirect
    if (isNewFacility && StoreUtil.didJustSave(facility)) {
      return (<Redirect to={NavigationUtil.userLink(user,
        "/facility/" + StoreUtil.getData(facility).id)} />);
    }

    const {
      status,
      failure
    } = FormUtil.GetLoadStatus(facility, 'facility');

    let facilityData = StoreUtil.getData(facility);
    return (
      <div className={classes.pageContainer}>
        <MaterialForm
          schema={schema}
          uiSchema={uiSchema}
          onChange={this.onFormChange}
          onSubmit={this.onFormSubmit}
          onError={this.onFormError}
          formData={facilityData}
          status={status}
          statusIsError={failure}
        />
      </div>
    );
  }
}

FacilityEditPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  facility: PropTypes.object.isRequired,
  isNewFacility: PropTypes.bool.isRequired,
  facilityId: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state, existingProps) {
  const{
    facilities,
    user
  } = state;

  const { match } = existingProps;
  let facilityId;
  try{
    facilityId = parseInt(match.params.facilityId);
    if(isNaN(facilityId)){
      facilityId = 0;
    }
  }catch(error){
    console.log(error);
    facilityId = 0;
  }

  const isNewFacility = facilityId == 0;
  let facility = null;
  if(!isNewFacility){
    facility = StoreUtil.get(facilities, StoreUtil.COMMON_ITEM, facilityId);
  }else{
    facility = StoreUtil.get(facilities, StoreUtil.COMMON_NEW_ITEM);
  }

  return {
    facility,
    isNewFacility,
    facilityId,
    user
  }

}

const styledPage = withStyles(styles)(FacilityEditPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as FacilityEditPage};
