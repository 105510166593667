
import StoreUtil from 'stores/StoreUtil';

class FormUtil{

  static GetLoadStatus(loadedDataItem, dataName){
    let status;
    let failure = false;
    if(StoreUtil.isSaveFailed(loadedDataItem)){
      status = 'There was an issue saving the '+dataName+'.  Please try again later.';
      failure = true;
    }else if(StoreUtil.isLoadFailed(loadedDataItem)){
      status = 'There was an issue retrieving the '+dataName+'.  Please try again later.';
      failure = true;
    }else if(StoreUtil.isSaved(loadedDataItem)){
      status = "Saved Successfully"
    }else if(StoreUtil.isSaving(loadedDataItem)){
      status = "Saving.."
    }

    return {
      status,
      failure
    };
  }
}

export {FormUtil as FormUtil}
export default FormUtil