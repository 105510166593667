import React from "react";
import PropTypes from "prop-types";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container:{
    width:'100%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column'
  },
  cPathway:{
    paddingTop:theme.spacing.unit * 2,
    paddingBottom:theme.spacing.unit * 2,
    paddingLeft:theme.spacing.unit,
    paddingRight:theme.spacing.unit,
    margin:0,
    flexDirection: 'row',
    alignItems:'center',
    justifyContent:'space-between'
  },
  cPathwayAlt:{
    backgroundColor: '#F5F5F5'
  },
  groupContainer:{
    flexDirection: 'row'
  },
  item:{
    marginRight: theme.spacing.unit * 5
  },
  inputLabel:{
    position:'relative',
    transform: 'none',
    lineHeight:'120%',
    width:'60%'
  },
  inputLabelPathway:{
    width:'60%'
  },
  helperLabel:{
  },
  helperLabelPathway:{
    position:'absolute',
    bottom:theme.spacing.unit * 2,
    right:theme.spacing.unit + 40
  }
});

function RadioWidget(props) {
  const {
    options,
    value,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    schema,
    rawErrors,
    classes
  } = props;
  // Generating a unique field name to identify this set of radio buttons
  const name = Math.random().toString();
  const { enumOptions/*, inline*/ } = options;
  // checked={checked} has been moved above name={name}, As mentioned in #349;
  // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
  /*let test = (
    <div className="field-radio-group">
      {enumOptions.map((option, i) => {
        const checked = option.value === value;
        const disabledCls = disabled || readonly ? "disabled" : "";
        const radio = (
          <span>
            <input
              type="radio"
              checked={checked}
              name={name}
              required={required}
              value={option.value}
              disabled={disabled || readonly}
              autoFocus={autofocus && i === 0}
              onChange={_ => onChange(option.value, _)}
            />
            <span>{option.label}</span>
          </span>
        );

        return inline ? (
          <label key={i} className={`radio-inline ${disabledCls}`}>
            {radio}
          </label>
        ) : (
          <div key={i} className={`radio ${disabledCls}`}>
            <label>{radio}</label>
          </div>
        );
      })}
    </div>
  );*/

  let containerClsList;
  let helperClsList;
  if(options.pathwayMode){
    helperClsList = `${classes.helperLabel} ${classes.helperLabelPathway}`;
    if(options.altRow){
      containerClsList = `${classes.container} ${classes.cPathway} ${classes.cPathwayAlt}`;
    }else{
      containerClsList = `${classes.container}  ${classes.cPathway}`;
    }
  }else{
    helperClsList = `${classes.helperLabel}`;
    containerClsList = `${classes.container}`;
  }

  return (
    <FormControl 
      required={required} 
      error={rawErrors != null}
      className={containerClsList}>
      <FormLabel className={classes.inputLabel} component="label">{schema.title}</FormLabel>
      <RadioGroup
        className={classes.groupContainer}
        aria-label={schema.title}
        name={name}
        value={value}
        onChange={(event,value)=>onChange(value)}
      >
        {enumOptions.map((option, i) => {
          return (
            <FormControlLabel 
              className={classes.item}
              key={i}
              value={option.value} 
              autoFocus={autofocus && i === 0}
              disabled={disabled || readonly}
              control={<Radio color="primary" />} 
              label={option.label} />
          );
        })}
      </RadioGroup>
      { rawErrors && <FormHelperText className={helperClsList} >Please Select Answer</FormHelperText> }
    </FormControl>
  );
}

RadioWidget.defaultProps = {
  autofocus: false
};

RadioWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool
  }).isRequired,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.any,
  classes: PropTypes.object.isRequired
};

const styled = withStyles(styles)(RadioWidget);
export {styled as RadioWidget};