import ReducerUtil from 'reducers/ReducerUtil'
import dotProp from 'dot-prop-immutable';
import { success } from 'redux-saga-requests';
import CSVUtil from 'util/CSVUtil'
import StoreUtil from 'stores/StoreUtil';
import DateUtil from 'util/DateUtil';

import {
  API_TABLE_HOSPITAL_EXPORT,
  SET_WORKING_UPLOAD_DATA,
  CLEAR_WORKING_UPLOAD_DATA,
  API_BULK_HOSPITAL_EXPORT,
  API_BULK_SURGERIES_SAVE,
  SET_WORKING_ROW_DATA,
  SET_WORKING_ENUM_MAP,
  API_TABLE_OLD_HOSPITAL_IMPORT,
  CLEAR_ERROR_WORKING_UPLOAD_DATA
} from 'actions/exportActions';

const getHospitalExportHash = (data, convertDateFromDisplay) => {
  if(convertDateFromDisplay){
    return data.Name
      +"::"+DateUtil.formatFromDB(data.BirthDate)
      +"::"+DateUtil.formatFromDB(data.DateofSurgery);
  }else{
    return data.Name+"::"+data.BirthDate+"::"+data.DateofSurgery;
  }
}

export const getObjWithLowerCaseKeys = (obj) => {
  // make all keys lowercase
  const lowerCaseKeys = {}
  for(let k in obj){
    lowerCaseKeys[k.toLowerCase()] = obj[k];
  }
  return lowerCaseKeys;
}

const doesExists = (surgeryByHash, hash) => {
  const lowerCaseKeys = getObjWithLowerCaseKeys(surgeryByHash);
  // check if the lower case hash is in lowerCaseKeys
  const exists = hash.toLowerCase() in lowerCaseKeys;
  return exists;
}

const isImportDataValid = (workingData, surgeryByHash) => {
  //check if have a surgery for each row
  let missingItems = 0;
  let hashesSeen = {};
  let hasMultipleRowsSameHash = false;
  let invalidPatients = [];

  workingData.forEach((row)=>{
    //working data NO longer has an id, have to check by name, dob, dos
    let hash = getHospitalExportHash(row, true);
    if(!hashesSeen[hash]){
      hashesSeen[hash] = true;
    }else{
      hasMultipleRowsSameHash = true;
    }

    const exists = doesExists(surgeryByHash, row.hash);
    if(!exists){
      const invalidPatientName = row.hash.split("::")[0];
      invalidPatients.push(invalidPatientName);
      missingItems++
    }
  });
  return {
    valid : !hasMultipleRowsSameHash && missingItems < 1,
    hasCollision:hasMultipleRowsSameHash,
    invalidPatients
  }
}

const markInvalidRows = (workingData, surgeryByHash, enumMap) => {
  let invalidRows = 0;
  const lowerCaseSurgeryByHash = getObjWithLowerCaseKeys(surgeryByHash);
  
  workingData.forEach((importedRow)=>{
    let dbData = lowerCaseSurgeryByHash[importedRow.hash.toLowerCase()];

    if(!importedRow.id){
      //set the id on the working data
      importedRow.id = dbData.id;
    }

    let invalidFields = CSVUtil.checkRowForInvalidFields(
      CSVUtil.HOSPITAL_HEADERS,
      importedRow,
      dbData,
      'DateofSurgery',
      enumMap);
    importedRow.invalid = invalidFields.length > 0;
    importedRow.invalidFields = invalidFields;
    if(importedRow.invalid){
      invalidRows++;
    }
  });

  return invalidRows;
}

const processWorkingDataState = (state) =>{
  const enumMap = state.enumMap;
  const surgeryBulk = StoreUtil.get(state,StoreUtil.COMMON_BULK);

  let unprocessedData;
  if(state.workingData && state.workingData.data){
    unprocessedData = state.workingData.data;
  }else{
    unprocessedData = state.unprocessedData;
  }

  if(!unprocessedData){
    return dotProp.set(state, "workingData", null);
  }

  let byId = StoreUtil.getData(surgeryBulk);
  if(!byId || !enumMap){
    return dotProp.set(state, "workingData",{
      processed:false,
      valid:true,
      invalidRows:0
    });
  }

  if(state.workingData && state.workingData.processed){
    return state;
  }

  let validityCheck = isImportDataValid(unprocessedData, byId);
  let invalidRows = 0;
  if(validityCheck.valid){
    invalidRows = markInvalidRows(unprocessedData, byId, enumMap);

    //sort desc dos
    if(unprocessedData){
      unprocessedData.forEach((row)=>{
        row.DOS = DateUtil.parseDateFromUser(row.DateofSurgery);
      })
      unprocessedData.sort((a,b)=>{
        return b.DOS.valueOf() - a.DOS.valueOf();
      })
    }
  }

  return dotProp.set(state, "workingData",{
    processed:true,
    valid:validityCheck.valid,
    hasCollision:validityCheck.hasCollision,
    invalidRows:invalidRows,
    invalidPatients: validityCheck.invalidPatients,
    data:unprocessedData
  });
}

const setIdOnStateData = (state, path, surgeryByHash) => {
  if(!state || !path || !surgeryByHash){
    return state;
  }
  let alteredState = state;
  let list = dotProp.get(state, path);
  if(!list || !Array.isArray(list)){
    console.log("CANNOT SET ID ON ",path, list);
    return state;
  }
  for(let x=0; x< list.length; x++){
    if(!list[x].id && surgeryByHash[list.hash]){
      alteredState = dotProp.set(
        alteredState, 
        `${path}.${x}.id`,
        surgeryByHash[list.hash].id);
    }
  }
  
  return alteredState;
}

export const exportData = (state = {}, action) => {
  const logout = ReducerUtil.reduceLogout(state, action, 'exportData');
  if(logout.actionConsumed){
    return logout.state;
  }
  
  const tableGet = ReducerUtil.reduceTableGet(state, action, API_TABLE_HOSPITAL_EXPORT);
  if(tableGet.actionConsumed){
    return tableGet.state;
  }

  const bulkGet = ReducerUtil.reduceBulkGet(state, action, API_BULK_HOSPITAL_EXPORT, getHospitalExportHash);
  if(bulkGet.actionConsumed){
    let newState = bulkGet.state;
    if(action.type == success(API_BULK_HOSPITAL_EXPORT)){
      //set the id on the unprocessed data
      if(newState.unprocessedData){
        const surgeryBulk = StoreUtil.get(state,StoreUtil.COMMON_BULK);
        const surgeryByHash = StoreUtil.getData(surgeryBulk);
        newState = setIdOnStateData(newState, "unprocessedData", surgeryByHash);
      }
      newState = processWorkingDataState(newState);
    }else if(action.type == API_BULK_HOSPITAL_EXPORT && state.workingData && state.workingData.processed){
      //loading surgeries, clear process
      newState = dotProp.set(state, 'workingData.processed', false);
    }
    return newState;
  }

  const oldImportGet = ReducerUtil.reduceListGet(state, action, API_TABLE_OLD_HOSPITAL_IMPORT);
  if(oldImportGet.actionConsumed){
    let newState = oldImportGet.state;
    if(action.type == success(API_TABLE_OLD_HOSPITAL_IMPORT)){
      //process date state
      let metaOldImport = StoreUtil.get(newState,StoreUtil.COMMON_LIST);
      let oldImportData = StoreUtil.getData(metaOldImport);
      if(oldImportData && Array.isArray(oldImportData) && oldImportData.length > 0){
        //set surgery id to id
        for(let x=0; x<oldImportData.length; x++){
          oldImportData[x].importId = oldImportData[x].id;
          oldImportData[x].id = oldImportData[x].SurgeryId;
          oldImportData[x].hash = getHospitalExportHash(oldImportData[x], true);
        }
        newState = dotProp.set(newState, 'unprocessedData', oldImportData);
        newState = dotProp.set(newState, StoreUtil.COMMON_BULK, null);
        newState = dotProp.set(newState, 'workingData', {
          processed:false
        });  
      }
    }
    return newState;
  }

  const bulkUpdate = ReducerUtil.reduceUpdate(state, action, API_BULK_SURGERIES_SAVE, 'id', 
    false, "surgeryBulkUpdate", "surgeryBulkUpdate");
  if(bulkUpdate.actionConsumed){
    if(action.type == success(API_BULK_SURGERIES_SAVE)){
      //clear working data
      state = dotProp.set(bulkUpdate.state, 'unprocessedData', null);
      return dotProp.set(state, 'workingData', null);
    }else{
      return bulkUpdate.state;
    }
  }

  switch(action.type){
  case CLEAR_WORKING_UPLOAD_DATA:{
    state = dotProp.set(state, 'unprocessedData', null);
    return dotProp.set(state, 'workingData', null);
  }
  case CLEAR_ERROR_WORKING_UPLOAD_DATA:{
    //clear out the error rows
    let errorRows = action.data;
    let removeHashes = {};

    errorRows.forEach((errorRow)=>{
      removeHashes[errorRow.hash] = true;
    });

    let oldWorkingData = state.workingData.data;
    let newUnprocessedData = [];
    oldWorkingData.forEach((row)=>{
      if(!removeHashes[row.hash]){
        newUnprocessedData.push(row);
      }
    });

    let newState = dotProp.set(state, 'unprocessedData', newUnprocessedData);
    //clear working data for reprocessing
    newState =  dotProp.set(newState, 'workingData', null);
    newState = processWorkingDataState(newState);
    //is there any data left?
    if(!newState.workingData.data || newState.workingData.data.length < 1){
      return dotProp.set(newState, 'workingData', null);
    }else{
      return newState;
    }
  }

  case SET_WORKING_ENUM_MAP:{
    state = dotProp.set(state, 'enumMap', action.enumMap);
    if(state.workingData && state.workingData.processed){
      state = dotProp.set(state, 'state.workingData.processed', false);
    }
    //check if process needed
    return processWorkingDataState(state);
  }
  case SET_WORKING_UPLOAD_DATA:{
    //process date state
    //add hashes
    if(action.data && Array.isArray(action.data)){
      for(let x=0; x<action.data.length; x++){
        action.data[x].hash = getHospitalExportHash(action.data[x], true);
      }
    }
    
    state = dotProp.set(state, 'unprocessedData', action.data);
    //clear surgeries bulk
    state = dotProp.set(state, StoreUtil.COMMON_BULK, null);
    return dotProp.set(state, 'workingData', {
      processed:false
    });
  }
  case success(SET_WORKING_UPLOAD_DATA):{
    let alteredState = state;
    //need to update with the import ids when they come in
    let serverAddedRows = action.data;
    if(serverAddedRows && Array.isArray(serverAddedRows)){
      let hashMap = {}
      for(let x=0; x<serverAddedRows.length; x++){
        serverAddedRows[x].hash = getHospitalExportHash(serverAddedRows[x], true);
        hashMap[serverAddedRows[x].hash] = serverAddedRows[x].id;
      }


      //set import id on unprocessed data
      if(alteredState.unprocessedData){
        for(let x=0; x<alteredState.unprocessedData.length; x++){
          alteredState = dotProp.set(
            alteredState, 
            `unprocessedData.${x}.importId`,
            hashMap[alteredState.unprocessedData[x].hash]);
        }
      }

      //set import id on working data
      if(alteredState.workingData 
        && alteredState.workingData.data){
        for(let x=0; x<alteredState.workingData.data.length; x++){
          alteredState = dotProp.set(
            alteredState, 
            `workingData.data.${x}.importId`,
            hashMap[alteredState.workingData.data[x].hash]);
        }
      }
    }else{
      console.log("ERROR : SUCCESS SET_WORKING_UPLOAD_DATA : Could not process import ids");
    }

    return alteredState;
  }
  case SET_WORKING_ROW_DATA:{
    //update just one row
    let row = action.data;
    row.invalid = false;
    row.invalidFields = [];

    //find the row, and replace
    let idx = -1;
    for(let x=0; x<state.workingData.data.length;x++){
      if(state.workingData.data[x].hash == row.hash){
        idx = x;
        break;
      }
    }
    if(idx > -1){
      state = dotProp.set(state, `workingData.data.${idx}`, action.data);
    }else{
      console.log("ERROR UPDATING WORKING DATA ROW!!! NOT FOUND");
    }
    
    //change row count
    return dotProp.set(state, "workingData.invalidRows", (state.workingData.invalidRows-1));
  }


  }
  return state;
}
