import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { tableHospitalVisitsForAdmission } from 'actions/hospitalVisitActions';
import { DynamicTable } from 'components/DynamicTable';
import { push } from 'connected-react-router';
import StoreUtil from 'stores/StoreUtil'
import { saveTablePreference } from 'actions/preferenceActions';
import DateUtil from 'util/DateUtil'
import NavigationUtil from 'util/NavigationUtil';

const styles = theme => ({
  container: {
    minHeight:50,
    width:'100%'
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  }
});

function dateFormat(date) {
  return DateUtil.formatFromDB(date);
}
function secondaryReasonsFormat(reasons) {
  if(reasons){
    let result = "";
    reasons.forEach((reason)=>{
      result += reason.Reasons.Text+" ";
    });
    return result;
  }else{
    return "";
  }
}
//this controls which columns are displayed and how they are looked up in data
const columns = [
  {label:"Date", key: "Date", sortable: true, sortDown: false, format: dateFormat},
  {label:"Type", key: "Status", sortable: true, sortDown: false},
  {label:"Facility", key: "ReadmissionFacility.Name", sortable: true, sortDown: false},
  {label:"Notes/Treatments", key: "ReadmissionNotes", sortable: true, sortDown: false},
  {label:"Primary Reason", key: "Reason.Text", sortable: true, sortDown: false},
  {label:"Secondary Reasons", key: "SecondaryReasons", sortable: false, 
    sortDown: false, format: secondaryReasonsFormat}
];

const TABLE_NAME = "AdmissionHospitalVisitsView";
class AdmissionHospitalVisitsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };

    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.fetchInitial();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.admission != null &&
      (prevProps.admission == null || this.props.admission.id != prevProps.admission.id )) {
      this.fetchInitial();
    }
  }

  fetchInitial() {
    this.setState({query: this.buildInitialQuery() }, this.fetch.bind(this));
  }

  buildInitialQuery() {
    const {
      preferenceQuery,
      admission
    } = this.props;

    let query = {
      include: [
        {
          association: "ReadmissionFacility",
          attributes: [
            "id",
            "Name"
          ]
        },
        {
          association: "Reason",
          attributes: [
            "id",
            "Text"
          ]
        },
        {
          association: "SecondaryReasons",
          attributes: ["id"],
          include:[
            {
              association: "Reasons",
              attributes: [
                "id",
                "Text"
              ]
            }
          ]
        }
      ],
      where: { },
      order: [["Date", "DESC"]],
      offset: 0,
      limit:100
    };

    if(preferenceQuery){
      query = preferenceQuery
    }

    query.where.AdmissionId = admission.id;

    return query;
  }

  fetch() {
    const { dispatch} = this.props;
    const { query } = this.state;

    dispatch(tableHospitalVisitsForAdmission(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query }, this.fetch.bind(this));
  }

  onRowClick(row){
    const { admission, dispatch, isPAC } = this.props;
    if(isPAC){
      const path =
      `/admission/${admission.id}`
      +`/ed/${row.id}`;

      const url = NavigationUtil.pacLink(path);
      dispatch(push(url));
    }
  }

  render() {
    const {
      query
    } = this.state;

    const {
      classes,
      list,
      isPAC
    } = this.props;

    let title;
    if(isPAC){
      title = "ED Visits";
    }else{
      title = "QCAP Readmissions";
    }
    return (
      <React.Fragment>

        <DynamicTable
          header={
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="headline">{title}</Typography>
            </div>
          }
          data={list}
          query={query}
          showFilters={false}
          onQueryChange={this.onQueryChange.bind(this)}
          columns={columns}
          onRowClick={this.onRowClick}
        />
      </React.Fragment>
    );
  }
}

AdmissionHospitalVisitsView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  admission: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  isPAC: PropTypes.bool
}

function mapStateToProps(state) {
  const { hospitalVisits, preference } = state;

  let list = StoreUtil.get(hospitalVisits, "admission");
  return {
    list: list,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styled = withStyles(styles)(AdmissionHospitalVisitsView);
const connected = connect(mapStateToProps)(styled);
export { connected as AdmissionHospitalVisitsView };
