import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { NumberCard } from 'components/NumberCard';

import { openMessageCount } from 'actions/messageActions';

import NavigationUtil from 'util/NavigationUtil'
import StoreUtil from 'stores/StoreUtil'

const styles = ({
  card: {
    
  }
});

class MessagesCountView extends React.Component {

  componentDidMount() {
    this.updateCount();
  }

  componentDidUpdate(prevProps) {
    //view as user changed
    if (this.props.viewAsUser != null && 
      (prevProps.viewAsUser == null || 
        (this.props.viewAsUser.id != prevProps.viewAsUser.id 
          || this.props.viewAsUser.FacilityId != prevProps.viewAsUser.FacilityId ))) {
      this.updateCount();
    }
  }

  updateCount() {
    const {
      dispatch,
      viewAsUser,
      counts
    } = this.props;
    
    if(StoreUtil.needsLoadNoCache(counts)){ 
      dispatch(openMessageCount(viewAsUser.id, viewAsUser.FacilityId));
    }
  }

  render() {
    const {
      counts,
      type,
      pacMode
    } = this.props;
    
    let title;
    let typePath;
    const planChangeMode = type != "other";
    let openCount = 0;
    let unreadCount = 0;
    let data = StoreUtil.getData(counts);
    if (!planChangeMode) {
      title = "Other Messages";
      typePath="other";
      if(data){
        openCount = data.otherOpenCount;
        unreadCount = data.otherUnreadCount;
      }
    } else {
      title = "Change Messages";
      typePath="plan";
      if(data){
        openCount = data.planOpenCount;
        unreadCount = data.planUnreadCount;
      }
    }

    let link;
    if(pacMode){
      link = NavigationUtil.pacLink("/conversations/"+typePath);
    }else{
      link = NavigationUtil.navLink("/conversations/"+typePath);
    }

    let cardColor;

    if(unreadCount > 0){
      cardColor = 'orange';
    }

    return (
      <NumberCard
        title={title}
        number={openCount}
        anotherNumber={unreadCount}
        action="View"
        path={link}
        cardColor={cardColor}
      />
    );
  }
}

MessagesCountView.defaultProps = {
  pacMode: false
};

MessagesCountView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  counts: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  pacMode: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const { 
    messages,
    user
  } = state;

  var { viewAsUser } = user;
  if(viewAsUser == null) {
    viewAsUser = user;
  }
  
  return {
    counts: StoreUtil.get(messages, "counts", viewAsUser.id),
    viewAsUser
  };
}

const styled = withStyles(styles)(MessagesCountView);
const connected = connect(mapStateToProps)(styled);
export { connected as MessagesCountView }; 