import ReducerUtil from 'reducers/ReducerUtil'

import {
  API_TABLE_RISKSCOREAUDIT
} from 'actions/riskScoreAuditActions';

export const riskScoreAudit = (state = {}, action) => {
  const tableGet = ReducerUtil.reduceTableGet(state, action, API_TABLE_RISKSCOREAUDIT);
  if(tableGet.actionConsumed){
    return tableGet.state;
  }

  return state;
}
  