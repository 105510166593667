import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { getPertinentPositivesForSurgeryDetailed } from 'actions/positivesActions';
import { LoadingView } from 'components/LoadingView'
import StoreUtil from 'stores/StoreUtil';
import NavigationUtil from 'util/NavigationUtil'
import QuestionGroupUtil from 'util/QuestionGroupUtil'

const styles = (theme) => ({
  container: {
    display:'flex',
    flexDirection:'column',
    padding : theme.spacing.unit*2
  },
  headerContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  },
  listContainer:{
    width:'100%',
    display:'flex',
    flexDirection:'column',
    flexWrap: 'wrap'
  },
  header: {
    marginRight: theme.spacing.unit * 2,
    flex: 1
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  subContainer: {
    paddingLeft: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width:'100%'
  },
  rowContainer: {
    width:'100%'
  },
  buttonColumn: {
    width:'30%',
    display:'inline-block',
    verticalAlign:'top',
    marginRight:theme.spacing.unit
  },
  linkStyle: {
    marginLeft:theme.spacing.unit,
    textDecoration: 'underline'
  },
  answersContainer:{
    margin:theme.spacing.unit*2
  },
  answers:{
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    marginBottom:theme.spacing.unit,
    backgroundColor:'#f5f5f5'
  },
  answerTitle:{
    fontWeight:550,
    paddingLeft:theme.spacing.unit
  },
  answerText:{
    margin:0,
    color:'black',
    fontSize:'0.8rem',
    paddingLeft:theme.spacing.unit,
    paddingBottom:3
  },
  subheading:{
    fontWeight:'bold',
    backgroundColor:'#f5f5f5',
    marginRight:theme.spacing.unit,
    paddingLeft:theme.spacing.unit
  },
  answerSingle:{
    display:'inline'
  },
  answerDiv:{
    marginLeft:theme.spacing.unit,
    marginRight:theme.spacing.unit
  }
});

class PertinentPositivesView extends React.Component {

  componentDidMount(){
    //check if data load needed
    const {
      positivesInfo,
      dispatch,
      surgery
    } = this.props;

    if(StoreUtil.needsLoadNoCache(positivesInfo)){
      dispatch(getPertinentPositivesForSurgeryDetailed(surgery.id));
    }
  }

  createPostivesRow(postives){
    const { classes,
      surgery
    } = this.props;

    let answered = [];
    return (
      <div className={classes.positivesGroup}>
        <div className={classes.rowContainer}>
          {postives.map((pp) => {
            const hasQuestions = pp.PertinentPositive.QuestionGroup
              && pp.PertinentPositive.QuestionGroup.Questions
              && pp.PertinentPositive.QuestionGroup.Questions.length>0

            let isStarted = false;

            if(hasQuestions){
              pp.PertinentPositive.QuestionGroup.Questions.forEach((question)=>{
                if(question.Answers
                  && question.Answers.length>0
                  && question.Answers[0].AnswerValues
                  && question.Answers[0].AnswerValues.length
                  && question.Answers[0].AnswerValues.length>0
                  && question.Answers[0].AnswerValues[0].Value){
                  isStarted = true;
                }
              })
            }
            
            
            if(!pp.PertinentPositive.QuestionGroup
              || (!isStarted && pp.PertinentPositive.QuestionGroup.IsHidden)){
              return (
                <div key={pp.PertinentPositiveId} className={classes.buttonColumn}>
                  <Button
                    style={{pointerEvents: 'none'}}
                    key={pp.PertinentPositiveId}
                    color="primary"
                    size="small"
                    variant="text">
                    {pp.PertinentPositive.Name}
                  </Button>
                </div>
              );
            }else{
              let buttonText;
              if (isStarted) {
                answered.push(pp);
                buttonText = "(view/edit)";
              } else {
                buttonText = "(start)";
              }
              let formId;
              if(pp.PertinentPositive.QuestionGroup && pp.PertinentPositive.QuestionGroup.id){
                formId = pp.PertinentPositive.QuestionGroup.id;
              }
              const link = NavigationUtil.navLink(`/surgery/${surgery.id}/form/${formId}`);

              return (
                <div key={pp.PertinentPositiveId} className={classes.buttonColumn}>
                  <Button
                    key={pp.PertinentPositiveId}
                    color="primary"
                    size="small"
                    variant="text"
                    component={Link} to={link}>
                    {pp.PertinentPositive.Name}  <b className={classes.linkStyle}>{buttonText}</b>
                  </Button>
                </div>
              );
            }
          })}
        </div>
        {answered.length > 0 &&
          <div className={classes.answersContainer}>
            {answered.map((pp) => {
              let responses = [];

              pp.PertinentPositive.QuestionGroup.Questions.forEach((question)=>{
                if(question.Answers
                  && question.Answers.length>0
                  && question.Answers[0].AnswerValues
                  && question.Answers[0].AnswerValues.length
                  && question.Answers[0].AnswerValues[0].Value){
                  responses.push({
                    question: question.Question,
                    answer: question.Answers[0].AnswerValues[0].Value,
                    key: question.Answers[0].AnswerValues[0].id+"answer"
                  });
                }
              })
              let nameText = pp.PertinentPositive.Name;
              if(pp.PertinentPositiveValue && pp.PertinentPositiveValue.Value){
                nameText += ": "+pp.PertinentPositiveValue.Value
              }
              return (
                <div key={`answers-${pp.PertinentPositiveId}`} className={classes.answers}>
                  <Typography variant='body2' className={classes.answerTitle}>{nameText}</Typography>
                  <Typography variant='caption' className={classes.answerText} >
                    {
                      QuestionGroupUtil.renderResponses(responses, classes)
                    }
                  </Typography>
                </div>
              );
            })}
          </div>
        }
      </div>
    );
  }

  render() {
    //find surgery
    const {
      classes,
      surgery,
      positivesInfo
    } = this.props;

    if(!StoreUtil.getData(positivesInfo)){
      return (<LoadingView />);
    }else if(StoreUtil.isLoadFailed(positivesInfo)){
      return (
        <Typography
          className={classes.headerContainer}
          variant='title' color='error'>
          There was an issue loading Pertinent Positives.
        </Typography>
      );

    }else{
      let positivesInfoData = StoreUtil.getData(positivesInfo);
      let medicalPositives = [];
      let socialPositives = [];
      if(positivesInfoData && positivesInfoData.length>0){
        positivesInfoData.forEach((pp)=>{
          if(pp.PertinentPositive.Type.toLowerCase() == 'medical'){
            medicalPositives.push(pp);
          }else{
            socialPositives.push(pp);
          }
        });
      }

      const positivesEditLink = NavigationUtil.navLink(`/surgery/${surgery.id}/positivesEdit/`);

      let medicalArea;
      if(medicalPositives.length > 0){
        medicalArea = (
          <div className={classes.subContainer}>
            <Typography className={classes.subheading} variant="subheading">Medical</Typography>
            {this.createPostivesRow(medicalPositives)}
          </div>
        );
      }
      let socialArea;
      if(socialPositives.length > 0){
        socialArea = (
          <div className={classes.subContainer}>
            <Typography  className={classes.subheading} variant="subheading">Social</Typography>
            {this.createPostivesRow(socialPositives)}
          </div>
        );
      }

      let listingArea;
      if(medicalArea || socialArea){
        listingArea = (
          <div className={classes.listContainer}>
            {medicalArea}
            {socialArea}
          </div>
        );
      }

      return (
        <Paper className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography className={classes.header} variant="headline">Pertinent Positives</Typography>
            <Button
              color="primary"
              size="small"
              component={Link} to={positivesEditLink}>
              <Icon className={classes.leftIcon}>add</Icon>
              Add/Remove Positives
            </Button>
          </div>

          {listingArea}
        </Paper>
      );
    }
  }
}

PertinentPositivesView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  surgery: PropTypes.object.isRequired,
  positivesInfo: PropTypes.object.isRequired
}

function mapStateToProps(state, existingProps) {
  const { surgery } = existingProps;
  const { positives } = state;
  let positivesInfo = StoreUtil.get(positives, 'detailed', surgery.id);

  return {
    positivesInfo
  };
}

const styled = withStyles(styles)(PertinentPositivesView);
const connected = connect(mapStateToProps)(styled);
export { connected as PertinentPositivesView };
