import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { HUDView } from 'containers/HUDView';
import { DynamicTable } from 'components/DynamicTable';
import { tasksToday, editTask, editPathway } from 'actions/tasksActions';
import StoreUtil from 'stores/StoreUtil';
import DateUtil from 'util/DateUtil';
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';
import TableUtil from 'util/TableUtil';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = ({
  pageContainer:{
    width: "100%"
  }
});

const columns = [
  {label:"MRN", key: "PatientsMedicalRecNum", sortable: true, sortDown: false, style:{width:60}},
  {label:"Last Name", key: "PatientsLastName", sortable: true, sortDown: false},
  {label:"First Name", key: "PatientsFirstName", sortable: true, sortDown: false},
  {label:"Surgery", key: "SurgeriesSurgeryDate",
    type:"date", sortable: true, format: DateUtil.formatFromDB, style:{width:200}
  },
  {label:"Task Type", key: "TaskTypeName", sortable: true, sortDown: false, style:{width:240}},
  {label:"Scheduled", key: "TasksScheduledFor", type:"date", sortable: true,
    format: DateUtil.formatFromDB, style:{width:240}},
  {label:"Status", key: "Status", sortable: true, sortDown: false},
  {label:"Notes", key: "StatusNote", sortable: true, sortDown: false, style: {width:400}},
  {label:"Visit Type", key: "SurgeryType", sortable: true, sortDown: false},
  {label:"Surgeon", key: "SurgeonName", sortable: true, sortDown: false},
  {label:"Discharge Disposition", key: "DischargeDisposition", sortable: true, sortDown: false, style: {width:200}}
];

var TABLE_NAME = "TasksTodayPage";
class TasksTodayPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this.fetchInitialTasks();
  }

  componentDidUpdate(prevProps) {
    //task type changed in route
    if (this.props.match.params.taskTypeName != prevProps.match.params.taskTypeName ||

      //or view as user changed
      (this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id ))) {

      this.fetchInitialTasks();
    }
  }

  fetchInitialTasks() {
    this.setState({query: this.buildInitialQuery() }, this.fetchTasks.bind(this));
  }

  buildInitialQuery(clearingFilters = false) {
    const {
      viewAsUser,
      match,
      preferenceQuery
    } = this.props;

    let query = {
      where: {},
      order: [
        ['TasksScheduledFor','ASC']
      ],
      limit: 50,
      offset: 0
    };

    if(preferenceQuery && !clearingFilters){
      query = preferenceQuery
    }

    query.where["EpisodesNavigatorUserId"] = viewAsUser.id;

    if (match.params.taskTypeName) {
      let taskType = match.params.taskTypeName;
      query.where.TaskTypeName = { $eq: taskType };
    } else {
      query.where.$or = [ {Automated: 0}, {Priority: 'High'} ];
    }
    
    return query;
  }

  fetchTasks() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(tasksToday(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchTasks.bind(this));
  }

  onRowClick(task) {
    const { dispatch } = this.props;
    if(task.QuestionGroupId || task.Automated){
      if(task.TaskTypeName == 'Post-Op'){
        dispatch(editPathway(task, true));
      } else{
        dispatch(editPathway(task));
      }
    } else {
      dispatch(editTask(task.SurgeryId, task.TaskId));
    }
  }

  onTabChange() {
    const clearingFilters = true;
    const query = this.buildInitialQuery(clearingFilters);
    this.onQueryChange(query);
  }

  render() {
    const {
      classes,
      tasks,
      match,
      tab
    } = this.props;

    const {
      query
    } = this.state;

    let header = 'Today\'s Tasks';

    if(match.params.taskTypeName) {
      header = 'Today\'s ' + match.params.taskTypeName + ' Tasks';
    }

    return (
      <div className={classes.pageContainer}>

        <HUDView />

        <Tabs value={tab} onChange={this.onTabChange}>
          <Tab label="Clear Filters" style={TableUtil.clearFilterStyles()}/>
        </Tabs>

        <DynamicTable
          columns={columns}
          data={tasks}
          header={header}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          idKey="TaskId"
        />

      </div>
    );
  }
}

TasksTodayPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  taskType: PropTypes.object,
  viewAsUser: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object,
  tab: PropTypes.number.isRequired
};

function mapStateToProps(state, existingProps) {
  const {
    tasks,
    user,
    preference
  } = state;

  const { match } = existingProps;

  var { viewAsUser } = user;
  if(viewAsUser == null){
    viewAsUser = user;
  }

  if (match && match.params && match.params.taskTypeName) {
    TABLE_NAME = 'TasksTodayPage' + match.params.taskTypeName;
  } else{
    TABLE_NAME = 'TasksTodayPage';
  }
  
  let taskList = StoreUtil.get(tasks, "todayView");
  return {
    tasks: taskList,
    viewAsUser,
    preferenceQuery:preference.tables[TABLE_NAME],
    tab: 0
  };
}

const styledPage = withStyles(styles)(TasksTodayPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as TasksTodayPage};
