import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { HUDView } from 'containers/HUDView';
import { ConversationsTable } from 'components/ConversationsTable';
import StoreUtil from 'stores/StoreUtil';
import DataUtil from 'util/DataUtil';
import { listConversations, viewConversation } from 'actions/messageActions';
import { saveTablePreference } from 'actions/preferenceActions';
import { viewSurgery } from 'actions/surgeryActions';

const styles = ({
  pageContainer:{
    width: "100%"
  }
});


const TABLE_NAME = "ConversationsPage";

class ConversationsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {}
    };
  }

  componentDidMount() {
    this.fetchInitialConversations();
  }

  componentDidUpdate(prevProps) {
    //task type changed in route
    if ((this.props.viewAsUser != null &&
      (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id ))
      || (prevProps.planChangeMode != this.props.planChangeMode) ) {

      this.fetchInitialConversations();
    }
  }

  fetchInitialConversations() {
    this.setState({query: this.buildInitialQuery() }, this.fetchConversations.bind(this));
  }

  buildInitialQuery() {
    const {
      viewAsUser,
      planChangeMode,
      preferenceQuery
    } = this.props;

    let query = {
      limit: 25,
      offset: 0,
      order:[['LastMessageDate','DESC']],
      where:{
        Status: {
          $ne: 'Resolved'
        }
      }
    };
    if(preferenceQuery){
      query = preferenceQuery
    }

    query.where["NavigatorUserId"] = viewAsUser.id;
    
    if(planChangeMode){
      query.where.ConversationType = DataUtil.CONVERSATION_TYPE_PLAN_CHANGE;
    }else{
      query.where.ConversationType = DataUtil.CONVERSATION_TYPE_OTHER;
    }
    return query;
  }

  fetchConversations() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(listConversations(query));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchConversations.bind(this));
  }

  onRowClick(conversation) {
    const { dispatch, planChangeMode } = this.props;

    if(planChangeMode){
      let surgery = { id: conversation.SurgeryId }
      dispatch(viewSurgery(surgery));
    }else{
      dispatch(viewConversation(conversation.SurgeryId, conversation.AdmissionId, conversation.id));
    }
  }

  render() {
    const {
      classes,
      conversations,
      planChangeMode
    } = this.props;

    const {
      query
    } = this.state;

    let header;
    if(planChangeMode){
      header = "Plan Change Rationale";
    }else{
      header = "Other Messages";
    }
    return (
      <div className={classes.pageContainer}>

        <HUDView />

        <ConversationsTable
          data={conversations}
          header={header}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          isPlanMode={planChangeMode}
        />

      </div>
    );
  }
}

ConversationsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  conversations: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired,
  planChangeMode : PropTypes.bool.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state, prevProps) {
  const {
    messages,
    user,
    preference
  } = state;

  const {
    match
  } = prevProps;

  const planChangeMode = match.params.conversationType == "plan";

  var { viewAsUser } = user;
  if(viewAsUser == null){
    viewAsUser = user;
  }

  return {
    conversations: StoreUtil.get(messages, "conversations"),
    viewAsUser,
    planChangeMode,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(ConversationsPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as ConversationsPage};
