export const API_LIST_DISCHARGEDISPOSITIONS = 'API_LIST_DISCHARGEDISPOSITIONS';

export const listDischargeDispositions = ()=> {
  return {
    type: API_LIST_DISCHARGEDISPOSITIONS,
    request: {
      url: '/DischargeDispositions',
      method: 'GET'
    }
  }
};
