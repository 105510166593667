import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { NavUserViewAsDialog } from 'containers/NavUserViewAsDialog';

import { updateViewAsNavUser } from 'actions/navUserActions';

const styles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  icon :{
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    fontSize: 20
  }
});

class ViewAsUserView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      viewAsDialogOpen: false
    };

    this.handleDialogClickOpen = this.handleDialogClickOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
  }	

  handleDialogClickOpen(){
    this.setState({
      viewAsDialogOpen: true
    });
  }

  handleDialogClose(selectedUser){
    this.setState({ viewAsDialogOpen: false });
    const {
      dispatch,
      viewAsUser
    } = this.props;

    if(viewAsUser.id != selectedUser.id){
      dispatch(updateViewAsNavUser(selectedUser));
    }
  }

  render() {
    const {
      classes,
      viewAsUser
    } = this.props;

    let viewAsName;
    if(viewAsUser){
      viewAsName = viewAsUser.Username;
      if(viewAsUser.LastName && viewAsUser.FirstName){
        viewAsName = viewAsUser.FirstName.trim() + " " + viewAsUser.LastName.trim();
      }
    }else{
      viewAsName = 'Unknown';
    }
    return (
      <div className={classes.root}>
        <Button 
          onClick={this.handleDialogClickOpen}
          size="small"
          color="inherit">
          {viewAsName}
          <Icon className={classes.icon}>arrow_drop_down</Icon>
        </Button>
        <NavUserViewAsDialog
          selectedUser={viewAsUser}
          open={this.state.viewAsDialogOpen}
          onClose={this.handleDialogClose}
        />
      </div>
    );
  }
}

ViewAsUserView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  viewAsUser: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  const { user } = state;
  let { viewAsUser } = user;
   
  if(viewAsUser == null){
    viewAsUser = user;
  }
  
  return {
    viewAsUser
  };
}

const styled = withStyles(styles)(ViewAsUserView);
const connected = connect(mapStateToProps)(styled);
export { connected as ViewAsUserView }; 