import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { HUDView } from 'containers/HUDView';
import { DynamicTable } from 'components/DynamicTable';
import { get90DayDischarge } from 'actions/discharge90DayPageActions';
import { viewSurgery } from 'actions/surgeryActions'
import { withStyles } from '@material-ui/core/styles';
import { saveTablePreference } from 'actions/preferenceActions';

const styles = ({
  pageContainer:{
    width: "100%"
  }
});

const columns = [
  {label:"Admission Id", key: "AdmissionID", sortable: true, sortDown: false, style:{width:120}},
  {label:"Alert Type", key: "AlertType", sortable: true, sortDown: false},
  {label:"Last Name", key: "PatientsLastName", sortable: true, sortDown: false},
  {label:"First Name", key: "PatientsFirstName", sortable: true, sortDown: false},
  {label:"MRN", key: "PatientsMedicalRecNum", sortable: true, sortDown: false, style:{width:60}},
  {label:"Surgery Id", key: "SurgeryID", sortable: true, sortDown: false, style:{width:120}}
];

const TABLE_NAME = "Discharge90DayPage";
class Discharge90DayPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      query: {
        limit: 50,
        offset: 0
      }
    };
  }

  componentDidMount() {
    this.fetchInitialDischarge90Day();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewAsUser != null &&
    (prevProps.viewAsUser == null || this.props.viewAsUser.id != prevProps.viewAsUser.id )) {

      this.fetchInitialDischarge90Day();
    }
  }

  fetchInitialDischarge90Day() {
    this.setState({query: this.buildInitialQuery() }, this.fetchlDischarge90Day(this));
  }

  buildInitialQuery() {

    let query = {
      limit: 50,
      offset: 0
    };

    return query;
  }

  fetchlDischarge90Day() {
    const { dispatch, viewAsUser } = this.props;
    const { query } = this.state;

    dispatch(get90DayDischarge(query, viewAsUser.id));
  }

  onQueryChange(query) {
    const { dispatch } = this.props;
    dispatch(saveTablePreference(TABLE_NAME, query));
    this.setState({query: query}, this.fetchlDischarge90Day.bind(this));
  }

  onRowClick(discharge90Day) {
    const { dispatch } = this.props;

    let surgery = { id: discharge90Day.SurgeryID }

    dispatch(viewSurgery(surgery))
  }

  render() {
    const {
      classes,
      discharge90Day
    } = this.props;

    const {
      query
    } = this.state;

    let header = '90/DC forms';

    return (
      <div className={classes.pageContainer}>

        <HUDView />

        <DynamicTable
          columns={columns}
          data={discharge90Day}
          header={header}
          query={query}
          onQueryChange={this.onQueryChange.bind(this)}
          onRowClick={this.onRowClick.bind(this)}
          idKey="id"
        />

      </div>
    );
  }
}

Discharge90DayPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  discharge90Day: PropTypes.object,
  viewAsUser: PropTypes.object.isRequired,
  preferenceQuery: PropTypes.object
};

function mapStateToProps(state) {
  const {
    discharge90Day,
    user,
    preference
  } = state;

  var { viewAsUser } = user;
  if(!viewAsUser){
    viewAsUser = user;
  }

  return {
    discharge90Day,
    viewAsUser,
    preferenceQuery:preference.tables[TABLE_NAME]
  };
}

const styledPage = withStyles(styles)(Discharge90DayPage);
const connectedPage = connect(mapStateToProps)(styledPage);
export {connectedPage as Discharge90DayPage};
